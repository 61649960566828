import { defineMessages } from "react-intl";

export default defineMessages({
  addToTeamTitle: {
    id: "PageNewTraining.addToTeamTitle",
    defaultMessage: "(Optional) Add player to team",
  },
  save: {
    id: "PageNewTraining.save",
    defaultMessage: "Save",
  },
  skip: {
    id: "PageNewTraining.skip",
    defaultMessage: "Skip",
  },
  email: {
    id: "PageNewTraining.email",
    defaultMessage: "Email",
  },
  name: {
    id: "PageNewTraining.Name",
    defaultMessage: "Name",
  },
  shortDescription: {
    id: "PageNewTraining.shortDescription",
    defaultMessage: "Short description",
  },
  nickname: {
    id: "PageNewTraining.Nickname",
    defaultMessage: "Nickname",
  },
  number: {
    id: "PageNewTraining.Number",
    defaultMessage: "Number",
  },
  dateOfBirth: {
    id: "PageNewTraining.DateOfBirth",
    defaultMessage: "Date of birth (yyyy-mm-dd)",
  },
  password: {
    id: "PageNewTraining.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "PageNewTraining.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  passwordError: {
    id: "PageNewTraining.PasswordError",
    defaultMessage: "Password requirements not fulfilled",
  },
  passwordNotMatch: {
    id: "PageNewTraining.PasswordNotMatch",
    defaultMessage: "Passwords do not match",
  },
  requiredFieldError: {
    id: "PageNewTraining.RequiredFieldError",
    defaultMessage: "Required field",
  },
  newTraining: {
    id: "PageNewTraining.newTraining",
    defaultMessage: "New Training",
  },
  loginLinkMessage: {
    id: "PageNewTraining.loginLink",
    defaultMessage: "Already have an account?",
  },
  login: {
    id: "PageNewTraining.login",
    defaultMessage: "Sign in",
  },
  passwordHintTitle: {
    id: "PageNewTraining.passwordHintTitle",
    defaultMessage: "Password field",
  },
  passwordHintMustBe: {
    id: "PageNewTraining.passwordHintMustBe",
    defaultMessage: "Your password must:",
  },
  passwordHint8Characters: {
    id: "PageNewTraining.passwordHint8Characters",
    defaultMessage: "Be at least 8 characters long",
  },
  passwordHint1Uppercase: {
    id: "PageNewTraining.passwordHint1Uppercase",
    defaultMessage: "Contain at least 1 upper case character",
  },
  passwordHint1Lowercase: {
    id: "PageNewTraining.passwordHint1Lowercase",
    defaultMessage: "Contain at least 1 lower case character",
  },
  passwordHint1Number: {
    id: "PageNewTraining.passwordHint1Number",
    defaultMessage: "Contain at least 1 number",
  },
  passwordHint1SpecialCharacter: {
    id: "PageNewTraining.passwordHint1SpecialCharacter",
    defaultMessage: "Contain at least 1 special character",
  },
  passwordHintNotSameEmail: {
    id: "PageNewTraining.passwordHintNotSameEmail",
    defaultMessage: "Not be the same as your email address",
  },
  next: {
    id: "PageNewTraining.next",
    defaultMessage: "Next",
  },
  category: {
    id: "PageNewTraining.category",
    defaultMessage: "Category",
  },
  subcategory: {
    id: "PageNewTraining.subcategory",
    defaultMessage: "Subcategory",
  },
  team: {
    id: "PageNewTraining.team",
    defaultMessage: "team",
  },
  details: {
    id: "PageNewTraining.details",
    defaultMessage: "Details",
  },
  addExercise: {
    id: "PageNewTraining.addExercise",
    defaultMessage: "Add Exercise",
  },
  finish: {
    id: "PageNewTraining.finish",
    defaultMessage: "Save Training",
  },
});
