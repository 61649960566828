import React from "react";
// import { FormattedMessage } from "react-intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
import { ITeam, IPlayer } from "../../types";
import EditIcon from "@material-ui/icons/EditOutlined";
// import RemoveIcon from "@material-ui/icons/Remove";
import {
  Card,
  Grid,
  // List,
  // ListItem,
  ListItemText,
  // Badge,
  // makeStyles,
  // Theme,
  Fab,
  Chip,
} from "@material-ui/core";
import Link from "../link";
// import messages from "./GroupSummaryCard.intl";
import UserAvatar from "../user-avatar";
import Button from "../button";
import { ActionLink } from "../atoms";
import useIsManager from "../../hooks/useIsManager";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";

// const useStylesList = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper,
//     },
//     inline: {
//       display: "inline",
//     },
//     badge: {
//       margin: theme.spacing(1),
//     },
//   })
// );

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  //   height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
  padding: theme.spacing(2),
}));

const Title = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
  currentUser: {
    id: string;
    role: string;
  };
}

const SquadCard = injectIntl<ITeamSummaryCardProps>(
  ({ id, team, currentUser, intl }) => {
    // const classesList = useStylesList();
    return (
      <CardWrapper data-testid={`group-summary-${id}`}>
        <Header>
          <Row>
            <Grid item xs={8}>
              <Title to={`/teams/${team.id}`}>
                {intl.formatMessage(messages.squad)}:
              </Title>
            </Grid>
          </Row>
        </Header>
        <Content>
          {/* <Row> */}
          {/* <Grid item xs={12}>
            <List className={classesList.root}> */}
          {team.squad &&
            team.squad
              // .sort((a, b) => b.totalGoals - a.totalGoals)
              .map((player: IPlayer) => (
                <>
                  {/* <ListItem
                    style={{ flex: 1, justifyContent: "space-between" }}
                  > */}
                  <Grid item xs={6} sm={3}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        marginBottom: "2em",
                        backgroundColor: "#FAFAFA",
                        marginRight: "2em",
                        borderRadius: "8px",
                        padding: "8px",
                        border: "dashed 1px #F5F3F3",
                        minHeight: "190px",
                        justifyContent: "space-between",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        {/* <Badge
                        color="secondary"
                        badgeContent={player.totalGoals}
                        className={classesList.badge}
                      > */}
                        <ActionLink to={`/players/${player.id}/information`}>
                          <UserAvatar
                            style={{ width: 80, height: 80, fontSize: "3rem" }}
                            user={{
                              name: player.name,
                              surname: player.surname,
                              avatarUrl: player.avatarUrl,
                            }}
                            data-testid={`user-avatar-${player.id}`}
                          />
                        </ActionLink>
                        {player.totalGoals > 0 && (
                          <Chip
                            style={{
                              position: "absolute",
                              left: "calc(50% + 25px)",
                              top: 0,
                              borderRadius: "50%",
                              width: "32px",
                              height: "32px",
                            }}
                            size="small"
                            label={player.totalGoals}
                            onClick={() => null}
                            color="secondary"
                          />
                        )}
                        {/* </Badge> */}
                        <div
                          style={{
                            position: "absolute",
                            bottom: "-5px",
                            left: "calc(50% + 25px)",
                          }}
                        >
                          {currentUser && useIsManager(currentUser.role) && (
                            <ActionLink to={`/players/${player.id}/edit`}>
                              <Fab
                                variant="round"
                                size="small"
                                color="primary"
                                aria-label="add"
                              >
                                <EditIcon />
                              </Fab>
                            </ActionLink>
                          )}
                        </div>

                        {/* <Fab
                        variant="round"
                        size="small"
                        color="inherit"
                        aria-label="add"
                        onClick={_ => alert("hello")}
                      >
                        <RemoveIcon />
                      </Fab> */}
                        {/* </ListItem> */}
                        {/* <Divider variant="fullWidth" component="li" /> */}
                      </div>
                      <div style={{ marginTop: "8px" }}>
                        <ActionLink to={`/players/${player.id}/information`}>
                          <ListItemText
                            primary={`${player.name} ${player.surname}`}
                          />
                        </ActionLink>
                      </div>
                    </div>
                  </Grid>
                </>
              ))}
          {/* </List>
          </Grid> */}

          {currentUser && useIsManager(currentUser.role) && (
            <Grid item xs={12} style={{ paddingBottom: 16, paddingTop: 8 }}>
              <ActionLink to="/players/newplayer">
                <Button>Add player</Button>
              </ActionLink>
            </Grid>
          )}
        </Content>
      </CardWrapper>
    );
  }
);

export default SquadCard;
