import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import { Chip } from "@material-ui/core";
import { AddElementDialog } from "../../atoms";

const ALL_OBJECTIVES = gql`
  query AllObjectives {
    allObjectives
  }
`;

const ADD_OBJECTIVE = gql`
  mutation AddObjective($name: String) {
    addObjective(name: $name)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      borderRadius: 24,
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name: string, objectives: string[], theme: Theme) => {
  return {
    fontWeight:
      objectives && objectives.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const objectivesMultiSelect = ({ callback }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [objectives, setObjectives] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const { data, refetch } = useQuery<any>(ALL_OBJECTIVES);
  const [addObjective, { data: objectiveAdded }] = useMutation<any>(
    ADD_OBJECTIVE
  );

  useEffect(() => {
    callback(selected);
  }, [selected]);

  useEffect(() => {
    if (data) {
      setObjectives(data.allObjectives);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [objectiveAdded]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string[]);
  };

  const handleAddObjective = async (objective: string) => {
    await addObjective({
      variables: {
        name: objective,
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "baseline",
      }}
    >
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">objectives</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          // native
          // variant="filled"
          value={selected}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip
                  variant="outlined"
                  color="secondary"
                  key={value}
                  label={value}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {objectives &&
            objectives.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, objectives, theme)}
              >
                {name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <AddElementDialog
        title={"new Objective"}
        callback={(x: string) => handleAddObjective(x)}
      />
    </div>
  );
};

export default objectivesMultiSelect;
