import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface IValidatePasswordDialog {
  isOpen: boolean;
  callback(password: string): void;
}

export default function ValidatePasswordDialog({
  isOpen,
  callback,
}: IValidatePasswordDialog) {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    callback("");
  };

  const handleChange = (evt: any) => {
    setPassword(evt.target.value);
  };

  const handleApprove = () => {
    if (password.length > 0) {
      callback(password);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Validate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To perform this operation we need to validate your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter your password"
            type="password"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApprove} color="primary">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
