import React from "react";
import {
  Formik,
  FormikActions,
  Form as FormikForm,
  Field,
  FormikProps,
} from "formik";
import { injectIntl, FormattedMessage } from "react-intl";
import TextInput from "../../text-input";
import Typography from "../../typography";
import InputErrorMessage from "../../input-error-message";

import messages from "../PageNewExerciswe.intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../../types/styled-arguments";
import RegisterStepButton from "../next-step-button";
import { required } from "../../form-utils";
// import { AddElementDialog } from "../../atoms";
import ObjectivesMultiSelect from "./ObjectivesMultiSelect";
import CategoryComponent from "./CategoryComponent";
import ImageHeaderComponent from "./ImageHeaderComponent";
import { TextField } from "@material-ui/core";

const Title = styled(({ children }) => (
  <Typography.H5>{children}</Typography.H5>
))((props: IStyledArguments) => {
  const { md } = props.theme.breakpoints.values;

  return {
    marginBottom: 23,
    [`@media (max-width: ${md}px)`]: {
      marginBottom: 27,
    },
  };
});

export interface IFormValues {
  name: string;
  category: string;
  subcategory: string;
  objectives: string[];
  shortDescription: String;
  taskDevelopment: String;
}

const StyledForm = styled(FormikForm)(() => ({
  display: "flex",
  flexDirection: "column",
}));

interface IForm {
  onSubmit: (arg: IFormValues) => void;
  checkValid: boolean;
  name: string;
  shortDescription: string;
  taskDevelopment: string;
  category: string;
  subcategory: string;
  objectives: string[];
}

const NewExerciseForm = injectIntl<IForm>(
  ({
    onSubmit,
    checkValid, // email,
    name,
    category,
    subcategory,
    objectives,
    taskDevelopment,
    shortDescription,
    // surname,
    // number,
    // position,
    // nickname, // dateOfBirth,
    intl,
  }) => {
    return (
      <Formik
        initialValues={{
          name,
          category,
          subcategory,
          objectives,
          taskDevelopment,
          shortDescription,
        }}
        isInitialValid={checkValid}
        onSubmit={(
          values: IFormValues,
          actions: FormikActions<IFormValues>
        ) => {
          onSubmit(values);
          actions.setSubmitting(false);
        }}
        render={({
          errors,
          touched,
          // values,
          isValid,
          setFieldValue,
        }: FormikProps<IFormValues>) => (
          <>
            <ImageHeaderComponent />
            <Title>
              <FormattedMessage {...messages.newExercise} />
            </Title>
            <StyledForm data-testid="add-player-form">
              <Field
                name="name"
                validate={required(intl)}
                error={errors.name && touched.name}
                component={TextInput}
                inputProps={{
                  "data-testid": "name",
                }}
                label={intl.formatMessage(messages.name)}
              />
              <InputErrorMessage name="name" />
              <Field
                name="shortDescription"
                validate={required(intl)}
                error={errors.shortDescription && touched.shortDescription}
                component={TextInput}
                inputProps={{
                  "data-testid": "shortDescription",
                }}
                label={intl.formatMessage(messages.shortDescription)}
              />
              <InputErrorMessage name="shortDescription" />

              <CategoryComponent
                label={intl.formatMessage(messages.category)}
                title={"new Category"}
                callback={({ category, subcategory }: any) => {
                  console.log("Callback");
                  console.log(category);
                  console.log(subcategory);
                  setFieldValue("category", category);
                  setFieldValue("subcategory", subcategory);
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <ObjectivesMultiSelect
                  callback={(objectives: string[]) => {
                    setFieldValue("objectives", objectives);
                  }}
                />
              </div>

              {/* <TextareaAutosize
                aria-label="minimum height"
                rows={3}
                placeholder="Task Development"
                onChange={(e) =>
                  setFieldValue("taskDevelopment", e.target.value)
                }
              /> */}
              <TextField
                variant="outlined"
                placeholder="Task Development"
                multiline
                rows={6}
                onChange={(e) =>
                  setFieldValue("taskDevelopment", e.target.value)
                }
              />

              <div>
                <RegisterStepButton
                  type="submit"
                  data-testid="go-next-button"
                  disabled={!isValid}
                >
                  {intl.formatMessage(messages.next)}
                </RegisterStepButton>
              </div>
            </StyledForm>
          </>
        )}
      />
    );
  }
);

export default NewExerciseForm;
