import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import { styled } from '@material-ui/styles';
import { IStyledArguments } from '../../types/styled-arguments';

interface ILocaleOptions {
  id: string;
  label: string;
}
interface ILanguageSwitcher {
  locale?: string;
  locales: ILocaleOptions[];
  onChange: (arg: string) => void;
}

const Container = styled('div')(({ theme }: IStyledArguments) => ({
  display: 'flex',
  maxWidth: theme.layout.maxContentWidth,
  margin: '0 auto',
  padding: theme.spacing(0, 3),
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(4),
}));

const LanguageSwitcherBar = (props: ILanguageSwitcher) => {
  return (
    <Container>
      <LanguageSwitcher {...props} />
    </Container>
  );
};

export default LanguageSwitcherBar;
