import React from "react";
import { injectIntl } from "react-intl";
import messages from "./Matches.intl";
import { styled, makeStyles } from "@material-ui/styles";
import { Theme, Fab } from "@material-ui/core";
import MatchesList from "./MatchesList";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Loader from "../loader";
import AddIcon from "@material-ui/icons/Add";
import useIsManager from "../../hooks/useIsManager";
import { ActionLink } from "../atoms";

export const MATCHES_QUERY = gql`
  query GetMatches {
    allMatches {
      id
      date
      location
      time
      status
      homeTeam {
        id
        name
      }
      isHomeTeamLocal
      isFriendly
      awayTeam
      score {
        home
        away
      }
      playersSelected {
        id
        name
      }
      playersConfirmed {
        id
        name
      }
      scorerList {
        player {
          id
          name
          surname
          avatarUrl
        }
        goals
      }
      ownGoals
    }
    currentUser {
      id
      role
    }
  }
`;

const FooterContainer = styled("div")<Theme>(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  height: 90,
  width: "auto",
  right: 0,
  position: "fixed",
  bottom: 70,
}));

const useLoaderStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: `calc(100vh - ${theme.layout.headerHeight -
      theme.layout.tabsHeight}px )`, // compensate for list filter bar height
  },
}));

const Main = styled("main")<Theme>(() => ({
  display: "block", // IE
  // minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  // background: theme.palette.common.white
}));

const Matches = injectIntl(({ intl }) => {
  const { data, loading } = useQuery<any>(MATCHES_QUERY);
  const loaderClasses = useLoaderStyles({});

  return (
    <>
      <Main>
        <Loader
          className={loaderClasses.root}
          errorMessage={intl.formatMessage(messages.loadError)}
          loading={loading}
          // error={error}
          data={data}
        >
          {({ allMatches, currentUser }) => (
            <>
              <MatchesList items={allMatches} currentUser={currentUser} />
              <FooterContainer>
                {useIsManager(currentUser.role) && (
                  <ActionLink to="/matches/newmatch">
                    <Fab
                      aria-label="add match"
                      color="secondary"
                      style={{ zIndex: 1200 }}
                    >
                      <AddIcon />
                    </Fab>
                  </ActionLink>
                )}
              </FooterContainer>
            </>
          )}
        </Loader>
      </Main>
    </>
  );
});

export default Matches;
