import React from "react";
import Avatar, { AvatarProps } from "@material-ui/core/Avatar";
import { styled } from "@material-ui/styles";

const StyledAvatar = styled(Avatar)({
  border: "solid 3px white",
});

interface IUser {
  name: string;
  surname: string;
  number?: string;
  avatarUrl?: string;
}

interface IUserAvatarProps extends AvatarProps {
  user: IUser;
}

const getInitials = (p: IUser) =>
  `${(p.name[0] && p.name[0].toUpperCase()) || ""}${(p.surname[0] &&
    p.surname[0].toUpperCase()) ||
    ""}`;
// ${p.number || ""}
// const getNumber = (p: IUser) => `${p.number || ""}`;

const UserAvatar = ({ user, ...props }: IUserAvatarProps) => (
  <>
    {user.avatarUrl ? (
      <StyledAvatar {...props} src={user.avatarUrl} />
    ) : (
      <StyledAvatar {...props}>{getInitials(user)}</StyledAvatar>
    )}
  </>
);

export default UserAvatar;
