import React from "react";
import {
  Router,
  RouteComponentProps,
  Redirect,
  globalHistory,
} from "@reach/router";
import PageLogin from "./components/page-login";
import PageUser from "./components/page-user";
import PageRegister from "./components/page-register";
import { IntlProvider } from "react-intl";
import localeData from "./locale-data/locales/intl.json";
// import LoggedOutRoute from "./components/logged-out-route/LoggedOutRoute";
import ProtectedRoute from "./components/protected-route";
import PageDashboard from "./components/page-dashboard";
import ResponsiveLayout from "./components/responsive-layout/ResponsiveLayout";
// import PageProfiles from "./components/page-profiles/PageProfiles";
// import PageProfileView from "./components/page-view-profile/PageViewProfile";
// import useAuthContext from "./hooks/useAuthContext";
import useLocaleContext from "./hooks/useLocaleContext";
// import PageCreateProfile from "./components/page-create-profile";
import { CssBaseline } from "@material-ui/core";
import PagePeople from "./components/page-people";
import PageSendResetPassword from "./components/page-send-reset-password";
import PageResetPassword from "./components/page-reset-password";
import { QueryParamProvider } from "use-query-params";
import { Permission } from "./components/auth-context-provider";
import PageNewPlayer from "./components/page-new-player";
import PageNewTeam from "./components/page-new-team";
import PageNewMatch from "./components/page-new-match";
import PageNewExercise from "./components/page-new-exercise";
import PageViewMatch from "./components/page-view-match";
import PageViewLeague from "./components/page-view-league";
import PageViewPlayer from "./components/page-view-player";
import PageEditPlayer from "./components/page-edit-player";
import PageViewTeam from "./components/page-view-team";
import PageAddLeague from "./components/page-new-league";
// import PageExerciseLibray from "./components/page-exercise-library";
import PageCoachLibrary from "./components/page-coach-library";
import PageNewTraining from "./components/page-new-training";
import PageViewExercise from "./components/page-view-exercise";
import PageViewTraining from "./components/page-view-training";
import PageViewScouting from "./components/page-view-scouting";
import PageValidateAccount from "./components/page-validate-account";
import PageViewProfile from "./components/views";
// import PageCreateLinkPlayer from "./components/pages/create-and-link-player";

// navigator.serviceWorker.register("/sw.js");
// NOTE: This will be replaced by a real 404
/* istanbul ignore next */
const NotFound = (_: RouteComponentProps) => <Redirect to="/" noThrow />;

const App = () => {
  const [currentLocale] = useLocaleContext();
  // const [{ isLoggedIn }] = useAuthContext();
  console.log("localeData");
  console.log(localeData);
  console.log((localeData as any)[currentLocale]);
  return (
    <>
      <CssBaseline />
      <>
        <IntlProvider
          locale={currentLocale}
          messages={(localeData as any)[currentLocale]}
          defaultLocale="en_GB"
        >
          {/* <ResponsiveLayout disabled={isLoggedIn}> */}
          <ResponsiveLayout disabled={false}>
            <QueryParamProvider reachHistory={globalHistory}>
              <Router id="router">
                <NotFound default />
                <PageRegister path="/register/:registrationCode" />
                <PageRegister path="/register" />

                <PageValidateAccount path="/validate/:token" />
                {/* <LoggedOutRoute component={PageRegister} path="/register/:token" /> */}
                <ProtectedRoute
                  component={PageNewPlayer}
                  path="/players/newplayer"
                />
                <ProtectedRoute
                  component={PageNewMatch}
                  path="/matches/newmatch"
                />

                <ProtectedRoute component={PageViewTeam} path="/teams/:id" />
                <ProtectedRoute component={PageViewMatch} path="/matches/:id" />
                <ProtectedRoute
                  component={PageViewPlayer}
                  path="/players/:id"
                />
                <ProtectedRoute
                  component={PageEditPlayer}
                  path="/players/:id/edit"
                />
                <ProtectedRoute
                  component={PageViewPlayer}
                  path="/players/:id/information"
                />
                <ProtectedRoute
                  component={PageViewPlayer}
                  path="/players/:id/notes"
                />
                <ProtectedRoute
                  component={PageViewPlayer}
                  path="/players/:id/statistics"
                />
                <ProtectedRoute component={PageViewScouting} path="/scouting" />

                <ProtectedRoute component={PageNewTeam} path="/teams/newteam" />
                <ProtectedRoute
                  component={PageAddLeague}
                  path="/leagues/:id/addLeague"
                />
                <ProtectedRoute
                  component={PageViewLeague}
                  path="/leagues/:id"
                />
                <ProtectedRoute
                  component={PageViewLeague}
                  path="/leagues/:id/table"
                />
                <ProtectedRoute
                  component={PageViewLeague}
                  path="/leagues/:id/teams"
                />
                <ProtectedRoute
                  component={PageViewLeague}
                  path="/leagues/:id/matches"
                />
                <PageLogin path="/login" />
                <PageSendResetPassword path="/forgot-password" />
                <PageResetPassword path="/password/:token" />
                <ProtectedRoute component={PageUser} path="/user" />
                <ProtectedRoute
                  component={PageDashboard}
                  path="/dashboard"
                  withPermission={Permission.LEADERS_ADD}
                  unauthorizedRedirectTo="/people/players"
                />
                {/* <ProtectedRoute
                component={PageDashboard}
                path="/people/dashboard"
                withPermission={Permission.LEADERS_ADD}
                unauthorizedRedirectTo="/people/players"
              /> */}
                <ProtectedRoute component={PagePeople} path="/people/players" />
                <ProtectedRoute component={PagePeople} path="/people/teams" />
                <ProtectedRoute component={PagePeople} path="/people/matches" />

                {/* <ProtectedRoute
                component={PageExerciseLibray}
                path="/library"
                withPermission={Permission.LEADERS_ADD}
                unauthorizedRedirectTo="/dashboard"
              /> */}

                <ProtectedRoute
                  component={PageCoachLibrary}
                  path="/library/exercises"
                  unauthorizedRedirectTo="/dashboard"
                />
                <ProtectedRoute
                  component={PageCoachLibrary}
                  path="/library/trainings"
                />
                <ProtectedRoute
                  component={PageCoachLibrary}
                  path="/library/admin"
                />
                <ProtectedRoute
                  component={PageNewExercise}
                  path="/library/newexercise"
                />
                <ProtectedRoute
                  component={PageViewExercise}
                  path="/exercises/:id"
                />
                <ProtectedRoute
                  component={PageNewTraining}
                  path="/library/newtraining"
                />
                <ProtectedRoute
                  component={PageViewTraining}
                  path="/trainings/:id"
                />
                <ProtectedRoute component={PageViewProfile} path="/profile" />
                {/* <ProtectedRoute
                  component={PageCreateLinkPlayer}
                  path="/users/:id/linkplayer"
                /> */}

                <Redirect noThrow from="/" to="/dashboard" />
              </Router>
            </QueryParamProvider>
          </ResponsiveLayout>
        </IntlProvider>
      </>
    </>
  );
};

export default App;
