import React from "react";
// import { FormattedMessage } from "react-intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
import { ITeam } from "../../types";
import { Card, Grid, Chip } from "@material-ui/core";
import Link from "../link";
import messages from "./TeamSummaryCard.intl";
import { injectIntl } from "react-intl";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

const Title = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Subtitle = styled(Typography.Subtitle1)(
  ({ theme }: IStyledArguments) => ({
    color: theme.palette.text.secondary,
  })
);

const CreatedDate = styled(Typography.Subtitle1)(() => ({
  textAlign: "right",
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
}

const getNumberOfMatchesWon = (matches: any) => {
  if (matches) {
    const numberOfWonGames = matches
      .map((match: any) => (match && match.isWon ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfWonGames);
    return numberOfWonGames;
  }
  return 0;
};

const getNumberOfMatchesTied = (matches: any) => {
  if (matches) {
    const numberOTiedMatches = matches
      .map((match: any) => (match && match.isTied ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOTiedMatches);
    return numberOTiedMatches;
  }
  return 0;
};

const getNumberOfMatchesLost = (matches: any) => {
  if (matches) {
    const numberOfLostMatches = matches
      .map((match: any) => (match && match.isLost ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfLostMatches);
    return numberOfLostMatches;
  }
  return 0;
};

const TeamSummaryCard = injectIntl<ITeamSummaryCardProps>(
  ({ id, team, intl }) => {
    const matchesWon = getNumberOfMatchesWon(team.matches);
    const matchesTied = getNumberOfMatchesTied(team.matches);
    const matchesLost = getNumberOfMatchesLost(team.matches);

    return (
      <CardWrapper data-testid={`group-summary-${id}`}>
        <Header>
          <Row>
            <Grid item xs={8}>
              <Title to={`/teams/${team.id}`}>
                {team.name}
                {">"}
              </Title>
            </Grid>
            <Grid item xs={4}>
              <CreatedDate>{team.foundationYear}</CreatedDate>
            </Grid>
            <Grid item xs={12}>
              {team.coach && (
                <Subtitle>
                  {team.coach.name}&nbsp;{team.coach.surname}&nbsp;(
                  {team.coach.nickName})
                </Subtitle>
              )}
            </Grid>
            <Grid item xs={12}>
              {team.squad && (
                <Subtitle>
                  {team.squad.length}&nbsp;
                  {intl.formatMessage(messages.players)}
                </Subtitle>
              )}
            </Grid>
          </Row>
        </Header>
        <Content>
          <Row>
            <Grid item xs={12}>
              <Typography.Subtitle1>
                {intl.formatMessage(messages.totalMatches)}:{" "}
                {(team.matches && team.matches.length) || 0}
              </Typography.Subtitle1>
            </Grid>
            <Grid item xs={12}>
              <Typography.Subtitle1>
                {intl.formatMessage(messages.league)}:{" "}
                {(team.league && team.league.name) ||
                  intl.formatMessage(messages.noLeague)}
              </Typography.Subtitle1>
            </Grid>
            {/* <Row> */}
            <Grid item xs={12}>
              <Chip
                style={{ marginBottom: "8px", marginRight: 8 }}
                color="primary"
                size="small"
                label={`${matchesWon} ${intl.formatMessage(messages.won)}`}
                onClick={() => null}
              />
              <Chip
                style={{ marginBottom: "8px", marginRight: 8 }}
                color="default"
                size="small"
                label={`${matchesTied} ${intl.formatMessage(messages.drawn)}`}
                onClick={() => null}
              />
              <Chip
                style={{ marginBottom: "8px" }}
                color="secondary"
                size="small"
                label={`${matchesLost} ${intl.formatMessage(messages.lost)}`}
                onClick={() => null}
              />
            </Grid>
          </Row>
        </Content>
      </CardWrapper>
    );
  }
);

export default TeamSummaryCard;
