import React from "react";

import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { FormattedMessage } from "react-intl";
import messages from "./SendResetPassword.intl";
// import { Link } from "@reach/router";
// import ButtonLink from "../button-link";
import { Title } from "../unauthenticated-layout-elements";
import { ActionLink } from "../atoms";
import Button from "../button";

const StyledParagraph = styled("p")((props: IStyledArguments) => {
  const { md } = props.theme.breakpoints.values;

  return {
    marginBottom: 23,
    [`@media (max-width: ${md}px)`]: {
      marginBottom: 27,
    },
  };
});

const ConfirmationPage = () => {
  return (
    <>
      <Title>
        <FormattedMessage {...messages.emailSent} />
      </Title>
      <StyledParagraph data-testid="confirm-message">
        <FormattedMessage {...messages.emailSentMessage} />
      </StyledParagraph>
      <div>
        {/* <ButtonLink
          variant="contained"
          component={Link}
          data-testid="back-to-login-button"
          to="/login"
        > */}
        <ActionLink to="/login">
          <Button>
            <FormattedMessage {...messages.backToLoginButton} />
          </Button>
        </ActionLink>
        {/* </ButtonLink> */}
      </div>
    </>
  );
};

export default ConfirmationPage;
