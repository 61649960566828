import { defineMessages } from "react-intl";

export default defineMessages({
  email: {
    id: "RegisterForm.email",
    defaultMessage: "Email",
  },
  firstName: {
    id: "RegisterForm.FirstName",
    defaultMessage: "First name",
  },
  lastName: {
    id: "RegisterForm.LastName",
    defaultMessage: "Last name",
  },
  password: {
    id: "RegisterForm.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "RegisterForm.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  passwordError: {
    id: "RegisterForm.PasswordError",
    defaultMessage: "Password requirements not fulfilled",
  },
  passwordNotMatch: {
    id: "RegisterForm.PasswordNotMatch",
    defaultMessage: "Passwords do not match",
  },
  requiredFieldError: {
    id: "RegisterForm.RequiredFieldError",
    defaultMessage: "Required field",
  },
  registerTitle: {
    id: "RegisterForm.RegisterTitle",
    defaultMessage: "Start Registration",
  },
  loginLinkMessage: {
    id: "RegisterForm.loginLink",
    defaultMessage: "Already have an account?",
  },
  login: {
    id: "RegisterForm.login",
    defaultMessage: "Sign in",
  },
  passwordHintTitle: {
    id: "RegisterForm.passwordHintTitle",
    defaultMessage: "Password field",
  },
  passwordHintMustBe: {
    id: "RegisterForm.passwordHintMustBe",
    defaultMessage: "Your password must:",
  },
  passwordHint8Characters: {
    id: "RegisterForm.passwordHint8Characters",
    defaultMessage: "Be at least 8 characters long",
  },
  passwordHint1Uppercase: {
    id: "RegisterForm.passwordHint1Uppercase",
    defaultMessage: "Contain at least 1 upper case character",
  },
  passwordHint1Lowercase: {
    id: "RegisterForm.passwordHint1Lowercase",
    defaultMessage: "Contain at least 1 lower case character",
  },
  passwordHint1Number: {
    id: "RegisterForm.passwordHint1Number",
    defaultMessage: "Contain at least 1 number",
  },
  passwordHint1SpecialCharacter: {
    id: "RegisterForm.passwordHint1SpecialCharacter",
    defaultMessage: "Contain at least 1 special character",
  },
  passwordHintNotSameEmail: {
    id: "RegisterForm.passwordHintNotSameEmail",
    defaultMessage: "Not be the same as your email address",
  },
  role: {
    id: "RegisterForm.role",
    defaultMessage: "Role",
  },
});
