import React from "react";
import { gql } from "@apollo/client";
import { RouteComponentProps, Redirect } from "@reach/router";
import { Grid, Paper, Hidden } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { useQuery } from "@apollo/client";
import { IUserData } from "../../types";
import Typography from "../typography";
import Link from "../link";
import { actions } from "../auth-context-provider";
import useAuthContext from "../../hooks/useAuthContext";
import Header from "../header";

const Content = styled((props) => <Paper {...props} />)(
  (props: IStyledArguments) => {
    const { md } = props.theme.breakpoints.values;
    return {
      padding: props.theme.spacing(4),
      textAlign: "center",
      [`@media (max-width: ${md}px)`]: {
        boxShadow: "none",
      },
    };
  }
);

const Main = styled((props) => <main {...props} />)(
  (props: IStyledArguments) => ({
    display: "flex",
    margin: props.theme.spacing(1, 0),
    flex: 1,
  })
);

export const USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      firstName
      lastName
    }
  }
`;

const PageUser = (_: RouteComponentProps) => {
  const [state, dispatch] = useAuthContext();

  const { data, loading } = useQuery<IUserData>(USER_QUERY, {
    skip: !state.token,
  });

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  let content = null;

  if (loading) {
    content = <Typography.H4>Loading...</Typography.H4>;
  } else if (data && data.currentUser) {
    content = (
      <div data-testid="user-info">
        <Typography.Body data-testid="first-name">
          First name: {data.currentUser.name}
        </Typography.Body>
        <Typography.Body data-testid="last-name">
          Last name: {data.currentUser.surname}
        </Typography.Body>
      </div>
    );
  } else {
    dispatch(actions.logout());
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div>
      <Hidden mdUp>
        <Header />
      </Hidden>
      <Main>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Content>
              <Typography.H3>User page</Typography.H3>
              {content}
              <Link to="/" data-testid="logout" onClick={handleLogout}>
                Log out
              </Link>
            </Content>
          </Grid>
        </Grid>
      </Main>
    </div>
  );
};

export default PageUser;
