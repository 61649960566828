import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
// import Exercises from "../exercises-collapse";
// import SquadCard from "./SquadCard";
import SquadCollapse from "../molecules/squad-collapse";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "sticky",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ISelectPlayers {
  team: any;
  isOpen: boolean;
  initialFormation?: any;
  callback: (exercises: string[]) => void;
}

const SelectPlayers = ({
  team,
  isOpen,
  initialFormation,
  callback,
}: ISelectPlayers) => {
  console.log("isOpen: ", isOpen);
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(isOpen);
  // const { data } = useQuery(GET_EXERCISES);
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedPlayers && selectedPlayers.length > 0) {
      callback(selectedPlayers);
      setOpen(false);
    }
  }, [selectedPlayers]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    // callback(newElement);
    callback(selectedPlayers);
    setOpen(false);
  };

  const handleSelectPlayer = (player: any) => {
    setSelectedPlayers(player);
    console.log("player selected: ", player);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Select Player
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography variant="h6" className={classes.title}>
              Sound
            </Typography> */}
            <Button autoFocus color="inherit" onClick={handleClose}>
              Select
            </Button>
          </Toolbar>
        </AppBar>

        {/* <Exercises
          isTraining={true}
          callback={(exercises) => setSelectedExercises(exercises)}
        /> */}
        {/* <SquadCard team={team} /> */}
        <SquadCollapse
          selectable={true}
          selectedPlayers={initialFormation}
          callback={(player: any) => handleSelectPlayer(player)}
          team={team}
        />
      </Dialog>
    </div>
  );
};

export default SelectPlayers;
