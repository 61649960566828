import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { styled } from "@material-ui/styles";

import { Card } from "@material-ui/core";

import Typography from "../typography";
import { IStyledArguments } from "../../types/styled-arguments";
import { injectIntl } from "react-intl";

import messages from "./PageViewTeam.intl";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
}));

const GraphWrapper = styled("div")({
  // display: "flex",
  // alignItems: "center",
  // padding: theme.spacing(2),
  width: "300px",
  height: "350px",
});

const colors: Record<string, string> = {
  lost: "#de5a41",
  drawn: "#FF5722",
  won: "#77dd91",
};

const getColor = (bar: any) => colors[bar.id];

const GamesChart = injectIntl(({ data, intl }: any) => (
  <CardWrapper>
    <Header>
      <Typography.H6>
        {intl.formatMessage(messages.statisticsTitle)}
      </Typography.H6>
    </Header>
    <GraphWrapper>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        // colors={{ scheme: "pastel1" }}
        colors={getColor}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          // {
          //   match: {
          //     id: "drawn",
          //   },
          //   id: "dots",
          // },

          {
            match: {
              id: "lost",
            },
            id: "lines",
          },
        ]}
        // legends={[
        //   {
        //     anchor: "bottom",
        //     direction: "row",
        //     translateY: 56,
        //     itemWidth: 100,
        //     itemHeight: 18,
        //     itemTextColor: "#999",
        //     symbolSize: 18,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </GraphWrapper>
  </CardWrapper>
));

export default GamesChart;
