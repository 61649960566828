import React from "react";
import AddLeagueForm from "./add-league-form";
import { IFormValues } from "./add-league-form/addLeagueForm";
import { ITeam } from "../../types";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  // match?: IMatch;
  teams?: ITeam[];
}

const StepInitial = ({ onGoNext, teams }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    onGoNext(formValues);
  };
  return <AddLeagueForm onSubmit={handleSubmit} teams={teams} />;
};

export default StepInitial;
