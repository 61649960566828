import { defineMessages } from "react-intl";

export default defineMessages({
  kickOff: {
    id: "MatchSummaryCard.kickOff",
    defaultMessage: "Kick off",
  },
  own: {
    id: "MatchSummaryCard.Own",
    defaultMessage: "Own",
  },
  goal: {
    id: "MatchSummaryCard.Goal",
    defaultMessage: "Goal",
  },
  prepareGame: {
    id: "MatchSummaryCard.prepareGame",
    defaultMessage: "Prepare game",
  },
  viewGame: {
    id: "MatchSummaryCard.viewGame",
    defaultMessage: "View game",
  },
  playersSelected: {
    id: "MatchSummaryCard.playersSelected",
    defaultMessage: "Players selected",
  },
  playersConfirmed: {
    id: "MatchSummaryCard.playersConfirmed",
    defaultMessage: "Players confirmed",
  },
  location: {
    id: "MatchSummaryCard.location",
    defaultMessage: "Location",
  },
  friendly: {
    id: "MatchSummaryCard.friendly",
    defaultMessage: "friendly",
  },
});
