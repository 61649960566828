import { defineMessages } from "react-intl";

export default defineMessages({
  teamsLabel: {
    id: "DashboardCoachInfoCard.teamsLabel",
    defaultMessage: `{n, plural,
      =0 {teams}
      one {team}
      other {teams}
    }`
  },
  matchesLabel: {
    id: "DashboardCoachInfoCard.matchesLabel",
    defaultMessage: `{n, plural,
      =0 {matches}
      one {match}
      other {matches}
    }`
  },
  playersLabel: {
    id: "DashboardCoachInfoCard.playersLabel",
    defaultMessage: `{n, plural,
      =0 {players}
      one {player}
      other {players}
    }`
  },
  noNotifications: {
    id: "DashboardCoachInfoCard.noNotifications",
    defaultMessage: "you don't have any notification"
  }
});
