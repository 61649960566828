import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { styled } from "@material-ui/styles";
import { Theme, Paper } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import PlayerDashboard from "./PlayerDashboard";
import CoachDashboard from "./CoachDashboard";
import useIsManager from "../../hooks/useIsManager";
import CreateAndLinkPlayer from "../pages/create-and-link-player";

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      name
      surname
      email
      role
      playerLink
      avatarUrl
      workspace
      playerProfile {
        id
        name
        surname
        nickName
        number
        position
        dateOfBirth
        team
        totalGoals
        totalMatches
        isInvited
        yellowCards
        redCards
        scouting
        avatarUrl
      }
      notifications {
        id
        message
        timestamp
        read
      }
    }
  }
`;

const StyledPaper = styled(Paper)<Theme>(({ theme }) => ({
  boxSizing: "border-box",
  marginBottom: theme.spacing(5),
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: 830,
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    boxShadow: "none",
  },
}));

const PageDashboard = () => {
  const { data } = useQuery(CURRENT_USER);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    console.log("DASHBOARD~~~~~~~~~~~~~~~~~~~");
    if (data) {
      setUser(data.currentUser);
      console.log(data.currentUser);
    }
  }, [data]);

  return (
    <StyledPaper>
      {/* <Loader
        {...queryResult}
        errorMessage={intl.formatMessage(messages.loadError)}
      > */}
      {user && useIsManager(user.role) && <CoachDashboard user={user} />}
      {/* {user &&
        !useIsManager(user.role) &&
        (user.playerLink || user.playerProfile) && (
          <PlayerDashboard id={user.playerLink} user={user} />
        )} */}
      {user && !useIsManager(user.role) && (
        <>
          {!user.playerLink ? (
            // <Redirect to={`/users/${user.id}/linkplayer`} noThrow />
            <CreateAndLinkPlayer
              callback={(playerId: string) => {
                const tmpUser = { ...user };
                tmpUser.playerLink = playerId;
                setUser(tmpUser);
              }}
            />
          ) : (
            <PlayerDashboard id={user.playerLink} user={user} />
          )}
        </>
      )}

      {/* </Loader>  */}
    </StyledPaper>
  );
};

export default PageDashboard;
