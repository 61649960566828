import "date-fns";
import React from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
import {
  Card,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import messages from "./PageViewPlayer.intl";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
  textAlign: "left",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  padding: theme.spacing(2),
}));

const Title = styled("div")(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const HistoricalCard = injectIntl<any>(({ data, intl }) => (
  <>
    <CardWrapper style={{ marginTop: 8 }}>
      <Header>
        <Row>
          <Grid item xs={12}>
            {data && (
              <Title>
                {intl.formatMessage(messages.historicalData)}
                {">"}
              </Title>
            )}
          </Grid>
        </Row>
      </Header>
      <Content>
        {data &&
          data.map((season: any) => {
            console.log("season");
            console.log(season);
            return (
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`panel-${season.id}`}
                  style={{ width: "100%" }}
                >
                  <Typography.Subtitle1>
                    {season.league.name} - {season.league.startDate}
                  </Typography.Subtitle1>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Section>
                      <SectionTitle>{season.team.name}</SectionTitle>
                      <Typography.Subtitle1>
                        Total goals: {season.totalGoals}
                      </Typography.Subtitle1>
                      <Typography.Subtitle1>
                        Yellow cards: {season.yellowCards}
                      </Typography.Subtitle1>
                      <Typography.Subtitle1>
                        Red cards: {season.redCards}
                      </Typography.Subtitle1>
                    </Section>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
      </Content>
    </CardWrapper>
  </>
));

export default HistoricalCard;
