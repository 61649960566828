import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import { Card, Chip, Button } from "@material-ui/core";
import { RouteComponentProps, Redirect } from "@reach/router";
import { injectIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import messages from "./PageViewExercise.intl";
import { IStyledArguments } from "../../types/styled-arguments";
import { gql } from "@apollo/client";
import Breadcrumb from "./Breadcrumb";
import PictureUploader from "./PictureUploader";
import SnackBar from "../snackbar";
import CancelIcon from "@material-ui/icons/CancelOutlined";

const GET_EXERCISE = gql`
  query GetExercise($id: ID!) {
    exercise(id: $id) {
      name
      shortDescription
      category
      subcategory
      objectives
      taskDevelopment
    }
  }
`;

const DELETE_EXERCISE = gql`
  mutation DeleteTraining($id: ID!) {
    deleteExercise(id: $id)
  }
`;

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/exerciseBG.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "250px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
}));

const Title = styled("div")({
  color: "white",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});
const Description = styled("div")({
  color: "white",
  position: "relative",
  bottom: "-160px",
});

const Section = styled("div")({
  borderLeft: "solid",
  padding: "16px",
  marginBottom: "16px",
});

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const Main = styled("main")(({ theme }: IStyledArguments) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight +
    theme.layout.tabsHeight}px )`,
  background: theme.palette.common.white,
  paddingBottom: theme.layout.footerHeight,
}));

const PageViewExercise = injectIntl<{ id: string } & RouteComponentProps>(
  ({ id, intl }) => {
    const { data } = useQuery<any>(GET_EXERCISE, { variables: { id } });
    const [exercise, setExercise] = useState<any>({});
    const [exerciseDeleted, setExerciseDeleted] = useState(false);
    const [deleteExercise, { data: exerciseDeletedData }] = useMutation(
      DELETE_EXERCISE
    );
    const [errorDeletingExercise, setErrorDeletingExercise] = useState(false);

    useEffect(() => {
      if (exerciseDeletedData) {
        console.log("useEffect");
        console.log(exerciseDeletedData);
        if (exerciseDeletedData.deleteExercise === "ok") {
          console.log("setting exercise deleted");
          setExerciseDeleted(true);
        } else {
          console.log("error deleting exercise");
          setErrorDeletingExercise(true);
        }
      }
    }, [exerciseDeletedData]);

    useEffect(() => {
      if (data && data.exercise) {
        setExercise(data.exercise);
      }
    }, [data]);

    // useEffect(() => {
    if (exerciseDeleted) {
      console.log("redirecting...");
      return (
        <Redirect to="/library/exercises?action=exerciseDeleted" noThrow />
      );
    }
    // }, [exerciseDeleted]);

    const handleDeleteExercise = () => {
      console.log("deleting exercise...");
      deleteExercise({
        variables: {
          id,
        },
      });
      // return <Redirect to="/library/trainings" noThrow />;
    };

    return (
      <Main>
        {exercise && (
          <>
            <div style={{ padding: 16 }}>
              <Breadcrumb exercise={exercise} />
            </div>
            <CardWrapper>
              <Title>{exercise.name}</Title>
              <Description>{exercise.shortDescription}</Description>
            </CardWrapper>
            <div style={{ padding: 16 }}>
              <Section>
                <SectionTitle>Task Development:</SectionTitle>
                <div>{exercise.taskDevelopment}</div>
              </Section>
              <Section>
                <SectionTitle>Objectives:</SectionTitle>
                {exercise.objectives &&
                  exercise.objectives.map((obj: string) => (
                    <Chip style={{ marginRight: "8px" }} label={obj} />
                  ))}
              </Section>
              <Section>
                <SectionTitle>
                  {intl.formatMessage(messages.images)}:
                </SectionTitle>
                <PictureUploader id={id} />
              </Section>
            </div>
            <div style={{ padding: "16px" }}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleDeleteExercise}
              >
                {intl.formatMessage(messages.delete)}
              </Button>
            </div>
            <SnackBar
              data-testid="delete-error"
              open={errorDeletingExercise}
              message={intl.formatMessage(messages.errorDeleting)}
              variant="error"
              action={() => {
                setErrorDeletingExercise(false);
              }}
              actionText={<CancelIcon />}
            />
          </>
        )}
      </Main>
    );
  }
);

export default PageViewExercise;
