import Body from './body';
import Caption from './caption';
import H1 from './h1';
import H2 from './h2';
import H3 from './h3';
import H4 from './h4';
import H5 from './h5';
import H6 from './h6';
import Overline from './overline';
import Subtitle1 from './subtitle-1';
import Subtitle2 from './subtitle-2';

export default {
  Body,
  Caption,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Overline,
  Subtitle1,
  Subtitle2,
};
