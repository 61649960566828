import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "PageViewTeam.Error",
    defaultMessage: "Error loading team details",
  },
  scorers: {
    id: "PageViewTeam.scorers",
    defaultMessage: "Scorers",
  },
  squad: {
    id: "PageViewTeam.squad",
    defaultMessage: "Squad",
  },
  matchesPlayed: {
    id: "PageViewTeam.matchesPlayed",
    defaultMessage: "Matches played",
  },
  won: {
    id: "PageViewTeam.won",
    defaultMessage: "Won",
  },
  draw: {
    id: "PageViewTeam.draw",
    defaultMessage: "Draw",
  },
  lost: {
    id: "PageViewTeam.lost",
    defaultMessage: "Lost",
  },
  league: {
    id: "PageViewTeam.league",
    defaultMessage: "League",
  },
  addLeague: {
    id: "PageViewTeam.addLeague",
    defaultMessage: "Add to a league",
  },
  noLeagueTitle: {
    id: "PageViewTeam.noLeagueTitle",
    defaultMessage:
      "Your team is not in a league yet, you can add it in a league clicking below",
  },
  endLeague: {
    id: "PageViewTeam.endLeague",
    defaultMessage: "Finish league",
  },
  confirmEndLeagueTitle: {
    id: "PageViewTeam.confirmEndLeagueTitle",
    defaultMessage: "End League",
  },
  confirmEndLeagueMessage: {
    id: "PageViewTeam.confirmEndLeagueMessage",
    defaultMessage:
      "Are you sure you want to end this leeague, all the data will be stored as a historical records, this action can not be undo.",
  },
  confirmEndLeagueOK: {
    id: "PageViewTeam.confirmEndLeagueOK",
    defaultMessage: "end",
  },
  confirmEndLeagueKO: {
    id: "PageViewTeam.confirmEndLeagueKO",
    defaultMessage: "cancel",
  },
  statisticsTitle: {
    id: "PageViewTeam.statisticsTitle",
    defaultMessage: "Statistics",
  },
  historicalData: {
    id: "PageViewTeam.historicalData",
    defaultMessage: "Historical data",
  },
  actionNoReverse: {
    id: "PageViewTeam.actionNoReverse",
    defaultMessage: `This action can not be reversed, are you sure that you want to
    remove the team and all of their data.`,
  },
});
