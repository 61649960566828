import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { styled, makeStyles, createStyles } from "@material-ui/styles";
import DashboardPlayerInfoCard from "../dashboard-player-info-card";
import {
  Theme,
  Grid,
  ButtonGroup,
  Button,
  TextField,
  Paper,
} from "@material-ui/core";
import Content from "../content";
import { useQuery, useMutation } from "@apollo/client";
import Typography from "../typography";
import moment from "moment";
import PlayerEvents from "./PlayerEventsComponent";
import { injectIntl } from "react-intl";
import messages from "./PageDashboard.intl";
import { IStyledArguments } from "../../types/styled-arguments";
import InitialCard from "./InitialCard";
import NotificationsCard from "./NotificationsCard";
import { Redirect } from "@reach/router";

const QUERY_PLAYER = gql`
  query playerDetails($id: ID!) {
    player(id: $id) {
      id
      name
      surname
      nickName
      number
      position
      dateOfBirth
      avatarUrl
      team {
        id
        name
        trainingSetup {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        nextTraining {
          trainingAttendance {
            player {
              id
              name
            }
            attendance
            reason
          }
          date
        }
      }
      notes {
        note
        date
      }
      totalGoals
      totalMatches
      reviews {
        data {
          speed
          vision
          dribbling
          shoot
          physical
          leadership
          defense
        }
        date
      }
    }
  }
`;

const ATTEND_TRAINING = gql`
  mutation AttendTraining(
    $id: ID!
    $teamId: ID!
    $date: String!
    $attend: Boolean!
    $reason: String
  ) {
    attendTraining(
      id: $id
      teamId: $teamId
      date: $date
      attend: $attend
      reason: $reason
    )
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

const dayMapper: any = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  7: "sunday",
};

const TimeWrapper = styled("div")({
  margin: "16px",
});

const HoursBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
  };
});

const MinutesBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
  };
});

const ColonBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    color: theme.palette.primary.main,
  };
});

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const TopContainer = styled(Grid)<Theme>(({ theme }) => ({
  padding: theme.spacing(6, 0),
}));

const CardWrapper = styled(Grid)({
  marginTop: 0,
});

const CardTraining = styled(Paper)(({ theme }: IStyledArguments) => ({
  marginTop: 16,
  paddingBottom: 16,
  background: theme.palette.extra.backgroundGrey,
  borderRadius: 8,
}));

const CardTrainingHeader = styled("div")({
  backgroundImage: "url('/images/trainingBG.jpg')",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "200px",
  backgroundRepeat: "no-repeat",
  color: "white",
  top: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: 8,
  position: "relative",
  marginBottom: 16,
});

interface IPlayerDashboard {
  id: string;
  user: any;
}
const PlayerDashboard = injectIntl<IPlayerDashboard>(({ id, user, intl }) => {
  //TODO: change it to user.playerLink

  const { data, refetch } = useQuery<any>(QUERY_PLAYER, {
    variables: { id: id ? id : user.playerLink },
  });
  console.log("******", id ? id : user.playerLink);
  console.log("******", user);
  console.log("******3", user.playerLink);
  const [player, setPlayer] = useState<any>(user.playerProfile);
  const [nextTraining, setNextTraining] = useState<any>();
  const [trainingTime, setTrainingTime] = useState<any>();
  const [trainingWeekList, setTrainingWeekList] = useState<any>();

  const [attendTraining] = useMutation<any>(ATTEND_TRAINING);
  const [attendTrainingYes, setAttendTrainingYes] = useState<
    boolean | undefined
  >();
  const [absentReason, setAbsentReason] = useState<string>("");
  const [sentAbsentReason, setSendAbsentReason] = useState<boolean>(false);
  const [redirectToCreateLinkPlayer, setRedirectToCreateLinkPlayer] = useState<
    boolean
  >(false);

  const handleAttendTraining = async (nextState: boolean) => {
    await attendTraining({
      variables: {
        id,
        teamId: player.team.id,
        date: moment(nextTraining).format("YYYY-MM-DD"),
        attend: nextState,
        reason: absentReason,
      },
    });
    console.log(moment(nextTraining).format("YYYY-MM-DD"));
  };

  const handleYes = () => {
    setAttendTrainingYes(true);
    handleAttendTraining(true);
  };

  const handleNo = () => {
    setAttendTrainingYes(false);
  };

  const handleAddReason = (e: any) => {
    console.log("value", e.target.value);
    setAbsentReason(e.target.value);
  };

  const handleSendAbsentReason = () => {
    setSendAbsentReason(true);
    if (attendTrainingYes !== undefined) {
      handleAttendTraining(attendTrainingYes);
    }
  };

  useEffect(() => {
    if (data && data.player === null) {
      //TODO: redirect to create player
      setRedirectToCreateLinkPlayer(true);
    }
    if (data && data.player) {
      setPlayer(data.player);
    }
    if (data && data.player.team) {
      const trainingSetup = { ...data.player.team.trainingSetup };

      if (trainingSetup) {
        console.log(trainingSetup);
        const filtered = Object.keys(trainingSetup).filter((key) => {
          if (key !== "__typename") {
            return trainingSetup[key];
          }
        });

        const trainingList = filtered.map((day) =>
          moment()
            .day(day)
            .isBefore(Date.now(), "day")
            ? moment()
                .day(day)
                .add(7, "day")
                .toDate()
            : moment()
                .day(day)
                .toDate()
        );
        trainingList.sort((a, b) =>
          moment(a).isAfter(moment(b, "day")) ? 1 : -1
        );
        setNextTraining(trainingList[0]);
        const trainingWeekListMapped = trainingList.map((day) =>
          moment(day).date()
        );

        const nextDayTraining = dayMapper[moment(trainingList[0]).isoWeekday()];
        const nextTrainingTime = trainingSetup[nextDayTraining];
        setTrainingTime(nextTrainingTime);

        console.log(trainingWeekListMapped);
        setTrainingWeekList(trainingWeekListMapped);
      }
      console.log(data.player.team);
      if (data.player.team.nextTraining) {
        const attendance = [
          ...data.player.team.nextTraining.trainingAttendance,
        ];
        console.log("attendance====");
        console.log(attendance);
        const amIInTheList = attendance.filter(
          (element) => element.player.id === id
        );
        console.log("amIInTheList", amIInTheList);
        if (amIInTheList && amIInTheList.length === 1) {
          setAttendTrainingYes(amIInTheList[0].attendance);
          setAbsentReason(amIInTheList[0].reason);
        }
      }
    }
  }, [data]);

  const classes = useStyles();
  return (
    <>
      {redirectToCreateLinkPlayer && (
        <Redirect to={`/users/${user.id}/linkplayer`} noThrow />
      )}
      <MainContent container>
        <Content>
          {player && (
            <TopContainer container spacing={6}>
              <CardWrapper item xs={12}>
                <DashboardPlayerInfoCard
                  player={player}
                  notifications={user.notifications}
                  user={user}
                />
              </CardWrapper>
              <CardWrapper item xs={12}>
                <NotificationsCard
                  userId={user.id.toString()}
                  notifications={user.notifications}
                  callback={() => refetch()}
                />
              </CardWrapper>
              <CardWrapper item xs={12}>
                {trainingWeekList && (
                  <>
                    <PlayerEvents
                      teamId={player.team.id}
                      trainingWeekList={trainingWeekList}
                      user={user}
                    />
                    <CardTraining>
                      <CardTrainingHeader>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            color: "white",
                            fontSize: "x-large",
                            fontWeight: 100,
                          }}
                        >
                          {intl.formatMessage(messages.nextTrainings)} {">"}
                        </div>
                      </CardTrainingHeader>
                      <Typography.H6>
                        {moment(nextTraining).isSame(Date.now(), "day")
                          ? "Today"
                          : moment(nextTraining).format("DD-MM-YYYY")}
                        &nbsp;at
                      </Typography.H6>
                      <TimeWrapper>
                        <HoursBox>
                          {trainingTime && trainingTime.split(":")[0]}
                        </HoursBox>
                        <ColonBox>{":"}</ColonBox>
                        <MinutesBox>
                          {trainingTime && trainingTime.split(":")[1]}
                        </MinutesBox>
                      </TimeWrapper>

                      <div className={classes.root}>
                        <Typography.Subtitle1>
                          {intl.formatMessage(messages.confirmAttendance)}
                        </Typography.Subtitle1>
                        <ButtonGroup
                          size="large"
                          color="primary"
                          aria-label="large outlined primary button group"
                        >
                          {attendTrainingYes !== undefined &&
                          attendTrainingYes ? (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={handleYes}
                            >
                              {intl.formatMessage(messages.yes)}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={handleYes}
                            >
                              {intl.formatMessage(messages.yes)}
                            </Button>
                          )}
                          {attendTrainingYes !== undefined &&
                          !attendTrainingYes ? (
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={handleNo}
                            >
                              {intl.formatMessage(messages.no)}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={handleNo}
                            >
                              {intl.formatMessage(messages.no)}
                            </Button>
                          )}
                        </ButtonGroup>
                        {!sentAbsentReason &&
                          attendTrainingYes !== undefined &&
                          !attendTrainingYes && (
                            <>
                              <TextField
                                placeholder="absent reason"
                                onChange={handleAddReason}
                                value={absentReason}
                              />
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={absentReason === ""}
                                onClick={handleSendAbsentReason}
                              >
                                {intl.formatMessage(messages.send)}
                              </Button>
                            </>
                          )}
                        {sentAbsentReason && (
                          <Typography.Subtitle1>
                            {intl.formatMessage(messages.reason)}&nbsp;
                            {absentReason}
                          </Typography.Subtitle1>
                        )}
                      </div>
                    </CardTraining>
                  </>
                )}
              </CardWrapper>
              {user && !user.workspace && (
                <CardWrapper item xs={12}>
                  <InitialCard
                    user={user}
                    callback={() => {
                      console.log("[process] callback in PlayerDashboard");
                    }}
                  />
                </CardWrapper>
              )}
              {player && !player.team && (
                <>
                  <CardWrapper item xs={12}>
                    <Typography.H6>No team yet</Typography.H6>
                    <Typography.Subtitle1>{`Your coach will assign you to a new team soon`}</Typography.Subtitle1>
                  </CardWrapper>
                  <CardWrapper
                    item
                    xs={12}
                    style={{ border: "1px solid lightgray" }}
                  >
                    <Typography.H6>No events yet</Typography.H6>
                  </CardWrapper>
                </>
              )}
            </TopContainer>
          )}
        </Content>
      </MainContent>
    </>
  );
});

export default PlayerDashboard;
