import React, { useState } from "react";
import moment from "moment";
import { ButtonGroup, Button, Grid } from "@material-ui/core";
// import { styled, withStyles } from "@material-ui/styles";
// import MuiChip from "@material-ui/core/Chip";
import { injectIntl } from "react-intl";
import messages from "./CalendarComponent.intl";

// const LegendChipDefault = withStyles({
//   root: {
//     minWidth: "30%",
//     maxWidth: "100%",
//     borderRadius: 17,
//   },
//   label: {
//     display: "block",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     maxWidth: "100%",
//     color: "white",
//   },
// })(MuiChip);

// const LegendRow = styled("div")({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   marginTop: 20,
// });

// const LegendChip = withStyles((theme) => ({
//   root: {
//     marginRight: theme.spacing(1),
//     maxWidth: "100%",
//     minWidth: "30%",
//     borderRadius: 17,
//   },
//   label: {
//     display: "block",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     maxWidth: "100%",
//   },
// }))(MuiChip);

interface ICalendarComponent {
  nextMatch?: any;
  trainingWeekList?: any;
}

const CalendarComponent = injectIntl<ICalendarComponent>(
  ({ trainingWeekList, intl }) => {
    const today = moment();
    const begin = moment(today).isoWeekday(1);
    const buildWeek = [];
    for (let i = 0; i < 7; i++) {
      buildWeek.push(
        moment(begin)
          .add(i, "days")
          .date()
      );
    }
    const [week] = useState(buildWeek);
    console.log("week", week);

    return (
      <>
        <Grid xs={12}>
          <ButtonGroup
            fullWidth
            variant="text"
            color="secondary"
            style={{
              maxWidth: "100%",
              marginBottom: 10,
              border: 0,
              // backgroundColor: "FF572220"
            }}
            aria-label="large outlined secondary button group"
          >
            {1 === moment().day() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.mondayShort)}
              </Button>
            )}
            {1 !== moment().day() && (
              <Button>{intl.formatMessage(messages.mondayShort)}</Button>
            )}
            {2 === moment().day() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.tuesdayShort)}
              </Button>
            )}
            {2 !== moment().day() && (
              <Button>{intl.formatMessage(messages.tuesdayShort)}</Button>
            )}
            {3 === moment().day() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.wednesdayShort)}
              </Button>
            )}
            {3 !== moment().day() && (
              <Button>{intl.formatMessage(messages.wednesdayShort)}</Button>
            )}
            {4 === moment().day() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.thursdayShort)}
              </Button>
            )}
            {4 !== moment().day() && (
              <Button>{intl.formatMessage(messages.thursdayShort)}</Button>
            )}
            {5 === moment().day() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.fridayShort)}
              </Button>
            )}
            {5 !== moment().day() && (
              <Button>{intl.formatMessage(messages.fridayShort)}</Button>
            )}
            {6 === moment().day() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.saturdayShort)}
              </Button>
            )}
            {6 !== moment().day() && (
              <Button>{intl.formatMessage(messages.saturdayShort)}</Button>
            )}
            {7 === moment().isoWeekday() && (
              <Button style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                {intl.formatMessage(messages.sundayShort)}
              </Button>
            )}
            {7 !== moment().isoWeekday() && (
              <Button>{intl.formatMessage(messages.sundayShort)}</Button>
            )}
          </ButtonGroup>
        </Grid>
        <Grid xs={12}>
          <ButtonGroup
            fullWidth
            color="secondary"
            style={{ maxWidth: "100%", marginBottom: 10 }}
            aria-label="large outlined secondary button group"
          >
            {week &&
              week.map((day: number, index: number) => {
                console.log("day", day);
                // if (nextMatch && day === moment(nextMatch.date).date()) {
                //   return (
                //     <Button
                //       style={{ backgroundColor: "#005473", color: "white" }}
                //     >
                //       {day}
                //     </Button>
                //   );
                // }
                // if (trainingWeekList && trainingWeekList.includes(day)) {
                //   return (
                //     <Button
                //       style={{ backgroundColor: "#FF5722", color: "white" }}
                //     >
                //       {day}
                //     </Button>
                //   );
                // }
                switch (index) {
                  case 0:
                    if (trainingWeekList.monday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  case 1:
                    if (trainingWeekList.tuesday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  case 2:
                    if (trainingWeekList.wednesday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  case 3:
                    if (trainingWeekList.thursday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  case 4:
                    if (trainingWeekList.friday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  case 5:
                    if (trainingWeekList.saturday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  case 6:
                    if (trainingWeekList.sunday) {
                      return (
                        <Button
                          style={{ backgroundColor: "#FF5722", color: "white" }}
                        >
                          {day}
                        </Button>
                      );
                    }
                    break;
                  default:
                    return <Button>{day}</Button>;
                }
                // if (day == moment().date()) {
                //   return (
                //     <Button
                //       style={{
                //         backgroundColor: "rgb(224, 224, 224)",
                //         color: "white",
                //       }}
                //     >
                //       {day}
                //     </Button>
                //   );
                // }

                return <Button>{day}</Button>;
              })}
          </ButtonGroup>
        </Grid>
        {/* <LegendRow>
          <LegendChip
            size="small"
            label={intl.formatMessage(messages.legendTrainings)}
            onClick={() => null}
            color="secondary"
          />
          <LegendChip
            size="small"
            label={intl.formatMessage(messages.legendMatches)}
            onClick={() => null}
            color="primary"
          />
          <LegendChipDefault
            size="small"
            label={intl.formatMessage(messages.legendToday)}
            onClick={() => null}
            color="default"
          />
        </LegendRow> */}
      </>
    );
  }
);

export default CalendarComponent;
