import React from "react";
import BaseButton from "../../button";
import { IStyledArguments } from "../../../types/styled-arguments";
import { styled } from "@material-ui/styles";

const RegisterStepButton = styled(props => (
  <BaseButton size="large" {...props} />
))((props: IStyledArguments) => ({
  marginTop: props.theme.spacing(4)
}));

export default RegisterStepButton;
