import React, { useState, useEffect } from "react";
import SpiderGraph from "../spider-graph";
import Typography from "../typography";
import { Slider, Theme, Grid, styled } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Content from "../content";
import Button from "../button";
// import gql from "graphql-tag";
import { gql, useMutation } from "@apollo/client";
import { injectIntl } from "react-intl";
import messages from "./PageViewPlayer.intl";
import moment from "moment";

const CREATE_REVIEW_MUTATION = gql`
  mutation CreateReview(
    $playerId: ID!
    $speed: Int!
    $vision: Int!
    $dribbling: Int!
    $shoot: Int!
    $physical: Int!
    $leadership: Int!
    $defense: Int!
  ) {
    createReview(
      input: {
        playerId: $playerId
        speed: $speed
        vision: $vision
        dribbling: $dribbling
        shoot: $shoot
        physical: $physical
        leadership: $leadership
        defense: $defense
      }
    ) {
      data {
        speed
        vision
        dribbling
        shoot
        physical
        leadership
        defense
      }
      date
    }
  }
`;

const wrapperStyles = {
  width: "300px",
  backgroundColor: "white",
  marginTop: "4em",
};

const colorList: string[] = [
  "#b3e5fc90",
  "#26a69a90",
  "#81c78490",
  "#00838f90",
  "#ff572290",
  "#29b6f690",
  "#ce93d890",
  "#ffeb3b90",
  "#f0629290",
  "#90a4ae90",
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300,
    },
    margin: {
      height: theme.spacing(3),
      minHeight: 100,
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

const valuetext = (value: number) => `${value}`;

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

const LastEvaluationContainer = styled("div")(() => ({
  width: "100%",
}));

const NewEvaluationContainer = styled("div")(() => ({
  width: "100%",
}));

const GridContainer = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
});

const Main = styled("main")<Theme>(({ theme }) => ({
  padding: theme.spacing(2),
  display: "block", // IE
  minHeight: "100vh",
  // minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  background: theme.palette.common.white,
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StatsTab = injectIntl<any>(({ reviews, id, intl }: any) => {
  const lastReview = reviews && reviews[reviews.length - 1];
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [vision, setVision] = useState(0);
  const [dribbling, setDribbling] = useState(0);
  const [shoot, setShoot] = useState(0);
  const [physical, setPhysical] = useState(0);
  const [leadership, setLeadership] = useState(0);
  const [defense, setDefense] = useState(0);
  const [spiderDataSet, setSpiderDataset] = useState<any>([
    // {
    //   label: "last",
    //   backgroundColor: "rgba(173, 2, 252, 0.6)",
    //   data: [
    //     (lastReview && lastReview.data.speed) || 0,
    //     (lastReview && lastReview.data.vision) || 0,
    //     (lastReview && lastReview.data.dribbling) || 0,
    //     (lastReview && lastReview.data.shoot) || 0,
    //     (lastReview && lastReview.data.physical) || 0,
    //     (lastReview && lastReview.data.leadership) || 0,
    //     (lastReview && lastReview.data.defense) || 0
    //   ]
    // }
  ]);

  useEffect(() => {
    if (reviews) {
      const mappedData = reviews.map((review: any, index: number) => {
        console.log("[spider]", review.date);
        return {
          label: moment(Number(review.date)).format("DD-MM-YYYY"),
          backgroundColor: colorList[index],
          borderColor: colorList[index],
          borderWidth: 1,
          data: [
            review.data.speed,
            review.data.vision,
            review.data.dribbling,
            review.data.shoot,
            review.data.physical,
            review.data.leadership,
            review.data.defense,
          ],
        };
      });
      setSpiderDataset(mappedData);
    }
  }, [reviews]);

  const [createReview] = useMutation(CREATE_REVIEW_MUTATION);

  const [lastEvaluation] = useState(lastReview && lastReview.data);

  const handleSpeed = (value: any) => {
    setSpeed(value);
  };
  const handleVision = (value: any) => {
    setVision(value);
  };
  const handleDribbling = (value: any) => {
    setDribbling(value);
  };

  const handleShoot = (value: any) => {
    setShoot(value);
  };
  const handlePhysical = (value: any) => {
    setPhysical(value);
  };
  const handleLeadership = (value: any) => {
    setLeadership(value);
  };
  const handleDefense = (value: any) => {
    setDefense(value);
  };

  const generateSpiderMap = () => {
    const newState = [...spiderDataSet];
    newState.push({
      label: "new",
      backgroundColor: "rgba(13, 69, 93, 0.5)",
      data: [speed, vision, dribbling, shoot, physical, leadership, defense],
    });
    setSpiderDataset(newState);
  };

  const handleSave = () => {
    console.log("saving...");
    generateSpiderMap();
    createReview({
      variables: {
        playerId: id,
        speed,
        vision,
        dribbling,
        shoot,
        physical,
        leadership,
        defense,
      },
    });
  };

  return (
    <Main>
      <MainContent container>
        <GridContainer>
          {!isEdit && lastEvaluation && (
            <LastEvaluationContainer>
              <Grid item xs={12}>
                <Typography.H6 id="discrete-slider-always" gutterBottom>
                  {`${intl.formatMessage(messages.reviewed)} ${intl.formatDate(
                    new Date(Number(lastReview.date))
                  )}`}
                </Typography.H6>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.speed)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.speed}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.vision)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.vision}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.dribbling)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.dribbling}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.shoot)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.shoot}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.physical)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.physical}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.leadership)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.leadership}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.defense)}
                  </Typography.Subtitle1>
                  <Slider
                    value={lastEvaluation.defense}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />
                </div>
              </Grid>
            </LastEvaluationContainer>
          )}
          {isEdit && (
            <NewEvaluationContainer>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.speed)}
                  </Typography.Subtitle1>
                  <Slider
                    value={speed}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handleSpeed(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.vision)}
                  </Typography.Subtitle1>
                  <Slider
                    value={vision}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handleVision(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.dribbling)}
                  </Typography.Subtitle1>
                  <Slider
                    value={dribbling}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handleDribbling(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.shoot)}
                  </Typography.Subtitle1>
                  <Slider
                    value={shoot}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handleShoot(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.physical)}
                  </Typography.Subtitle1>
                  <Slider
                    value={physical}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handlePhysical(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.leadership)}
                  </Typography.Subtitle1>
                  <Slider
                    value={leadership}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handleLeadership(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  <Typography.Subtitle1
                    id="discrete-slider-always"
                    gutterBottom
                  >
                    {intl.formatMessage(messages.defense)}
                  </Typography.Subtitle1>
                  <Slider
                    value={defense}
                    max={10}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                    disabled={!isEdit}
                    onChange={(_, value) => handleDefense(value)}
                  />
                </div>
              </Grid>
            </NewEvaluationContainer>
          )}
          {!isEdit && (
            <Grid item xs={12}>
              <Button onClick={() => setIsEdit(true)}>
                {intl.formatMessage(messages.newPlayerReview)}
              </Button>
            </Grid>
          )}
          {isEdit && (
            <Grid item xs={12}>
              <Button onClick={handleSave}>
                {intl.formatMessage(messages.save)}
              </Button>
            </Grid>
          )}

          {/* {spiderDataSet && spiderDataSet.length > 0 && ( */}
          <Grid item xs={12}>
            <Centered>
              <div style={wrapperStyles}>
                <SpiderGraph
                  width={500}
                  height={500}
                  labels={[
                    intl.formatMessage(messages.speed),
                    intl.formatMessage(messages.vision),
                    intl.formatMessage(messages.dribbling),
                    intl.formatMessage(messages.shoot),
                    intl.formatMessage(messages.physical),
                    intl.formatMessage(messages.leadership),
                    intl.formatMessage(messages.defense),
                  ]}
                  tooltipLabel="Score"
                  datasets={spiderDataSet}
                />
              </div>
            </Centered>
          </Grid>
          {/* )} */}
        </GridContainer>
      </MainContent>
    </Main>
  );
});

export default StatsTab;
