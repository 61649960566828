import { defineMessages } from "react-intl";

export default defineMessages({
  // groups: {
  //   id: "Groups.Groups",
  //   defaultMessage: "Groups"
  // },
  searchGroups: {
    id: "Matches.SearchGroups",
    defaultMessage: "Search groups",
  },
  loadError: {
    id: "Matches.LoadError",
    defaultMessage: "Error loading matches",
  },
  leadersCount: {
    id: "Matches.LeadersCount",
    defaultMessage: `{n, plural,
      =0 {no players}
      one {1 player}
      other {{n} players}
    }`,
  },
  sortBy: {
    id: "Matches.SortBy",
    defaultMessage: "Sort by",
  },
  mostRecentSorting: {
    id: "Matches.MostRecentSorting",
    defaultMessage: "Most recent",
  },
  nameSorting: {
    id: "Matches.NameSorting",
    defaultMessage: "Name",
  },
  newGroup: {
    id: "Matches.NewGroup",
    defaultMessage: "Create group",
  },
  newGroupDescription: {
    id: "Matches.NewGroupDescription",
    defaultMessage: "Enter the group name",
  },
  cancelButton: {
    id: "Matches.CancelButton",
    defaultMessage: "Cancel",
  },
  createButton: {
    id: "Matches.CreateButton",
    defaultMessage: "Create",
  },
  groupNameLabel: {
    id: "Matches.GroupNameLabel",
    defaultMessage: "Group name",
  },
  noResults: {
    id: "Matches.NoResults",
    defaultMessage: "No results",
  },
  noMatches: {
    id: "Matches.noMatches",
    defaultMessage: "No matches yet",
  },
  defaultError: {
    id: "Matches.DefaultError",
    defaultMessage: "Error creating group",
  },
});
