import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { IPlayer } from "../../types";
import Link from "../link";
import { injectIntl } from "react-intl";
import messages from "./PageViewPlayer.intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      flexWrap: "wrap"
    },
    paper: {
      padding: theme.spacing(1, 2),
      marginTop: theme.spacing(2)
    }
  })
);

const Breadcrumb = injectIntl<{ player: IPlayer }>(({ player, intl }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            {intl.formatMessage(messages.team)}
          </Link>
          <Link color="inherit" to="/people/players">
            {intl.formatMessage(messages.players)}
          </Link>
          <Typography color="textPrimary">
            {player.name} {player.surname}
          </Typography>
        </Breadcrumbs>
      </Paper>
      <br />
    </div>
  );
});

export default Breadcrumb;
