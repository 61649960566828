import { defineMessages } from "react-intl";

export default defineMessages({
  programmes: {
    id: "Players.Programmes",
    defaultMessage: `Programmes`,
  },
  searchLeaders: {
    id: "Players.SearchLeaders",
    defaultMessage: `Search leaders`,
  },
  searchAdmins: {
    id: "Players.searchAdmins",
    defaultMessage: `Search admins`,
  },
  nameColumn: {
    id: "Players.NameColumn",
    defaultMessage: `Name`,
  },
  functionColumn: {
    id: "Players.FunctionColumn",
    defaultMessage: `Function`,
  },
  emailColumn: {
    id: "Players.EmailColumn",
    defaultMessage: `Email address`,
  },
  positionColumn: {
    id: "Players.positionColumn",
    defaultMessage: `Position`,
  },
  teamColumn: {
    id: "Players.teamColumn",
    defaultMessage: `Team`,
  },
  loading: {
    id: "Players.Loading",
    defaultMessage: `Loading...`,
  },
  noResults: {
    id: "Players.NoResults",
    defaultMessage: `No results`,
  },
  assessmentDone: {
    id: "Players.AssessmentAvailable",
    defaultMessage: `Assessment available`,
  },
  assessmentNotDone: {
    id: "Players.AssessmentNotAvailable",
    defaultMessage: `Assessment not available`,
  },
  addButtonAriaLabel: {
    id: "Players.addButtonAriaLabel",
    defaultMessage: `Add leader`,
  },
  removeButtonAriaLabel: {
    id: "Players.removeButtonAriaLabel",
    defaultMessage: `Remove leader`,
  },
  addAdminButtonAriaLabel: {
    id: "Players.addAdminButtonAriaLabel",
    defaultMessage: `Add admin`,
  },
  removeAdminButtonAriaLabel: {
    id: "Players.removeAdminButtonAriaLabel",
    defaultMessage: `Remove admin`,
  },
  selectFilterAllProgrammes: {
    id: "Players.SelectFilterAllProgrammes",
    defaultMessage: `All leaders`,
  },
  onlyShowMembers: {
    id: "Players.OnlyShowMembers",
    defaultMessage: `Only show members`,
  },
  onlyShowLinkedAdmins: {
    id: "Players.onlyShowLinkedAdmins",
    defaultMessage: `Only show assigned admins`,
  },
  leadersPageError: {
    id: "Players.leadersPageError",
    defaultMessage:
      "An error occurred while attempting to perform the requested action",
  },
  tabsLabelLeaders: {
    id: "Players.tabsLabelLeaders",
    defaultMessage: "Leaders",
  },
  tabsLabelAdmins: {
    id: "Players.tabsLabelAdmins",
    defaultMessage: "Admin",
  },
  programmeOptionsButton: {
    id: "Players.programmeOptionsButton",
    defaultMessage: "Programme options",
  },
  manageMenuItem: {
    id: "Players.manageMenuItem",
    defaultMessage: "Manage",
  },
  deleteMenuItem: {
    id: "Players.deleteMenuItem",
    defaultMessage: "Delete",
  },
  deleteProgrammeTitle: {
    id: "Players.deleteProgrammeTitle",
    defaultMessage: "Delete programme",
  },
  deleteProgrammeMessage: {
    id: "Players.deleteProgrammeMessage",
    defaultMessage:
      "Are you sure you want to delete this programme? This action can not be undone.",
  },
  deleteProgrammeError: {
    id: "Players.deleteProgrammeError",
    defaultMessage: "Error while deleting programme",
  },
  createNewInsight: {
    id: "Players.createNewInsight",
    defaultMessage: "New Insight",
  },
  createNewPlayer: {
    id: "Players.createNewPlayer",
    defaultMessage: "New Player",
  },
  noPlayers: {
    id: "Players.noPlayers",
    defaultMessage: "No players yet",
  },
  statistics: {
    id: "Players.statistics",
    defaultMessage: "Statistics",
  },
  goals: {
    id: "Players.goals",
    defaultMessage: "goals",
  },
  matches: {
    id: "Players.matches",
    defaultMessage: "matches",
  },
  yellowCards: {
    id: "Players.yellowCards",
    defaultMessage: "yellow cards",
  },
  redCards: {
    id: "Players.redCards",
    defaultMessage: "red cards",
  },
  details: {
    id: "Players.details",
    defaultMessage: "Details",
  },
});
