import React from "react";
import {
  Formik,
  FormikActions,
  Form as FormikForm,
  Field,
  FormikProps,
} from "formik";
// import { useQuery } from "@apollo/client";
import { injectIntl, FormattedMessage } from "react-intl";
import TextInput from "../../text-input";
import Typography from "../../typography";
import InputErrorMessage from "../../input-error-message";

import messages from "../PageNewTraining.intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../../types/styled-arguments";
import GoNextButton from "../GoNextButton";
import { required } from "../../form-utils";
// import { AddElementDialog } from "../../atoms";
// import ObjectivesMultiSelect from "./ObjectivesMultiSelect";
// import CategoryComponent from "./CategoryComponent";
import ImageHeaderComponent from "./ImageHeaderComponent";
// import {
//   TextField,
//   ExpansionPanel,
//   ExpansionPanelSummary,
//   ExpansionPanelDetails,
//   Fab,
//   NativeSelect,
//   FormHelperText,
//   FormControl,
//   InputLabel,
// } from "@material-ui/core";
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import AddSection from "../AddExercise";
// import { GET_EXERCISES } from "../../exercises-collapse/Exercises";

const Title = styled(({ children }: any) => (
  <Typography.H5>{children}</Typography.H5>
))((props: IStyledArguments) => {
  const { md } = props.theme.breakpoints.values;

  return {
    marginBottom: 23,
    [`@media (max-width: ${md}px)`]: {
      marginBottom: 27,
    },
  };
});

export interface IFormValues {
  name: string;
  // category: string;
  // subcategory: string;
  // objectives: string[];
  // shortDes
}

const StyledForm = styled(FormikForm)(() => ({
  display: "flex",
  flexDirection: "column",
}));

// const Section = styled("div")(({ theme }: IStyledArguments) => ({
//   borderLeft: "solid",
//   borderColor: theme.palette.extra.borderColor,
//   padding: "16px",
//   marginBottom: "16px",
// }));

// const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
//   color: theme.palette.extra.borderColor,
//   fontWeight: "bolder",
//   fontSize: "large",
//   marginBottom: "5px",
// }));

interface IForm {
  onSubmit: (arg: IFormValues) => void;
  // checkValid: boolean;
  // name: string;
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       "& > *": {
//         margin: theme.spacing(1),
//       },
//     },
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//   })
// );

const NewTrainingForm = injectIntl<IForm>(({ onSubmit, intl }) => {
  // ({ onSubmit, checkValid, name, intl }) => {
  // const classes = useStyles();
  // const [disabledAddSection, setDisabledAddSection] = useState<boolean>(true);
  // const [inputNewSection, setInputNewSection] = useState<string>("");
  // const [sections, setSections] = useState<any>({});
  // const [allExercises, setAllExercises] = useState<any>([]);
  // const { data } = useQuery<any>(GET_EXERCISES);

  // const [state, setState] = useState<{
  //   age: string | number;
  //   name: string;
  // }>({
  //   age: "",
  //   name: "hai",
  // });

  // useEffect(() => {
  //   if (data && data.allExercises) {
  //     console.log("data.allExercises");
  //     console.log(data.allExercises);
  //     setAllExercises(data.allExercises);
  //   }
  // }, [data]);

  // const handleChange = (
  //   event: React.ChangeEvent<{ name?: string; value: unknown }>,
  //   exerciseId: string,
  //   section: string
  // ) => {
  //   const time = event.target.value;
  //   const newSections = { ...sections };
  //   const oldTime = newSections[section].exercises[exerciseId].time;

  //   newSections[section].exercises[exerciseId].time = Number(time);
  //   newSections[section].duration =
  //     Number(newSections[section].duration) - Number(oldTime) + Number(time);

  //   console.log("==========");
  //   console.log(newSections);
  //   console.log("==========");
  //   console.log(JSON.stringify(newSections));
  //   // setInputNewSection("");
  //   setSections(newSections);
  // };

  // const handleChangeAddInput = (e: any) => {
  //   const input = e.target.value;
  //   if (input.length > 0) {
  //     // setDisabledAddSection(false);
  //     setInputNewSection(input);
  //   }
  // };
  // const handleAddSection = () => {
  //   if (inputNewSection !== "") {
  //     const newSections = { ...sections };
  //     newSections[inputNewSection] = {
  //       exercises: [],
  //       duration: 0,
  //     };
  //     setInputNewSection("");
  //     setSections(newSections);
  //   }
  // };

  // const handleExercisesAdded = (section: string, exercises: string[]) => {
  //   const newSections = { ...sections };
  //   // newSections[section] = [...newSections[section], ...exercises];
  //   // newSections[]
  //   exercises.forEach(
  //     (exercise) => (newSections[section].exercises[exercise] = { time: 0 })
  //   );
  //   console.log("==========");
  //   console.log(newSections);
  //   console.log(JSON.stringify(newSections));
  //   console.log("==========");
  //   setInputNewSection("");
  //   setSections(newSections);
  // };

  // useEffect(() => {
  //   if (sections) {
  //     console.log("sections updated", sections);
  //   }
  // }, [sections]);

  // const getExerciseInfo = (id: string): any => {
  //   return allExercises.filter((ex: any) => ex.id === id)[0];
  // };

  return (
    <Formik
      initialValues={{
        name,
      }}
      // isInitialValid={checkValid}
      onSubmit={(values: IFormValues, actions: FormikActions<IFormValues>) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      render={({
        errors,
        touched,
        // values,
        isValid,
      }: // setFieldValue,
      FormikProps<IFormValues>) => (
        <>
          <ImageHeaderComponent />
          <Title>
            <FormattedMessage {...messages.newTraining} />
          </Title>
          <StyledForm data-testid="add-player-form">
            <Field
              name="name"
              validate={required(intl)}
              error={errors.name && touched.name}
              component={TextInput}
              inputProps={{
                "data-testid": "name",
              }}
              label={intl.formatMessage(messages.name)}
            />
            <InputErrorMessage name="name" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <ObjectivesMultiSelect
                  callback={(objectives: string[]) => {
                    setFieldValue("objectives", objectives);
                  }}
                /> */}
            </div>{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "baseline",
                marginBottom: "16px",
              }}
            >
              {/* <TextField
                  id="standard-basic"
                  value={inputNewSection}
                  label="Add new section"
                  onChange={handleChangeAddInput}
                /> */}

              {/* <Fab
                  variant="round"
                  size="small"
                  color="inherit"
                  aria-label="add"
                  // disabled={disabledAddSection}
                  onClick={handleAddSection}
                  disabled={inputNewSection === ""}
                >
                  <AddIcon />
                </Fab> */}
              {/* <AddElementDialog
                  title={inputNewSection}
                  callback={(x: string) => handleAddSection(x)}
                  disable={inputNewSection === ""}
                  children={<div>hola</div>}
                /> */}
            </div>
            {/* {sections &&
                Object.keys(sections).length > 0 &&
                Object.keys(sections).map((section) => (
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography.Subtitle1>
                        {section}
                        {` (${sections[section].duration} min)`}
                      </Typography.Subtitle1>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {sections[section] &&
                          Object.keys(sections[section].exercises).map(
                            (exerciseId) => (
                              <Section>
                                <SectionTitle>
                                  {getExerciseInfo(exerciseId) &&
                                    getExerciseInfo(exerciseId).name}
                                </SectionTitle>
                                <Typography.Subtitle2>
                                  {getExerciseInfo(exerciseId) &&
                                    getExerciseInfo(exerciseId)
                                      .shortDescription}
                                </Typography.Subtitle2>
                                <FormControl className={classes.formControl}>
                                  <InputLabel htmlFor="age-native-helper">
                                    Duration
                                  </InputLabel>
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleChange(e, exerciseId, section)
                                    }
                                    inputProps={{
                                      name: "age",
                                      id: "age-native-helper",
                                    }}
                                  >
                                    <option aria-label="None" value="" />
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                  </NativeSelect>
                                  <FormHelperText>minutes</FormHelperText>
                                </FormControl>
                              </Section>
                            )
                          )}
                        <AddSection
                          callback={(exercises) => {
                            handleExercisesAdded(section, exercises);
                            console.log(
                              "los ejerecicios seleccionados son: ",
                              exercises
                            );
                          }}
                        />
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))} */}
            <div>
              <GoNextButton
                type="submit"
                data-testid="go-next-button"
                disabled={!isValid}
              >
                {intl.formatMessage(messages.next)}
              </GoNextButton>
            </div>
          </StyledForm>
        </>
      )}
    />
  );
});

export default NewTrainingForm;
