import React from "react";
import useAuthContext from "../../hooks/useAuthContext";
import { Redirect, RouteComponentProps } from "@reach/router";
import Header from "../headerV2";
// import Navigation from "../navigation";
// import Navigation from "../bottom-navigation";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { Permission } from "../auth-context-provider";
import { useHasPermission } from "../../hooks/useHasPermission";

interface IProtectedRouteProps extends RouteComponentProps {
  component: any;
  /**
   * Where the user should be redirected to if not authenticated or permitted
   *
   * @default "/login"
   */
  unauthorizedRedirectTo?: string;
  withPermission?: Permission;
}

const HeaderSpacer = styled("div")(({ theme }: IStyledArguments) => ({
  height: theme.layout.headerHeight,
  width: "100%",
}));

const FlexContainer = styled("div")(({ theme }: IStyledArguments) => ({
  display: "flex",
  minHeight: `calc(100vh - ${theme.layout.headerHeight})`,
}));

const ComponentWrapper = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  height: ` calc(100vh - ${theme.layout.headerHeight})`,
  // marginLeft: theme.layout.navDrawerWidthClosed,
  [theme.breakpoints.up("xl")]: {
    marginLeft: 0,
  },
}));

function ProtectedRoute<T = void>({
  component: Component,
  unauthorizedRedirectTo = "/login",
  withPermission,
  ...rest
}: IProtectedRouteProps & Partial<T>): JSX.Element {
  const [state] = useAuthContext();

  let hasPermission = true;
  if (withPermission) {
    hasPermission = useHasPermission(withPermission);
  }

  if (!state.isLoggedIn) {
    console.log("no esta logueado....");
    return <Redirect to="/login" noThrow />;
  }
  if (!hasPermission) {
    console.log("no tiene permiso....");
    return <Redirect to={unauthorizedRedirectTo} noThrow />;
  }

  return (
    <>
      <Header />
      <HeaderSpacer />
      <FlexContainer>
        <ComponentWrapper>
          <Component {...rest} />
        </ComponentWrapper>
      </FlexContainer>
      {/* <Navigation /> */}
    </>
  );
}

export default ProtectedRoute;
