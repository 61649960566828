import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { Fab, Badge } from "@material-ui/core";
// import Typography from "../typography";

import { SET_LINEUP } from "./MatchQueries";
import { FormattedMessage, injectIntl } from "react-intl";

import messages from "./PageViewMatch.intl";
import SelectPlayers from "./SelectPlayers";
import UserAvatar from "../user-avatar";
import { useMutation } from "@apollo/client";

import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { YellowCardIcon, RedCardIcon, GoalIcon } from "./StyledElements";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      // border: "px solid #000",
      borderRadius: 8,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  })
);

const Pitch = styled("div")(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/ground.png')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "contain",
  display: "flex",
  margin: "15px",
  position: "relative",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    minHeight: "270px",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    minHeight: "500px",
  },
}));

const Player = styled(Fab)(({ theme }: IStyledArguments) => ({
  position: "absolute",
  width: "50px",
  height: "50px",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: "36px",
    height: "36px",
  },
}));
const GoalKeeper = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "2em",
  left: "calc(50% - 25px)",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "1em",
    left: "calc(50% - 17px)",
  },
}));

const CentreBackLeft = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "8em",
  left: "calc(50% - 11em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "4em",
    left: "calc(50% - 6em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "8em",
    left: "calc(50% - 9em)",
  },
}));

const CentreBack = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "8em",
  left: "calc(50% - 1.5em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "4.5em",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "8em",
  },
}));

const CentreBackRight = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "8em",
  left: "calc(50% + 10em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "4em",
    left: "calc(50% + 3em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "8em",
    left: "calc(50% + 7em)",
  },
}));

const LeftWing = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "20em",
  left: "calc(50% + 14em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "9em",
    left: "calc(50% - 8em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "18em",
    left: "calc(50% - 14em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
    left: "calc(50% - 14em)",
  },
}));
const RightWing = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "20em",
  left: "calc(50% + 14em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "9em",
    left: "calc(50% + 5em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "18em",
    left: "calc(50% + 9em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
    left: "calc(50% + 10em)",
  },
}));

const CentreMidfield = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "18em",
  left: "calc(50% - 1.5em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "8.3em",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "16em",
  },
}));

// const AttackingMidfield = styled(Player)(({ theme }: IStyledArguments) => ({
//   bottom: "18em",
//   left: "calc(50% - 1.5em)",
//   [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
//     bottom: "10em",
//   },
//   [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
//     bottom: "20em",
//   },
// }));

const LeftStriker = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "24em",
  // left: "calc(50% + 14em)",
  left: "calc(50% - 1.5em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "12em",
    // left: "calc(50% - 4em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "24em",
    // left: "calc(50% - 8em)",
  },
}));

const RightStriker = styled(Player)(({ theme }: IStyledArguments) => ({
  bottom: "24em",
  // left: "calc(50% + 14em)",
  left: "calc(50% - 1.5em)",
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    bottom: "16em",
    // left: "calc(50% + 1em)",
  },
  [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
    bottom: "30em",
    // left: "calc(50% + 3em)",
  },
}));

const formation = {
  GK: "",
  LB: "",
  CB: "",
  RB: "",
  LW: "",
  RW: "",
  MF: "",
  AMF: "",
  ST: "",
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: "90vw",
    height: "50vh",
  };
}

const Formation9 = injectIntl<any>(
  ({
    matchId,
    team,
    lineUp,
    callback,
    isMatchOngoing,
    subs,
    onMatchEvent,
    scorerList,
  }) => {
    const classes = useStyles();
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectingPosition, setSelectingPosition] = useState<string>("");
    const [initialFormation, setIntialFormation] = useState<any>(formation);
    const [setLineUp] = useMutation(SET_LINEUP);
    const [modalStyle] = useState<any>(getModalStyle);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalBody, setModalBody] = useState<any>();

    useEffect(() => {}, [scorerList]);

    useEffect(() => {
      if (lineUp) {
        setIntialFormation(lineUp);
      }
    }, [lineUp]);

    useEffect(() => {
      if (initialFormation) {
        console.log("[formation9]initialFormation=============");
        console.log(initialFormation);
        // const selected = calculatePlayerSelected();
        // console.log("8888888888888 ", selected);
        callback(initialFormation);
        console.log(
          "[formation9]SavingFormation=============",
          initialFormation
        );
        handleSetLineUp();
      }
    }, [initialFormation]);

    useEffect(() => {
      if (modalBody) {
        handleOpenModal();
      }
    }, [modalBody]);

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const getPlayer = (position: string) => {
      const user = {
        id: "",
        name: "Not",
        surname: "Found",
        avatarUrl: undefined,
      };
      const playerId = initialFormation[position];
      if (playerId && playerId !== "") {
        const player = team.filter((player: any) => player.id === playerId)[0];
        if (player) {
          user.id = player.id;
          user.name = player.name;
          user.surname = player.surname;
          user.avatarUrl = player.avatarUrl;
        }
      }
      return user;
    };

    const handleSetLineUp = () => {
      let empty = true;
      const lineUp = Object.keys(initialFormation).map((key: string) => {
        if (initialFormation[key] !== "") {
          empty = false;
        }

        return {
          player: initialFormation[key],
          position: key,
        };
      });
      if (!empty) {
        setLineUp({
          variables: {
            matchId,
            lineUp,
          },
        });
      } else {
        console.log("[formation9] empty, not saving formation");
      }
    };

    const handleOpenSelect = (position: string) => {
      if (isMatchOngoing) {
        console.log("match is on going", position);
        console.log("match is on going", initialFormation[position]);
        console.log("match is on going", team);
        const player = team.find(
          (p: any) => p.id === initialFormation[position]
        );
        if (player) {
          console.log(player);
          // handleOpenModal();
          const newModalBody = generateModalBody(player);
          setModalBody(newModalBody);
        }
      } else {
        setSelectingPosition(position);
        setSelectOpen(true);
      }
    };

    const handleCloseSelect = (selection: any) => {
      const newFormation = { ...initialFormation };
      newFormation[selectingPosition] = selection;
      setIntialFormation(newFormation);
      setSelectOpen(false);
    };

    const generateModalBody = (player: any) => {
      // const [subsVisible, setSubsVisible] = useState<any>(false);

      return (
        <div style={modalStyle} className={classes.paper}>
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            id="match-ongoing-action"
          >
            {`${player.name} ${player.surname}`}
            <Badge
              color="secondary"
              badgeContent={scorerList && scorerList[player.id]}
            >
              <UserAvatar
                style={{ width: "120px", height: "120px" }}
                user={player}
              />
            </Badge>
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <Fab
              onClick={() => {
                const matchEvent = {
                  type: "Goal",
                  player: player.id,
                };
                onMatchEvent(matchEvent);
                handleCloseModal();
              }}
            >
              <GoalIcon />
            </Fab>
            {scorerList && scorerList[player.id] ? (
              <Fab
                onClick={() => {
                  const matchEvent = {
                    type: "removeGoal",
                    player: player.id,
                  };
                  onMatchEvent(matchEvent);
                  handleCloseModal();
                }}
              >
                <RemoveIcon />
              </Fab>
            ) : (
              <></>
            )}
            <Fab
              onClick={() => {
                const matchEvent = {
                  type: "yellowCard",
                  player: player.id,
                };
                onMatchEvent(matchEvent);
                handleCloseModal();
              }}
            >
              <YellowCardIcon />
            </Fab>
            <Fab
              onClick={() => {
                const matchEvent = {
                  type: "redCard",
                  player: player.id,
                };
                onMatchEvent(matchEvent);
                handleCloseModal();
              }}
            >
              <RedCardIcon />
            </Fab>
            {/* {subsVisible && ( */}
            <>
              <div
                style={{
                  width: "100%",
                  marginTop: "16px",
                  textAlign: "center",
                }}
              >
                Substituions{" "}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "2em",
                }}
              >
                {subs &&
                  subs.map((sub: any) => (
                    <div
                      id={sub.id}
                      onClick={() => {
                        const matchEvent = {
                          type: "substitution",
                          playerIn: sub.id,
                          playerOut: player.id,
                        };
                        onMatchEvent(matchEvent);
                        handleCloseModal();
                      }}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={scorerList && scorerList[sub.id]}
                      >
                        <UserAvatar user={sub} />
                      </Badge>
                    </div>
                  ))}
              </div>
            </>
            {/* )} */}
          </div>
        </div>
      );
    };

    return (
      <>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
        <SelectPlayers
          callback={(selection) => handleCloseSelect(selection)}
          team={team}
          initialFormation={initialFormation}
          isOpen={selectOpen}
        />
        <Pitch>
          <GoalKeeper onClick={() => handleOpenSelect("GK")}>
            {initialFormation && initialFormation["GK"] === "" ? (
              <FormattedMessage {...messages.GK} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("GK").id]}
              >
                <UserAvatar user={getPlayer("GK")} />
              </Badge>
            )}
          </GoalKeeper>

          <CentreBackLeft onClick={() => handleOpenSelect("LB")}>
            {initialFormation && initialFormation["LB"] === "" ? (
              <FormattedMessage {...messages.LB} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("LB").id]}
              >
                <UserAvatar user={getPlayer("LB")} />
              </Badge>
            )}
          </CentreBackLeft>

          <CentreBack onClick={() => handleOpenSelect("CB")}>
            {initialFormation && initialFormation["CB"] === "" ? (
              <FormattedMessage {...messages.CB} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("CB").id]}
              >
                <UserAvatar user={getPlayer("CB")} />
              </Badge>
            )}
          </CentreBack>

          <CentreBackRight onClick={() => handleOpenSelect("RB")}>
            {initialFormation && initialFormation["RB"] === "" ? (
              <FormattedMessage {...messages.RB} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("RB").id]}
              >
                <UserAvatar user={getPlayer("RB")} />
              </Badge>
            )}
          </CentreBackRight>
          <LeftWing onClick={() => handleOpenSelect("LW")}>
            {initialFormation && initialFormation["LW"] === "" ? (
              <FormattedMessage {...messages.LW} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("LW").id]}
              >
                <UserAvatar user={getPlayer("LW")} />
              </Badge>
            )}
          </LeftWing>
          <RightWing onClick={() => handleOpenSelect("RW")}>
            {initialFormation && initialFormation["RW"] === "" ? (
              <FormattedMessage {...messages.RW} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("RW").id]}
              >
                <UserAvatar user={getPlayer("RW")} />
              </Badge>
            )}
          </RightWing>
          <CentreMidfield onClick={() => handleOpenSelect("MF")}>
            {initialFormation && initialFormation["MF"] === "" ? (
              <FormattedMessage {...messages.MF} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("MF").id]}
              >
                <UserAvatar user={getPlayer("MF")} />
              </Badge>
            )}
          </CentreMidfield>
          <LeftStriker onClick={() => handleOpenSelect("AMF")}>
            {initialFormation && initialFormation["AMF"] === "" ? (
              <FormattedMessage {...messages.AMF} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("AMF").id]}
              >
                <UserAvatar user={getPlayer("AMF")} />
              </Badge>
            )}
          </LeftStriker>
          <RightStriker onClick={() => handleOpenSelect("ST")}>
            {initialFormation && initialFormation["ST"] === "" ? (
              <FormattedMessage {...messages.ST} />
            ) : (
              <Badge
                color="secondary"
                badgeContent={scorerList && scorerList[getPlayer("ST").id]}
              >
                <UserAvatar user={getPlayer("ST")} />
              </Badge>
            )}
          </RightStriker>
        </Pitch>
      </>
    );
  }
);

export default Formation9;
