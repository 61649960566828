import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import { IMatch } from "../../types";
import Link from "../link";
import { injectIntl } from "react-intl";
import messages from "./PageViewTraining.intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1, 2),
    },
  })
);

const Breadcrumb = injectIntl<{ id: string }>(({ id, intl }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/library">
            {intl.formatMessage(messages.library)}
          </Link>
          <Link color="inherit" to={`/library/trainings`}>
            {intl.formatMessage(messages.trainings)}
          </Link>
          <Link color="inherit" to={`/library/trainings/${id}`}>
            {id}
          </Link>
        </Breadcrumbs>
      </Paper>
      <br />
    </div>
  );
});

export default Breadcrumb;
