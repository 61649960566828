import React, { useState, useEffect } from "react";

import { useQuery, useMutation } from "@apollo/client";
import { injectIntl } from "react-intl";
import Typography from "../typography";
import messages from "./PageNewTraining.intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import AddIcon from "@material-ui/icons/Add";

import {
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Fab,
  NativeSelect,
  FormHelperText,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddExercise from "./AddExercise";
import { GET_EXERCISES } from "../exercises-collapse/Exercises";
import { gql } from "@apollo/client";

export interface IStepAddSections {
  onGoNext: (values: any) => void;
  id: string;
}

const CREATE_SECTION = gql`
  mutation AddSection(
    $name: String!
    $exercises: [ID]
    $sectionDuration: Int
    $durations: [ExerciseDurationInput]
  ) {
    addSection(
      input: {
        name: $name
        exercises: $exercises
        sectionDuration: $sectionDuration
        durations: $durations
      }
    ) {
      id
    }
  }
`;

const ADD_SECTION_TO_TRAINING = gql`
  mutation AddSectionToTraining($id: ID!, $sectionId: ID!) {
    addSectionToTrainingSession(id: $id, sectionId: $sectionId) {
      id
      sections {
        id
        name
      }
    }
  }
`;

const GET_TRAINING = gql`
  query getTraining($id: ID!) {
    trainingSession(id: $id) {
      name
      trainingDuration
      sections {
        name
        sectionDuration
        durations {
          exerciseId
          duration
        }
        exercises {
          id
          name
          objectives
          shortDescription
        }
        durations {
          exerciseId
          duration
        }
      }
    }
  }
`;

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const StepAddSections = injectIntl<IStepAddSections>(
  ({ onGoNext, id, intl }) => {
    const classes = useStyles();
    const [inputNewSection, setInputNewSection] = useState<string>("");
    const [sections, setSections] = useState<any>({});
    // const [sectionJustAdded, setSectionJustAdded] = useState<any>(false);
    const [allExercises, setAllExercises] = useState<any>([]);
    const [readyToSave, setReadyToSave] = useState<boolean>(false);
    const { data } = useQuery<any>(GET_EXERCISES);
    const [createSection, { data: sectionData }] = useMutation(CREATE_SECTION);
    const [
      AddSectionToTraining,
      { data: sectionAddedToTraining },
    ] = useMutation(ADD_SECTION_TO_TRAINING);
    const { data: trainingData, refetch } = useQuery<any>(GET_TRAINING, {
      variables: { id },
    });
    const [training, setTraining] = useState<any>({});
    // const [createSection, { data: sectionData }] = useMutation(CREATE_SECTION);
    const [activeSection, setActiveSection] = useState("");

    useEffect(() => {
      if (trainingData && trainingData.trainingSession) {
        // console.log("trainingData");
        // console.log(trainingData);
        setTraining(trainingData.trainingSession);
      }
    }, [trainingData]);

    useEffect(() => {
      console.log("sectionAdded to training...");
      if (
        sectionAddedToTraining &&
        sectionAddedToTraining.addSectionToTrainingSession
      ) {
        console.log("sectionAdded to training...");
        refetch();
        setActiveSection("");
        setSections({});
      }
    }, [sectionAddedToTraining]);

    useEffect(() => {
      if (data && data.allExercises) {
        // console.log("data.allExercises");
        // console.log(data.allExercises);
        setAllExercises(data.allExercises);
      }
    }, [data]);

    useEffect(() => {
      if (sectionData && sectionData.addSection) {
        console.log("sectionData.addSection");
        console.log(sectionData.addSection);
        //TODO: set setionId
      }
    }, [sectionData]);

    useEffect(() => {
      if (sections) {
      }
    }, [sections]);

    const handleChangeDuration = (
      event: React.ChangeEvent<{ name?: string; value: unknown }>,
      exerciseId: string,
      section: string
    ) => {
      const time = event.target.value;
      const newSections = { ...sections };
      const oldTime = newSections[section].exercises[exerciseId].time;

      newSections[section].exercises[exerciseId].time = Number(time);
      newSections[section].duration =
        Number(newSections[section].duration) - Number(oldTime) + Number(time);

      setSections(newSections);
      setReadyToSave(isReadyToSave());
    };

    const handleChangeAddInput = (e: any) => {
      const input = e.target.value;
      // if (input.length > 0) {
      // setDisabledAddSection(false);
      setInputNewSection(input);
      // }
    };
    const handleAddSection = () => {
      if (inputNewSection !== "") {
        const newSections = { ...sections };
        newSections[inputNewSection] = {
          exercises: [],
          duration: 0,
        };
        setActiveSection(inputNewSection);
        setInputNewSection("");
        setSections(newSections);
        // setSectionJustAdded(true);
        setReadyToSave(false);
      }
    };

    const handleExercisesAdded = (section: string, exercises: string[]) => {
      const newSections = { ...sections };
      // newSections[section] = [...newSections[section], ...exercises];
      // newSections[]
      exercises.forEach(
        (exercise) => (newSections[section].exercises[exercise] = { time: 0 })
      );

      setInputNewSection("");
      setSections(newSections);
    };

    const handleSaveSection = async () => {
      const duration = Object.keys(sections[activeSection].exercises).map(
        (ex) => {
          return {
            exerciseId: ex,
            duration: sections[activeSection].exercises[ex].time,
          };
        }
      );
      const payload = {
        variables: {
          name: activeSection,
          exercises: Object.keys(sections[activeSection].exercises),
          sectionDuration: sections[activeSection].duration,
          durations: duration,
        },
      };
      console.log(payload);
      const result: any = await createSection(payload);
      const resultAdded = await AddSectionToTraining({
        variables: {
          id,
          sectionId: result.data.addSection.id,
        },
      });
      console.log("resultAdded to training");
      console.log(resultAdded);
    };

    const getExerciseInfo = (id: string): any => {
      return allExercises.filter((ex: any) => ex.id === id)[0];
    };

    const isReadyToSave = (): boolean => {
      let ok = true;
      Object.keys(sections).forEach((sec) => {
        if (sections[sec].exercises === undefined) {
          ok = false;
        } else {
          Object.keys(sections[sec].exercises).forEach((ex) => {
            if (sections[sec].exercises[ex].time === 0) {
              ok = false;
            }
          });
        }
      });
      return ok;
    };

    return (
      <>
        <div style={{ marginBottom: "16px" }}>
          <Section key={`exercise-${training.id}`}>
            <SectionTitle>{training.name}</SectionTitle>
            <Typography.Subtitle2>
              total Training time: {training.trainingDuration}
            </Typography.Subtitle2>
          </Section>
          {training.sections &&
            training.sections.map((section: any) => (
              <ExpansionPanel key={`expansion-panel-section-${section.id}`}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`section-${section.name}`}
                >
                  <Typography.Subtitle1>
                    {`${section.name} (${section.sectionDuration}) min`}
                  </Typography.Subtitle1>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {section.exercises &&
                      section.exercises.map((exercise: any) => (
                        <Section key={`exercise-${exercise.id}`}>
                          <SectionTitle>
                            {`${exercise.name} (${0})min`}
                          </SectionTitle>
                          <Typography.Subtitle2>
                            {exercise.shortDescription}
                          </Typography.Subtitle2>
                        </Section>
                      ))}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
        </div>

        {activeSection === "" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "baseline",
              marginBottom: "16px",
            }}
          >
            <TextField
              id="standard-basic"
              value={inputNewSection}
              label="Add new section"
              onChange={handleChangeAddInput}
            />

            <Fab
              variant="round"
              size="small"
              color="inherit"
              aria-label="add"
              // disabled={disabledAddSection}
              onClick={handleAddSection}
              disabled={inputNewSection === ""}
            >
              <AddIcon />
            </Fab>
          </div>
        )}

        {sections &&
          Object.keys(sections).length > 0 &&
          Object.keys(sections).map((section) => (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography.Subtitle1>
                  {section}
                  {` (${sections[section].duration} min)`}
                </Typography.Subtitle1>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {sections[section] &&
                    Object.keys(sections[section].exercises).map(
                      (exerciseId) => (
                        <Section>
                          <SectionTitle>
                            {getExerciseInfo(exerciseId) &&
                              getExerciseInfo(exerciseId).name}
                          </SectionTitle>
                          <Typography.Subtitle2>
                            {getExerciseInfo(exerciseId) &&
                              getExerciseInfo(exerciseId).shortDescription}
                          </Typography.Subtitle2>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor={`select-time-${exerciseId}`}>
                              Duration
                            </InputLabel>
                            <NativeSelect
                              onChange={(e) =>
                                handleChangeDuration(e, exerciseId, section)
                              }
                              inputProps={{
                                name: "age",
                                id: `select-time-${exerciseId}`,
                              }}
                            >
                              <option aria-label="None" value="" />
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={15}>15</option>
                              <option value={20}>20</option>
                              <option value={25}>25</option>
                              <option value={30}>30</option>
                              <option value={35}>35</option>
                              <option value={40}>40</option>
                              <option value={45}>45</option>
                              <option value={50}>50</option>
                              <option value={55}>55</option>
                              <option value={60}>60</option>
                            </NativeSelect>
                            <FormHelperText>minutes</FormHelperText>
                          </FormControl>
                        </Section>
                      )
                    )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <AddExercise
                      callback={(exercises) =>
                        handleExercisesAdded(section, exercises)
                      }
                    />
                    <Button
                      style={{ marginLeft: 8 }}
                      variant="outlined"
                      color="secondary"
                      onClick={handleSaveSection}
                      disabled={!readyToSave}
                    >
                      {intl.formatMessage(messages.save)}
                    </Button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        <Button
          variant="outlined"
          color="secondary"
          onClick={onGoNext}
          disabled={!readyToSave}
          fullWidth
        >
          {intl.formatMessage(messages.finish)}
        </Button>
      </>
    );
  }
);

export default StepAddSections;
