import { defineMessages } from 'react-intl';

export default defineMessages({
  myProfileDetails: {
    id: 'UserMenu.MyProfileDetails',
    defaultMessage: 'My profile details',
  },
  myProfileDetailsTitle: {
    id: 'UserMenu.MyProfileDetailsTitle',
    defaultMessage: 'My Profile',
  },
  myAccount: {
    id: 'UserMenu.MySubscription',
    defaultMessage: 'My subscription',
  },
  subscriptionManagers: {
    id: 'UserMenu.SubscriptionManagers',
    defaultMessage: 'Subscription managers',
  },
  programmeAdmins: {
    id: 'UserMenu.ProgrammeAdmins',
    defaultMessage: 'Programme admins',
  },
  close: {
    id: 'UserMenu.Close',
    defaultMessage: 'Close',
  },
  back: {
    id: 'UserMenu.Back',
    defaultMessage: 'Back',
  },
  addSubscriptionManager: {
    id: 'UserMenu.AddSubscriptionManager',
    defaultMessage: 'Add subscription manager',
  },
  addProgrammeAdmin: {
    id: 'UserMenu.AddProgrammeAdmin',
    defaultMessage: 'Add programme admin',
  },
  loadingResults: {
    id: 'UserMenu.LoadingResults',
    defaultMessage: 'Loading results',
  },
  noResults: {
    id: 'UserMenu.NoResults',
    defaultMessage: 'No results found',
  },
  loadError: {
    id: 'UserMenu.LoadError',
    defaultMessage: 'An error occurred while performing the operation',
  },
  logout: {
    id: 'UserMenu.Logout',
    defaultMessage: 'Sign out',
  },
  email: {
    id: 'UserMenu.Email',
    defaultMessage: 'Email',
  },
  firstName: {
    id: 'UserMenu.FirstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'UserMenu.LastName',
    defaultMessage: 'Last name',
  },
  password: {
    id: 'UserMenu.Password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'UserMenu.ConfirmPassword',
    defaultMessage: 'Confirm password',
  },
  update: {
    id: 'UserMenu.Update',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'UserMenu.Cancel',
    defaultMessage: 'Cancel',
  },
  role: {
    id: 'UserMenu.Role',
    defaultMessage: 'Role',
  },
  updateUserError: {
    id: 'UserMenu.UpdateUserError',
    defaultMessage: 'Failed to update user profile.',
  },
  loadUserError: {
    id: 'UserMenu.LoadUserError',
    defaultMessage: 'Failed to load user profile.',
  },
  LEADER: {
    id: 'UserMenu.Leader',
    defaultMessage: 'Leader',
  },
  SM: {
    id: 'UserMenu.SubscriptionManager',
    defaultMessage: 'Subscription Manager',
  },
  GA: {
    id: 'UserMenu.GroupAdministrator',
    defaultMessage: 'Group Administrator',
  },
});
