import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Card,
  Chip,
} from "@material-ui/core";

import Typography from "../../typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { gql } from "@apollo/client";
import { styled } from "@material-ui/styles";
// import { ActionLink } from "../../atoms";
// import AddIcon from "@material-ui/icons/Add";
import { injectIntl } from "react-intl";
import messages from "./Squad.intl";
import { IStyledArguments } from "../../../types/styled-arguments";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import useIsAdmin from "../../../hooks/useIsAdmin";
import UserAvatar from "../../user-avatar";
import SoccerBallIcon from "@material-ui/icons/SportsSoccer";
import EventIcon from "@material-ui/icons/Event";
import NoSimIcon from "@material-ui/icons/NoSim";

// export const GET_EXERCISES = gql`
//   query {
//     allExercises {
//       id
//       name
//       shortDescription
//       category
//       subcategory
//       objectives
//       taskDevelopment
//     }
//     currentUser {
//       id
//       role
//     }
//   }
// `;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "11px",
      width: "100%",
      backgroundColor: theme.palette.extra.lighterGrey,
      padding: "16px",
      minHeight: "75vh",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

// const FooterContainer = styled("div")<Theme>(({ theme }) => ({
//   display: "flex",
//   justifyContent: "flex-end",
//   padding: theme.spacing(2),
//   height: 90,
//   width: "auto",
//   right: 0,
//   position: "fixed",
//   bottom: 70,
// }));

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/exerciseBG.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "450px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
}));

const Title = styled("div")({
  color: "White",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});

interface ISquad {
  selectable?: boolean;
  selectedPlayers?: any;
  team: any;
  callback?: (player: string) => void;
}

const SquadCollapse = injectIntl<ISquad>(
  ({ selectable = false, callback, team, selectedPlayers, intl }) => {
    const classes = useStyles();

    console.log("selectable", selectable);
    // const { data, loading } = useQuery<any>(GET_EXERCISES);
    // const [allExercises, setAllExercises] = useState([]);
    const [selectedExercises, setSelectedExercises] = useState<string[]>([]);
    const [selectedPlayer, setSelectedPlayer] = useState<string>();
    const [open] = React.useState(false);
    // console.log(data);

    // useEffect(() => {
    //   if (data && data.allExercises) {
    //     setAllExercises(data.allExercises);
    //   }
    // }, [data]);

    // useEffect(() => {
    //   if (loading) {
    //     setOpen(true);
    //   } else {
    //     setOpen(false);
    //   }
    // }, [loading]);

    useEffect(() => {
      if (selectedPlayer) {
        callback && callback(selectedPlayer);
      }
    }, [selectedPlayer]);

    const handleSelectPlayer = (id: string) => {
      const newSelectedExercises = [...selectedExercises];
      newSelectedExercises.push(id);
      setSelectedExercises(newSelectedExercises);
      setSelectedPlayer(id);
      // callback && callback(selectedExercises);
      // setOpen(false);
    };
    const handleUnSelectPlayer = (id: string) => {
      const newSelectedExercises = [...selectedExercises];
      const index = newSelectedExercises.indexOf(id);
      if (index > -1) {
        newSelectedExercises.splice(index, 1);
      }
      setSelectedExercises(newSelectedExercises);
    };

    const playerAlreadySelected = (playerId: string): boolean => {
      let found = false;
      if (selectedPlayers) {
        const keys = Object.keys(selectedPlayers);
        // iterate over object
        keys.forEach((key: string) => {
          if (selectedPlayers[key] === playerId) {
            found = true;
            return;
          }
        });
      }
      return found;
    };

    return (
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!team ||
          (team.length === 0 && (
            <CardWrapper>
              <Title>{intl.formatMessage(messages.noResults)}</Title>
            </CardWrapper>
          ))}
        {team &&
          team.map((player: any) => (
            <ExpansionPanel
              style={{
                background: playerAlreadySelected(player.id) ? "#f5f5f5" : "",
              }}
              disabled={playerAlreadySelected(player.id)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={`panel-${player.id}`}
              >
                <Typography.Subtitle1>
                  {`${player.name} ${player.surname}`}
                </Typography.Subtitle1>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Section>
                    <SectionTitle>
                      {/* {intl.formatMessage(messages.description)} */}
                    </SectionTitle>
                    <Typography.Subtitle2>
                      <UserAvatar
                        style={{ width: 80, height: 80, fontSize: "3rem" }}
                        user={{
                          name: player.name,
                          surname: player.surname,
                          avatarUrl: player.avatarUrl,
                        }}
                      />
                    </Typography.Subtitle2>
                  </Section>
                  <Section>
                    <SectionTitle>
                      {intl.formatMessage(messages.statistics)}
                    </SectionTitle>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Chip
                        style={{
                          borderRadius: 17,
                          backgroundColor: "#4CAF50",
                          color: "white",
                          marginRight: 16,
                          marginTop: 8,
                        }}
                        size="small"
                        icon={<SoccerBallIcon style={{ color: "white" }} />}
                        label={`${player.totalGoals || 0} ${intl.formatMessage(
                          messages.goals
                        )}`}
                        onClick={() => null}
                      />
                      <Chip
                        style={{
                          borderRadius: 17,
                          marginRight: 16,
                          marginTop: 8,
                        }}
                        size="small"
                        color="primary"
                        icon={<EventIcon />}
                        label={`${player.totalMatches ||
                          0} ${intl.formatMessage(messages.matches)}`}
                        onClick={() => null}
                      />
                      <Chip
                        style={{
                          borderRadius: 17,
                          backgroundColor: "#ffea00", //yellow
                          color: "black",
                          marginRight: 16,
                          marginTop: 8,
                        }}
                        size="small"
                        icon={<NoSimIcon />}
                        label={`${player.yellowCards || 0} ${intl.formatMessage(
                          messages.yellowCards
                        )}`}
                        onClick={() => null}
                      />
                      <Chip
                        style={{
                          borderRadius: 17,
                          backgroundColor: "#e53935", //red
                          color: "white",
                          marginTop: 8,
                        }}
                        size="small"
                        icon={<NoSimIcon style={{ color: "white" }} />}
                        label={`${player.redCards || 0} ${intl.formatMessage(
                          messages.redCards
                        )}`}
                        onClick={() => null}
                      />
                    </div>
                  </Section>
                  <div>
                    {selectable && !selectedExercises.includes(player.id) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={() => handleSelectPlayer(player.id)}
                      >
                        {intl.formatMessage(messages.addToTraining)}
                      </Button>
                    )}
                    {selectable && selectedExercises.includes(player.id) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => handleUnSelectPlayer(player.id)}
                      >
                        {intl.formatMessage(messages.removeFromTraining)}
                      </Button>
                    )}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        {/* <FooterContainer>
          {!isTraining && useIsAdmin() && (
            <ActionLink to="/library/newexercise">
              <Fab
                aria-label="add team"
                color="secondary"
                style={{ zIndex: 1200 }}
              >
                <AddIcon />
              </Fab>
            </ActionLink>
          )}
        </FooterContainer> */}
      </div>
    );
  }
);

export default SquadCollapse;
