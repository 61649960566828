import React, { useState, useEffect } from "react";
import { Formik, Form as FormikForm, Field, FormikProps } from "formik";
import messages from "./PageViewProfile.intl";
import { injectIntl } from "react-intl";
import { styled } from "@material-ui/core/styles";
import TextInput from "../../text-input";
import InputErrorMessage from "../../input-error-message";
import { required } from "../../form-utils";
import Button from "../../button";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import { Grid, Chip } from "@material-ui/core";
import SnackBar from "../../snackbar";
import WorkIcon from "@material-ui/icons/Work";
import { UserAvatarUploader } from "../../atoms/";

interface IFormValues {
  name: string;
  surname: string;
  email: string;
  role: string;
  password: string;
  passwordConfirmation: string;
}

const StyledForm = styled(FormikForm)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const FormButton = styled((props) => <Button size="large" {...props} />)(
  (props) => ({
    marginTop: props.theme.spacing(4),
  })
);

export const USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      name
      surname
      email
      role
      telephone
      workspace
      avatarUrl
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateProfile($name: String, $email: String, $surname: String) {
    updateProfile(input: { name: $name, email: $email, surname: $surname }) {
      name
      surname
    }
  }
`;

// interface IUserData {
//   currentUser: any;
// }

// const useStyles = makeStyles(theme => ({
//   root: {
//     minHeight: `calc(100vh - ${theme.layout.headerHeight + 112}px )` // Remove the Drawer Header height
//   }
// }));

const PageViewProfile = injectIntl<any>(({ intl }) => {
  const [showUpdateError, setShowUpdateError] = useState(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const { data, error } = useQuery(USER_QUERY);

  const [updateUserProfile, { error: updateUserError }] = useMutation(
    UPDATE_USER_PROFILE
  );

  useEffect(() => {
    if (updateUserError) {
      setShowUpdateError(true);
    }
  }, [updateUserError]);

  useEffect(() => {
    if (data) {
      console.log("data------");
      console.log(data);
      setCurrentUser(data.currentUser);
    }
    if (error) {
      console.log("error loading user data", error);
    }
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 24,
      }}
    >
      {currentUser && (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: 16,
            }}
          >
            <UserAvatarUploader
              style={{ width: 80, height: 80, fontSize: "3rem" }}
              user={{
                name: (currentUser && currentUser.name) || "F",
                surname: (currentUser && currentUser.surname) || "O",
                email: (currentUser && currentUser.email) || "",
                avatarUrl: (currentUser && currentUser.avatarUrl) || "",
              }}
            />
          </div>
          <Formik
            initialValues={{
              name: (currentUser && currentUser.name) || "",
              surname: (currentUser && currentUser.surname) || "",
              email: (currentUser && currentUser.email) || "",
              role: (currentUser && currentUser.role) || "",
              password: "",
              passwordConfirmation: "",
            }}
            isInitialValid={true}
            onSubmit={(values: IFormValues) => {
              console.log("updating profile");
              console.log(currentUser.email);
              console.log(values.name);
              console.log(values.surname);
              updateUserProfile({
                variables: {
                  email: currentUser.email,
                  name: values.name,
                  surname: values.surname,
                },
              })
                .then(() => setShowUpdateSuccess(true))
                .catch(() => null);
            }}
            render={({ errors, dirty, isValid }: FormikProps<IFormValues>) => {
              return (
                <>
                  <StyledForm data-testid="my-profile-details-form">
                    <Field
                      name="name"
                      validate={required(intl)}
                      error={errors.name}
                      component={TextInput}
                      inputProps={{
                        "data-testid": "name",
                      }}
                      label={intl.formatMessage(messages.firstName)}
                    />
                    <InputErrorMessage name="name" />
                    <Field
                      name="surname"
                      validate={required(intl)}
                      error={errors.surname}
                      component={TextInput}
                      inputProps={{
                        "data-testid": "surname",
                      }}
                      label={intl.formatMessage(messages.lastName)}
                    />
                    <InputErrorMessage name="surname" />
                    <Field
                      name="email"
                      component={TextInput}
                      inputProps={{
                        "data-testid": "email",
                      }}
                      disabled
                      label={intl.formatMessage(messages.email)}
                    />
                    <Field
                      name="role"
                      component={TextInput}
                      inputProps={{
                        "data-testid": "role",
                      }}
                      disabled
                      label={intl.formatMessage(messages.role)}
                    />
                    <Chip
                      icon={<WorkIcon />}
                      size="medium"
                      variant="default"
                      label={currentUser.workspace}
                      onClick={() => null}
                      color="primary"
                    />

                    <Grid container spacing={2}>
                      <Grid item xs="auto">
                        <FormButton
                          type="submit"
                          data-testid="update-button"
                          // disabled={!isValid || updateUserLoading}
                          disabled={!isValid || !dirty}
                        >
                          {intl.formatMessage(messages.update)}
                        </FormButton>
                      </Grid>
                      {/* <Grid item xs="auto">
                        <FormButton
                          data-testid="cancel-button"
                          onClick={() => null}
                          color="default"
                        >
                          {intl.formatMessage(messages.cancel)}
                        </FormButton>
                      </Grid> */}
                    </Grid>
                  </StyledForm>
                </>
              );
            }}
          />
        </>
      )}
      <SnackBar
        data-testid="snackbar-error-message"
        open={showUpdateError}
        message={intl.formatMessage(messages.updateUserError)}
        variant="error"
        onClose={
          // onClose is called by SnackBar after 6s
          /* istanbul ignore next */ () => setShowUpdateError(false)
        }
      />
      <SnackBar
        data-testid="snackbar-success-message"
        open={showUpdateSuccess}
        message={intl.formatMessage(messages.updateUserSuccess)}
        variant="success"
        onClose={
          // onClose is called by SnackBar after 6s
          /* istanbul ignore next */ () => setShowUpdateSuccess(false)
        }
      />
    </div>
  );
});

export default PageViewProfile;
