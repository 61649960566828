import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import {
  Theme,
  Button,
  TextField,
  ListItem,
  Divider,
  ListItemText,
  List,
} from "@material-ui/core";

// import { Redirect } from "@reach/router";

import { styled } from "@material-ui/core/styles";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import LoaderV2 from "../../loaderV2";
import Typography from "../../typography";
import UserAvatar from "../../user-avatar";

export const GET_CURRENT_USER = gql`
  query {
    currentUser {
      id
      name
      surname
      email
      role
      avatarUrl
      playerProfile {
        id
        name
      }
      playerLink
      workspace
    }
  }
`;
export const GET_PLAYERS = gql`
  query AllPlayersByWorkspace($workspace: String!) {
    allPlayersByWorkspace(workspace: $workspace) {
      id
      position
      name
      surname
      avatarUrl
      number
      totalGoals
      totalMatches
      yellowCards
      redCards
      team {
        id
        name
      }
      user {
        id
        name
        surname
        role
      }
    }
  }
`;

export const CREATE_PLAYER_LINK_USER = gql`
  mutation createPlayerAndLinkParent(
    $name: String
    $surname: String
    $userId: String!
    $workspaceId: String
  ) {
    createPlayerAndLinkParent(
      input: { name: $name, surname: $surname }
      userId: $userId
      workspaceId: $workspaceId
    ) {
      id
    }
  }
`;

export const LINK_PARENT = gql`
  mutation LinkParent($playerId: ID!, $userId: ID!) {
    linkParent(playerId: $playerId, userId: $userId) {
      op
    }
  }
`;

const Main = styled("div")<Theme>(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.extra.lighterGrey,
  padding: "16px",
  minHeight: "100vh",
  paddingBottom: 80,
}));

interface ICreateAndLinkPlayer {
  callback(playerId: string): void;
}

const CreateLinkAndPlayer = ({ callback }: ICreateAndLinkPlayer) => {
  const { data: userData, loading: userLoading } = useQuery<any>(
    GET_CURRENT_USER
  );
  const [loadPlayers, { data, loading }] = useLazyQuery<any>(GET_PLAYERS);
  const [allPlayers, setAllPlayers] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [name, setName] = useState<any>();
  const [surname, setSurname] = useState<any>();
  // const [newPlayerId, setNewPlayerId] = useState<string>();
  // const [redirect, setRedirect] = useState<boolean>(false);

  const [
    createPlayer,
    { data: createPlayerData, loading: createPlayerLoading },
  ] = useMutation(CREATE_PLAYER_LINK_USER);
  const [linkUser, { data: linkData, loading: linkLoading }] = useMutation(
    LINK_PARENT
  );

  useEffect(() => {
    if (createPlayerData && createPlayerData.createPlayerAndLinkParent) {
      // setNewPlayerId(createPlayerData.createPlayerAndLinkParent.id);
      // setRedirect(true);
      callback(createPlayerData.createPlayerAndLinkParent.id);
    }
  }, [createPlayerData]);
  useEffect(() => {
    if (userData && userData.currentUser) {
      console.log("******* currentUser", userData.currentUser);
      setCurrentUser(userData.currentUser);
    }
  }, [userData]);

  useEffect(() => {
    if (currentUser) {
      loadPlayers({ variables: { workspace: currentUser.workspace } });
    }
  }, [currentUser]);

  useEffect(() => {
    if (data && data.allPlayersByWorkspace) {
      console.log("******* players", data.allPlayersByWorkspace);
      setAllPlayers(data.allPlayersByWorkspace);
    }
  }, [data]);

  useEffect(() => {
    console.log("linkData");
    console.log(linkData);
    if (linkData && linkData.linkParent.op === "ok") {
      // setRedirect(true);
    }
  }, [linkData]);

  //   useEffect(() => {
  //     if (createPlayerData && createPlayerData.op === "ok") {
  //       setRedirect(true);
  //     }
  //   }, [createPlayerData]);

  const handleChangeName = (evt: any) => {
    setName(evt.target.value);
  };

  const handleChangeSurname = (evt: any) => {
    setSurname(evt.target.value);
  };

  const handleSelectPlayer = async (playerId: string) => {
    await linkUser({ variables: { userId: currentUser.id, playerId } });
    callback(playerId);
  };

  const handleAddChild = () => {
    createPlayer({
      variables: {
        name,
        surname,
        userId: currentUser.id,
      },
    });
  };

  // if (redirect) {
  //   return <Redirect to={`/dashboard`} />;
  // }

  return (
    <Main>
      {(loading || createPlayerLoading || linkLoading || userLoading) && (
        <LoaderV2 />
      )}
      {/* {redirect && <Redirect to={`/dashboard`} noThrow />} */}
      {currentUser && (
        <>
          <Typography.H5>{`Hi ${currentUser.name} ${currentUser.surname} `}</Typography.H5>
          {allPlayers && allPlayers.length > 0 ? (
            <>
              <div>Is your child already in the team?</div>
              <List>
                {allPlayers.map((player: any) => {
                  return (
                    //   <Card style={{ padding: "24px", marginBottom: 16 }}>
                    //     {`${player.name} ${
                    //       player.user && player.user.role === "parent"
                    //         ? "parent: " +
                    //           player.user.name +
                    //           " " +
                    //           player.user.surname
                    //         : ""
                    //     }`}{" "}
                    //   </Card>
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          handleSelectPlayer(player.id);
                        }}
                      >
                        <UserAvatar
                          style={{ width: 40, height: 40, marginRight: 16 }}
                          user={{
                            name: player.name,
                            surname: player.surname,
                            avatarUrl: player.avatarUrl,
                          }}
                        />
                        <ListItemText
                          primary={`${player.name} ${player.surname}`}
                          secondary={`${
                            player.user && player.user.role === "parent"
                              ? "parent: " +
                                player.user.name +
                                " " +
                                player.user.surname
                              : ""
                          }`}
                        />
                      </ListItem>
                      <Divider />
                    </>
                  );
                })}
              </List>

              <div>Your child is not in the list, don't worry, add him now</div>
            </>
          ) : (
            <div>There are no players yet</div>
          )}
          <Typography.H6>Introduce your child's name</Typography.H6>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "2em",
            }}
          >
            <TextField
              placeholder="Name"
              onChange={handleChangeName}
              value={name}
              variant="outlined"
              style={{ marginBottom: "1em" }}
            />
            <TextField
              placeholder="Surname"
              onChange={handleChangeSurname}
              value={surname}
              variant="outlined"
              style={{ marginBottom: "1em" }}
            />
            <Button color="primary" onClick={handleAddChild} variant="outlined">
              Add child
            </Button>
          </div>
        </>
      )}
    </Main>
  );
};

export default CreateLinkAndPlayer;
