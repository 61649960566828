import React, { useState, useEffect } from "react";
// import { Grid, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import ProgressBar from "../progress-bar";
// import StepInitial from "./StepInitial";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { injectIntl } from "react-intl";
import Content from "../content";
import Button from "../button";
import { ActionLink } from "../atoms";
import messages from "./PageValidateAccount.intl";
import UserAvatar from "../user-avatar";
import TextInput from "../text-input";
import { RouteComponentProps } from "@reach/router";
import { UnauthenticatedLayout } from "../unauthenticated-layout-elements";

const VALIDATE_ACCOUNT = gql`
  query ValidateAccount($token: String!) {
    validateAccount(token: $token) {
      validated
      error
      user {
        name
        surname
      }
    }
  }
`;

const RESEND_EMAIL_VALIDATION = gql`
  mutation ResendEmail($email: String!) {
    resendEmailValidation(email: $email)
  }
`;

const MessageSpace = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const ButtonRow = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

// const StyledPaper = styled(Paper)(({ theme }: IStyledArguments) => ({
//   boxSizing: "border-box",
//   padding: theme.spacing(4),
//   marginBottom: theme.spacing(5),
//   textAlign: "center",
//   display: "flex",
//   flexDirection: "column",
//   margin: "0 auto",
//   maxWidth: 630,
//   [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
//     boxShadow: "none",
//   },
// }));

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: 24,
}));

const PageValidateAccount = injectIntl<RouteComponentProps<{ token: string }>>(
  ({ token, intl }) => {
    console.log("token....", token);
    const [userDetails, setUserDetails] = useState<any>();
    // const [registrationDetails, setRegistrationDetails] = useState<any>();
    const [email, setEmail] = useState<string>("");
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [errorValidation, setErrorValidation] = useState(false);

    const { data, loading, error } = useQuery<any>(VALIDATE_ACCOUNT, {
      variables: {
        token,
      },
    });

    const [resendEmail] = useMutation<any>(RESEND_EMAIL_VALIDATION);

    const handleEmailChange = (e: any) => {
      setEmail(e.target.value);
    };

    const handleResendEmail = async () => {
      await resendEmail({
        variables: {
          email,
        },
      });
      setEmailSent(true);
    };
    // const handleSignUp = async (form: any) => {
    //   await validate({
    //     variables: {
    //       name: form.name,
    //       email: form.email.toLowerCase(),
    //       surname: form.surname,
    //       password: form.password,
    //       role: registerData.role || "Manager",
    //       workspace: registerData.workspace || undefined,
    //       invite: registerData.id || undefined,
    //     },
    //   });
    //   setCurrentStep(2);
    // };

    // let registerData: any = {};
    // if (token) {
    //   registerData = registrationMapping(token);
    //   console.log("111");
    //   console.log(registerData.id);
    // }
    //TODO: ver si registerData existe.
    // const { loading, data } = useQuery(VALIDATE_ACCOUNT_QUERY, {
    //   variables: { id: registerData.id },
    // });

    useEffect(() => {
      if (data && data.validateAccount && data.validateAccount.error) {
        console.log("error on validate account");
        setErrorValidation(true);
      } else if (data && data.validateAccount && data.validateAccount.user) {
        console.log("===========");
        console.log(data.validateAccount.user);
        setUserDetails(data.validateAccount.user);
      }

      //   const newState = { ...data.player };
      //   newState.email = registerData.email;
      //   setRegistrationDetails(newState);
    }, [data]);

    useEffect(() => {
      if (error) {
        console.log("errorrrrrrrrrrr");
        console.log(error);
        setErrorValidation(true);
      }
    }, [error]);

    if (loading) {
      // TODO: LOADING SPINNER
      return (
        <Content>
          <p>LOADING</p>
        </Content>
      );
    }

    return (
      <UnauthenticatedLayout>
        {/* <Content>
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12} md={6}> */}
        {/* <StyledPaper> */}
        <Centered>
          <img src="/images/footik-main.png" width="280px" height="280px" />
        </Centered>
        {errorValidation && !emailSent && (
          <>
            {intl.formatMessage(messages.validationError)}
            <TextInput
              name="email"
              inputProps={{
                "data-testid": "email",
              }}
              value={email}
              onChange={handleEmailChange}
              label={intl.formatMessage(messages.email)}
            />
            <ButtonRow>
              <Button onClick={handleResendEmail} disabled={email === ""}>
                {intl.formatMessage(messages.resend)}
              </Button>
            </ButtonRow>
          </>
        )}
        {errorValidation && emailSent && (
          <>
            {intl.formatMessage(messages.emailSent)}

            <ButtonRow>
              <ActionLink to="/login">
                <Button>Login</Button>
              </ActionLink>
            </ButtonRow>
          </>
        )}

        {userDetails && (
          <>
            <Centered>
              <UserAvatar
                style={{ width: 80, height: 80, fontSize: "3em" }}
                user={{
                  name: (userDetails && userDetails.name) || "C",
                  surname: (userDetails && userDetails.surname) || "R",
                }}
              ></UserAvatar>
              <MessageSpace>
                {`${intl.formatMessage(
                  messages.congratulations
                )} ${userDetails && userDetails.name}`}
                <br />
                {intl.formatMessage(messages.accountValidated)}
              </MessageSpace>
              <ButtonRow>
                <ActionLink to="/login">
                  <Button>Login</Button>
                </ActionLink>
              </ButtonRow>
            </Centered>
          </>
        )}
        {/* </StyledPaper> */}
        {/* </Grid>
        </Grid>
      </Content> */}
      </UnauthenticatedLayout>
    );
  }
);

export default PageValidateAccount;
