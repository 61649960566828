import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Fab,
  Button,
  Card,
} from "@material-ui/core";

import Typography from "../typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { gql } from "@apollo/client";
import { styled } from "@material-ui/styles";
import { ActionLink } from "../atoms";
import AddIcon from "@material-ui/icons/Add";
import { injectIntl } from "react-intl";
import messages from "./Exercises.intl";
import { IStyledArguments } from "../../types/styled-arguments";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useIsAdmin from "../../hooks/useIsAdmin";

export const GET_EXERCISES = gql`
  query {
    allExercises {
      id
      name
      shortDescription
      category
      subcategory
      objectives
      taskDevelopment
    }
    currentUser {
      id
      role
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "11px",
      width: "100%",
      backgroundColor: theme.palette.extra.lighterGrey,
      padding: "16px",
      minHeight: "75vh",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      //fontWeight: theme.typography.fontWeightRegular,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const FooterContainer = styled("div")<Theme>(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  height: 90,
  width: "auto",
  right: 0,
  position: "fixed",
  bottom: 70,
}));

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/exerciseBG.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "450px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
}));

const Title = styled("div")({
  color: "White",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});

interface IExercises {
  isTraining?: boolean;
  callback?: (exercises: string[]) => void;
}

const ExercisesCollapse = injectIntl<IExercises>(
  ({ isTraining = false, callback, intl }) => {
    const classes = useStyles();

    console.log("isTraining", isTraining);
    const { data, loading } = useQuery<any>(GET_EXERCISES);
    const [allExercises, setAllExercises] = useState([]);
    const [selectedExercises, setSelectedExercises] = useState<string[]>([]);
    const [open, setOpen] = React.useState(false);
    console.log(data);

    useEffect(() => {
      if (data && data.allExercises) {
        setAllExercises(data.allExercises);
      }
    }, [data]);

    useEffect(() => {
      if (loading) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [loading]);

    useEffect(() => {
      if (selectedExercises) {
        callback && callback(selectedExercises);
      }
    }, [selectedExercises]);

    const handleAddExercise = (id: string) => {
      const newSelectedExercises = [...selectedExercises];
      newSelectedExercises.push(id);
      setSelectedExercises(newSelectedExercises);
    };
    const handleRemoveExercise = (id: string) => {
      const newSelectedExercises = [...selectedExercises];
      const index = newSelectedExercises.indexOf(id);
      if (index > -1) {
        newSelectedExercises.splice(index, 1);
      }
      setSelectedExercises(newSelectedExercises);
    };

    return (
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!loading && (!allExercises || allExercises.length === 0) && (
          <CardWrapper>
            <Title>{intl.formatMessage(messages.noResults)}</Title>
          </CardWrapper>
        )}
        {allExercises &&
          allExercises.map((exercise: any) => (
            <ExpansionPanel
              style={{
                background: selectedExercises.includes(exercise.id)
                  ? "#f5f5f5"
                  : "",
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={`panel-${exercise.id}`}
              >
                <Typography.Subtitle1>{exercise.name}</Typography.Subtitle1>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Section>
                    <SectionTitle>
                      {intl.formatMessage(messages.description)}
                    </SectionTitle>
                    <Typography.Subtitle2>
                      {exercise.shortDescription}
                    </Typography.Subtitle2>
                  </Section>
                  <Section>
                    <SectionTitle>
                      {intl.formatMessage(messages.task)}
                    </SectionTitle>
                    <Typography.Subtitle2>
                      {exercise.taskDevelopment}
                    </Typography.Subtitle2>
                  </Section>
                  {!isTraining && (
                    <ActionLink to={`/exercises/${exercise.id}`}>
                      <Button variant="contained" color="secondary">
                        {intl.formatMessage(messages.details)}
                      </Button>
                    </ActionLink>
                  )}
                  <div>
                    {isTraining && !selectedExercises.includes(exercise.id) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={() => handleAddExercise(exercise.id)}
                      >
                        {intl.formatMessage(messages.addToTraining)}
                      </Button>
                    )}
                    {isTraining && selectedExercises.includes(exercise.id) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => handleRemoveExercise(exercise.id)}
                      >
                        {intl.formatMessage(messages.removeFromTraining)}
                      </Button>
                    )}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        <FooterContainer>
          {!isTraining && useIsAdmin() && (
            <ActionLink to="/library/newexercise">
              <Fab
                aria-label="add team"
                color="secondary"
                style={{ zIndex: 1200 }}
              >
                <AddIcon />
              </Fab>
            </ActionLink>
          )}
        </FooterContainer>
      </div>
    );
  }
);

export default ExercisesCollapse;
