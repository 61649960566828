import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "PageViewTraining.Error",
    defaultMessage: "Error loading match details",
  },
  instructionsTitle: {
    id: "PageViewTraining.instructionsTitle",
    defaultMessage: "Info",
  },
  selectPlayers: {
    id: "PageViewTraining.selectPlayers",
    defaultMessage: "Select available players",
  },
  instructions: {
    id: "PageViewTraining.instructions",
    defaultMessage:
      "Here you can find the list of players that have already accepted the invitation to the match, but you can also add new players to conform the final list to start the Match.",
  },
  location: {
    id: "PageViewTraining.location",
    defaultMessage: "Location",
  },
  library: {
    id: "PageViewTraining.library",
    defaultMessage: "Library",
  },
  backToPrepare: {
    id: "PageViewTraining.backToPrepare",
    defaultMessage: "Back",
  },
  images: {
    id: "PageViewTraining.images",
    defaultMessage: "Images",
  },
  downloadPDF: {
    id: "PageViewTraining.downloadPDF",
    defaultMessage: "Download training session as pdf",
  },
  download: {
    id: "PageViewTraining.download",
    defaultMessage: "Download",
  },
  duration: {
    id: "PageViewTraining.duration",
    defaultMessage: "Duration",
  },
  loadingDocument: {
    id: "PageViewTraining.loadingDocument",
    defaultMessage: "Loading document...",
  },
  exercise: {
    id: "PageViewTraining.exercise",
    defaultMessage: "Exercise",
  },
  description: {
    id: "PageViewTraining.description",
    defaultMessage: "Description",
  },
  task: {
    id: "PageViewTraining.task",
    defaultMessage: "Task",
  },
  section: {
    id: "PageViewTraining.section",
    defaultMessage: "Section",
  },
  createdBy: {
    id: "PageViewTraining.createdBy",
    defaultMessage: "Created by",
  },
  date: {
    id: "PageViewTraining.date",
    defaultMessage: "Date",
  },
  tactics: {
    id: "PageViewTraining.tactics",
    defaultMessage: "Tactics",
  },
  notes: {
    id: "PageViewTraining.notes",
    defaultMessage: "Notes",
  },
  trainings: {
    id: "PageViewTraining.trainings",
    defaultMessage: "Trainings",
  },
  delete: {
    id: "PageViewTraining.delete",
    defaultMessage: "Delete",
  },
  configurationPDF: {
    id: "PageViewTraining.configurationPDF",
    defaultMessage: "Select the type of PDF, you want to create",
  },
});
