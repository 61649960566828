import { defineMessages } from "react-intl";

export default defineMessages({
  addToTeamTitle: {
    id: "PageNewPlayer.addToTeamTitle",
    defaultMessage: "(Optional) Add player to team",
  },
  save: {
    id: "PageNewPlayer.save",
    defaultMessage: "Save",
  },
  skip: {
    id: "PageNewPlayer.skip",
    defaultMessage: "Skip",
  },
  email: {
    id: "PageNewPlayer.email",
    defaultMessage: "Email",
  },
  name: {
    id: "PageNewPlayer.Name",
    defaultMessage: "Name",
  },
  surname: {
    id: "PageNewPlayer.Surname",
    defaultMessage: "Surname",
  },
  nickname: {
    id: "PageNewPlayer.Nickname",
    defaultMessage: "Nickname",
  },
  number: {
    id: "PageNewPlayer.Number",
    defaultMessage: "Number",
  },
  dateOfBirth: {
    id: "PageNewPlayer.DateOfBirth",
    defaultMessage: "Date of birth (yyyy-mm-dd)",
  },
  password: {
    id: "PageNewPlayer.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "PageNewPlayer.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  passwordError: {
    id: "PageNewPlayer.PasswordError",
    defaultMessage: "Password requirements not fulfilled",
  },
  passwordNotMatch: {
    id: "PageNewPlayer.PasswordNotMatch",
    defaultMessage: "Passwords do not match",
  },
  requiredFieldError: {
    id: "PageNewPlayer.RequiredFieldError",
    defaultMessage: "Required field",
  },
  registerTitle: {
    id: "PageNewPlayer.RegisterTitle",
    defaultMessage: "Add new player",
  },
  loginLinkMessage: {
    id: "PageNewPlayer.loginLink",
    defaultMessage: "Already have an account?",
  },
  login: {
    id: "PageNewPlayer.login",
    defaultMessage: "Sign in",
  },
  passwordHintTitle: {
    id: "PageNewPlayer.passwordHintTitle",
    defaultMessage: "Password field",
  },
  passwordHintMustBe: {
    id: "PageNewPlayer.passwordHintMustBe",
    defaultMessage: "Your password must:",
  },
  passwordHint8Characters: {
    id: "PageNewPlayer.passwordHint8Characters",
    defaultMessage: "Be at least 8 characters long",
  },
  passwordHint1Uppercase: {
    id: "PageNewPlayer.passwordHint1Uppercase",
    defaultMessage: "Contain at least 1 upper case character",
  },
  passwordHint1Lowercase: {
    id: "PageNewPlayer.passwordHint1Lowercase",
    defaultMessage: "Contain at least 1 lower case character",
  },
  passwordHint1Number: {
    id: "PageNewPlayer.passwordHint1Number",
    defaultMessage: "Contain at least 1 number",
  },
  passwordHint1SpecialCharacter: {
    id: "PageNewPlayer.passwordHint1SpecialCharacter",
    defaultMessage: "Contain at least 1 special character",
  },
  passwordHintNotSameEmail: {
    id: "PageNewPlayer.passwordHintNotSameEmail",
    defaultMessage: "Not be the same as your email address",
  },
  next: {
    id: "PageNewPlayer.next",
    defaultMessage: "Next",
  },
  position: {
    id: "PageNewPlayer.position",
    defaultMessage: "Position",
  },
  team: {
    id: "PageNewPlayer.team",
    defaultMessage: "team",
  },
});
