import React from "react";
import { styled, createStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
import { IMatch } from "../../types";
import {
  Card,
  Grid,
  Chip,
  Badge,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core";
import Link from "../link";
import Button from "../button";
import UserAvatar from "../user-avatar";
import useIsManager from "../../hooks/useIsManager";
import { injectIntl } from "react-intl";
import messages from "./MatchSummaryCard.intl";
import Score from "./Score";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  height: "100%",
  display: "flex",
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

const OwnGoalBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "red",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid red",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

const StyledActionLink = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    ...theme.typography.caption,
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    textTransform: "uppercase",
  };
});

const ButtonRow = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

const Container = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
  paddingBottom: 8,
});

interface IMatchSummaryCardProps {
  id: string;
  match: IMatch;
  currentUser: {
    id: string;
    role: string;
  };
}

const MatchSummaryCard = injectIntl<IMatchSummaryCardProps>(
  ({ id, match, currentUser, intl }) => {
    const classes = useStyles();
    console.log("match.isHomeTeamLocal:");
    console.log(match.isHomeTeamLocal);
    return (
      <CardWrapper data-testid={`group-summary-${id}`}>
        <Score match={match} />
        <Content>
          <Container>
            <Grid item xs={12}>
              <Typography.Subtitle1>{`${intl.formatMessage(
                messages.playersSelected
              )}: ${match.playersSelected.length}`}</Typography.Subtitle1>
            </Grid>
            <Grid item xs={12}>
              <Typography.Subtitle1>{`${intl.formatMessage(
                messages.playersConfirmed
              )}: ${match.playersConfirmed.length}`}</Typography.Subtitle1>
            </Grid>
            <Grid item xs={12}>
              <Typography.Subtitle1>{`${intl.formatMessage(
                messages.location
              )}: ${match.location}`}</Typography.Subtitle1>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: 8 }}>
              <Chip
                size="small"
                color="primary"
                label={`${intl.formatMessage(messages.kickOff)} ${match.time}`}
                onClick={() => null}
              />
            </Grid>
            {match.status === "FINISHED" && (
              <Grid item xs={12} style={{ paddingBottom: 8 }}>
                {match.scorerList &&
                  match.scorerList.length > 0 &&
                  match.scorerList.map((scorer: any) => (
                    <Badge
                      color="secondary"
                      badgeContent={scorer.goals}
                      className={classes.margin}
                    >
                      <UserAvatar
                        user={{
                          name: scorer.player.name,
                          surname: scorer.player.surname,
                          avatarUrl: scorer.player.avatarUrl,
                        }}
                        data-testid={`user-avatar-${scorer.player.id}`}
                      />
                    </Badge>
                  ))}
                {match.ownGoals > 0 && (
                  <OwnGoalBadge
                    color="secondary"
                    style={{ margin: 0 }}
                    badgeContent={match.ownGoals}
                    className={classes.margin}
                  >
                    <UserAvatar
                      style={{ backgroundColor: "#F3421C" }}
                      user={{
                        name: intl.formatMessage(messages.own),
                        surname: intl.formatMessage(messages.goal),
                      }}
                    />
                  </OwnGoalBadge>
                )}
              </Grid>
            )}

            {match.status !== "PREPARING" && (
              <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <Chip
                  size="small"
                  label={match.status}
                  onClick={() => null}
                  color="secondary"
                />
              </Grid>
            )}
            {match.isFriendly && (
              <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <Chip
                  size="small"
                  label={intl.formatMessage(messages.friendly)}
                  onClick={() => null}
                  color="secondary"
                />
              </Grid>
            )}
            {useIsManager(currentUser.role) ? (
              <Grid item xs={12}>
                {match.status === "PREPARING" && (
                  <ButtonRow>
                    <StyledActionLink to={`/matches/${match.id}`}>
                      <Button>
                        {intl.formatMessage(messages.prepareGame)}
                      </Button>
                    </StyledActionLink>
                  </ButtonRow>
                )}
                {match.status !== "PREPARING" && (
                  <ButtonRow>
                    <StyledActionLink to={`/matches/${match.id}`}>
                      <Button>{intl.formatMessage(messages.viewGame)}</Button>
                    </StyledActionLink>
                  </ButtonRow>
                )}
              </Grid>
            ) : (
              <>
                {match.status === "FINISHED" && (
                  <ButtonRow>
                    <StyledActionLink to={`/matches/${match.id}`}>
                      <Button>{intl.formatMessage(messages.viewGame)}</Button>
                    </StyledActionLink>
                  </ButtonRow>
                )}
              </>
            )}
          </Container>
        </Content>
      </CardWrapper>
    );
  }
);

export default MatchSummaryCard;
