import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    marginBottom: "24px",
    color: "red",
  },
});

interface IProgressBar {
  steps: number;
  activeStep: number;
}

const ProgressBar = ({ steps, activeStep }: IProgressBar) => {
  const classes = useStyles();

  return (
    <MobileStepper
      variant="progress"
      steps={steps}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={null}
      backButton={null}
    />
  );
};

export default ProgressBar;
