import React from "react";
import {
  Formik,
  FormikActions,
  Form as FormikForm,
  Field,
  FormikProps,
} from "formik";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import TextInput from "../../text-input";
import PasswordInput from "../../password-input";
import Typography from "../../typography";
import InputErrorMessage from "../../input-error-message";

import messages from "./RegisterForm.intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../../types/styled-arguments";
import RegisterStepButton from "../register-step-button";
import {
  required,
  validPassword,
  FormPasswordInstructions,
} from "../../form-utils";

const Title = styled(({ children }) => (
  <Typography.H5>{children}</Typography.H5>
))((props: IStyledArguments) => {
  const { md } = props.theme.breakpoints.values;

  return {
    marginBottom: 23,
    [`@media (max-width: ${md}px)`]: {
      marginBottom: 27,
    },
  };
});

// const RoleSelect = injectIntl<SelectProps>(({ onChange, value, intl }) => {
//   const positions: { [index: string]: string } = {
//     COACH: "Coach",
//     CLUB: "Club",
//     PLAYER: "PLAYER",
//     CENTREBACK: "Centre Back",
//     CENTREMIDFIELD: "Centre Midfield",
//     LEFTMIDFIELD: "Left Midfield",
//     RIGHTMIDFIELD: "Right Midfield",
//     STRIKER: "Striker",
//   };

//   return (
//     <Select
//       id="position-select"
//       label={intl.formatMessage(messages.role)}
//       value={value}
//       onChange={onChange}
//       fullWidth
//     >
//       {Object.keys(positions).map((key, idx) => (
//         <Option key={idx} value={key}>
//           {positions[key]}
//         </Option>
//       ))}
//     </Select>
//   );
// });

export interface IFormValues {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  newUser: boolean;
  // role: string;
}

interface IForm extends InjectedIntlProps {
  onSubmit: (arg: IFormValues) => void;
  checkValid: boolean;
  name: string;
  surname: string;
  email: string;
  password?: string;
  passwordConfirmation?: string;
  newUser: boolean;
  registrationDetails: any;
  // role: string;
}

const StyledForm = styled(FormikForm)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: 24,
}));

const RegisterForm = ({
  onSubmit,
  checkValid,
  // email,
  name,
  surname,
  password,
  passwordConfirmation = "",
  newUser,
  registrationDetails,
  // role,
  intl,
}: IForm) => {
  return (
    <Formik
      initialValues={{
        email: (registrationDetails && registrationDetails.email) || "",
        name,
        surname,
        password: password || "",
        passwordConfirmation,
        newUser: false,
      }}
      isInitialValid={checkValid}
      onSubmit={(values: IFormValues, actions: FormikActions<IFormValues>) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      render={({
        errors,
        touched,
        values,
        isValid,
      }: FormikProps<IFormValues>) => (
        <>
          <Centered>
            <img src="/images/footik-main.png" width="280px" height="280px" />
          </Centered>
          <Title>
            <FormattedMessage {...messages.registerTitle} />
          </Title>
          <p>
            <FormattedMessage {...messages.loginLinkMessage} />
            &nbsp;
            <a data-testid="navigate-to-login" href="/login">
              <FormattedMessage {...messages.login} />
            </a>
          </p>
          <StyledForm data-testid="register-form">
            <Field
              name="email"
              component={TextInput}
              inputProps={{
                "data-testid": "email",
              }}
              disabled={!newUser}
              label={intl.formatMessage(messages.email)}
            />
            <Field
              name="name"
              validate={required(intl)}
              error={errors.name && touched.name}
              component={TextInput}
              inputProps={{
                "data-testid": "name",
              }}
              label={intl.formatMessage(messages.firstName)}
            />
            <InputErrorMessage name="name" />
            <Field
              name="surname"
              validate={required(intl)}
              error={errors.name && touched.surname}
              component={TextInput}
              inputProps={{
                "data-testid": "surname",
              }}
              label={intl.formatMessage(messages.lastName)}
            />
            <InputErrorMessage name="surname" />
            <Field
              name="password"
              validate={(value: string) =>
                validPassword(intl)(value, values.email)
              }
              component={PasswordInput}
              inputProps={{
                "data-testid": "password",
              }}
              label={intl.formatMessage(messages.password)}
            />
            <InputErrorMessage name="password" />
            <FormPasswordInstructions />
            <div>
              <RegisterStepButton
                type="submit"
                data-testid="go-next-button"
                disabled={!isValid}
              >
                Next
              </RegisterStepButton>
            </div>
          </StyledForm>
        </>
      )}
    />
  );
};

export default injectIntl(RegisterForm);
