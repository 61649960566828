import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import {
  Card,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Fab,
  Button,
} from "@material-ui/core";
import { RouteComponentProps, Redirect } from "@reach/router";
import { injectIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";

import messages from "./PageViewTraining.intl";
import { IStyledArguments } from "../../types/styled-arguments";
import { gql } from "@apollo/client";

import TextFormatIcon from "@material-ui/icons/TextFormat";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import useIsAdmin from "../../hooks/useIsAdmin";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";
import PDFComponent from "./PDFComponent";
import ReducedPDFComponent from "./ReducedPDFComponent";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import useAuthContext from "../../hooks/useAuthContext";
import Breadcrumb from "./Breadcrumb";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const GET_TRAINING = gql`
  query GetTraining($id: ID!) {
    trainingSession(id: $id) {
      name
      trainingDuration
      sections {
        name
        sectionDuration
        exercises {
          name
          shortDescription
          category
          subcategory
          objectives
          taskDevelopment
          images
        }
      }
    }
  }
`;

const DELETE_TRAINING = gql`
  mutation DeleteTraining($id: ID!) {
    deleteTrainingSession(id: $id)
  }
`;

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

const FooterContainer = styled("div")(({ theme }: IStyledArguments) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  height: 90,
  width: "auto",
  right: 0,
  position: "fixed",
  bottom: 70,
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/trainingBG.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "250px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
}));

const Title = styled("div")({
  color: "White",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "relative",
  bottom: "-155px",
});
const Description = styled("div")({
  color: "White",
  position: "relative",
  bottom: "-160px",
});

const Section = styled("div")({
  borderLeft: "solid",
  padding: "16px",
  marginBottom: "16px",
  borderColor: "grey",
});

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const TimeLineWrapper = styled("div")(({ theme }: IStyledArguments) => ({
  backgroundColor: theme.palette.extra.lighterGrey,
  padding: theme.spacing(2),
}));

const Main = styled("main")(({ theme }: IStyledArguments) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight +
    theme.layout.tabsHeight}px )`,
  background: theme.palette.common.white,
  paddingBottom: theme.layout.footerHeight,
}));

const ImageBox = ({ url, index, onClick }: any) => {
  const Box = styled("div")(({ theme }: any) => ({
    border: "solid 1px",
    borderRadius: "8px",
    borderColor: theme.palette.extra.borderColor,
    background: `url(${url}) no-repeat center`,
    backgroundSize: "cover",
    width: "80px",
    height: "80px",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
    margin: theme.spacing(1, 1, 1, 0),
  }));

  return <Box key={`imageBox-${index}`} onClick={() => onClick(index)} />;
};

const PageViewTraining = injectIntl<{ id: string } & RouteComponentProps>(
  ({ id, intl }) => {
    const classes = useStyles();
    const { data } = useQuery<any>(GET_TRAINING, { variables: { id } });
    const [trainingSession, setTrainingSession] = useState<any>({});
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [modalStyle] = useState(getModalStyle);
    const [openModal, setOpenModal] = useState(false);
    const [trainingDeleted, setTrainingDeleted] = useState(false);
    const [deleteTraining, { data: trainingDeletedData }] = useMutation(
      DELETE_TRAINING
    );
    const [pdfOption, setPdfOption] = useState<string>("");

    const [state] = useAuthContext();
    const userName = `${state && state.user && state.user.name} ${state &&
      state.user &&
      state.user.surname} `;

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    useEffect(() => {
      if (data && data.trainingSession) {
        setTrainingSession(data.trainingSession);
      }
    }, [data]);

    useEffect(() => {
      if (trainingDeletedData) {
        console.log("useEffect");
        console.log(trainingDeletedData);
        setTrainingDeleted(true);
      }
    }, [trainingDeletedData]);

    if (trainingDeleted) {
      return (
        <Redirect to="/library/trainings?action=trainingDeleted" noThrow />
      );
    }

    const handleOpenLightBox = (index: number) => {
      setIndex(index);
      setIsOpen(true);
    };

    const handleDeleteTraining = () => {
      console.log("deleting training...");
      deleteTraining({
        variables: {
          id,
        },
      });
      // return <Redirect to="/library/trainings" noThrow />;
    };

    const handleRadioChange = (event: any) => {
      setPdfOption(event.target.value);
    };

    const modalBody = (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">
          {intl.formatMessage(messages.downloadPDF)}
        </h2>
        <FormControl component="fieldset" style={{ margin: 16 }}>
          <FormLabel component="legend">
            {intl.formatMessage(messages.configurationPDF)}
          </FormLabel>
          <RadioGroup
            aria-label="pdfOption"
            name="pdfOption"
            value={pdfOption}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="reduced"
              control={<Radio />}
              label="Reduced version (1 page)"
            />
            <FormControlLabel
              value="standar"
              control={<Radio />}
              label="Standar version"
            />
          </RadioGroup>
          {/* <FormHelperText>{helperText}</FormHelperText> */}
        </FormControl>
        {trainingSession && (
          <div>
            {pdfOption === "reduced" && (
              <PDFDownloadLink
                document={
                  <ReducedPDFComponent
                    training={trainingSession}
                    userName={userName}
                    intl={intl}
                  />
                }
                fileName={`${trainingSession.name}_${moment()}_reduced`}
              >
                {({ loading }) =>
                  loading ? (
                    intl.formatMessage(messages.loadingDocument)
                  ) : (
                    <Button variant="outlined">
                      {intl.formatMessage(messages.download)}
                    </Button>
                  )
                }
              </PDFDownloadLink>
            )}
            {pdfOption === "standar" && (
              <PDFDownloadLink
                document={
                  <PDFComponent
                    training={trainingSession}
                    userName={userName}
                    intl={intl}
                  />
                }
                fileName={`${trainingSession.name}_${moment()}`}
              >
                {({ loading }) =>
                  loading ? (
                    intl.formatMessage(messages.loadingDocument)
                  ) : (
                    <Button variant="outlined">
                      {intl.formatMessage(messages.download)}
                    </Button>
                  )
                }
              </PDFDownloadLink>
            )}
          </div>
        )}
      </div>
    );

    return (
      <>
        <Main>
          {trainingSession && (
            <>
              <Breadcrumb id={id} />
              <CardWrapper>
                <Title>{trainingSession.name}</Title>
                <Description>
                  {intl.formatMessage(messages.download)}
                  {": "}
                  {trainingSession.trainingDuration} min
                </Description>
              </CardWrapper>
              <TimeLineWrapper>
                <VerticalTimeline>
                  {trainingSession &&
                    trainingSession.sections &&
                    trainingSession.sections.map((section: any) => (
                      <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                          background: "white",
                          color: "grey",
                        }}
                        contentArrowStyle={{
                          borderRight: "7px solid white",
                        }}
                        date={`${moment()
                          .minutes(section.sectionDuration)
                          .format("mm")} min`}
                        iconStyle={{
                          background: "#005473",
                          color: "#fff",
                        }}
                        icon={<TextFormatIcon />}
                      >
                        <h3
                          className="vertical-timeline-element-title"
                          style={{ color: "grey" }}
                        >
                          {section.name}
                        </h3>
                        {section.exercises &&
                          section.exercises.map((exercise: any) => (
                            <Section>
                              <SectionTitle>
                                {`${intl.formatMessage(messages.exercise)}: ${
                                  exercise.name
                                }`}
                              </SectionTitle>
                              <div style={{ color: "grey" }}>
                                <p>
                                  {`${intl.formatMessage(
                                    messages.description
                                  )} > ${exercise.shortDescription}`}
                                </p>
                                <p>
                                  {`${intl.formatMessage(messages.task)} > ${
                                    exercise.taskDevelopment
                                  }`}
                                </p>
                                {exercise.images && (
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id={`panel-${exercise.id}`}
                                    >
                                      {intl.formatMessage(messages.images)}
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      {exercise.images &&
                                        exercise.images.map(
                                          (image: any, index: number) => (
                                            <ImageBox
                                              url={image}
                                              index={index}
                                              onClick={handleOpenLightBox}
                                            />
                                          )
                                        )}
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                )}
                              </div>
                              {isOpen &&
                                exercise.images &&
                                exercise.images.length > 0 && (
                                  <Lightbox
                                    mainSrc={exercise.images[index]}
                                    nextSrc={
                                      exercise.images[
                                        (index + 1) % exercise.images.length
                                      ]
                                    }
                                    prevSrc={
                                      exercise.images[
                                        (index + exercise.images.length - 1) %
                                          exercise.images.length
                                      ]
                                    }
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                      setIndex(
                                        (index + exercise.images.length - 1) %
                                          exercise.images.length
                                      )
                                    }
                                    onMoveNextRequest={() =>
                                      setIndex(
                                        (index + 1) % exercise.images.length
                                      )
                                    }
                                  />
                                )}
                            </Section>
                          ))}
                      </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
              </TimeLineWrapper>
              <div style={{ padding: "16px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleDeleteTraining}
                >
                  {intl.formatMessage(messages.delete)}
                </Button>
              </div>
            </>
          )}
        </Main>
        <FooterContainer>
          {useIsAdmin() && (
            <>
              <Fab
                aria-label="add team"
                color="secondary"
                style={{ zIndex: 1200 }}
                onClick={handleOpenModal}
              >
                <PictureAsPdfRoundedIcon />
              </Fab>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {modalBody}
              </Modal>
            </>
          )}
        </FooterContainer>
        {/* <SnackBar
          data-testid="delete-error"
          open={trainingDeleted}
          message={intl.formatMessage(messages.trainingDeleted)}
          variant="success"
        /> */}
        {/* <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={trainingDeleted}
          message={intl.formatMessage(messages.trainingDeleted)}
          // variant="success"
        /> */}
        {/* <Snackbar open={trainingDeleted} autoHideDuration={6000}>
          <Alert onClose={null} severity="success">
            This is a success message!
          </Alert>
        </Snackbar> */}
      </>
    );
  }
);

export default PageViewTraining;
