import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import ProgressBar from "../progress-bar";
import StepInitial from "./StepInitial";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
// import useAuthContext from "../../hooks/useAuthContext";
import { injectIntl } from "react-intl";
import Content from "../content";
import Button from "../button";
import Link from "../link";
import ImageHeaderComponent from "./new-training-form/ImageHeaderComponent";
import StepAddSections from "./StepAddSections";

export const CREATE_TRAINING = gql`
  mutation AddTraining($name: String!) {
    addTrainingSession(input: { name: $name }) {
      id
    }
  }
`;

const StyledActionLink = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    ...theme.typography.caption,
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    textTransform: "uppercase",
  };
});

const ButtonRow = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const MessageSpace = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }: IStyledArguments) => ({
  boxSizing: "border-box",
  padding: theme.spacing(4),
  marginBottom: theme.spacing(5),
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: 630,
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    boxShadow: "none",
  },
  backgroundColor: "rgb(255,255,255, 0.9)",
}));

const Background = styled("div")(() => ({
  backgroundImage: "url('/images/trainingBG.jpg')",
  marginTop: "-10px",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "100px",
  height: "110vh",
}));

const PageNewTraining = () => {
  const [trainingId, setTrainingId] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const [createTraining, { data, loading }] = useMutation(CREATE_TRAINING);

  const handleCreateTraining = async (form: any) => {
    createTraining({
      variables: {
        name: form.name,
      },
    });
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!data) {
      return;
    } else {
      console.log("data received....");
      console.log(data);
      setTrainingId(data.addTrainingSession.id);
      setCurrentStep(2);
    }
  }, [data]);

  if (loading) {
    // TODO: LOADING SPINNER
    return (
      <Content>
        <p>LOADING</p>
      </Content>
    );
  }

  // if (error) {
  //   return <Redirect to="/library" noThrow />;
  // }

  return (
    <Background>
      <Content>
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <ProgressBar steps={3} activeStep={currentStep} />
              {currentStep === 1 && (
                <StepInitial
                  // exerciseDetails={exerciseDetails}
                  onGoNext={(values: any) => {
                    console.log(values);
                    handleCreateTraining(values);
                  }}
                />
              )}
              {currentStep === 2 && (
                <StepAddSections
                  id={trainingId}
                  onGoNext={(values: any) => {
                    console.log(values);
                    // handleAddExercise(values);
                    setCurrentStep(3);
                  }}
                />
              )}

              {currentStep === 3 && (
                <Content>
                  <ImageHeaderComponent />
                  <MessageSpace>Training created</MessageSpace>
                  <ButtonRow>
                    <StyledActionLink to="/library/trainings">
                      <Button>Ok</Button>
                    </StyledActionLink>
                  </ButtonRow>
                </Content>
              )}
            </StyledPaper>
          </Grid>
        </Grid>
      </Content>
    </Background>
  );
};

export default injectIntl(PageNewTraining);
