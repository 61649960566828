import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../../types/styled-arguments";

const HeaderBar = styled((props) => (
  <AppBar elevation={0} position="fixed" {...props} />
))(({ theme }: IStyledArguments) => ({
  background: "rgb(0 97 133 / 0%)",
  zIndex: theme.zIndex.drawer + 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(2, 2),
}));

export default HeaderBar;
