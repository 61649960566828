// enum Roles {
//   Admin = "Admin",
//   Manager = "Manager",
//   Player = "Player",
//   Parent = "Parent"
// }

const useIsManager = (role: string) => {
  // if (Object.values(Roles).includes(Roles[role])){
  //   return false;
  // }
  return ["Manager", "Admin", "coach"].includes(role);
};

export default useIsManager;
