import React from "react";
import { Formik, FormikActions, FormikProps, Field } from "formik";
import { styled } from "@material-ui/styles";
// import { IStyledArguments } from "../../../types/styled-arguments";
import TextInput from "../../text-input";
import PasswordInput from "../../password-input";
// import Link from "../../link";
import InputErrorMessage from "../../input-error-message";
// import Typography from "../../typography";
import {
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
  InjectedIntl,
} from "react-intl";
import messages from "./LoginForm.intl";
import {
  VerticalForm,
  Title,
  ActionButton,
} from "../../unauthenticated-layout-elements";

// const ForgotPassword = styled(Typography.Caption)(
//   (props: IStyledArguments) => ({
//     textAlign: "left",
//     fontWeight: 500,
//     marginTop: props.theme.spacing(1),
//     marginLeft: 12
//   })
// );
// const ForgotPasswordLink = styled(Link)((props: IStyledArguments) => ({
//   fontWeight: 500,
//   color: `${props.theme.palette.secondary.dark}`
// }));

const required = (intl: InjectedIntl) => (value: string) => {
  let errorMessage;
  if (!value) {
    errorMessage = intl.formatMessage(messages.requiredFieldError);
  }
  return errorMessage;
};

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: 24,
}));

export interface ILoginFormValues {
  email: string;
  password: string;
}

interface ILoginForm extends InjectedIntlProps {
  onSubmit: (arg: ILoginFormValues) => void;
  hasLoginError: boolean;
}

const LoginForm = ({ onSubmit, intl, hasLoginError }: ILoginForm) => (
  <Formik
    initialValues={{ email: "", password: "" }}
    onSubmit={(
      values: ILoginFormValues,
      actions: FormikActions<ILoginFormValues>
    ) => {
      onSubmit(values);
      actions.setSubmitting(false);
    }}
    render={({ errors, touched }: FormikProps<ILoginFormValues>) => (
      <>
        <Centered>
          <img src="/images/footik-main.png" width="280px" height="280px" />
        </Centered>
        <Title>
          <FormattedMessage {...messages.signIn} />
        </Title>
        <p>
          <FormattedMessage {...messages.registerLinkMessage} />
          &nbsp;
          <a data-testid="navigate-to-register" href="/register">
            <FormattedMessage {...messages.register} />
          </a>
        </p>
        <p>
          <FormattedMessage {...messages.forgotPasswordMessage} />
          &nbsp;
          <a data-testid="navigate-to-forgot-password" href="/forgot-password">
            <FormattedMessage {...messages.forgotPassword_here} />
          </a>
        </p>
        <VerticalForm data-testid="login-form">
          <Field
            validate={required(intl)}
            name="email"
            error={hasLoginError || (errors.email && touched.email)}
            component={TextInput}
            inputProps={{
              "data-testid": "email",
              autoComplete: "email",
              autoFocus: true,
            }}
            label={intl.formatMessage(messages.emailAddressLabel)}
          />
          <InputErrorMessage name="email" />
          <Field
            validate={required(intl)}
            name="password"
            error={hasLoginError || (errors.password && touched.password)}
            component={PasswordInput}
            inputProps={{
              "data-testid": "password",
              autoComplete: "current-password",
            }}
            label={intl.formatMessage(messages.passwordLabel)}
          />
          <InputErrorMessage name="password" />
          {/* <ForgotPassword>
            <ForgotPasswordLink
              to="/forgot-password"
              data-testid="forgot-password"
            >
              <FormattedMessage {...messages.forgotPassword} />
            </ForgotPasswordLink>
          </ForgotPassword> */}
          <div>
            <ActionButton data-testid="login-button">
              <FormattedMessage {...messages.signInButton} />
            </ActionButton>
          </div>
        </VerticalForm>
      </>
    )}
  />
);

export default injectIntl(LoginForm);
