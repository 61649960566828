import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "PageViewMatch.Error",
    defaultMessage: "Error loading match details",
  },
  instructionsTitle: {
    id: "PageViewMatch.instructionsTitle",
    defaultMessage: "Info",
  },
  selectPlayers: {
    id: "PageViewMatch.selectPlayers",
    defaultMessage: "Select available players",
  },
  instructions: {
    id: "PageViewMatch.instructions",
    defaultMessage:
      "Here you can find the list of players that have already accepted the invitation to the match, but you can also add new players to conform the final list to start the Match.",
  },
  markConfirmed: {
    id: "PageViewMatch.markConfirmed",
    defaultMessage: "Mark as confirmed",
  },
  location: {
    id: "PageViewMatch.location",
    defaultMessage: "Location",
  },
  playersConfirmed: {
    id: "PageViewMatch.playersConfirmed",
    defaultMessage: "Players confirmed",
  },
  kickOff: {
    id: "PageViewMatch.kickOff",
    defaultMessage: "Kick Off",
  },
  team: {
    id: "PageViewMatch.team",
    defaultMessage: "Team",
  },
  matches: {
    id: "PageViewMatch.matches",
    defaultMessage: "Matches",
  },
  startGame: {
    id: "PageViewMatch.startGame",
    defaultMessage: "Start game",
  },
  ownGoal: {
    id: "PageViewMatch.ownGoal",
    defaultMessage: "Own goal",
  },
  endGame: {
    id: "PageViewMatch.endGame",
    defaultMessage: "End game",
  },
  own: {
    id: "PageViewMatch.own",
    defaultMessage: "Own",
  },
  goal: {
    id: "PageViewMatch.goal",
    defaultMessage: "Goal",
  },
  backMatches: {
    id: "PageViewMatch.backMatches",
    defaultMessage: "Back to matches",
  },
  backToPrepare: {
    id: "PageViewMatch.backToPrepare",
    defaultMessage: "Back",
  },
  infoSelected: {
    id: "PageViewMatch.infoSelected",
    defaultMessage: `That's the final list with the confirmed players.
    when you're ready click on start game button to go to the ongoing match screen.
    `,
  },
  squad: {
    id: "PageViewMatch.squad",
    defaultMessage: "Squad",
  },
  configureMatch: {
    id: "PageViewMatch.configureMatch",
    defaultMessage: "Configure match",
  },
  noLimitSubstitutions: {
    id: "PageViewMatch.noLimitSubstitutions",
    defaultMessage: "No limit in substitions",
  },
  numPlayers: {
    id: "PageViewMatch.numPlayers",
    defaultMessage: "Number of players",
  },
  gameParts: {
    id: "PageViewMatch.gameParts",
    defaultMessage: "Number of parts in the game",
  },
  timePerPart: {
    id: "PageViewMatch.timePerPart",
    defaultMessage: "Time each part",
  },
  substitutions: {
    id: "PageViewMatch.substitutions",
    defaultMessage: "Substitutions",
  },
  selectPlayer: {
    id: "PageViewMatch.selectPlayer",
    defaultMessage: "Select player",
  },
  goalKeeper: {
    id: "PageViewMatch.goalKeeper",
    defaultMessage: "Goal Keeper",
  },
  GK: {
    id: "PageViewMatch.gk",
    defaultMessage: "GK",
  },
  leftBack: {
    id: "PageViewMatch.leftBack",
    defaultMessage: "Left Back",
  },
  LB: {
    id: "PageViewMatch.lb",
    defaultMessage: "LB",
  },
  RightBack: {
    id: "PageViewMatch.RightBack",
    defaultMessage: "Right Back",
  },
  RB: {
    id: "PageViewMatch.rb",
    defaultMessage: "RB",
  },
  leftWing: {
    id: "PageViewMatch.leftWing",
    defaultMessage: "Left Wing",
  },
  LW: {
    id: "PageViewMatch.lw",
    defaultMessage: "LW",
  },
  rightWing: {
    id: "PageViewMatch.rightWing",
    defaultMessage: "Right Wing",
  },
  RW: {
    id: "PageViewMatch.rw",
    defaultMessage: "RW",
  },
  centreBack: {
    id: "PageViewMatch.centreBack",
    defaultMessage: "Centre Back",
  },
  CB: {
    id: "PageViewMatch.cb",
    defaultMessage: "CB",
  },
  leftCentreBack: {
    id: "PageViewMatch.leftCentreBack",
    defaultMessage: "Left Centre Back",
  },
  LCB: {
    id: "PageViewMatch.lcb",
    defaultMessage: "LCB",
  },
  rightCentreBack: {
    id: "PageViewMatch.rightCentreBack",
    defaultMessage: "Right Centre Back",
  },
  RCB: {
    id: "PageViewMatch.rcb",
    defaultMessage: "RCB",
  },
  Midfielder: {
    id: "PageViewMatch.Midfielder",
    defaultMessage: "Midfielder",
  },
  MF: {
    id: "PageViewMatch.mf",
    defaultMessage: "MF",
  },
  leftMidfielder: {
    id: "PageViewMatch.leftMidfielder",
    defaultMessage: "Left midfielder",
  },
  LMF: {
    id: "PageViewMatch.lmf",
    defaultMessage: "LMF",
  },
  rightMidfielder: {
    id: "PageViewMatch.rightMidfielder",
    defaultMessage: "Right midfielder",
  },
  RMF: {
    id: "PageViewMatch.rmf",
    defaultMessage: "RMF",
  },
  attackingMidfielder: {
    id: "PageViewMatch.attackingMidfielder",
    defaultMessage: "Attacking Midfielder",
  },
  AMF: {
    id: "PageViewMatch.amf",
    defaultMessage: "AMF",
  },
  leftStriker: {
    id: "PageViewMatch.leftStriker",
    defaultMessage: "Left Striker",
  },
  LST: {
    id: "PageViewMatch.lst",
    defaultMessage: "LST",
  },
  rightStriker: {
    id: "PageViewMatch.rightStriker",
    defaultMessage: "right Striker",
  },
  RST: {
    id: "PageViewMatch.rst",
    defaultMessage: "RST",
  },
  striker: {
    id: "PageViewMatch.striker",
    defaultMessage: "Striker",
  },
  ST: {
    id: "PageViewMatch.st",
    defaultMessage: "ST",
  },
  selectFormation: {
    id: "PageViewMatch.selectFormation",
    defaultMessage: "Select formation",
  },
});
