import React from "react";
import { Radar } from "react-chartjs-2";
import theme from "../../theme";

interface IOptions {
  collapseLabels: boolean;
  showPoints: boolean;
  tooltipLabel?: string;
}

const getOptions = ({
  collapseLabels,
  showPoints,
  tooltipLabel,
}: IOptions) => ({
  segmentShowStroke: false,
  elements: {
    point: {
      radius: showPoints ? 4 : 0,
    },
  },
  legend: {
    display: true,
  },
  tooltips: {
    enabled: true,
    callbacks: {
      // custom callback: in chartjs 2.8 data value tooltips are broken.

      label: /* istanbul ignore next */ (item: any, data: any) => {
        const value = data.datasets[item.datasetIndex].data[item.index];
        return tooltipLabel ? `${tooltipLabel}: ${value}` : value;
      },
    },
  },
  scale: {
    angleLines: {
      lineWidth: 1,
      color: "rgba(13, 69, 93, 0.3)",
      borderDash: [2, 7],
      borderDashOffset: 2,
    },
    pointLabels: {
      callback: collapseLabelCallback(collapseLabels),
      fontFamily: theme.typography.alternateFontFamily,
      fontColor: "rgb(112, 112, 112)",
      lineHeight: "14px",
    },
    gridLines: {
      lineWidth: 1,
      color: "#86a2ae",
    },
    ticks: {
      display: false,
      maxTicksLimit: 1,
      min: 0,
      max: 10,
    },
  },
});

function mapDataset(showPoints: boolean) {
  return (ds: any) =>
    !showPoints
      ? {
          ...ds,
          borderColor: "transparent",
        }
      : ds;
}

const collapseLabelCallback = (collapseLabels: boolean) => (
  label: string,
  index: number
): string => {
  if (collapseLabels) {
    return String(index + 1);
  }

  return label.length > 20 ? `${label.substr(0, 19)}...` : label;
};

interface ISpiderGraphProps {
  width: number;
  height: number;
  labels: string[];
  tooltipLabel?: string;
  datasets: Array<{
    backgroundColor: string;
    data: number[];
  }>;
  collapseLabels?: boolean;
  showPoints?: boolean;
}

const SpiderGraph = ({
  labels,
  datasets,
  tooltipLabel,
  collapseLabels = false,
  showPoints = true,
  width,
  height,
}: ISpiderGraphProps) => {
  const chartData = {
    labels,
    datasets: datasets.map(mapDataset(showPoints)),
  };
  return (
    <Radar
      data={chartData}
      options={getOptions({ collapseLabels, showPoints, tooltipLabel })}
      width={width}
      height={height}
    />
  );
};

export default SpiderGraph;
