import { InjectedIntl } from "react-intl";
import messages from "./FormValidators.intl";

type FieldValidator = (
  intl: InjectedIntl
) => (...args: any[]) => string | undefined;

export const required: FieldValidator = (intl) => (value: string) => {
  let errorMessage;
  if (!value) {
    errorMessage = intl.formatMessage(messages.requiredFieldError);
  }
  return errorMessage;
};

export const validPassword: FieldValidator = (intl) => (
  value: string,
  email?: string
) => {
  let errorMessage;
  const passwordRegExp = /^(?=.{8,})(?=.*[1-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[(!@#$%^&*()_+|~\- =\`{}[\]:”;'<>?,.\/, )])(?!.*(.)\1{2,}).+$/;
  // const passwordRegExold = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (!value) {
    return intl.formatMessage(messages.requiredFieldError);
  }

  if ((email && value === email) || !passwordRegExp.test(value)) {
    errorMessage = intl.formatMessage(messages.passwordError);
  }

  return errorMessage;
};

export const matchesPassword: FieldValidator = (intl) => (
  value: string,
  pass: string
) => {
  let errorMessage;

  if (value !== pass) {
    errorMessage = intl.formatMessage(messages.passwordNotMatch);
  }

  return errorMessage;
};
