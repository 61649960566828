import React, { useEffect, useState } from "react";
// import { FormattedMessage } from "react-intl";
import { styled, createStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
import { ITeam, IPlayer } from "../../types";
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Link from "../link";
import UserAvatar from "../user-avatar";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";

const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Title = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
}

const ScorersCard = injectIntl<ITeamSummaryCardProps>(({ id, team, intl }) => {
  console.log("team=====================");
  console.log(team);
  const classesList = useStylesList();
  const [sortedPlayers, setSortedPlayers] = useState<IPlayer[]>([]);

  useEffect(() => {
    if (team && team.matches) {
      const tmpSquad = [...team.squad];
      try {
        const sortedPlayersByGoals = tmpSquad.sort(
          (a, b) => b.totalGoals - a.totalGoals
        );
        setSortedPlayers(sortedPlayersByGoals);
      } catch (e) {
        console.log("[error], error sorting", e);
      }
    }
  }, [team]);

  return (
    <CardWrapper data-testid={`group-summary-${id}`}>
      <Header>
        <Row>
          <Grid item xs={8}>
            <Title to={`/teams/${team.id}`}>
              {intl.formatMessage(messages.scorers)}:
            </Title>
          </Grid>
        </Row>
      </Header>
      <Content>
        <Grid item xs={12}>
          <List className={classesList.root}>
            {sortedPlayers.slice(0, 3).map((player: IPlayer) => (
              <>
                <ListItem alignItems="center">
                  <Badge
                    color="secondary"
                    badgeContent={player.totalGoals}
                    className={classesList.margin}
                  >
                    <UserAvatar
                      user={{
                        name: player.name,
                        surname: player.surname,
                        avatarUrl: player.avatarUrl,
                      }}
                      data-testid={`user-avatar-${player.id}`}
                    />
                  </Badge>

                  <ListItemText
                    style={{ paddingLeft: 16 }}
                    primary={`${player.name} ${player.surname}`}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ))}
          </List>
        </Grid>
      </Content>
    </CardWrapper>
  );
});

export default ScorersCard;
