import React from "react";
import NewExerciseForm from "./new-exercise-form";
import { IFormValues } from "./new-exercise-form/NewExerciseForm";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  exerciseDetails: any;
}

const StepInitial = ({ onGoNext, exerciseDetails }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    console.log("!!!!!!!!!!!!!");
    console.log(formValues);
    onGoNext(formValues);
  };
  return (
    <NewExerciseForm
      style={{ backgroundColor: "rgb(255,255,255, 0.9)" }}
      onSubmit={handleSubmit}
      {...exerciseDetails}
    />
  );
};

export default StepInitial;
