import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "PageViewPlayer.Error",
    defaultMessage: "Error loading player details",
  },
  tabInfo: {
    id: "PageViewPlayer.tabInfo",
    defaultMessage: "Info",
  },
  tabNotes: {
    id: "PageViewPlayer.tabNotes",
    defaultMessage: "Notes",
  },
  tabEvaluations: {
    id: "PageViewPlayer.tabEvaluations",
    defaultMessage: "Evaluations",
  },
  years: {
    id: "PageViewPlayer.years",
    defaultMessage: "years",
  },
  games: {
    id: "PageViewPlayer.games",
    defaultMessage: "Games",
  },
  goals: {
    id: "PageViewPlayer.goals",
    defaultMessage: "Goals",
  },
  information: {
    id: "PageViewPlayer.information",
    defaultMessage: "Information",
  },
  accepted: {
    id: "PageViewPlayer.accepted",
    defaultMessage: "Accepted",
  },
  invited: {
    id: "PageViewPlayer.invited",
    defaultMessage: "Invited",
  },
  invite: {
    id: "PageViewPlayer.invite",
    defaultMessage: "Invite",
  },
  team: {
    id: "PageViewPlayer.team",
    defaultMessage: "Team",
  },
  players: {
    id: "PageViewPlayer.players",
    defaultMessage: "Players",
  },
  annotations: {
    id: "PageViewPlayer.annotations",
    defaultMessage: "Annotations",
  },
  addNote: {
    id: "PageViewPlayer.addNote",
    defaultMessage: "New note",
  },
  save: {
    id: "PageViewPlayer.save",
    defaultMessage: "Save",
  },
  reviewed: {
    id: "PageViewPlayer.reviewed",
    defaultMessage: "Reviewed on",
  },
  speed: {
    id: "PageViewPlayer.speed",
    defaultMessage: "Speed",
  },
  vision: {
    id: "PageViewPlayer.vision",
    defaultMessage: "Vision",
  },
  dribbling: {
    id: "PageViewPlayer.dribbling",
    defaultMessage: "Dribbling",
  },
  shoot: {
    id: "PageViewPlayer.shoot",
    defaultMessage: "Shoot",
  },
  physical: {
    id: "PageViewPlayer.physical",
    defaultMessage: "Physical",
  },
  leadership: {
    id: "PageViewPlayer.leadership",
    defaultMessage: "Leadership",
  },
  defense: {
    id: "PageViewPlayer.defense",
    defaultMessage: "Defense",
  },
  newPlayerReview: {
    id: "PageViewPlayer.newPlayerReview",
    defaultMessage: "Add new evaluation",
  },
  ConfirmDialogTitle: {
    id: "PageViewPlayer.ConfirmDialogTitle",
    defaultMessage: "Are you sure you want to delete that user?",
  },
  ConfirmDialogMessage: {
    id: "PageViewPlayer.ConfirmDialogMessage",
    defaultMessage: "this action can not be reverted",
  },
  messageOnDelete: {
    id: "PageViewPlayer.messageOnDelete",
    defaultMessage: "error deleting player",
  },
  historicalData: {
    id: "PageViewPlayer.historicalData",
    defaultMessage: "Historical data",
  },
  contactDetails: {
    id: "PageViewPlayer.contactDetails",
    defaultMessage: "Contact Details",
  },
  managePlayer: {
    id: "PageViewPlayer.managePlayer",
    defaultMessage: "Manage Player",
  },
  removePlayer: {
    id: "PageViewPlayer.removePlayer",
    defaultMessage: "Remove Player",
  },
  removeAssociatedUser: {
    id: "PageViewPlayer.removeAssociatedUser",
    defaultMessage: "Remove associated user",
  },
  resendInvitation: {
    id: "PageViewPlayer.resendInvitation",
    defaultMessage: "Resend Invitation",
  },
  transferPlayer: {
    id: "PageViewPlayer.transferPlayer",
    defaultMessage: "Transfer player",
  },
});
