import useAuthContext from "./useAuthContext";

const useIsAdmin = () => {
  // if (Object.values(Roles).includes(Roles[role])){
  //   return false;
  // }
  const [state] = useAuthContext();
  const role = (state && state.user && state.user.role) || "";
  return ["Manager", "Admin"].includes(role);
};

export default useIsAdmin;
