import { defineMessages } from "react-intl";

export default defineMessages({
  addToTeamTitle: {
    id: "PageEditPlayer.addToTeamTitle",
    defaultMessage: "(Optional) Add player to team"
  },
  error: {
    id: "PageEditPlayer.error",
    defaultMessage: "error loading player info"
  }
});
