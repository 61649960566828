import { defineMessages } from "react-intl";

export default defineMessages({
  // groups: {
  //   id: "Groups.Groups",
  //   defaultMessage: "Groups"
  // },
  // searchGroups: {
  //   id: "Teams.SearchGroups",
  //   defaultMessage: "Search groups"
  // },
  loadingError: {
    id: "Teams.LoadingError",
    defaultMessage: "Error loading Teams",
  },
  leadersCount: {
    id: "Teams.LeadersCount",
    defaultMessage: `{n, plural,
      =0 {no players}
      one {1 player}
      other {{n} players}
    }`,
  },
  sortBy: {
    id: "Teams.SortBy",
    defaultMessage: "Sort by",
  },
  mostRecentSorting: {
    id: "Teams.MostRecentSorting",
    defaultMessage: "Most recent",
  },
  nameSorting: {
    id: "Teams.NameSorting",
    defaultMessage: "Name",
  },
  newGroup: {
    id: "Teams.NewGroup",
    defaultMessage: "Create group",
  },
  newGroupDescription: {
    id: "Teams.NewGroupDescription",
    defaultMessage: "Enter the group name",
  },
  cancelButton: {
    id: "Teams.CancelButton",
    defaultMessage: "Cancel",
  },
  createButton: {
    id: "Teams.CreateButton",
    defaultMessage: "Create",
  },
  groupNameLabel: {
    id: "Teams.GroupNameLabel",
    defaultMessage: "Group name",
  },
  noResults: {
    id: "Teams.NoResults",
    defaultMessage: "No results",
  },
  noTeams: {
    id: "Teams.noTeams",
    defaultMessage: "No teams yet",
  },
  defaultError: {
    id: "Teams.DefaultError",
    defaultMessage: "Error creating group",
  },
});
