import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import messages from "./TransferPlayerDialog.intl";
import { gql } from "@apollo/client";
import { injectIntl } from "react-intl";

import { useQuery } from "@apollo/client";
// import AddIcon from "@material-ui/icons/Add";

const ALL_TEAMS = gql`
  query AllTeams {
    allTeams {
      id
      name
      location
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TranserPlayerDialog = injectIntl(
  ({ currentTeam, callback, intl }: any) => {
    const classes = useStyles();

    const { data: dataTeams } = useQuery(ALL_TEAMS);
    const [open, setOpen] = useState<boolean>(false);
    // const [newElement, setNewElement] = useState<string>("");
    const [teams, setTeams] = useState<any>();
    const [value, setValue] = useState<any>();

    useEffect(() => {
      if (dataTeams) {
        console.log("data teams recieved: ", dataTeams);
        setTeams(dataTeams.allTeams);
      }
    }, [dataTeams]);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      callback(value);
      setOpen(false);
      // setNewElement("");
    };
    const handleChange = (evt: any) => {
      setValue(evt.target.value);
    };

    return (
      <div>
        {/* <Fab
        variant="round"
        size="small"
        color="inherit"
        aria-label="add"
        disabled={disable}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          fullWidth
          style={{ marginBottom: 8 }}
        >
          {intl.formatMessage(messages.transferPlayer)}
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Sound
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button>
            </Toolbar>
          </AppBar>

          {/* <TextField
          id="filled-number"
          label={title}
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          value={newElement}
          onChange={(evt) => setNewElement(evt.target.value)}
        /> */}

          <div style={{ padding: "16px" }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Teams</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChange}
              >
                {teams &&
                  teams.map((team: any) => {
                    if (team.name === currentTeam) {
                      return (
                        <FormControlLabel
                          value={team.id}
                          disabled
                          control={<Radio />}
                          label={`${team.name} (current team)`}
                        />
                      );
                    } else {
                      return (
                        <FormControlLabel
                          value={team.id}
                          control={<Radio />}
                          onClick={() => setValue(team.id)}
                          label={team.name}
                        />
                      );
                    }
                  })}
              </RadioGroup>
            </FormControl>
          </div>
        </Dialog>
      </div>
    );
  }
);

export default TranserPlayerDialog;
