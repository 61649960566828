// import ApolloClient from 'apollo-boost';
import tokenStorage from "./components/auth-context-provider/tokenStorage";
// import { ApolloClient } from "apollo-client";
import { ApolloClient } from "@apollo/client";
// import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ApolloLink, InMemoryCache, Observable } from "@apollo/client";

const request = async (operation: any) => {
  const authToken = tokenStorage.getToken();

  if (authToken && operation.operationName !== "Login") {
    operation.setContext({
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

export const createApolloClient = () => {
  //old attempt
  // const client = new ApolloClient({
  //   uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
  //   request: async (operation) => {
  //     const authToken = tokenStorage.getToken();

  //     if (authToken && operation.operationName !== 'Login') {
  //       operation.setContext({
  //         headers: {
  //           authorization: `Bearer ${authToken}`,
  //         },
  //       });
  //     }
  //   },
  //   onError: ({ graphQLErrors }) => {
  //     if (graphQLErrors) {
  //       const foundError = graphQLErrors.find((err) =>
  //         Boolean(err.extensions && err.extensions.code === 'UNAUTHENTICATED')
  //       );

  //       if (foundError) {
  //         tokenStorage.clearToken();
  //         document.location.href = '/login';
  //       }
  //     }
  //   },
  // });

  //attemp2
  // const client = new ApolloClient({
  //   link: ApolloLink.from([
  //     onError(({ graphQLErrors, networkError }) => {
  //       if (graphQLErrors)
  //         graphQLErrors.forEach(({ message, locations, path }) =>
  //           console.log(
  //             `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //           )
  //         );
  //       if (networkError) console.log(`[Network error]: ${networkError}`);
  //     }),
  //     new HttpLink({
  //       // uri: 'https://w5xlvm3vzz.lp.gql.zone/graphql',
  //       // credentials: 'same-origin'
  //       uri: process.env.REACT_APP_GRAPHQL_URI || "/graphql",
  //     }),
  //   ]),
  //   cache: new InMemoryCache(),
  // });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          const foundError = graphQLErrors.find((err) =>
            Boolean(err.extensions && err.extensions.code === "UNAUTHENTICATED")
          );
          if (foundError) {
            tokenStorage.clearToken();
            document.location.href = "/login";
          }
        }
        // if (networkError) {
        //   logoutUser();
        // }
      }),
      requestLink,
      new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URI || "/graphql",
        // credentials: "include",
      }),
    ]),
    cache: new InMemoryCache(),
    resolvers: {
      Mutation: {
        updateNetworkStatus: (_, { isConnected }, { cache }) => {
          cache.writeData({ data: { isConnected } });
          return null;
        },
      },
    },
  });

  // client.defaultOptions = {
  //   watchQuery: {
  //     fetchPolicy: 'cache-and-network',
  //     errorPolicy: 'all',
  //   },
  //   query: {
  //     fetchPolicy: 'cache-first',
  //     errorPolicy: 'all',
  //   },
  // };

  return client;
};
