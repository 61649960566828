import { defineMessages } from "react-intl";

export default defineMessages({
  description: {
    id: "SquadCollapse.description",
    defaultMessage: "Description",
  },
  task: {
    id: "SquadCollapse.task",
    defaultMessage: "Task",
  },
  details: {
    id: "SquadCollapse.details",
    defaultMessage: "Details",
  },
  addToTraining: {
    id: "SquadCollapse.addToTraining",
    defaultMessage: "Select",
  },
  removeFromTraining: {
    id: "SquadCollapse.removeFromTraining",
    defaultMessage: "Remove",
  },
  noResults: {
    id: "SquadCollapse.noResults",
    defaultMessage: "No SquadCollapse, create the first one.",
  },
  statistics: {
    id: "SquadCollapse.statistics",
    defaultMessage: "Statistics",
  },
  goals: {
    id: "SquadCollapse.goals",
    defaultMessage: "Goals",
  },
  yellowCards: {
    id: "SquadCollapse.yellowCards",
    defaultMessage: "Yellow cards",
  },
  redCards: {
    id: "SquadCollapse.redCards",
    defaultMessage: "Red cards",
  },
  matches: {
    id: "SquadCollapse.matches",
    defaultMessage: "Fixtures",
  },
});
