import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "NewMatchForm.title",
    defaultMessage: "Create new match",
  },
  location: {
    id: "NewMatchForm.location",
    defaultMessage: "Location",
  },
  date: {
    id: "NewMatchForm.date",
    defaultMessage: "Date",
  },
  time: {
    id: "NewMatchForm.time",
    defaultMessage: "Time",
  },
  homeTeam: {
    id: "NewMatchForm.homeTeam",
    defaultMessage: "Home team",
  },
  awayTeam: {
    id: "NewMatchForm.awayTeam",
    defaultMessage: "Away team",
  },
  next: {
    id: "NewMatchForm.next",
    defaultMessage: "Next",
  },
  isHomeTeam: {
    id: "NewMatchForm.isHomeTeam",
    defaultMessage: "Is your team playing as local?",
  },
  friendly: {
    id: "NewMatchForm.friendly",
    defaultMessage: "friendly?",
  },
  home: {
    id: "NewMatchForm.home",
    defaultMessage: "Home",
  },
  away: {
    id: "NewMatchForm.away",
    defaultMessage: "Away",
  },
  homeAwayTitle: {
    id: "NewMatchForm.homeAwayTitle",
    defaultMessage: "Playing Home or Away",
  },
});
