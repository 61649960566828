import React, { useState, useEffect } from "react";
import { Select, InputLabel, FormControl, Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      borderRadius: 24,
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const GenericSelect = ({
  id,
  label,
  collection,
  onChange,
  value,
  placeholder,
}: any) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    if (value) {
      console.log("[GenericSelect]->value");
      console.log(value);
      console.log("[GenericSelect]->selectedOption");
      console.log(selectedOption);
      setSelectedOption(value.toUpperCase());
      onChange();
    }
  }, [value]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel shrink={true} id={`generic-select-${label}`}>
          {label}
        </InputLabel>
        <Select
          variant="filled"
          native
          id={id}
          value={selectedOption}
          onChange={onChange}
          style={{ marginRight: "16px" }}
          placeholder={placeholder || `Select ${label}`}
          fullWidth
        >
          <option aria-label="None" value="" />
          {Object.keys(collection).map((key, idx) => (
            <option key={idx} value={key}>
              {collection[key]}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default GenericSelect;
