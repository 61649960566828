import React from "react";
import Select from "../select";
import Option from "../select/option";

interface ILocaleOptions {
  id: string;
  label: string;
}

interface ILanguageSwitcher {
  locale?: string;
  light?: boolean;
  locales: ILocaleOptions[];
  onChange: (arg: string) => void;
}

const LanguageSwitcher = ({
  locale = "en-GB",
  light = false,
  locales,
  onChange
}: ILanguageSwitcher) => {
  console.log("locales--------------");
  console.log(locales);
  return (
    <Select
      light={light}
      id="language-switcher"
      inputProps={{
        "data-testid": "language-switcher",
        "aria-label": "Language"
      }}
      value={locale}
      onChange={(event: any) => onChange(event.target.value)}
    >
      {locales.map((option, idx) => (
        <Option key={idx} value={option.id}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;
