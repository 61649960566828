import React from "react";
import NewPlayerForm from "./new-player-form";
import { IFormValues } from "./new-player-form/NewPlayerForm";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  userDetails: any;
}

const StepInitial = ({ onGoNext, userDetails }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    onGoNext(formValues);
  };
  return <NewPlayerForm onSubmit={handleSubmit} {...userDetails} />;
};

export default StepInitial;
