import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Exercises from "../exercises-collapse";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "sticky",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IAddExercise {
  callback: (exercises: string[]) => void;
}

const AddExercise = ({ callback }: IAddExercise) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  // const { data } = useQuery(GET_EXERCISES);
  const [selectedExercises, setSelectedExercises] = useState<string[]>([]);

  useEffect(() => {
    if (selectedExercises) {
      // callback(selectedExercises);
    }
  }, [selectedExercises]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // callback(newElement);
    callback(selectedExercises);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Add task
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography variant="h6" className={classes.title}>
              Sound
            </Typography> */}
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>

        <Exercises
          isTraining={true}
          callback={(exercises) => setSelectedExercises(exercises)}
        />
      </Dialog>
    </div>
  );
};

export default AddExercise;
