import React from "react";
import { Grid, Card } from "@material-ui/core";
import TeamSummaryCard from "../team-summary-card";
import { injectIntl, InjectedIntlProps } from "react-intl";
import messages from "./Teams.intl";
import { ITeam } from "../../types";
import ScoutCard from "../scout-card";
import useIsAdmin from "../../hooks/useIsAdmin";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";

interface IGroupFilterState {
  items: ITeam[];
}

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/team.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "450px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
  width: "100%",
}));

const Title = styled("div")({
  color: "White",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});

const GroupList = injectIntl<IGroupFilterState & InjectedIntlProps>(
  ({ items, intl }) => {
    if (!items.length) {
      return (
        <CardWrapper>
          <Title>{intl.formatMessage(messages.noTeams)}</Title>
        </CardWrapper>
      );
    }

    return (
      <Grid container spacing={4} data-testid="group-cards">
        {items.map((group) => {
          return (
            <Grid item xs={12} sm={6} key={`grid-item-${group.id}`}>
              <TeamSummaryCard
                key={group.id}
                id={group.id}
                // squad={group.squad}
                // title={group.name}
                // subtitle={intl.formatMessage(messages.leadersCount, {
                //   n: group.squad.length
                // })}
                // foundation={group.foundationYear}
                team={group}
              />
            </Grid>
          );
        })}
        {useIsAdmin() && (
          <Grid item xs={12} sm={6} key={`grid-item-scout1`}>
            <ScoutCard />
          </Grid>
        )}
      </Grid>
    );
  }
);

export default GroupList;
