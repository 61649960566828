import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Card,
  // Theme,
  Chip,
} from "@material-ui/core";
import Typography from "../typography";
import moment from "moment";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RouteComponentProps } from "@reach/router";
import { injectIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import messages from "./PageViewScouting.intl";
import { IStyledArguments } from "../../types/styled-arguments";
import { gql } from "@apollo/client";
import TodayIcon from "@material-ui/icons/Today";
// import Breadcrumb from "./Breadcrumb";
// import PictureUploader from "./PictureUploader";
// import SnackBar from "../snackbar";
// import CancelIcon from "@material-ui/icons/CancelOutlined";
import { ActionLink } from "../atoms";
// import { grey } from "@material-ui/core/colors";

export const GET_PLAYERS = gql`
  query {
    allPlayers(team: "scouting") {
      id
      position
      name
      surname
      number
      totalGoals
      totalMatches
      yellowCards
      redCards
      notes {
        note
        date
      }
    }
    currentUser {
      id
      role
    }
  }
`;

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/soccer-open-field.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "250px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
}));

const Title = styled("div")({
  color: "white",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
  paddingBottom: "10px",
});
const Description = styled("div")({
  color: "white",
  position: "absolute",
  bottom: 0,
});

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: `solid ${theme.palette.extra.borderColor}`,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const ListWrapper = styled("div")(({ theme }: IStyledArguments) => ({
  marginTop: "11px",
  width: "100%",
  backgroundColor: theme.palette.extra.lighterGrey,
  padding: "16px",
}));

const Main = styled("main")(({ theme }: IStyledArguments) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight +
    theme.layout.tabsHeight}px )`,
  background: theme.palette.common.white,
  paddingBottom: theme.layout.footerHeight,
}));

const PageViewExercise = injectIntl<RouteComponentProps>(({ intl }) => {
  // const { data } = useQuery<any>(GET_EXERCISE, { variables: { id } });
  const { data } = useQuery<any>(GET_PLAYERS);
  const [allPlayers, setAllPlayers] = useState<any>();

  // const [exercise, setExercise] = useState<any>({});
  // const [exerciseDeleted, setExerciseDeleted] = useState(false);
  // const [deleteExercise, { data: exerciseDeletedData }] = useMutation(
  //   DELETE_EXERCISE
  // );
  // const [errorDeletingExercise, setErrorDeletingExercise] = useState(false);

  // useEffect(() => {
  //   if (exerciseDeletedData) {
  //     console.log("useEffect");
  //     console.log(exerciseDeletedData);
  //     if (exerciseDeletedData.deleteExercise === "ok") {
  //       console.log("setting exercise deleted");
  //       setExerciseDeleted(true);
  //     } else {
  //       console.log("error deleting exercise");
  //       setErrorDeletingExercise(true);
  //     }
  //   }
  // }, [exerciseDeletedData]);

  useEffect(() => {
    if (data && data.allPlayers) {
      setAllPlayers(data.allPlayers);
      // setCurrentUser(data.currentUser);
    }
  }, [data]);

  // useEffect(() => {
  // if (exerciseDeleted) {
  //   console.log("redirecting...");
  //   return (
  //     <Redirect to="/library/exercises?action=exerciseDeleted" noThrow />
  //   );
  // }
  // }, [exerciseDeleted]);

  // const handleDeleteExercise = () => {
  //   console.log("deleting exercise...");
  //   deleteExercise({
  //     variables: {
  //       id,
  //     },
  //   });
  //   // return <Redirect to="/library/trainings" noThrow />;
  // };

  return (
    <Main>
      <>
        {/* <div style={{ padding: 16 }}>
            <Breadcrumb exercise={exercise} />
          </div> */}
        <CardWrapper>
          <Title>Scouting</Title>
          <Description>Players: {allPlayers && allPlayers.length}</Description>
        </CardWrapper>
        <ListWrapper>
          {allPlayers && allPlayers.length === 0 && (
            <Card
              style={{
                padding: "16px",
                height: "180px",
                backgroundColor: "white",
                color: "grey",
              }}
            >
              <div style={{ fontSize: "x-large" }}>
                {intl.formatMessage(messages.noPlayers)}
              </div>
              <div style={{ fontSize: "medium" }}>
                {intl.formatMessage(messages.noPlayers_add)}&nbsp;
                <ActionLink to="/players/newplayer">
                  {intl.formatMessage(messages.noPlayers_here)}
                </ActionLink>
              </div>
            </Card>
          )}
          {allPlayers &&
            allPlayers.length > 0 &&
            allPlayers.map((player: any) => (
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`panel-${player.id}`}
                >
                  <Typography.Subtitle1>
                    {player.name}&nbsp;{player.surname}
                  </Typography.Subtitle1>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Section>
                      <SectionTitle>Notes:</SectionTitle>
                      {player.notes &&
                        player.notes.map((note: any) => (
                          <>
                            <Chip
                              style={{
                                borderRadius: 16,
                                padding: 5,
                                marginTop: 16,
                                marginBottom: 8,
                              }}
                              icon={<TodayIcon />}
                              label={moment(Number(note.date)).format(
                                "DD-MM-YYYY"
                              )}
                              variant="outlined"
                              color="secondary"
                            />
                            {/* <div>
                              {moment(Number(note.date)).format("DD-MM-YYYY")}
                            </div> */}
                            <div>{note.note}</div>
                          </>
                        ))}
                    </Section>
                    <ActionLink to={`/players/${player.id}/information`}>
                      <Button variant="outlined" color="secondary">
                        {intl.formatMessage(messages.details)}
                      </Button>
                    </ActionLink>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
        </ListWrapper>
      </>
    </Main>
  );
});

export default PageViewExercise;
