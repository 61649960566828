import { defineMessages } from "react-intl";

export default defineMessages({
  goalsLabel: {
    id: "DashboardPlayerInfoCard.goalsLabel",
    defaultMessage: `{n, plural,
      =0 {goals}
      one {goal}
      other {goals}
    }`
  },
  matchesLabel: {
    id: "DashboardPlayerInfoCard.matchesLabel",
    defaultMessage: `{n, plural,
      =0 {matches}
      one {match}
      other {matches}
    }`
  },
  notificationLabel: {
    id: "DashboardPlayerInfoCard.notificationLabel",
    defaultMessage: `{n, plural,
      =0 {notifications}
      one {notification}
      other {notifications}
    }`
  },
  noNotifications: {
    id: "DashboardPlayerInfoCard.noNotifications",
    defaultMessage: "you don't have any notification"
  },
  birthday: {
    id: "DashboardPlayerInfoCard.birthday",
    defaultMessage: "Birthday"
  }
});
