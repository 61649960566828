import React, { useState, useEffect } from "react";

import {
  Grid,
  makeStyles,
  Theme,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled,
  createStyles,
  Card,
  Chip,
  Button,
  Modal,
  Fab,
  TextField,
} from "@material-ui/core";
import Snackbar from "../snackbar";
import Typography from "../typography";
import Content from "../content";
import { IStyledArguments } from "../../types/styled-arguments";

import SoccerBallIcon from "@material-ui/icons/SportsSoccer";
import EventIcon from "@material-ui/icons/Event";
import { injectIntl } from "react-intl";
import messages from "./PageViewPlayer.intl";
import { ConfirmDialog } from "../atoms";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import CopyIcon from "@material-ui/icons/FileCopy";
import useIsAdmin from "../../hooks/useIsAdmin";
import { Redirect } from "@reach/router";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import HistoricalCard from "./HistoricalCard";
import ContactDetailsCard from "./ContactDetailsCard";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TransferPlayerDialog from "./TransferPlayerDialog";

const DELETE_PLAYER_SCOUTING = gql`
  mutation DeletePlayerScouting($id: ID!) {
    deletePlayerScouting(id: $id) {
      op
      error
    }
  }
`;

const SEND_INVITE = gql`
  mutation InvitePlayer($id: ID!, $email: String!, $role: String!) {
    invitePlayer(id: $id, email: $email, role: $role)
  }
`;

const TRANSFER_PLAYER = gql`
  mutation TransferPlayer($id: ID!, $teamID: ID!) {
    transferPlayer(id: $id, teamID: $teamID) {
      op
      error
    }
  }
`;

const UNLINK_USER = gql`
  mutation UnLinkUser($id: ID!) {
    unLinkUser(id: $id) {
      op
      error
    }
  }
`;
const REMOVE_PLAYER = gql`
  mutation RemovePlayer($id: ID!) {
    removePlayer(id: $id) {
      op
      error
    }
  }
`;

const useStylesAccordion = makeStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  },
}));

const GridContainer = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
    paper: {
      position: "absolute",
      maxWidth: "90vw",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

const Main = styled("main")<Theme>(({ theme }) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  background: theme.palette.common.white,
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  padding: "24px",
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
}));

const calculateAge = (dateOfBirth: string) => {
  console.log(new Date(Number(dateOfBirth)));
  const now = Date.now();
  const difference = now - Number(dateOfBirth);
  const years = Math.abs(new Date(difference).getUTCFullYear() - 1970);
  return years;
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const InfoTab = injectIntl<any>(
  ({ player, historicalData, intl, callback }) => {
    const classes = useStyles();
    const classesAccordion = useStylesAccordion();
    const [value, setValue] = useState("right");

    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState<boolean>(false);
    const [inviteEmail, setInviteEmail] = useState<string>("");
    const [invitationURL, setInvitationURL] = useState<any>();
    const [textArea, setTextArea] = useState<any>();
    const [copySuccess, setCopySuccess] = useState<any>();
    const [openRedirect, setOpenRedirect] = useState<boolean>(false);
    const [openError, setOpenError] = useState<boolean>(false);
    const [sendInvite, { data: sendInviteData }] = useMutation(SEND_INVITE);
    const [deletePlayerScouting, { data: deletedData }] = useMutation(
      DELETE_PLAYER_SCOUTING
    );
    const [transferPlayer, { data: transferPlayerData }] = useMutation(
      TRANSFER_PLAYER
    );
    const [removePlayer, { data: removePlayerData }] = useMutation(
      REMOVE_PLAYER
    );
    const [unlinkUser] = useMutation(UNLINK_USER);

    useEffect(() => {
      if (sendInviteData) {
        setInvitationURL(sendInviteData.invitePlayer);
      }
    }, [sendInviteData]);

    useEffect(() => {
      if (transferPlayerData) {
        callback();
      }
    }, [transferPlayerData]);

    useEffect(() => {
      if (deletedData && deletedData.deletePlayerScouting) {
        if (deletedData.deletePlayerScouting.op === "ok") {
          setOpenRedirect(true);
        } else {
          setOpenError(true);
        }
      }
    }, [deletedData]);

    useEffect(() => {
      if (removePlayerData) {
        if (removePlayerData.removePlayer.op === "ok") {
          setOpenRedirect(true);
        } else {
          setOpenError(true);
        }
      }
    }, [removePlayerData]);

    if (openRedirect) {
      console.log("redirecting...");
      return <Redirect to="/people/players?userDeleted=true" noThrow />;
    }

    const handleChange = (event: any) => {
      setValue(event.target.value);
    };

    const handleDeletePlayer = async () => {
      await deletePlayerScouting({
        variables: {
          id: player.id,
        },
      });
    };

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const copyToClipboard = (e: any) => {
      textArea.select();
      document.execCommand("copy");
      // This is just personal preference.
      // I prefer to not show the the whole text area selected.
      e.target.focus();
      setCopySuccess("Copied!");
    };

    const handleSetInviteEmail = (event: any) => {
      setInviteEmail(event.target.value);
    };

    const handleSendInvite = async () => {
      console.log(inviteEmail);
      await sendInvite({
        variables: {
          id: player.id,
          email: inviteEmail,
          role: "PLAYER",
        },
      });
    };
    const handleTransfer = async (newTeamID: string) => {
      console.log(newTeamID);
      await transferPlayer({
        variables: {
          id: player.id,
          teamID: newTeamID,
        },
      });
    };

    const handleRemovePlayer = async () => {
      await removePlayer({
        variables: {
          id: player.id,
        },
      });
    };
    const handleUnlinkUser = async () => {
      await unlinkUser({
        variables: {
          id: player.id,
        },
      });
      callback();
    };

    return (
      <Main>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">
              Invite {player.name} {player.surname}
            </h2>

            <p id="simple-modal-description">
              Introduce the email of the player
            </p>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="email"
              margin="normal"
              variant="outlined"
              onChange={(e) => handleSetInviteEmail(e)}
              value={inviteEmail}
            />
            {invitationURL && (
              <div>
                <form>
                  <textarea
                    style={{ width: "200px", height: "80px" }}
                    ref={(textarea) => setTextArea(textarea)}
                    value={invitationURL}
                  />
                </form>
                {document.queryCommandSupported("copy") && (
                  <>
                    <p id="simple-modal-description">
                      An email have been sent. But you can copy the invitation
                      and share it by Whatsapp or text message.
                    </p>
                    <div>
                      <Fab
                        size="small"
                        color="inherit"
                        aria-label="add"
                        onClick={copyToClipboard}
                      >
                        <CopyIcon />
                      </Fab>
                      {copySuccess}
                    </div>
                  </>
                )}
              </div>
            )}

            <GridContainer>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendInvite}
                  disabled={invitationURL}
                >
                  Send
                </Button>
                <Button
                  style={{ marginLeft: 16 }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  close
                </Button>
              </Grid>
            </GridContainer>
          </div>
        </Modal>
        <MainContent container>
          <GridContainer>
            <Grid item xs={12}>
              {/* <Typography.H5>
              {intl.formatMessage(messages.information)}:
            </Typography.H5> */}
            </Grid>
            <Grid item xs={12}>
              <CardWrapper>
                <Grid item xs={10}>
                  <Typography.H5>
                    {player.name} {player.surname}
                  </Typography.H5>
                </Grid>
                <Grid item xs={12}>
                  <Typography.H6>
                    {player.team && player.team.name}
                  </Typography.H6>
                </Grid>
                <div style={{ marginBottom: 8 }}>
                  <Chip
                    style={{ borderRadius: 17, marginRight: 8 }}
                    size="small"
                    variant="default"
                    color="primary"
                    icon={<SoccerBallIcon />}
                    label={`${player.totalGoals || 0} ${intl.formatMessage(
                      messages.goals
                    )}`}
                    onClick={() => null}
                  />
                  <Chip
                    style={{ borderRadius: 17, marginRight: 8 }}
                    size="small"
                    variant="default"
                    color="secondary"
                    icon={<EventIcon />}
                    label={`${player.totalMatches || 0} ${intl.formatMessage(
                      messages.games
                    )}`}
                    onClick={() => null}
                  />
                  <Chip
                    style={{ borderRadius: 17, marginRight: 8, color: "grey" }}
                    size="small"
                    variant="default"
                    color="default"
                    icon={<EventIcon />}
                    label={`${calculateAge(player.dateOfBirth) ||
                      0} ${intl.formatMessage(messages.years)}`}
                    onClick={() => null}
                  />
                </div>
                {useIsAdmin() && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      marginTop: 16,
                    }}
                  >
                    {(player.scouting || player.team === null) && (
                      <ConfirmDialog
                        title={intl.formatMessage(messages.ConfirmDialogTitle)}
                        message={intl.formatMessage(
                          messages.ConfirmDialogMessage
                        )}
                        trigger={({ onOpen }: any) => (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={onOpen}
                            fullWidth
                          >
                            DELETE
                          </Button>
                        )}
                        callback={(response: any) => {
                          if (response === true) {
                            handleDeletePlayer();
                          }
                        }}
                      />
                    )}
                    {player.isInvited === false &&
                      !player.user &&
                      !player.scouting &&
                      player.team !== null && (
                        <Button
                          style={{ marginLeft: "8px" }}
                          variant="outlined"
                          color="secondary"
                          onClick={handleOpen}
                          fullWidth
                        >
                          {intl.formatMessage(messages.invite)}
                        </Button>
                      )}
                  </div>
                )}
                {player.user && (
                  //   <CardWrapper style={{ marginBottom: 16 }}>
                  //     <Grid item xs={12}>
                  //       <Typography.H5>Contact Details</Typography.H5>
                  //     </Grid>
                  //     <Grid item xs={12}>
                  //       <Typography.H6>email: {player.user.email}</Typography.H6>
                  //     </Grid>
                  //   </CardWrapper>
                  <Grid item xs={12}>
                    <ContactDetailsCard user={player.user} />
                  </Grid>
                )}
                {player.historicalData && (
                  <Grid item xs={12}>
                    <HistoricalCard data={historicalData} />
                  </Grid>
                )}
              </CardWrapper>
            </Grid>
          </GridContainer>
          {useIsAdmin() && (
            <div className={classesAccordion.root}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography.H5 className={classesAccordion.heading}>
                    {intl.formatMessage(messages.managePlayer)}
                  </Typography.H5>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      flex: "true",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleRemovePlayer}
                      fullWidth
                      style={{ marginBottom: 8 }}
                      disabled={player.user}
                    >
                      {intl.formatMessage(messages.removePlayer)}
                    </Button>
                    {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRemovePlayer}
                    fullWidth
                    style={{ marginBottom: 8 }}
                  >
                    [SC]Transfer player
                  </Button> */}
                    <TransferPlayerDialog
                      title={intl.formatMessage(messages.transferPlayer)}
                      currentTeam={player.team && player.team.name}
                      callback={(x: any) => handleTransfer(x)}
                    />
                    {player.user && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUnlinkUser}
                        fullWidth
                        style={{ marginBottom: 8 }}
                      >
                        {intl.formatMessage(messages.removeAssociatedUser)}
                      </Button>
                    )}
                    {player.isInvited && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        fullWidth
                        style={{ marginBottom: 8 }}
                      >
                        {intl.formatMessage(messages.resendInvitation)}
                      </Button>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography.H5 className={classesAccordion.heading}>
                    Technical Details (comming soon)
                  </Typography.H5>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Foot</FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="right"
                          control={<Radio />}
                          label="Right"
                        />
                        <FormControlLabel
                          value="left"
                          control={<Radio />}
                          label="Left"
                        />
                        <FormControlLabel
                          value="both"
                          control={<Radio />}
                          label="Both"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </MainContent>

        <Snackbar
          open={openError}
          message={intl.formatMessage(messages.messageOnDelete)}
          variant="error"
          onClose={handleClose}
          action={() => {
            setOpenError(false);
          }}
          actionText={<CancelIcon />}
        />
      </Main>
    );
  }
);

export default InfoTab;
