import React from "react";
// import { FormattedMessage } from "react-intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
// import { ITeam } from "../../types";
import { Card } from "@material-ui/core";
import Link from "../link";
import messages from "./ScoutCard.intl";
import { injectIntl } from "react-intl";

// const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
//   background: theme.palette.extra.backgroundGrey,
//   flexDirection: "column",
//   height: "100%",
//   display: "flex",
// }));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/soccer-open-field.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "100%",
  display: "flex",
  // margin: "15px",
  minHeight: "200px",
  paddingLeft: theme.spacing(2),
  position: "relative",
  // "&hover": {
  //   transition: "all .2s ease-in-out",
  //   transform: "scale(1.1)",
  // },
}));

// const Header = styled("div")(({ theme }: IStyledArguments) => ({
//   background: theme.palette.extra.lighterGrey,
//   display: "flex",
//   justifyContent: "space-between",
//   padding: theme.spacing(1, 1.5, 1, 1.5),
// }));

// const Content = styled("div")(({ theme }: IStyledArguments) => ({
//   background: theme.palette.extra.default,
//   display: "flex",
//   justifyContent: "space-between",
//   padding: theme.spacing(1, 1.5, 1, 1.5),
// }));

const Title = styled("div")({
  color: "white",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});

// const Subtitle = styled(Typography.Subtitle1)(
//   ({ theme }: IStyledArguments) => ({
//     color: theme.palette.text.secondary,
//   })
// );

// const CreatedDate = styled(Typography.Subtitle1)(() => ({
//   textAlign: "right",
// }));

// const Row = styled((props) => <Grid container {...props} />)({
//   width: "100%",
//   flex: 1,
//   justifyContent: "space-between",
// });

// interface ITeamSummaryCardProps {
//   id: string;
//   team: ITeam;
// }
const StyledActionLink = styled(Link)({
  textDecoration: "none",
});

// const getNumberOfMatchesWon = (matches: any) => {
//   if (matches) {
//     const numberOfWonGames = matches
//       .map((match: any) => (match && match.isWon ? 1 : 0))
//       .reduce((acc: any, current: any) => acc + current, 0);
//     console.log(numberOfWonGames);
//     return numberOfWonGames;
//   }
//   return 0;
// };

// const getNumberOfMatchesTied = (matches: any) => {
//   if (matches) {
//     const numberOTiedMatches = matches
//       .map((match: any) => (match && match.isTied ? 1 : 0))
//       .reduce((acc: any, current: any) => acc + current, 0);
//     console.log(numberOTiedMatches);
//     return numberOTiedMatches;
//   }
//   return 0;
// };

// const getNumberOfMatchesLost = (matches: any) => {
//   if (matches) {
//     const numberOfLostMatches = matches
//       .map((match: any) => (match && match.isLost ? 1 : 0))
//       .reduce((acc: any, current: any) => acc + current, 0);
//     console.log(numberOfLostMatches);
//     return numberOfLostMatches;
//   }
//   return 0;
// };

const ScoutCard = injectIntl<any>(({ intl }) => {
  return (
    <StyledActionLink to={`/scouting`}>
      <CardWrapper>
        <Title>{intl.formatMessage(messages.scoutTitle)}</Title>
      </CardWrapper>
    </StyledActionLink>
  );
});

export default ScoutCard;
