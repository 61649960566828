import React from "react";
import { Card } from "@material-ui/core";
// import GroupSummaryCard from "../group-summary-card";
import MatchSummaryCard from "../match-summary-card";
import { injectIntl, InjectedIntlProps } from "react-intl";
import messages from "./Matches.intl";
import { IMatch } from "../../types";
import moment from "moment";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import BallIcon from "@material-ui/icons/SportsSoccer";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";

interface IGroupFilterState {
  items: IMatch[];
  currentUser: {
    id: string;
    role: string;
  };
}

const TimeLineWrapper = styled("div")(({ theme }: IStyledArguments) => ({
  backgroundColor: theme.palette.extra.lighterGrey,
  padding: theme.spacing(2),
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/exerciseBG.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "450px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
}));

const Title = styled("div")({
  color: "White",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});

const MatchesList = injectIntl<IGroupFilterState & InjectedIntlProps>(
  ({ items, currentUser, intl }) => {
    if (!items || !items.length) {
      return (
        <CardWrapper>
          <Title>{intl.formatMessage(messages.noMatches)}</Title>
        </CardWrapper>
      );
    } else {
      return (
        <TimeLineWrapper>
          <VerticalTimeline>
            {items
              .sort(
                (a, b) =>
                  Number(b.date.replace(new RegExp("-", "g"), "")) -
                  Number(a.date.replace(new RegExp("-", "g"), ""))
              )
              .map((match) => {
                return (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "white",
                      color: "grey",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid white",
                    }}
                    date={`${moment(match.date).format("DD-MM-YYYY")}`}
                    iconStyle={{
                      background: "#005473",
                      color: "#fff",
                    }}
                    icon={<BallIcon />}
                  >
                    <MatchSummaryCard
                      key={match.id}
                      id={match.id}
                      match={match}
                      currentUser={currentUser}
                    />
                  </VerticalTimelineElement>
                );
              })}
          </VerticalTimeline>
        </TimeLineWrapper>
      );
    }
  }
);

export default MatchesList;
