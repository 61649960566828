import React from "react";
import { styled } from "@material-ui/styles";
import Content from "../content";
import { Grid, Theme, Card } from "@material-ui/core";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";

const Main = styled("main")<Theme>(({ theme }) => ({
  display: "block", // IE
  // minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  background: theme.palette.common.white,
  paddingBottom: "70px"
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4)
}));

const GridContainer = styled(props => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between"
});

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  //   height: "100%",
  display: "flex",
  padding: theme.spacing(2)
}));

const Step1 = () => {
  return (
    <Main>
      <MainContent container>
        <GridContainer>
          <Grid item xs={12} sm={6} lg={3} style={{ padding: "8px" }}>
            <CardWrapper data-testid={`group-summary-${1}`}>
              <Typography.H6>Step1</Typography.H6>
            </CardWrapper>
          </Grid>
        </GridContainer>
      </MainContent>
    </Main>
  );
};

export default Step1;
