import React from "react";
import BaseButton from "../button";
import { IStyledArguments } from "../../types/styled-arguments";
import { styled } from "@material-ui/styles";

const GoNextButton = styled((props) => (
  <BaseButton size="large" {...props} onClick={props.onClick} />
))((props: IStyledArguments) => ({
  marginTop: props.theme.spacing(4),
  marginRight: props.theme.spacing(4),
}));

export default GoNextButton;
