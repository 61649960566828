import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/core/styles";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import {
  Theme,
  //   useTheme,
  Fab,
  //   Card,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Chip,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import useIsManager from "../../hooks/useIsManager";
import { ActionLink } from "../atoms";
import { IStyledArguments } from "../../types/styled-arguments";
import { injectIntl } from "react-intl";
import messages from "./Players.intl";
import Typography from "../typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserAvatar from "../user-avatar";
import SoccerBallIcon from "@material-ui/icons/SportsSoccer";
import EventIcon from "@material-ui/icons/Event";
import NoSimIcon from "@material-ui/icons/NoSim";
import Link from "../link";
import AssignTeamModal from "../assign-team-modal";

const FooterContainer = styled("div")<Theme>(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  height: 90,
  width: "auto",
  right: 0,
  position: "fixed",
  bottom: 70,
}));

export const GET_PLAYERS = gql`
  query {
    allPlayers {
      id
      position
      name
      surname
      avatarUrl
      number
      totalGoals
      totalMatches
      yellowCards
      redCards
      team {
        id
        name
      }
      user {
        id
        name
        surname
        role
      }
    }
    allTeams {
      id
      name
    }
    currentUser {
      id
      role
    }
  }
`;
const ASSIGN_PLAYER_TO_TEAM = gql`
  mutation AssignTeamToPlayer($playerId: ID!, $teamId: ID!) {
    assignPlayerToTeam(playerId: $playerId, teamId: $teamId) {
      op
      error
    }
  }
`;

const Main = styled("div")<Theme>(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.extra.lighterGrey,
  padding: "16px",
  minHeight: "100vh",
  paddingBottom: 80,
}));

export interface ILeaderSortingState {
  column: string;
  isDescending: boolean;
}

export enum ColumnHeader {
  NAME = "name",
  EMAIL = "email",
  OPQ = "opq",
  EXPERIENCE = "experience",
}

interface IPlayers {
  refetchPlayers: Boolean;
}

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const StyledLink = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.subtitle2,
  marginBottom: 0,
}));

const Players = injectIntl<IPlayers>(({ refetchPlayers, intl }) => {
  const { data, refetch } = useQuery<any>(GET_PLAYERS);
  const [allPlayers, setAllPlayers] = useState<any>();
  const [teams, setTeams] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [assignTeam, { data: assignData }] = useMutation(ASSIGN_PLAYER_TO_TEAM);

  useEffect(() => {
    if (refetchPlayers || assignData) {
      refetch();
    }
  }, [refetchPlayers, assignData]);

  useEffect(() => {
    if (data && data.allPlayers) {
      setAllPlayers(data.allPlayers);
      setCurrentUser(data.currentUser);
    }
    if (data && data.allTeams) {
      setTeams(data.allTeams);
    }
  }, [data]);

  const handleAssingPlayerIntoTeam = (playerId: string, teamId: string) => {
    assignTeam({
      variables: {
        playerId,
        teamId,
      },
    });
  };

  return (
    <>
      <Main>
        {allPlayers &&
          allPlayers.length > 0 &&
          allPlayers.map((player: any) => (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={`panel-${player.id}`}
              >
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <UserAvatar
                    user={{
                      name: player.name,
                      surname: player.surname,
                      avatarUrl: player.avatarUrl,
                    }}
                    data-testid={`user-avatar-${player.id}`}
                  />
                  &nbsp;
                  <Typography.Subtitle1>{player.name}</Typography.Subtitle1>
                  &nbsp;
                  <Typography.Subtitle1>{player.surname}</Typography.Subtitle1>
                  &nbsp;(
                  <Typography.Subtitle2>
                    {player.team ? player.team.name : "no team"}
                  </Typography.Subtitle2>
                  )
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {player.user && (
                    <Section>
                      <SectionTitle>Parent</SectionTitle>
                      <Typography.Subtitle1>{`${player.user.name} ${player.user.surname}`}</Typography.Subtitle1>
                    </Section>
                  )}
                  <Section>
                    <SectionTitle>
                      {intl.formatMessage(messages.statistics)}
                    </SectionTitle>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Chip
                        style={{
                          borderRadius: 17,
                          backgroundColor: "#4CAF50",
                          color: "white",
                          marginRight: 16,
                          marginTop: 8,
                        }}
                        size="small"
                        icon={<SoccerBallIcon style={{ color: "white" }} />}
                        label={`${player.totalGoals || 0} ${intl.formatMessage(
                          messages.goals
                        )}`}
                        onClick={() => null}
                      />
                      <Chip
                        style={{
                          borderRadius: 17,
                          marginRight: 16,
                          marginTop: 8,
                        }}
                        size="small"
                        color="primary"
                        icon={<EventIcon />}
                        label={`${player.totalMatches ||
                          0} ${intl.formatMessage(messages.matches)}`}
                        onClick={() => null}
                      />
                      <Chip
                        style={{
                          borderRadius: 17,
                          backgroundColor: "#ffea00", //yellow
                          color: "black",
                          marginRight: 16,
                          marginTop: 8,
                        }}
                        size="small"
                        icon={<NoSimIcon />}
                        label={`${player.yellowCards || 0} ${intl.formatMessage(
                          messages.yellowCards
                        )}`}
                        onClick={() => null}
                      />
                      <Chip
                        style={{
                          borderRadius: 17,
                          backgroundColor: "#e53935", //red
                          color: "white",
                          marginTop: 8,
                        }}
                        size="small"
                        icon={<NoSimIcon style={{ color: "white" }} />}
                        label={`${player.redCards || 0} ${intl.formatMessage(
                          messages.redCards
                        )}`}
                        onClick={() => null}
                      />
                    </div>
                  </Section>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <StyledLink to={`/players/${player.id}/information`}>
                      <Button variant="outlined" color="primary">
                        {intl.formatMessage(messages.details)}
                      </Button>
                    </StyledLink>
                    {!player.team &&
                      currentUser &&
                      useIsManager(currentUser.role) && (
                        <AssignTeamModal
                          player={player}
                          teams={teams}
                          callback={handleAssingPlayerIntoTeam}
                        />
                      )}
                  </div>

                  <div>
                    {/* {isTraining && !selectedExercises.includes(exercise.id) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={() => handleAddExercise(exercise.id)}
                      >
                        {intl.formatMessage(messages.addToTraining)}
                      </Button>
                    )}
                    {isTraining && selectedExercises.includes(exercise.id) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => handleRemoveExercise(exercise.id)}
                      >
                        {intl.formatMessage(messages.removeFromTraining)}
                      </Button>
                    )} */}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </Main>
      <FooterContainer>
        {currentUser && useIsManager(currentUser.role) && (
          <ActionLink to="/players/newplayer">
            <Fab
              aria-label="add team"
              color="secondary"
              style={{ zIndex: 1200 }}
            >
              <AddIcon />
            </Fab>
          </ActionLink>
        )}
      </FooterContainer>
    </>
  );
});

export default Players;
