import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import {
  Paper,
  // Button,
  Toolbar,
  // TableRow,
  // TableHead,
  // TableContainer,
  // TableCell,
  // TableBody,
  // Table
} from "@material-ui/core";
// import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
// import CheckIcon from "@material-ui/icons/Check";
// import CloseIcon from "@material-ui/icons/Close";
// import { TextField, Fab } from "@material-ui/core";
import { injectIntl } from "react-intl";
// import { useQuery } from "@apollo/client";

// interface Data {
//   //   calories: number;
//   //   carbs: number;
//   //   fat: number;
//   name: string;
//   //   protein: number;
// }

// function createData(
//   name: string,
//   calories: number,
//   fat: number,
//   carbs: number,
//   protein: number
// ): Data {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Alaves", 305, 3.7, 67, 4.3),
//   createData("Real Madrid", 452, 25.0, 51, 4.9),
//   createData("Atletico de Madrid", 262, 16.0, 24, 6.0),
//   createData("FC Barcelona", 159, 6.0, 24, 4.0),
//   createData("Real Betis", 356, 16.0, 49, 3.9),
//   createData("Real Sevilla", 408, 3.2, 87, 6.5),
//   createData("Real Celta", 237, 9.0, 37, 4.3),
//   createData("Mallorca CF", 375, 0.0, 94, 0.0),
//   createData("Eibar", 518, 26.0, 65, 7.0),
//   createData("Athletic de Bilbao", 392, 0.2, 98, 0.0),
//   createData("Real Sociedad", 318, 0, 81, 2.0),
//   createData("Granada CF", 360, 19.0, 9, 37.0),
//   createData("Osasuna", 437, 18.0, 63, 4.0)
// ];

// function desc<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// type Order = "asc" | "desc";

// function getSorting<K extends keyof any>(
//   order: Order,
//   orderBy: K
// ): (
//   a: { [key in K]: number | string },
//   b: { [key in K]: number | string }
// ) => number {
//   return order === "desc"
//     ? (a, b) => desc(a, b, orderBy)
//     : (a, b) => -desc(a, b, orderBy);
// }

// interface HeadCell {
//   disablePadding: boolean;
//   id: keyof Data;
//   label: string;
//   numeric: boolean;
// }

// const headCells: HeadCell[] = [
//   {
//     id: "name",
//     numeric: false,
//     disablePadding: true,
//     label: "Team name"
//   }
//   //   { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
//   //   { id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
//   //   { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
//   //   { id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" }
// ];

// interface EnhancedTableProps {
//   classes: ReturnType<typeof useStyles>;
//   numSelected: number;
//   league: any;
//   //   onRequestSort: (
//   //     event: React.MouseEvent<unknown>,
//   //     property: keyof Data
//   //   ) => void;
//   onSelectAllClick: (
//     event: React.ChangeEvent<HTMLInputElement>,
//     checked: boolean
//   ) => void;
//   //   order: Order;
//   //   orderBy: string;
//   //   rowCount: number;
// }

// const EnhancedTableHead = (props: EnhancedTableProps) => {
//   const {
//     // classes,
//     // onSelectAllClick,
//     // order,
//     // orderBy
//     // numSelected,
//     // rowCount,
//     // onRequestSort
//   } = props;
//   //   const createSortHandler = (property: keyof Data) => (
//   //     event: React.MouseEvent<unknown>
//   //   ) => {
//   //     onRequestSort(event, property);
//   //   };

//   return (
//     <TableHead>
//       <TableRow>
//         <TableCell padding="checkbox">
//           {/* <Checkbox
//             indeterminate={numSelected > 0 && numSelected < rowCount}
//             checked={numSelected === rowCount}
//             onChange={onSelectAllClick}
//             inputProps={{ "aria-label": "select all desserts" }}
//           /> */}
//         </TableCell>
//         {headCells.map(headCell => (
//           <TableCell
//             key={headCell.id}
//             align={headCell.numeric ? "right" : "left"}
//             padding={headCell.disablePadding ? "none" : "default"}
//             // sortDirection={orderBy === headCell.id ? order : false}
//           >
//             {headCell.label}
//             {/* <TableSortLabel
//               active={orderBy === headCell.id}
//               direction={order}
//               onClick={createSortHandler(headCell.id)}
//             >
//               {headCell.label}
//               {orderBy === headCell.id ? (
//                 <span className={classes.visuallyHidden}>
//                   {order === "desc" ? "sorted descending" : "sorted ascending"}
//                 </span>
//               ) : null}
//             </TableSortLabel> */}
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// };

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  addingMode: () => void;
  deleteMode: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, addingMode, deleteMode } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Teams
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={deleteMode}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <AddIcon onClick={addingMode} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: theme.spacing(3),
    },
    paper: {
      width: "100%",
      //   margin: theme.spacing(2)
      //   paddingRight: 5
    },
    table: {
      minWidth: 200,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      //   margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const EnhancedTable = injectIntl<{ league: any }>(({ league }) => {
  console.log(league);
  const classes = useStyles();
  //   const [order, setOrder] = useState<Order>("asc");
  //   const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const [selected, setSelected] = useState<string[]>([]);
  //   const [page, setPage] = React.useState(0);
  //   const [dense, setDense] = React.useState(false);
  //   const [rowsPerPage, setRowsPerPage] = useState(5);
  //   const [addProgrammeAdmin] = useQuery(GET);
  const [rows, setRows] = useState<any>([
    { name: "Sporting" },
    { name: "Real Madrid" },
  ]);

  console.log(1111111);
  console.log(1111111);
  useEffect(() => {
    const theRows =
      league.teams &&
      league.teams.map((team: any) => {
        console.log(11111112);
        console.log(team);
        return { name: team };
      });
    setRows(theRows);
  }, league);

  // const [newTeam, setNewTeam] = useState("");
  // const [addingMode, setAddingMode] = useState(false);

  //   const handleRequestSort = (
  //     event: React.MouseEvent<unknown>,
  //     property: keyof Data
  //   ) => {
  //     const isDesc = orderBy === property && order === "desc";
  //     setOrder(isDesc ? "asc" : "desc");
  //     setOrderBy(property);
  //   };

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n: any) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected: string[] = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleDelete = () => {
    console.log("newSelected", selected);
    const newTeams = [...rows];
    selected.forEach((team: string) => {
      const index = newTeams.findIndex((x) => x.name === team);
      if (index > -1) {
        newTeams.splice(index, 1);
      }
    });
    console.log(newTeams);
    setRows(newTeams);
    setSelected([]);
  };

  //   const handleChangePage = (event: unknown, newPage: number) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (
  //     event: React.ChangeEvent<HTMLInputElement>
  //   ) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  //   const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setDense(event.target.checked);
  //   };

  // const handleChangeNewTeam = (event: any) => {
  //   setNewTeam(event.target.value);
  // };

  // const handleAddTeam = () => {
  //   const newRows = [...rows];
  //   newRows.push({ name: newTeam });
  //   setRows(newRows);
  //   setNewTeam("");
  //   handleClose();
  // };

  // const handleClose = () => {
  //   setAddingMode(false);
  // };

  // const isSelected = (name: string) => selected.indexOf(name) !== -1;

  //   const emptyRows =
  //     rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  //   const collection = isOrderEnabled
  //     ? stableSort(rows, getSorting(order, orderBy))
  //     : rows;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          addingMode={() => null} //setAddingMode(true)}
          deleteMode={() => handleDelete()}
        />
        {/* <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              league={league}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {//   stableSort(rows, getSorting(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              rows.map((row: any, index: number) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                  </TableRow>
                );
              })}

              {addingMode && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    component="th"
                    id="add-row"
                    scope="row"
                    padding="none"
                  >
                    <TextField
                      label="new team"
                      style={{ marginBottom: 16 }}
                      onChange={event => handleChangeNewTeam(event)}
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <Fab
                      variant="round"
                      size="small"
                      color="inherit"
                      aria-label="add"
                    >
                      <Button onClick={handleAddTeam}>
                        <CheckIcon />
                      </Button>
                    </Fab>
                  </TableCell>
                  <TableCell>
                    <Fab
                      variant="round"
                      size="small"
                      color="inherit"
                      aria-label="cancel"
                    >
                      <Button onClick={handleClose}>
                        <CloseIcon />
                      </Button>
                    </Fab>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Paper>
    </div>
  );
});

export default EnhancedTable;
