import { defineMessages } from "react-intl";

export default defineMessages({
  congratulations: {
    id: "PageValidateAccount.congratulations",
    defaultMessage: "Congratulations,",
  },
  accountCreated: {
    id: "PageValidateAccount.accountCreated",
    defaultMessage:
      "your account has been created properly, please check your email to validate your account.",
  },
  accountValidated: {
    id: "PageValidateAccount.accountValidated",
    defaultMessage: "your account has been validated, please login ",
  },
  email: {
    id: "PageValidateAccount.email",
    defaultMessage: "email",
  },
  validationError: {
    id: "PageValidateAccount.validationError",
    defaultMessage:
      "Error validating your account, your token has expired or is invalid, please enter your email back to get a new link",
  },
  resend: {
    id: "PageValidateAccount.resend",
    defaultMessage: "Resend",
  },
  emailSent: {
    id: "PageValidateAccount.emailSent",
    defaultMessage:
      "A new email is sent to your account, please click on the link to validate your account",
  },
});
