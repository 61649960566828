import { defineMessages } from "react-intl";

export default defineMessages({
  email: {
    id: "EditPlayerForm.email",
    defaultMessage: "Email",
  },
  name: {
    id: "EditPlayerForm.Name",
    defaultMessage: "Name",
  },
  surname: {
    id: "EditPlayerForm.Surname",
    defaultMessage: "Surname",
  },
  nickname: {
    id: "EditPlayerForm.Nickname",
    defaultMessage: "Nickname",
  },
  number: {
    id: "EditPlayerForm.Number",
    defaultMessage: "Number",
  },
  dateOfBirth: {
    id: "EditPlayerForm.DateOfBirth",
    defaultMessage: "Date of birth (yyyy-mm-dd)",
  },
  password: {
    id: "EditPlayerForm.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "EditPlayerForm.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  passwordError: {
    id: "EditPlayerForm.PasswordError",
    defaultMessage: "Password requirements not fulfilled",
  },
  passwordNotMatch: {
    id: "EditPlayerForm.PasswordNotMatch",
    defaultMessage: "Passwords do not match",
  },
  requiredFieldError: {
    id: "EditPlayerForm.RequiredFieldError",
    defaultMessage: "Required field",
  },
  registerTitle: {
    id: "EditPlayerForm.RegisterTitle",
    defaultMessage: "Edit player",
  },
  loginLinkMessage: {
    id: "EditPlayerForm.loginLink",
    defaultMessage: "Already have an account?",
  },
  login: {
    id: "EditPlayerForm.login",
    defaultMessage: "Sign in",
  },
  passwordHintTitle: {
    id: "EditPlayerForm.passwordHintTitle",
    defaultMessage: "Password field",
  },
  passwordHintMustBe: {
    id: "EditPlayerForm.passwordHintMustBe",
    defaultMessage: "Your password must:",
  },
  passwordHint8Characters: {
    id: "EditPlayerForm.passwordHint8Characters",
    defaultMessage: "Be at least 8 characters long",
  },
  passwordHint1Uppercase: {
    id: "EditPlayerForm.passwordHint1Uppercase",
    defaultMessage: "Contain at least 1 upper case character",
  },
  passwordHint1Lowercase: {
    id: "EditPlayerForm.passwordHint1Lowercase",
    defaultMessage: "Contain at least 1 lower case character",
  },
  passwordHint1Number: {
    id: "EditPlayerForm.passwordHint1Number",
    defaultMessage: "Contain at least 1 number",
  },
  passwordHint1SpecialCharacter: {
    id: "EditPlayerForm.passwordHint1SpecialCharacter",
    defaultMessage: "Contain at least 1 special character",
  },
  passwordHintNotSameEmail: {
    id: "EditPlayerForm.passwordHintNotSameEmail",
    defaultMessage: "Not be the same as your email address",
  },
});
