import React from "react";
import { styled, createStyles, withStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import {
  Grid,
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
} from "@material-ui/core";
import Button from "../button";
import { IPlayer, IMatch } from "../../types";
import UserAvatar from "../user-avatar";
import { ActionLink } from "../atoms";
import { TacticsIcon } from "./StyledElements";
import { injectIntl } from "react-intl";
import messages from "./PageViewMatch.intl";
import PictureUploader from "./PictureUploader";

const OwnGoalBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "red",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid red",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

const ButtonRow = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
  })
);

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

interface IFinalState {
  id: string;
  match: IMatch;
  scorerList: any;
}

const FinalState = injectIntl<IFinalState>(
  ({ id, match, scorerList, intl }) => {
    // const { match, scorerList } = args;
    const classes = useStyles();
    const classesList = useStylesList();

    return (
      <>
        <Grid item xs={4}>
          <Centered style={{ paddingTop: 25 }}>
            <TacticsIcon />
          </Centered>
        </Grid>
        <Grid item xs={8}>
          <List className={classesList.root}>
            {match.playersConfirmed &&
              match.playersConfirmed.map((player: IPlayer) => (
                <>
                  <ListItem alignItems="center">
                    <Badge
                      color="secondary"
                      style={{ margin: 0 }}
                      badgeContent={scorerList[player.id]}
                      className={classes.margin}
                    >
                      <UserAvatar
                        user={{
                          name: player.name,
                          surname: player.surname,
                          avatarUrl: player.avatarUrl,
                          // number: player.number
                        }}
                        data-testid={`user-avatar-${player.id}`}
                      />
                    </Badge>

                    <ListItemText
                      style={{ paddingLeft: 16 }}
                      primary={`${player.name} ${player.surname}`}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ))}
            {match.ownGoals > 0 && (
              <>
                <ListItem alignItems="center">
                  <OwnGoalBadge
                    color="secondary"
                    style={{ margin: 0 }}
                    badgeContent={match.ownGoals}
                    className={classes.margin}
                  >
                    <UserAvatar
                      style={{ backgroundColor: "#F3421C" }}
                      user={{
                        name: intl.formatMessage(messages.own),
                        surname: intl.formatMessage(messages.goal),
                      }}
                    />
                  </OwnGoalBadge>

                  <ListItemText
                    style={{ paddingLeft: 16 }}
                    primary={intl.formatMessage(messages.ownGoal)}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <PictureUploader id={id} />
        </Grid>
        <Grid item xs={12}>
          <ButtonRow style={{ marginBottom: "30px" }}>
            <ActionLink to={`/people/matches`}>
              <Button>{intl.formatMessage(messages.backMatches)}</Button>
            </ActionLink>
          </ButtonRow>
        </Grid>
      </>
    );
  }
);

export default FinalState;
