import React from "react";
import EditPlayerForm from "./edit-player-form";
import { IFormValues } from "./edit-player-form/EditPlayerForm";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  player: any;
}

const StepInitial = ({ onGoNext, player }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    onGoNext(formValues);
  };

  return <EditPlayerForm onSubmit={handleSubmit} {...player} />;
};

export default StepInitial;
