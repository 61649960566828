import React, { useState, useEffect } from "react";
// import { Grid, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import StepInitial from "./StepInitial";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { injectIntl } from "react-intl";
import Content from "../content";
import Button from "../button";
import { ActionLink } from "../atoms";
import messages from "./PageRegister.intl";
import UserAvatar from "../user-avatar";
// import { RouteComponentProps } from "@reach/router";
import { UnauthenticatedLayout } from "../unauthenticated-layout-elements";

export const SIGN_UP = gql`
  mutation SignUp(
    $name: String!
    $email: String!
    $surname: String!
    $password: String!
    $role: String!
    $workspace: String
    $invite: String
  ) {
    signUp(
      input: {
        name: $name
        email: $email
        surname: $surname
        password: $password
        role: $role
        workspace: $workspace
      }
      invite: $invite
    ) {
      name
      email
    }
  }
`;

export const PLAYER_QUERY = gql`
  query player($id: ID!) {
    player(id: $id) {
      name
      surname
    }
  }
`;

const MessageSpace = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const ButtonRow = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

// const StyledPaper = styled(Paper)(({ theme }: IStyledArguments) => ({
//   boxSizing: "border-box",
//   padding: theme.spacing(4),
//   marginBottom: theme.spacing(5),
//   textAlign: "center",
//   display: "flex",
//   flexDirection: "column",
//   margin: "0 auto",
//   maxWidth: 630,
//   [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
//     boxShadow: "none",
//   },
// }));

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: 24,
}));

const registrationMapping = (registrationCode: string) => {
  console.log("registrationCode");
  console.log(registrationCode);
  const registration = atob(registrationCode);
  const parts = registration.split(";");
  console.log(parts);
  const myObject: { [index: string]: string } = {};
  for (let part of parts) {
    console.log(part);
    const temp = part.split("=");
    myObject[temp[0]] = temp[1];
  }
  return myObject;
};

// type PageRegisterProps = Required<
//   Pick<
//     RouteComponentProps<{ registrationCode: string }>,
//     "navigate" | "path" | "location"
//   >
// >;

// const PageRegister = injectIntl<
//   RouteComponentProps<{ registrationCode: string }>
const PageRegister = injectIntl<any>(({ registrationCode, intl }) => {
  console.log("code..... ", registrationCode);
  const [userDetails, setUserDetails] = useState<any>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [registrationDetails, setRegistrationDetails] = useState<any>();
  const [signup] = useMutation(SIGN_UP);

  const handleSignUp = async (form: any) => {
    await signup({
      variables: {
        name: form.name,
        email: form.email.toLowerCase(),
        surname: form.surname,
        password: form.password,
        role: registerData.role || "Manager",
        workspace: registerData.workspace || undefined,
        invite: registerData.id || undefined,
      },
    });
    setCurrentStep(2);
  };

  let registerData: any = {};
  if (registrationCode) {
    registerData = registrationMapping(registrationCode);
    console.log("111");
    console.log(registerData.id);
  }
  //TODO: ver si registerData existe.
  const { loading, data } = useQuery(PLAYER_QUERY, {
    variables: { id: registerData.id },
  });

  useEffect(() => {
    if (!data || !data.player) {
      return;
    }

    const newState = { ...data.player };
    newState.email = registerData.email;
    setRegistrationDetails(newState);
  }, [data]);

  if (loading) {
    // TODO: LOADING SPINNER
    return (
      <Content>
        <p>LOADING</p>
      </Content>
    );
  }

  return (
    <UnauthenticatedLayout>
      {currentStep === 1 && (
        <StepInitial
          userDetails={registrationDetails}
          checkValid={
            /* istanbul ignore next */
            userDetails ? true : false
          }
          newUser={registrationDetails ? false : true} //todo: change this on the route /register
          registrationDetails={registerData}
          onGoNext={(values: any) => {
            setUserDetails(values);
            handleSignUp(values);
          }}
        />
      )}
      {currentStep === 2 && (
        <Content>
          <Centered>
            <img
              src="/images/footik-main.png"
              width="280px"
              height="280px"
              alt="main-logo"
            />
          </Centered>
          <UserAvatar
            style={{ width: 80, height: 80, fontSize: "3rem" }}
            user={{
              name: userDetails.name,
              surname: userDetails.surname,
            }}
          ></UserAvatar>
          <MessageSpace>
            {`${intl.formatMessage(messages.congratulations)} ${userDetails &&
              userDetails.name}`}
            <br />
            {intl.formatMessage(messages.accountCreated)}
          </MessageSpace>
          <ButtonRow>
            <ActionLink to="/login">
              <Button>Login</Button>
            </ActionLink>
          </ButtonRow>
        </Content>
      )}
    </UnauthenticatedLayout>
  );
});

export default PageRegister;
