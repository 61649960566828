import React, { useEffect } from "react";
import { IUserData } from "../../types";
import { ApolloCache, gql, useQuery } from "@apollo/client";
import useAuthContextReducer, {
  IAuthAction,
  IAuthState,
  actions,
} from "./useAuthContextReducer";
// import { ApolloCache } from "apollo-cache";
import tokenStorage from "./tokenStorage";
import { Permission } from ".";

interface IUtils {
  hasPermission: (e: Permission) => boolean;
  isAdmin: () => boolean;
}

export interface IAuthReducerTuple
  extends Array<IAuthState | object | ((e: IAuthAction) => void)> {
  0: IAuthState;
  1: (e: IAuthAction) => void;
  2: IUtils;
}

export const AuthContext = React.createContext<IAuthReducerTuple>(
  ([] as unknown) as IAuthReducerTuple
);

export const USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      name
      surname
      email
      role
      avatarUrl
      playerLink
    }
  }
`;

interface IAuthContextProviderProps {
  apolloCache: ApolloCache<any>;
  children: React.ReactNode;
}

// const userHasPermission = (state: IAuthState, permission: Permission) => {
const userHasPermission = (state: IAuthState) => {
  if (!state.user) {
    return false;
  }
  return true;
  // if (!state.user || !state.user.permissions) {
  //   return false;
  // }

  // const permissionObject = state.user.permissions.find(
  //   (el: any) => el.key === permission
  // );

  /* istanbul ignore next */
  // if (!permissionObject) {
  //   return false;
  // }

  // return permissionObject.hasPermission;
};

const isAdmin = (state: IAuthState) => {
  const role = (state && state.user && state.user.role) || "";
  return ["Manager", "Admin", "Coach"].includes(role);
};

export default ({ apolloCache, children }: IAuthContextProviderProps) => {
  const [state, dispatch] = useAuthContextReducer({
    tokenStorage,
    apolloCache,
  });
  const { token, isReady } = state;
  const { data, error } = useQuery<IUserData>(USER_QUERY, {
    skip: !token,
  });

  const utils = {
    hasPermission: () =>
      // hasPermission: (permission: Permission) =>
      userHasPermission(state),
    // userHasPermission(state, permission)
    isAdmin: () => isAdmin(state),
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    dispatch(actions.logout());
  }, [error]);

  useEffect(() => {
    if (data && data.currentUser) {
      dispatch(actions.setUser(data.currentUser));
    }
  }, [token, data]);

  useEffect(() => {
    const persistedToken = tokenStorage.getToken();

    if (!persistedToken) {
      dispatch(actions.setReady());
      return;
    }

    dispatch(actions.setToken(persistedToken));
  }, []);

  return (
    <AuthContext.Provider value={[state, dispatch, utils] as any}>
      {/* <AuthContext.Provider value={[state, dispatch] as any}> */}
      {isReady ? children : null}
    </AuthContext.Provider>
  );
};
