import React from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
import { IMatch } from "../../types";
import { Card, Grid, Chip } from "@material-ui/core";
import moment from "moment";
import SoccerBallIcon from "@material-ui/icons/SportsSoccer";
import PersonPin from "@material-ui/icons/PersonPin";
import { injectIntl } from "react-intl";
import messages from "./PageDashboard.intl";
import { DisplayMapFC } from "./Maps";
// import HPlatform, { HMap, HMapGeoCode, HMapMarker } from "react-here-map";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
}));

// const Header = styled("div")(({ theme }: IStyledArguments) => ({
//   background: theme.palette.extra.lighterGrey,
//   display: "flex",
//   justifyContent: "space-between",
//   padding: theme.spacing(1, 1.5, 1, 1.5),
// }));
const Header = styled("div")({
  color: "#005473",
  fontSize: "x-large",
  fontWeight: 700,
  textAlign: "center",
  position: "absolute",
  top: 16,
  display: "flex",
  flexWrap: "wrap",
});

const OpacityLayer = styled("div")({
  backgroundColor: "#ffffff",
  opacity: 0.7,
  // filter: "alpha(opacity=60)",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
});

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

const Container = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
  paddingBottom: 8,
});

interface IMatchSummaryCardProps {
  match: IMatch;
}

const CardNextMatch = styled("div")({
  backgroundImage: "url('/images/stadium4.jpg')",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "200px",
  backgroundRepeat: "no-repeat",
  color: "white",
  top: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: 8,
  position: "relative",
  marginBottom: 16,
  fontWeight: 500,
});

const DateWrapper = styled("div")({
  margin: "16px",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  position: "absolute",
  bottom: 44,
  left: "calc(50% - 78px)",
});

const TimeWrapper = styled("div")({
  // marginBottom: "24px",
  margin: "16px",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  position: "absolute",
  bottom: "-48px",
  left: "calc(50% - 95px)",
});

const HoursBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
  };
});

const MinutesBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
  };
});

const ColonBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    color: theme.palette.primary.main,
  };
});

const NextMatchCard = injectIntl<IMatchSummaryCardProps>(({ match, intl }) => {
  return (
    <CardWrapper>
      <CardNextMatch>
        <OpacityLayer />
        <Header>
          {match.isHomeTeamLocal ? (
            <>{match.homeTeam.name}</>
          ) : (
            <>{match.awayTeam}</>
          )}

          {" - "}
          {match.isHomeTeamLocal ? (
            <>{match.awayTeam}</>
          ) : (
            <>{match.homeTeam.name}</>
          )}
        </Header>
        <DateWrapper>
          <Typography.H6>
            {moment(match.date).isSame(Date.now(), "day")
              ? intl.formatMessage(messages.today)
              : moment(match.date).format("DD-MM-YYYY")}
            &nbsp;{intl.formatMessage(messages.at)}{" "}
          </Typography.H6>
        </DateWrapper>
        <TimeWrapper>
          <HoursBox>{match.time.split(":")[0]}</HoursBox>
          <ColonBox>{":"}</ColonBox>
          <MinutesBox>{match.time.split(":")[1]}</MinutesBox>
        </TimeWrapper>
      </CardNextMatch>
      <Content>
        <Container>
          <Grid item xs={12}>
            <Typography.Subtitle1>{`${intl.formatMessage(messages.location)}: ${
              match.location
            }`}</Typography.Subtitle1>
          </Grid>
          <Grid item xs={12}>
            <DisplayMapFC address={match.location} />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 8, marginTop: 16 }}>
            <Chip
              style={{ borderRadius: 17 }}
              icon={<SoccerBallIcon />}
              size="small"
              color="primary"
              label={`${intl.formatMessage(messages.kickOff)}: ${match.time}`}
              onClick={() => null}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 8 }}>
            <Chip
              style={{ borderRadius: 17 }}
              size="small"
              icon={<PersonPin />}
              color="secondary"
              label={`${intl.formatMessage(messages.meetingTime)}: ${moment(
                "2019-11-21T" + match.time + ":00Z"
              )
                .subtract(30, "minutes")
                .format("HH:mm")}`}
              onClick={() => null}
            />
          </Grid>

          {/* {match.status !== "PREPARING" && (
            <Grid item xs={12} style={{ paddingBottom: 8 }}>
              <Chip
                size="small"
                label={match.status}
                onClick={() => null}
                color="secondary"
              />
            </Grid>
          )} */}
        </Container>
      </Content>
    </CardWrapper>
  );
});

export default NextMatchCard;
