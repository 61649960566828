import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { styled } from "@material-ui/styles";
import { Theme, Grid, Card } from "@material-ui/core";
import Content from "../content";
import { useQuery, useMutation } from "@apollo/client";
import Typography from "../typography";
import DashboardCoachInfoCard from "../dashboard-coach-info-card";
import { IPlayer, ITeam } from "../../types";
import CoachTraininingComponent from "./CoachTrainingComponent";
import { ActionLink } from "../atoms";
import { injectIntl } from "react-intl";
import InitialCard from "./InitialCard";
import AssignTeamModal from "../assign-team-modal";
import SnackBar from "../snackbar";
import CancelIcon from "@material-ui/icons/CancelOutlined";

export const ALL_TEAMS = gql`
  query GetTeams {
    allTeams {
      id
      name
      trainingSetup {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      nextTraining {
        trainingAttendance {
          player {
            id
            name
            surname
          }
          attendance
        }
        date
      }
      squad {
        id
        name
        surname
        number
        nickName
        position
      }
      matches {
        id
        score {
          home
          away
        }
        isWon
        isTied
        isLost
      }
    }
    allPlayers {
      id
      name
      surname
      team {
        id
        name
      }
    }
    currentUser {
      id
      role
    }
  }
`;

const ASSIGN_PLAYER_TO_TEAM = gql`
  mutation AssignTeamToPlayer($playerId: ID!, $teamId: ID!) {
    assignPlayerToTeam(playerId: $playerId, teamId: $teamId) {
      op
      error
    }
  }
`;

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4, 4),
}));

const Item = styled(Grid)({
  marginTop: 0,
});

const CoachDashboard = injectIntl<{ user: any }>(({ user }) => {
  console.log(user);
  const { data: teamsData, refetch } = useQuery<any>(ALL_TEAMS);
  const [teams, setTeams] = useState<any>();
  const [teamsMapped, setTeamsMapped] = useState<any>([]);
  const [playersWithoutTeam, setPlayersWithoutTeam] = useState<IPlayer[]>([]);
  const [assignTeam, { data: assignData }] = useMutation(ASSIGN_PLAYER_TO_TEAM);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (teamsData && teamsData.allTeams) {
      setTeams(teamsData.allTeams);
      const teamsMapping: any = [];

      teamsData.allTeams.forEach((team: ITeam) => {
        console.log("team....");
        console.log(team);

        teamsMapping[team.id] = team;
      });

      setTeamsMapped(teamsMapping);
    }
    if (teamsData && teamsData.allPlayers) {
      const playersToAssign = [];
      for (let p of teamsData.allPlayers) {
        if (p.team === null) {
          playersToAssign.push(p);
        }
      }
      setPlayersWithoutTeam(playersToAssign);
    }
  }, [teamsData]);

  useEffect(() => {
    if (assignData) {
      refetch();
    }
  }, [assignData]);

  const handleAssingPlayerIntoTeam = (playerId: string, teamId: string) => {
    assignTeam({
      variables: {
        playerId,
        teamId,
      },
    });
  };

  return (
    <MainContent container>
      <Content>
        {user && (
          <Grid container spacing={6}>
            <Item item xs={12}>
              <DashboardCoachInfoCard
                user={user}
                teams={teams && teams.length}
                players={
                  teams &&
                  teams
                    .map((team: any) => (team.squad && team.squad.length) || 0)
                    .reduce((acc: number, curr: number) => acc + curr, 0)
                }
                matches={
                  teams &&
                  teams
                    .map(
                      (team: any) => (team.matches && team.matches.length) || 0
                    )
                    .reduce((acc: number, curr: number) => acc + curr, 0)
                }
              />
            </Item>
            {!teams ||
              (teams.length === 0 && (
                <Item item xs={12}>
                  <InitialCard
                    user={user}
                    callback={() => {
                      console.log("[process] callback calling refetch");
                      refetch();
                    }}
                  />
                </Item>
              ))}
            <Item item xs={12}>
              {teamsMapped &&
                Object.keys(teamsMapped).map((key) => {
                  return (
                    <>
                      <ActionLink to={`/teams/${teamsMapped[key].id}`}>
                        <Typography.H4
                          style={{ paddingTop: 16, fontWeight: 100 }}
                        >
                          {teamsMapped[key].name} {">"}
                        </Typography.H4>
                      </ActionLink>

                      {teamsMapped[key].trainingSetup && (
                        <>
                          <CoachTraininingComponent
                            trainingSetup={teamsMapped[key].trainingSetup}
                            attendance={
                              teamsMapped[key].nextTraining &&
                              teamsMapped[key].nextTraining.trainingAttendance
                            }
                            teamId={key}
                          />
                        </>
                      )}
                    </>
                  );
                })}
            </Item>
            <Item item xs={12}>
              {playersWithoutTeam &&
                playersWithoutTeam.map((player: any) => (
                  <Card elevation={2} style={{ marginBottom: 16, padding: 24 }}>
                    <Typography.H6>{`${player.name} ${player.surname} pending to assign`}</Typography.H6>
                    <AssignTeamModal
                      key={`player-${player.name}`}
                      player={player}
                      teams={teams}
                      callback={handleAssingPlayerIntoTeam}
                    />
                  </Card>
                ))}
            </Item>
          </Grid>
        )}
      </Content>
      <SnackBar
        data-testid="delete-error"
        open={showMessage}
        message="Player has been assigned"
        variant="success"
        action={() => {
          setShowMessage(false);
        }}
        actionText={<CancelIcon />}
      />
    </MainContent>
  );
});

export default CoachDashboard;
