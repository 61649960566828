import { gql } from "@apollo/client";

export const QUERY_MATCH = gql`
  query matchDetails($id: ID!) {
    match(id: $id) {
      location
      date
      time
      status
      homeTeam {
        name
        id
        squad {
          name
          surname
          id
        }
      }
      awayTeam
      isHomeTeamLocal
      score {
        home
        away
      }
      playersSelected {
        name
        id
      }
      playersConfirmed {
        name
        surname
        number
        id
        avatarUrl
      }
      scorerList {
        player {
          id
          name
          surname
          avatarUrl
        }
        goals
      }
      ownGoals
      config {
        numPlayers
        gameParts
        timePerPart
        substitutions
      }
      lineUp {
        player
        position
      }
    }
  }
`;

export const CREATE_MATCH_CALL = gql`
  mutation CreateMatchCall($matchId: ID!, $availablePlayers: [String]) {
    createMatchCall(matchId: $matchId, availablePlayers: $availablePlayers) {
      status
      playersConfirmed {
        name
        surname
        id
        number
      }
    }
  }
`;

export const START_MATCH = gql`
  mutation StartMatch($matchId: ID!) {
    startMatch(matchId: $matchId) {
      status
      score {
        home
        away
      }
    }
  }
`;

export const END_MATCH = gql`
  mutation EndMatch(
    $matchId: ID!
    $scoreHome: Int
    $scoreAway: Int
    $scorerList: [ScorerInput]
    $ownGoals: Int
    $admonisheds: [Admonished]
  ) {
    endMatch(
      input: {
        matchId: $matchId
        scoreHome: $scoreHome
        scoreAway: $scoreAway
        scorerList: $scorerList
        ownGoals: $ownGoals
        admonisheds: $admonisheds
      }
    ) {
      status
      score {
        home
        away
      }
    }
  }
`;
export const UPDATE_MATCH_EVENT = gql`
  mutation UpdateMatchEvent(
    $matchId: ID!
    $scoreHome: Int
    $scoreAway: Int
    $scorerList: [ScorerInput]
    $ownGoals: Int
    $admonisheds: [Admonished]
  ) {
    updateMatchEvent(
      input: {
        matchId: $matchId
        scoreHome: $scoreHome
        scoreAway: $scoreAway
        scorerList: $scorerList
        ownGoals: $ownGoals
        admonisheds: $admonisheds
      }
    ) {
      status
      score {
        home
        away
      }
    }
  }
`;

export const CONFIGURE_MATCH = gql`
  mutation ConfigureMatch(
    $matchId: ID!
    $numPlayers: Int!
    $gameParts: Int!
    $timePerPart: Int!
    $substitutions: Int!
  ) {
    configureMatch(
      input: {
        matchId: $matchId
        numPlayers: $numPlayers
        gameParts: $gameParts
        timePerPart: $timePerPart
        substitutions: $substitutions
      }
    ) {
      config {
        numPlayers
        gameParts
        timePerPart
        substitutions
      }
    }
  }
`;

export const SET_LINEUP = gql`
  mutation SetLineUp($matchId: ID!, $lineUp: [LineUpEntryInput]!) {
    setLineUp(matchId: $matchId, lineUp: $lineUp) {
      lineUp {
        player
        position
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateStatus($matchId: ID!, $status: String!) {
    setStatus(matchId: $matchId, status: $status) {
      id
    }
  }
`;
