import { defineMessages } from "react-intl";

export default defineMessages({
  signIn: {
    id: "LoginForm.SignIn",
    defaultMessage: "Welcome to Footik, please login",
  },
  signInButton: {
    id: "LoginForm.SignInButton",
    defaultMessage: "Sign in",
  },
  forgotPassword: {
    id: "LoginForm.ForgotPassword",
    defaultMessage: "Forgot password",
  },
  emailAddressLabel: {
    id: "LoginForm.EmailAddressLabel",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "LoginForm.PasswordLabel",
    defaultMessage: "Password",
  },
  requiredFieldError: {
    id: "LoginForm.RequiredFieldError",
    defaultMessage: "Required field",
  },
  loginError: {
    id: "LoginForm.LoginError",
    defaultMessage:
      "Your supplied login details do not match what we hold on record",
  },
  registerLinkMessage: {
    id: "LoginForm.registerLinkMessage",
    defaultMessage: "you can create an account, ",
  },
  register: {
    id: "LoginForm.register",
    defaultMessage: "here ",
  },
  forgotPasswordMessage: {
    id: "LoginForm.forgotPasswordMessage",
    defaultMessage: "Did you forget your password?, reset your password ",
  },
  forgotPassword_here: {
    id: "LoginForm.forgotPassword_here",
    defaultMessage: "here",
  },
});
