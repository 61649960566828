import React from 'react';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { styled } from '@material-ui/styles';
import { IStyledArguments } from '../../../types/styled-arguments';
import TextInput from '../../text-input';
import Link from '../../link';
import InputErrorMessage from '../../input-error-message';
import Typography from '../../typography';
import {
  FormattedMessage,
  InjectedIntl,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import messages from '../SendResetPassword.intl';
import {
  ActionButton,
  Title,
  VerticalForm,
} from '../../unauthenticated-layout-elements';
import validateEmail from '../../../utils/email';

const LinkContent = styled(Typography.Caption)((props: IStyledArguments) => ({
  textAlign: 'left',
  margin: props.theme.spacing(1, 0, 0, 1.5),
}));
const BackLink = styled(Link)((props: IStyledArguments) => ({
  fontWeight: 500,
  color: `${props.theme.palette.secondary.dark}`,
}));

const validateEmailField = (intl: InjectedIntl) => (value: string) => {
  let error;
  if (!value) {
    error = intl.formatMessage(messages.requiredFieldError);
  } else if (!validateEmail(value)) {
    error = intl.formatMessage(messages.errorInvalidEmail);
  }
  return error;
};

export interface ISendResetFormValues {
  email: string;
}

interface ISendResetForm extends InjectedIntlProps {
  onSubmit: (arg: ISendResetFormValues) => void;
}

const SendResetForm = ({ onSubmit, intl }: ISendResetForm) => (
  <Formik
    initialValues={{ email: '' }}
    onSubmit={(
      values: ISendResetFormValues,
      actions: FormikActions<ISendResetFormValues>
    ) => {
      onSubmit(values);
      actions.setSubmitting(false);
    }}
    render={({ errors, touched }: FormikProps<ISendResetFormValues>) => (
      <>
        <Title>
          <FormattedMessage {...messages.recoverPassword} />
        </Title>
        <VerticalForm data-testid="send-reset-form">
          <Field
            validate={validateEmailField(intl)}
            name="email"
            error={errors.email && touched.email}
            component={TextInput}
            inputProps={{
              'data-testid': 'email',
              autoComplete: 'email',
              autoFocus: true,
            }}
            label={intl.formatMessage(messages.emailAddressLabel)}
          />
          <InputErrorMessage name="email" />
          <LinkContent>
            <BackLink to="/login" data-testid="to-login">
              <FormattedMessage {...messages.takeBackLogin} />
            </BackLink>
          </LinkContent>
          <div>
            <ActionButton data-testid="send-link-button">
              <FormattedMessage {...messages.sendLink} />
            </ActionButton>
          </div>
        </VerticalForm>
      </>
    )}
  />
);

export default injectIntl(SendResetForm);
