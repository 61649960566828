import "date-fns";
import React, { useState, useEffect } from "react";
// import { FormattedMessage } from "react-intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
import { ITeam } from "../../types";
import moment from "moment";
import {
  Card,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Link from "../link";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import Button from "../button";
// import gql from "graphql-tag";
import { gql, useMutation } from "@apollo/client";
import useIsManager from "../../hooks/useIsManager";
import { CalendarComponent } from "../atoms";

export const SETUP_TRAININGS = gql`
  mutation SetupTraining(
    $id: ID!
    $monday: String!
    $tuesday: String!
    $wednesday: String!
    $thursday: String!
    $friday: String!
    $saturday: String!
    $sunday: String!
  ) {
    setupTraining(
      id: $id
      input: {
        monday: $monday
        tuesday: $tuesday
        wednesday: $wednesday
        thursday: $thursday
        friday: $friday
        saturday: $saturday
        sunday: $sunday
      }
    ) {
      id
    }
  }
`;

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  //   height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // padding: theme.spacing(1, 1.5, 1, 3),
  padding: theme.spacing(1),
}));

const Title = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
  currentUser: any;
}

const TrainingCard = ({ id, team, currentUser }: ITeamSummaryCardProps) => {
  console.log("team=====================");
  console.log(team);
  console.log("team trainingSetup=====================");
  console.log(team.trainingSetup);

  const [state, setState] = useState<any>({
    monday: (team.trainingSetup && team.trainingSetup.monday !== "") || false,
    tuesday: (team.trainingSetup && team.trainingSetup.tuesday !== "") || false,
    wednesday:
      (team.trainingSetup && team.trainingSetup.wednesday !== "") || false,
    thursday:
      (team.trainingSetup && team.trainingSetup.thursday !== "") || false,
    friday: (team.trainingSetup && team.trainingSetup.friday !== "") || false,
    saturday:
      (team.trainingSetup && team.trainingSetup.saturday !== "") || false,
    sunday: (team.trainingSetup && team.trainingSetup.sunday !== "") || false,
  });
  const [times, setTimes] = useState<any>({
    monday: moment()
      .set(
        "hour",
        (team.trainingSetup && team.trainingSetup.monday.split(":")[0]) || 8
      )
      .set(
        "minutes",
        (team.trainingSetup && team.trainingSetup.monday.split(":")[1]) || 8
      )
      .local()
      .toDate(),
    tuesday: moment()
      .set(
        "hour",
        (team.trainingSetup && team.trainingSetup.tuesday.split(":")[0]) || 8
      )
      .set(
        "minutes",
        (team.trainingSetup && team.trainingSetup.tuesday.split(":")[1]) || 8
      )
      .local()
      .toDate(),
    wednesday: moment()
      .set(
        "hour",
        (team.trainingSetup && team.trainingSetup.wednesday.split(":")[0]) || 8
      )
      .set(
        "minutes",
        (team.trainingSetup && team.trainingSetup.wednesday.split(":")[1]) || 8
      )
      .local()
      .toDate(),
    thursday: moment()
      .set(
        "hour",
        (team.trainingSetup &&
          team.trainingSetup.thursday &&
          team.trainingSetup.thursday.split(":")[0]) ||
          8
      )
      .set(
        "minutes",
        (team.trainingSetup &&
          team.trainingSetup.thursday &&
          team.trainingSetup.thursday.split(":")[1]) ||
          8
      )
      .local()
      .toDate(),
    friday: moment()
      .set(
        "hour",
        (team.trainingSetup && team.trainingSetup.friday.split(":")[0]) || 8
      )
      .set(
        "minutes",
        (team.trainingSetup && team.trainingSetup.friday.split(":")[1]) || 8
      )
      .local()
      .toDate(),
    saturday: moment()
      .set(
        "hour",
        (team.trainingSetup && team.trainingSetup.saturday.split(":")[0]) || 8
      )
      .set(
        "minutes",
        (team.trainingSetup && team.trainingSetup.saturday.split(":")[1]) || 8
      )
      .local()
      .toDate(),
    sunday: moment()
      .set(
        "hour",
        (team.trainingSetup && team.trainingSetup.sunday.split(":")[0]) || 8
      )
      .set(
        "minutes",
        (team.trainingSetup && team.trainingSetup.sunday.split(":")[1]) || 8
      )
      .local()
      .toDate(),
  });
  console.log("times");
  console.log(times);

  const [trainings, setTrainings] = useState<any>({
    monday: (team.trainingSetup && team.trainingSetup.monday) || "",
    tuesday: (team.trainingSetup && team.trainingSetup.tuesday) || "",
    wednesday: (team.trainingSetup && team.trainingSetup.wednesday) || "",
    thursday: (team.trainingSetup && team.trainingSetup.thursday) || "",
    friday: (team.trainingSetup && team.trainingSetup.friday) || "",
    saturday: (team.trainingSetup && team.trainingSetup.saturday) || "",
    sunday: (team.trainingSetup && team.trainingSetup.sunday) || "",
  });

  const [editableMode, setEditableMode] = useState<boolean>(false);
  const [saveTraining, { data }] = useMutation(SETUP_TRAININGS);

  useEffect(() => {
    if (data) {
      setEditableMode(false);
    }
  }, [data]);

  useEffect(() => {
    if (state) {
      console.log("state");
      console.log(state);
    }
  }, [state]);

  const handleDateChange = (date: Date | null, day: string) => {
    setTimes({ ...times, [day]: date });
    setTrainings({ ...trainings, [day]: moment(date).format("HH:mm") });
  };

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, [name]: event.target.checked });
    if (!event.target.checked) {
      setTrainings({ ...trainings, [name]: "" });
    }
  };

  const handleSave = async () => {
    await saveTraining({
      variables: {
        id,
        ...trainings,
      },
    });
  };

  return (
    <CardWrapper data-testid={`group-summary-${id}`}>
      <Header>
        <Title to={`/teams/${team.id}`}>Trainings:</Title>
      </Header>
      <Content>
        <CalendarComponent trainingWeekList={state} />
        {currentUser && useIsManager(currentUser.role) && (
          <Button
            style={{ marginTop: 16, marginBottom: 16 }}
            variant="outlined"
            onClick={() => setEditableMode(true)}
          >
            Edit
          </Button>
        )}
        {editableMode && (
          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ paddingBottom: 16 }}>
              <FormLabel component="legend" style={{ paddingBottom: 8 }}>
                Training days
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.monday}
                      onChange={handleChange("monday")}
                      value="monday"
                    />
                  }
                  label="Monday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.monday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.monday}
                        onChange={(date) => handleDateChange(date, "monday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.tuesday}
                      onChange={handleChange("tuesday")}
                      value="tuesday"
                    />
                  }
                  label="Tuesday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.tuesday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.tuesday}
                        onChange={(date) => handleDateChange(date, "tuesday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.wednesday}
                      onChange={handleChange("wednesday")}
                      value="wednesday"
                    />
                  }
                  label="Wednesday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.wednesday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.wednesday}
                        onChange={(date) => handleDateChange(date, "wednesday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.thursday}
                      onChange={handleChange("thursday")}
                      value="thursday"
                    />
                  }
                  label="thursday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.thursday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.thursday}
                        onChange={(date) => handleDateChange(date, "thursday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.friday}
                      onChange={handleChange("friday")}
                      value="friday"
                    />
                  }
                  label="Friday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.friday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.friday}
                        onChange={(date) => handleDateChange(date, "friday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.saturday}
                      onChange={handleChange("saturday")}
                      value="saturday"
                    />
                  }
                  label="Saturday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.saturday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.saturday}
                        onChange={(date) => handleDateChange(date, "saturday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.sunday}
                      onChange={handleChange("sunday")}
                      value="sunday"
                    />
                  }
                  label="Sunday"
                  disabled={currentUser && !useIsManager(currentUser.role)}
                />
                {state.sunday && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardTimePicker
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label="Training time"
                        value={times.sunday}
                        onChange={(date) => handleDateChange(date, "sunday")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={
                          currentUser && !useIsManager(currentUser.role)
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                )}
              </FormGroup>
              {/* <FormHelperText>Be careful</FormHelperText> */}
              {currentUser && useIsManager(currentUser.role) && (
                <Button onClick={handleSave} style={{ marginTop: 16 }}>
                  Save
                </Button>
              )}
            </FormControl>
          </Grid>
        )}
      </Content>
    </CardWrapper>
  );
};

export default TrainingCard;
