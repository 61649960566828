import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { styled } from "@material-ui/styles";
import { injectIntl } from "react-intl";
import { RouteComponentProps } from "@reach/router";
import messages from "./PageCoachLibrary.intl";
import Content from "../content";
import { Theme, Grid, Paper } from "@material-ui/core";
import Exercises from "../exercises-collapse";
import Trainings from "../trainings";
import queryString from "query-string";
import SnackBar from "../snackbar";
import CancelIcon from "@material-ui/icons/CancelOutlined";

const TabContainer = styled("div")<Theme>(({ theme }) => ({
  position: "relative",
  top: theme.layout.tabsHeight + 1,
  backgroundColor: theme.palette.extra.default,
  paddingBottom: `${theme.layout.footerHeight}px`,
}));

enum TabNames {
  exercises = "exercises",
  trainings = "trainings",
  admin = "admin",
}
const PATH_TO_TAB: { [path: string]: string } = {
  "/library/exercises": TabNames.exercises,
  "/library/trainings": TabNames.trainings,
  "/library/admin": TabNames.admin,
};
const TAB_TO_PATH: { [tabName: string]: string } = {
  [TabNames.exercises]: "/library/exercises",
  [TabNames.trainings]: "/library/trainings",
  [TabNames.admin]: "/library/admin",
};

const StyledPaper = styled(Paper)<Theme>(({ theme }) => ({
  boxSizing: "border-box",
  marginBottom: theme.spacing(5),
  // textAlign: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: 830,
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    boxShadow: "none",
  },
}));

const TabsWrapper = styled("div")<Theme>(({ theme }) => ({
  position: "fixed",
  width: "100%",
  // width: `calc(100% - ${theme.layout.navDrawerWidthClosed}px)`,
  top: "66px", //theme.layout.headerHeight,
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: "#fff",
  borderBottom: `1px solid ${theme.palette.extra.borderColor}`,
}));

type PagePeopleProps = Required<
  Pick<RouteComponentProps, "navigate" | "path" | "location">
>;

const PageCoachLibrary = injectIntl<PagePeopleProps>(
  ({ intl, navigate, path, location }) => {
    // console.log("============");
    // console.log("============");
    let params = queryString.parse(location.search);
    let action = params["action"];
    // console.log("============");
    // console.log("============");
    // console.log(params, action);

    const [openSnackbar, setOpenSnackbar] = useState<boolean>(
      action === "trainingDeleted" || action === "exerciseDeleted"
    );
    // const [snackbarMessage] = useState();
    const currentTab = PATH_TO_TAB[path] || TabNames.exercises;

    const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
      navigate(TAB_TO_PATH[newValue], {
        replace: true,
      });
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentTab]);

    return (
      <StyledPaper>
        <TabsWrapper>
          <Content alignItems="stretch">
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  centered
                  value={currentTab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="auto"
                >
                  <Tab
                    label={intl.formatMessage(messages.exercises)}
                    data-testid="exercises-tab"
                    value={TabNames.exercises}
                  />
                  <Tab
                    label={intl.formatMessage(messages.trainings)}
                    data-testid="trainings-tab"
                    value={TabNames.trainings}
                  />
                  <Tab
                    label={intl.formatMessage(messages.admin)}
                    data-testid="admin-tab"
                    value={TabNames.admin}
                    disabled
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Content>
        </TabsWrapper>
        <TabContainer>
          {currentTab === TabNames.exercises && <Exercises />}
          {currentTab === TabNames.trainings && <Trainings />}
          {currentTab === TabNames.admin && <></>}
        </TabContainer>
        <SnackBar
          data-testid="delete-error"
          open={openSnackbar}
          message={
            action === "trainingDeleted"
              ? intl.formatMessage(messages.trainingDeleted)
              : intl.formatMessage(messages.exerciseDeleted)
          }
          variant="success"
          action={() => {
            setOpenSnackbar(false);
          }}
          actionText={<CancelIcon />}
        />
      </StyledPaper>
    );
  }
);

export default PageCoachLibrary;
