import { Paper, Theme } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const PaperLayout = styled(Paper)<Theme>(({ theme }) => ({
  boxSizing: "border-box",
  marginBottom: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  // textAlign: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: 830,
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    boxShadow: "none",
  },
}));

export default PaperLayout;
