import React from "react";

declare var window: any;

export const DisplayMapFC = ({ address }: any) => {
  // Create a reference to the HTML element we want to put the map on
  const [lat, setLat] = React.useState(null);
  const [lon, setLon] = React.useState(null);
  //   const [result, setResult] = React.useState(null);
  const [err, setErr] = React.useState(null);
  // const [agent, setAgent] = React.useState("");
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //   setAgent("iOS");
  // }

  // else if (/android/i.test(userAgent)) {
  //   setAgent("android");
  // }

  const mapRef = React.useRef(null);

  const H = window.H;
  const platform = new H.service.Platform({
    apikey: "E8-fLMifIsweefsDEKDCF58PEQ5fDjLW9JIRWfIzT3Q",
  });
  var service = platform.getSearchService();
  service.geocode(
    {
      q: address,
    },
    (result: any) => {
      // Add a marker for each location found
      //   result.items.forEach((item: any) => {
      //     console.log("item------");
      //     console.log(item);
      //     // hMap.addObject(new H.map.Marker(item.position));
      //   });
      if (result.items && result.items.length > 0) {
        setLat(result.items[0].position.lat);
        setLon(result.items[0].position.lng);
        // setResult(result.items[0]);
      }
    },
    (err: any) => {
      console.log("error loading location", err);
      setErr(err);
    }
  );

  /**
   * Create the map instance
   * While `useEffect` could also be used here, `useLayoutEffect` will render
   * the map sooner
   */
  React.useLayoutEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: "E8-fLMifIsweefsDEKDCF58PEQ5fDjLW9JIRWfIzT3Q",
    });

    var icon = new H.map.Icon(
      "https://image.flaticon.com/icons/svg/252/252025.svg",
      { size: { w: 32, h: 32 } }
    );
    // var icon = new H.map.Icon(svgIcon),
    var coords = { lat: lat, lng: lon };
    var marker = new H.map.Marker(coords, { icon: icon });

    const defaultLayers = platform.createDefaultLayers();
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: lat, lng: lon },
      zoom: 16,
      pixelRatio: window.devicePixelRatio || 1,
    });

    hMap.addObject(marker);
    hMap.setCenter(coords);

    // if (result) {
    //   hMap.addObject(new H.map.Marker(result && result.position));
    // }

    // const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

    // const ui = H.ui.UI.createDefault(hMap, defaultLayers);

    // Call the geocode method with the geocoding parameters,
    // the callback and an error callback function (called if a
    // communication error occurs):

    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      hMap.dispose();
    };
  }, [mapRef, lat, lon]); // This will run this hook every time this ref is updated

  return (
    <>
      {!err && (
        <div>
          <div className="map" ref={mapRef} style={{ height: "300px" }} />
          {/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && (
            <div>
              <a href={`http://maps.apple.com/?sll=${lat},${lon}`}>Navigate</a>
            </div>
          )}
          {/android/i.test(userAgent) && (
            <div>
              <a
                href={`https://maps.google.com/maps?hl=en-GB&gfe_rd=cr&gws_rd=ssl&um=1&ie=UTF-8&fb=1&ll=${lat},${lon}&z=9`}
              >
                Navigate
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};
