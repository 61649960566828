import React from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { Card, Fab } from "@material-ui/core";
import Typography from "../typography";
import Link from "../link";
import IconErrorOutline from "@material-ui/icons/ErrorOutline";
import IconRightChevron from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import messages from "./DashboardPlayerInfoCard.intl";
import { injectIntl } from "react-intl";
import { IPlayer, IUser } from "../../types";
import UserAvatar from "../user-avatar";
import EditIcon from "@material-ui/icons/EditOutlined";
import { ActionLink } from "../atoms";

const StyledCard = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
}));

const StyledHeader = styled("div")(({ theme }: IStyledArguments) => {
  return {
    backgroundColor: theme.palette.extra.grey,
    textAlign: "center",
    padding: theme.spacing(2.5),
    position: "relative",
  };
});

const StyledBody = styled("div")(({ theme }: IStyledArguments) => {
  return {
    backgroundColor: theme.palette.extra.backgroundGrey,
    padding: "25px 5px",
  };
});

const StyledExpireDate = styled(Typography.Caption)(
  ({ theme }: IStyledArguments) => {
    return {
      display: "block",
      marginTop: theme.spacing(2.5),
    };
  }
);

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  // padding: theme.spacing(1, 1.5, 1, 1.5)
}));

const StyledLeaders = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    fontSize: 23,
    lineHeight: 1.39,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.alternateFontFamily,
    verticalAlign: "bottom",
    marginRight: "-20px",
  };
});

const StyledSeatsRemaining = styled(Typography.Caption)({
  display: "block",
  textAlign: "center",
});

const StyledInfoIcon = styled((props) => <IconErrorOutline {...props} />)({
  fontSize: 14,
  lineHeight: 1.4,
  verticalAlign: "middle",
});

const StyledDataPointsContainer = styled("div")({
  marginTop: 20,
  display: "flex",
  justifyContent: "space-between",
});

const StyledDataPoint = styled("div")({
  display: "block",
  minWidth: 100,
  textAlign: "center",
});

const StyledDataPointValue = styled("div")({
  fontSize: 46,
  fontWeight: 600,
});

const StyledDataPointText = styled(Typography.Caption)(
  ({ theme }: IStyledArguments) => {
    return {
      ...theme.typography.caption,
      textTransform: "uppercase",
      color: theme.palette.secondary.dark,
      display: "block",
      marginTop: -8,
    };
  }
);

const StyledLinearProgress = styled((props) => (
  <LinearProgress color="secondary" variant="determinate" {...props} />
))({});

const StyledLink = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    color: theme.palette.primary.main,
  };
});

interface IDataPoint {
  value: number;
  text: string;
  url: string;
  // disabled?: boolean;
}

const DataPoint = ({ value, text, url }: IDataPoint) => {
  return (
    <StyledDataPoint>
      <StyledLink to={url}>
        <StyledDataPointValue>{value}</StyledDataPointValue>
        <StyledDataPointText>{text}</StyledDataPointText>
      </StyledLink>
    </StyledDataPoint>
  );
};

interface ISubscriptionInfoCardProps {
  player: IPlayer;
  user: IUser;
  notifications?: any;
}

const DashboardPlayerInfoCard = injectIntl<ISubscriptionInfoCardProps>(
  ({ player, user, notifications, intl }) => {
    return (
      <StyledCard>
        <StyledHeader>
          <Typography.Overline
            gutterBottom={false}
            color="textSecondary"
            data-testid="subscription-name"
          >
            {player && player.team && player.team && player.team.name}
          </Typography.Overline>
          <StyledLeaders to={`/profile`}>
            {player.name} {player.surname}
            <IconRightChevron
              color="secondary"
              fontSize="small"
              style={{ verticalAlign: "middle" }}
            />
          </StyledLeaders>
          {user && user.role === "parent" && (
            <Typography.Subtitle1>{`Parent: you`}</Typography.Subtitle1>
          )}
          <Centered>
            <UserAvatar
              style={{ width: 80, height: 80, fontSize: "3rem" }}
              user={{
                name: player.name,
                surname: player.surname,
                avatarUrl: player.avatarUrl,
              }}
            />
            <ActionLink to={`/players/${player.id}/edit`}>
              <Fab
                style={{
                  position: "absolute",
                  left: "calc(50% + 18px)",
                  top: player.team ? "159px" : "139px",
                }}
                variant="round"
                size="small"
                color="secondary"
                aria-label="add"
              >
                <EditIcon />
              </Fab>
            </ActionLink>
          </Centered>
          <StyledExpireDate>
            {`${intl.formatMessage(messages.birthday)}: ${intl.formatDate(
              new Date(Number(player.dateOfBirth))
            )}`}
          </StyledExpireDate>
        </StyledHeader>
        <StyledLinearProgress value={100} />
        <StyledBody>
          {(!notifications || notifications.length === 0) && (
            <StyledSeatsRemaining>
              <StyledInfoIcon /> {intl.formatMessage(messages.noNotifications)}
            </StyledSeatsRemaining>
          )}
          <StyledDataPointsContainer>
            <DataPoint
              value={player.totalGoals || 0}
              text={intl.formatMessage(messages.goalsLabel, {
                n: player.totalGoals,
              })}
              url={`/players/${player.id}/information`}
            />
            <DataPoint
              value={player.totalMatches || 0}
              text={intl.formatMessage(messages.matchesLabel, {
                n: player.totalMatches,
              })}
              url={`/players/${player.id}/information`}
            />
            <DataPoint
              value={(notifications && notifications.length) || 0}
              text={intl.formatMessage(messages.notificationLabel, {
                n: (notifications && notifications.length) || 0,
              })}
              url="/people/players"
            />
          </StyledDataPointsContainer>
        </StyledBody>
      </StyledCard>
    );
  }
);

export default DashboardPlayerInfoCard;
