import { defineMessages } from "react-intl";

export default defineMessages({
  players: {
    id: "PagePeople.players",
    defaultMessage: `Players`,
  },
  teams: {
    id: "PagePeople.Teams",
    defaultMessage: `Teams`,
  },
  matches: {
    id: "PagePeople.matches",
    defaultMessage: `Matches`,
  },
});
