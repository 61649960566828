import React, { useEffect } from "react";
// import { styled } from "@material-ui/core/styles";
// import { IStyledArguments } from "../../types/styled-arguments";
// import { Fab } from "@material-ui/core";
// import Typography from "../typography";

// import { SET_LINEUP } from "./MatchQueries";
import { injectIntl } from "react-intl";

// import messages from "./PageViewMatch.intl";
// import SelectPlayers from "./SelectPlayers";
import UserAvatar from "../user-avatar";
// import { useMutation } from "@apollo/client";
// import Typography from "../typography";

// const Pitch = styled("div")(({ theme }: IStyledArguments) => ({

// }));

interface ISubstitutions {
  players: any;
}

const Substitutions = injectIntl<ISubstitutions>(({ players }) => {
  useEffect(() => {}, [players]);
  console.log("players", players);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "2em",
        margin: "8px",
      }}
    >
      {players && players.map((player: any) => <UserAvatar user={player} />)}
    </div>
  );
});

export default Substitutions;
