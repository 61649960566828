import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { styled } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import theme from "../../theme";
import Button from "../button";

type variantType = "info" | "warning" | "error" | "success";

const variantColor = {
  success: theme.palette.extra.success,
  warning: theme.palette.extra.warning,
  info: theme.palette.primary.dark,
  error: theme.palette.error.main,
};

interface IStyledSnackBarContent {
  message?: string | React.ReactNode;
  variant: variantType;
  action?: React.ReactNode;
}
const StyledSnackBarContent = styled(({ variant, ...props }) => (
  <SnackbarContent {...props} />
))<Theme, IStyledSnackBarContent>(({ variant }) => {
  return {
    width: 443,
    minHeight: 48,
    backgroundColor: variantColor[variant],
    marginBottom: 80,
  };
});

interface ISnackBarProps {
  message?: string | React.ReactNode;
  onClose?: () => void;
  open: boolean;
  variant: variantType;
  anchorOrigin?: any;
  actionText?: any;
  action?: () => void;
  autoHideDuration?: number;
}

const SnackBar = ({
  variant,
  message,
  open,
  onClose,
  anchorOrigin,
  actionText,
  action,
  autoHideDuration,
  ...props
}: ISnackBarProps & { action?: () => void }) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      message={message}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      {...props}
    >
      <StyledSnackBarContent
        aria-describedby="client-snackbar"
        message={<span data-testid="snack-message">{message}</span>}
        variant={variant}
        data-testid="snackbar-content"
        action={
          actionText && (
            <Button
              variant="text"
              color="inherit"
              data-testid="snackbar-action"
              onClick={action}
            >
              {actionText}
            </Button>
          )
        }
      />
    </Snackbar>
  );
};

SnackBar.defaultProps = {
  variant: "info",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  autoHideDuration: 1000,
  open: false,
};

export default SnackBar;
