import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "PageAddLeague.title",
    defaultMessage: "Add Team to a league"
  },
  leagueName: {
    id: "PageAddLeague.leagueName",
    defaultMessage: "League name"
  },
  location: {
    id: "PageAddLeague.location",
    defaultMessage: "Location"
  },
  leagueStartDate: {
    id: "PageAddLeague.leagueStartDate",
    defaultMessage: "Start date"
  },
  next: {
    id: "PageAddLeague.next",
    defaultMessage: "Next"
  },
  league: {
    id: "PageAddLeague.league",
    defaultMessage: "League"
  },
  created: {
    id: "PageAddLeague.created",
    defaultMessage: "created"
  },
  kindOfLeague: {
    id: "PageAddLeague.kindOfLeague",
    defaultMessage: "Type of league"
  },
  NumberOfPartsGame: {
    id: "PageAddLeague.NumberOfPartsGame",
    defaultMessage: "Number of parts in a game"
  }
});
