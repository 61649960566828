import { defineMessages } from "react-intl";

export default defineMessages({
  myProfileDetails: {
    id: "DrawerMenu.MyProfileDetails",
    defaultMessage: "My profile details",
  },
  myProfileDetailsTitle: {
    id: "DrawerMenu.MyProfileDetailsTitle",
    defaultMessage: "My Profile",
  },
  myAccount: {
    id: "DrawerMenu.MySubscription",
    defaultMessage: "My subscription",
  },
  subscriptionManagers: {
    id: "DrawerMenu.SubscriptionManagers",
    defaultMessage: "Subscription managers",
  },
  programmeAdmins: {
    id: "DrawerMenu.ProgrammeAdmins",
    defaultMessage: "Programme admins",
  },
  close: {
    id: "DrawerMenu.Close",
    defaultMessage: "Close",
  },
  back: {
    id: "DrawerMenu.Back",
    defaultMessage: "Back",
  },
  addSubscriptionManager: {
    id: "DrawerMenu.AddSubscriptionManager",
    defaultMessage: "Add subscription manager",
  },
  addProgrammeAdmin: {
    id: "DrawerMenu.AddProgrammeAdmin",
    defaultMessage: "Add programme admin",
  },
  loadingResults: {
    id: "DrawerMenu.LoadingResults",
    defaultMessage: "Loading results",
  },
  noResults: {
    id: "DrawerMenu.NoResults",
    defaultMessage: "No results found",
  },
  loadError: {
    id: "DrawerMenu.LoadError",
    defaultMessage: "An error occurred while performing the operation",
  },
  logout: {
    id: "DrawerMenu.Logout",
    defaultMessage: "Sign out",
  },
  email: {
    id: "DrawerMenu.Email",
    defaultMessage: "Email",
  },
  firstName: {
    id: "DrawerMenu.FirstName",
    defaultMessage: "First name",
  },
  lastName: {
    id: "DrawerMenu.LastName",
    defaultMessage: "Last name",
  },
  password: {
    id: "DrawerMenu.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "DrawerMenu.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  update: {
    id: "DrawerMenu.Update",
    defaultMessage: "Update",
  },
  cancel: {
    id: "DrawerMenu.Cancel",
    defaultMessage: "Cancel",
  },
  role: {
    id: "DrawerMenu.Role",
    defaultMessage: "Role",
  },
  updateUserError: {
    id: "DrawerMenu.UpdateUserError",
    defaultMessage: "Failed to update user profile.",
  },
  loadUserError: {
    id: "DrawerMenu.LoadUserError",
    defaultMessage: "Failed to load user profile.",
  },
  LEADER: {
    id: "DrawerMenu.Leader",
    defaultMessage: "Leader",
  },
  SM: {
    id: "DrawerMenu.SubscriptionManager",
    defaultMessage: "Subscription Manager",
  },
  GA: {
    id: "DrawerMenu.GroupAdministrator",
    defaultMessage: "Group Administrator",
  },
});
