import { createTheme } from "@material-ui/core/styles";

interface IExtraColors {
  default: string;
  grey: string;
  mediumGrey: string;
  lighterGrey: string;
  lightBlue: string;
  lightBorder: string;
  lightBorderActive: string;
  borderColor: string;
  warning: string;
  success: string;
  backgroundGrey: string;
  subtleContrastText: string;
  insightAccent: string;
  spiderGraphBackground: string;
}

interface ILayout {
  maxContentWidth: number;
  navDrawerWidthClosed: number;
  navDrawerWidthOpen: number;
  headerHeight: number;
  tabsHeight: number;
  listHeaderBarHeight: number;
  footerHeight: number;
  menuDrawerWidth: number;
}

declare module "@material-ui/core/styles/createPalette" {
  /* Adding an extra property to the Palette interface, based on a 'primary' type */
  // tslint:disable-next-line
  interface Palette {
    extra: IExtraColors;
  }

  // tslint:disable-next-line
  interface PaletteOptions {
    extra: IExtraColors;
  }
}

declare module "@material-ui/core/styles/createTypography" {
  /* Adding an extra property to the Palette interface, based on a 'primary' type */
  // tslint:disable-next-line
  interface Typography {
    alternateFontFamily: string;
  }

  // tslint:disable-next-line
  interface TypographyOptions {
    alternateFontFamily: string;
  }
}

declare module "@material-ui/core/styles/createTheme" {
  // tslint:disable-next-line
  interface Theme {
    layout: ILayout;
  }

  // tslint:disable-next-line
  interface ThemeOptions {
    layout: ILayout;
  }
}

/*------------------------------*/
/*        Color Variables       */
/*------------------------------*/
// const primaryMain = "#005473";
// const primaryLight = "#4380a2";
// const secondaryMain = "#FF5722";
// const secondaryDark = "#6700cb";
const primaryMain = "#00BCD4";
const primaryLight = "#B2EBF2";
const primaryDark = "#0097A7";
const secondaryMain = "#FF5722";
const secondaryDark = "#FF5722";
const insightAccent = "#30a0ea";
const errorMain = "#b00020";
const errorLight = "#f9dbdc";
const textSecondary = "#757575";
const contrastText = "#ffffff";
const subtleContrastText = "rgba(255, 255, 255, 0.69)";
const mediumGrey = "#757575";
const lightGrey = "#eef0f3";
const lighterGrey = "#f5f5f5";
const backgroundGrey = "#fafafa";
const lightBlue = "#94bed7";
const defaultBackground = "#fff";
const lightBorder = "rgba(255, 255, 255, 0.32)";
const lightBorderActive = "rgba(255, 255, 255, 0.82)";
const warning = "orange";
const success = "#4caf50";
const borderColor = "#e0e0e0";
const spiderGraphBackground = "rgba(13, 69, 93, 0.5)";

/*------------------------------*/
/*         Font Variables       */
/*------------------------------*/
const poppinsFontFamily = '"Poppins", "Helvetica", "Arial", sans-serif';
const robotoFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

const theme = createTheme({
  layout: {
    navDrawerWidthOpen: 190,
    navDrawerWidthClosed: 72,
    maxContentWidth: 1280,
    headerHeight: 56,
    tabsHeight: 48,
    listHeaderBarHeight: 120,
    footerHeight: 80,
    menuDrawerWidth: 330,
  },
  zIndex: {
    drawer: 1050,
  },
  spacing: 8,
  palette: {
    extra: {
      default: "white",
      grey: lightGrey,
      mediumGrey,
      lighterGrey,
      lightBorder,
      lightBorderActive,
      borderColor,
      lightBlue,
      warning,
      success,
      backgroundGrey,
      subtleContrastText,
      insightAccent,
      spiderGraphBackground,
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
      contrastText,
    },
    error: {
      main: errorMain,
      light: errorLight,
    },
    secondary: {
      main: secondaryMain,
      dark: secondaryDark,
    },
    text: {
      secondary: textSecondary,
    },
    divider: "#c1c7d0",
    background: {
      default: defaultBackground,
    },
  },
  typography: {
    fontFamily: robotoFontFamily,
    alternateFontFamily: poppinsFontFamily,
    fontSize: 14,
    fontWeightLight: 100,
    fontWeightRegular: 100,
    fontWeightMedium: 200,
    allVariants: {
      color: textSecondary,
      fontWeight: 100,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.5,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.25,
    },
    h1: {
      fontFamily: poppinsFontFamily,
      fontSize: 92,
      letterSpacing: -1.5,
    },
    h2: {
      fontFamily: poppinsFontFamily,
      fontSize: 57,
      letterSpacing: -0.5,
    },
    h3: {
      fontFamily: poppinsFontFamily,
      fontSize: 46,
    },
    h4: {
      fontFamily: poppinsFontFamily,
      fontSize: 32,
      letterSpacing: 0.25,
    },
    h5: {
      fontFamily: poppinsFontFamily,
      fontSize: 23,
    },
    h6: {
      fontFamily: poppinsFontFamily,
      fontSize: 19,
      letterSpacing: 0.25,
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontSize: 14,
      letterSpacing: 0.1,
    },
    button: {
      fontSize: 13,
      textTransform: "uppercase",
      letterSpacing: 1.25,
    },
    caption: {
      fontSize: 11,
      letterSpacing: 0.4,
      color: textSecondary,
      fontFamily: poppinsFontFamily,
    },
    overline: {
      textTransform: "uppercase",
      fontFamily: poppinsFontFamily,
      fontSize: 12,
      letterSpacing: 2,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontFamily: poppinsFontFamily,
        color: textSecondary,
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: poppinsFontFamily,
        margin: "0 12px 0",
        fontWeight: 100,
        fontSize: 11.4,
        "&$error": {
          margin: "0 12px 0",
        },
      },
      contained: {
        margin: "0 12px 0",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: robotoFontFamily,
        fontSize: "16px",
        "&:hover": {
          "& svg": {
            color: primaryMain,
          },
        },
        "& svg": {
          color: mediumGrey,
        },
      },
    },
    MuiLinearProgress: {
      bar1Determinate: {
        background: secondaryDark,
      },
    },
    MuiButton: {
      root: {
        padding: "12px 16px",
        fontSize: "13.4px",
        fontFamily: poppinsFontFamily,
        lineHeight: 1.2,
        borderRadius: 8,
        color: "primaryMain",
      },
      outlined: {
        padding: "11px 16px",
      },
      containedSecondary: {
        color: "white",
      },
      sizeSmall: {
        padding: "6px 16px 4px",
      },
      sizeLarge: {
        padding: "12px 18px",
      },
    },
    MuiSelect: {
      selectMenu: {
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    MuiTab: {
      root: {
        fontFamily: poppinsFontFamily,
      },
    },
    MuiChip: {
      root: {
        color: primaryMain,
        borderColor,
        borderRadius: 4,
        height: 27,
        fontFamily: poppinsFontFamily,
      },
      label: {
        paddingRight: 8,
        paddingLeft: 8,
      },
      avatar: {
        color: primaryMain,
        width: 27,
        height: 27,
        background: "transparent",
      },
    },
    MuiTextField: {
      root: {
        background: "white",
      },
    },
    MuiCard: {
      root: {
        borderRadius: "8px",
      },
    },
  },
});

export default theme;
