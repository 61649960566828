import React from "react";
import NewMatchForm from "./new-match-form";
import { IFormValues } from "./new-match-form/NewMatchForm";
import { ITeam, IMatch } from "../../types";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  match?: IMatch;
  teams?: ITeam[];
}

const StepInitial = ({ onGoNext, teams, match }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    console.log("form values", formValues);
    onGoNext(formValues);
  };
  return <NewMatchForm onSubmit={handleSubmit} {...match} teams={teams} />;
};

export default StepInitial;
