import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "@reach/router";
import useAuthContext from "../../hooks/useAuthContext";
import { gql, useMutation } from "@apollo/client";
import { injectIntl, InjectedIntlProps } from "react-intl";

import SendResetForm from "./send-reset-form";
import { ISendResetFormValues } from "./send-reset-form/SendResetForm";
import ConfirmationPage from "./ConfirmationPage";
import { UnauthenticatedLayout } from "../unauthenticated-layout-elements";

export const SEND_RESET_PASSWORD = gql`
  mutation sendPasswordReset($email: String!) {
    sendPasswordReset(email: $email)
  }
`;

const PageSendResetPassword = injectIntl(
  (props: RouteComponentProps & InjectedIntlProps) => {
    const [state] = useAuthContext();
    const [currentStep, setCurrentStep] = useState(1);

    const [sendPasswordReset] = useMutation(SEND_RESET_PASSWORD);

    if (state.isLoggedIn) {
      return <Redirect to="/" noThrow />;
    }

    return (
      <UnauthenticatedLayout>
        {currentStep === 1 && (
          <SendResetForm
            onSubmit={async ({ email }: ISendResetFormValues) => {
              await sendPasswordReset({
                variables: {
                  email,
                },
              }).catch(() => null);
              setCurrentStep(2);
            }}
            {...props}
          />
        )}
        {currentStep === 2 && <ConfirmationPage />}
      </UnauthenticatedLayout>
    );
  }
);

export default PageSendResetPassword;
