import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { GenericSelect, AddElementDialog } from "../../atoms";
import { useMutation, useQuery } from "@apollo/client";
import { injectIntl } from "react-intl";
import messages from "../PageNewExerciswe.intl";
import SnackBar from "../../snackbar";
import CancelIcon from "@material-ui/icons/CancelOutlined";

export const ADD_CATEGORY = gql`
  mutation AddCategory($name: String!) {
    addCategory(name: $name) {
      id
      name
    }
  }
`;
export const ADD_SUBCATEGORY = gql`
  mutation AddSubcategory($parentId: ID!, $name: String!) {
    addSubcategory(parentId: $parentId, name: $name) {
      id
      name
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query AllCategories {
    allCategories {
      id
      name
      subcategories
    }
  }
`;

interface ICategoryComponent {
  label: string;
  title: string;
  callback: any;
}

const CategoryComponent = injectIntl<ICategoryComponent>(
  ({ label, title, callback, intl }) => {
    const { data } = useQuery<any>(ALL_CATEGORIES);
    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
    const [selectedSubcategory, setSelectedSubcategory] = useState<string>();
    const [categories, setCategories] = useState<{ [index: string]: string }>({
      NOITEMS: "no categories",
    });

    const [subcategories, setSubcategories] = useState<{
      [index: string]: string;
    }>({
      NOITEMS: "no subcategories",
    });

    const [showError, setShowError] = useState(false);
    const [addCategory, { data: categoryAdded }] = useMutation(ADD_CATEGORY);
    const [addSubcategory, { data: subcategoryAdded }] = useMutation(
      ADD_SUBCATEGORY
    );

    useEffect(() => {
      if (data && data.allCategories) {
        const theCategories: { [index: string]: string } = {};
        data.allCategories.forEach((category: any) => {
          const key = category.name.toUpperCase();
          theCategories[key] = category.name;
        });

        console.log("--------111", theCategories);
        setCategories(theCategories);
        setAllCategories(data.allCategories);
        console.log("selectedCategory");
        console.log(selectedCategory);
        if (selectedCategory) {
          console.log("entrooooooooo");
          console.log({
            1: categories[selectedCategory],
            2: categories,
            3: selectedCategory,
            4: theCategories[selectedCategory],
          });
          handleCategoryChange(theCategories[selectedCategory]);
        }
      }
    }, [data]);

    useEffect(() => {
      if (categoryAdded) {
        console.log("categoryAdded");
        console.log(categoryAdded);
        const key = categoryAdded.addCategory.name.trim().toUpperCase();
        console.log("setting Key,", key);
        const theCategories = { ...categories };
        theCategories[key] = categoryAdded.addCategory.name;
        console.log("theCategories");
        console.log(theCategories);
        setCategories(theCategories);
        setSelectedCategory(key);
        setSelectedCategoryId(categoryAdded.addCategory.id);
        // setSelectedCategory(key);
        // refetch();
      }
    }, [categoryAdded]);

    useEffect(() => {
      if (subcategoryAdded) {
        console.log("==============");
        console.log(subcategoryAdded);
        console.log("==============");
        // refetch();
        const key =
          (selectedSubcategory && selectedSubcategory.toUpperCase()) || "";
        console.log("setting subcategory Key,", key);
        const theSubCategories = { ...subcategories };
        theSubCategories[key] = selectedSubcategory || "";
        setSubcategories(theSubCategories);
        setSelectedSubcategory(key);
      }
    }, [subcategoryAdded]);

    useEffect(() => {
      callback({
        category: selectedCategory,
        subcategory: selectedSubcategory,
      });
    }, [selectedCategory, selectedSubcategory]);

    useEffect(() => {
      console.log("aaaaaa");
      console.log("selectedCategory...");
      console.log(selectedCategory);
      console.log("aaaaaa");
      if (selectedCategory) {
        console.log("entrooooooooo2222");
        console.log({
          1: categories[selectedCategory],
          2: categories,
          3: selectedCategory,
        });
        handleCategoryChange(categories[selectedCategory]);
      }
    }, [categories]);

    const handleOnAddCategory = async (newCategory: string) => {
      const key = newCategory.toUpperCase().trim();
      if (key.length > 0) {
        console.log("22222.....");
        setSelectedCategory(key);
        await addCategory({
          variables: {
            name: newCategory,
          },
        });
      } else {
        setShowError(true);
      }
    };

    const handleOnAddSubCategory = async (newSubcategory: string) => {
      const Category: any = allCategories.filter((category: any) => {
        return category.name.toUpperCase().trim() === selectedCategory;
      })[0];

      console.log(
        "[CategoryComponent]->onSubcategoryAdded - Category: ",
        Category
      );
      console.log(
        "[CategoryComponent]->onSubcategoryAdded - CategoryID: ",
        selectedCategoryId
      );
      setSelectedSubcategory(newSubcategory.toUpperCase().trim());
      await addSubcategory({
        variables: {
          parentId: selectedCategoryId,
          name: newSubcategory,
        },
      });
      // await addSubcategory({
      //   variables: {
      //     parentId: Category.id,
      //     name: newSubcategory,
      //   },
      // });
    };

    const handleCategoryChange = (name: string | undefined) => {
      setSelectedCategory(name);
      const Category: any = allCategories.filter(
        (category: any) => category.name.toUpperCase().trim() === name
      )[0];
      let theSubCategories: {
        [index: string]: string;
      } = {};
      if (Category) {
        Category.subcategories &&
          Category.subcategories.forEach((sub: any) => {
            theSubCategories[sub.toUpperCase()] = sub;
          });
        if (theSubCategories) {
          setSubcategories(theSubCategories);
        }
      }
    };

    const handleSubcategoryChange = (name: string | undefined) => {
      setSelectedSubcategory(name);
    };

    return (
      <div style={{ marginBottom: "16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          <GenericSelect
            id="category-select"
            label={label}
            placeholder="Select category"
            value={selectedCategory}
            collection={categories}
            onChange={(e: any) => {
              let name = selectedCategory;
              if (e) {
                name = e.target.value;
              }
              handleCategoryChange(name);
            }}
          />

          <AddElementDialog
            title={title}
            callback={(x: string) => {
              console.log("[CategoryCompent]->callback return onAddCategory");
              console.log(x);
              handleOnAddCategory(x);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          <GenericSelect
            id="subcategory-select"
            label={intl.formatMessage(messages.subcategory)}
            placeholder="Select subcategory"
            value={selectedSubcategory}
            collection={subcategories}
            onChange={(e: any) => {
              let name = selectedSubcategory;
              if (e) {
                name = e.target.value;
              }
              handleSubcategoryChange(name);
            }}
          />

          <AddElementDialog
            title={"new Subcategory"}
            callback={(x: string) => {
              console.log(x);
              return handleOnAddSubCategory(x);
            }}
            disable={!selectedCategory || selectedCategory === ""}
          />
        </div>
        <SnackBar
          data-testid="delete-error"
          open={showError}
          message="error adding Category"
          variant="error"
          action={() => {
            setShowError(false);
          }}
          actionText={<CancelIcon />}
        />
      </div>
    );
  }
);

export default CategoryComponent;
