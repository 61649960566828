import { defineMessages } from "react-intl";

export default defineMessages({
  nextTrainings: {
    id: "PageDashboard.nextTrainings",
    defaultMessage: "Next trainings",
  },
  noTraining: {
    id: "PageDashboard.noTraining",
    defaultMessage: "No training set",
  },
  today: {
    id: "PageDashboard.today",
    defaultMessage: "Today",
  },
  attendance: {
    id: "PageDashboard.attendance",
    defaultMessage: "Attendance",
  },
  nextMatch: {
    id: "PageDashboard.nextMatch",
    defaultMessage: "Next match",
  },
  noMatch: {
    id: "PageDashboard.noMatch",
    defaultMessage: "No match set yet",
  },
  at: {
    id: "PageDashboard.at",
    defaultMessage: "at",
  },
  mondayShort: {
    id: "PageDashboard.mondayShort",
    defaultMessage: "Mo",
  },
  tuesdayShort: {
    id: "PageDashboard.tuesdayShort",
    defaultMessage: "Tu",
  },
  wednesdayShort: {
    id: "PageDashboard.wednesdayShort",
    defaultMessage: "Wd",
  },
  thursdayShort: {
    id: "PageDashboard.thursdayShort",
    defaultMessage: "Th",
  },
  fridayShort: {
    id: "PageDashboard.fridayShort",
    defaultMessage: "Fr",
  },
  saturdayShort: {
    id: "PageDashboard.saturdayShort",
    defaultMessage: "St",
  },
  sundayShort: {
    id: "PageDashboard.sundayShort",
    defaultMessage: "Su",
  },
  legendMatches: {
    id: "PageDashboard.legendMatches",
    defaultMessage: "Matches",
  },
  legendTrainings: {
    id: "PageDashboard.legendTrainings",
    defaultMessage: "Trainings",
  },
  legendToday: {
    id: "PageDashboard.legendToday",
    defaultMessage: "Today",
  },
  confirmAttendance: {
    id: "PageDashboard.confirmAttendance",
    defaultMessage: "Confirm attendance",
  },
  location: {
    id: "PageDashboard.location",
    defaultMessage: "Location",
  },
  kickOff: {
    id: "PageDashboard.kickOff",
    defaultMessage: "Kick Off",
  },
  meetingTime: {
    id: "PageDashboard.meetingTime",
    defaultMessage: "Meeting time",
  },
  initialInstructionsHeader: {
    id: "PageDashboard.initialInstructionsHeader",
    defaultMessage: "Welcome to Footik.",
  },
  initialInstructions: {
    id: "PageDashboard.initialInstructions",
    defaultMessage: "You're Current role is Manager, you can start here.",
  },
  initialInstructionsJoinWorkspace: {
    id: "PageDashboard.initialInstructionsJoinWorkspace",
    defaultMessage:
      "Or Join a team as a player, if your coach or manager have invited you, you can join here. Be aware your role will change an you won't be Manager anymore.",
  },
  initialInstructions1: {
    id: "PageDashboard.initialInstructions1",
    defaultMessage: "Create a team",
  },
  initialInstructions2: {
    id: "PageDashboard.initialInstructions2",
    defaultMessage: "Create players and add them to your team",
  },
  initialInstructions3: {
    id: "PageDashboard.initialInstructions3",
    defaultMessage: "Create matches",
  },
  initialInstructions4: {
    id: "PageDashboard.initialInstructions4",
    defaultMessage: "Create Trainings",
  },
  initialInstructionsFooter: {
    id: "PageDashboard.initialInstructionsFooter",
    defaultMessage:
      "Once you've created a match or training an invite will be sent to the players, this is a few of all the things you can manage with Footik.",
  },
  createTeam: {
    id: "PageDashboard.createTeam",
    defaultMessage: "Create Team",
  },
  yes: {
    id: "PageDashboard.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "PageDashboard.no",
    defaultMessage: "No ",
  },
  send: {
    id: "PageDashboard.send",
    defaultMessage: "Send",
  },
  reason: {
    id: "PageDashboard.reason",
    defaultMessage: "Reason: ",
  },
  joinWorkspaceMessage: {
    id: "PageDashboard.joinWorkspaceMessage",
    defaultMessage: "Are you sure you want to join the workspace",
  },
  createdBy: {
    id: "PageDashboard.createdBy",
    defaultMessage: "created by",
  },
  accept: {
    id: "PageDashboard.accept",
    defaultMessage: "accept",
  },
  cancel: {
    id: "PageDashboard.cancel",
    defaultMessage: "cancel",
  },
  joinExistingTeam: {
    id: "PageDashboard.joinExistingTeam",
    defaultMessage: "Join an existing Team",
  },
  joinTeam: {
    id: "PageDashboard.joinTeam",
    defaultMessage: "Join team",
  },
  workspaceNoFound: {
    id: "PageDashboard.workspaceNoFound",
    defaultMessage: "Workspace not found",
  },
});
