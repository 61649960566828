import React, { useState, useEffect } from "react";
import moment from "moment";
import Typography from "../typography";
import { Grid, Paper } from "@material-ui/core";
import CheckedIcon from "@material-ui/icons/CheckCircleOutline";
import UnCheckedIcon from "@material-ui/icons/HighlightOffOutlined";
// import gql from "graphql-tag";
import { gql, useQuery } from "@apollo/client";
import CalendarComponent from "./CalendarComponent";
import NextMatchCard from "./NexMatchCard";
import { ActionLink } from "../atoms";
import { injectIntl } from "react-intl";
import messages from "./PageDashboard.intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";

const NEXT_MATCH = gql`
  query NextMatch($teamId: ID!) {
    nextMatch(teamId: $teamId) {
      id
      date
      homeTeam {
        name
      }
      isHomeTeamLocal
      awayTeam
      location
      time
      status
      playersConfirmed {
        id
        name
        surname
      }
    }
  }
`;

const CardTraining = styled(Paper)(({ theme }: IStyledArguments) => ({
  marginTop: 16,
  paddingBottom: 16,
  background: theme.palette.extra.backgroundGrey,
  borderRadius: 8,
}));

const CardTrainingHeader = styled("div")({
  backgroundImage: "url('/images/trainingBG.jpg')",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "200px",
  backgroundRepeat: "no-repeat",
  color: "white",
  top: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: 8,
  position: "relative",
  marginBottom: 16,
});

const dayMapper: any = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  7: "sunday",
};

const TimeWrapper = styled("div")({
  marginBottom: "24px",
});

const HoursBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
  };
});

const MinutesBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
  };
});

const ColonBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    color: theme.palette.primary.main,
  };
});

const CoachTraininingComponent = injectIntl<{
  trainingSetup: any;
  attendance: any;
  teamId: string;
}>(({ trainingSetup, attendance, teamId, intl }) => {
  console.log("trainingSetup on coachDashboard...", trainingSetup);
  const { data } = useQuery(NEXT_MATCH, {
    variables: {
      teamId,
    },
  });
  const [nextMatch, setNextMatch] = useState<any>();

  useEffect(() => {
    if (data) {
      setNextMatch(data.nextMatch);
    }
  }, [data]);

  const filtered = Object.keys(trainingSetup).filter((key) => {
    if (key !== "__typename" && key !== "time") {
      return trainingSetup[key];
    }
  });
  const trainingList = filtered.map((day) =>
    moment()
      .day(day)
      .isBefore(Date.now(), "day")
      ? moment()
          .day(day)
          .add(7, "day")
          .toDate()
      : moment()
          .day(day)
          .toDate()
  );
  trainingList.sort((a, b) => (moment(a).isAfter(moment(b, "day")) ? 1 : -1));

  const nextTraining = trainingList[0];
  const nextDayTraining = dayMapper[moment(nextTraining).isoWeekday()];

  const trainingWeekList = trainingList.map((day) => moment(day).date());

  return (
    <>
      <CalendarComponent
        nextMatch={nextMatch}
        trainingWeekList={trainingWeekList}
      />
      <CardTraining>
        <CardTrainingHeader>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              color: "white",
              fontSize: "x-large",
              fontWeight: 500,
            }}
          >
            {intl.formatMessage(messages.nextTrainings)} {">"}
          </div>
        </CardTrainingHeader>

        {!nextTraining && (
          <Typography.H6>
            {intl.formatMessage(messages.noTraining)}
          </Typography.H6>
        )}
        {nextTraining && (
          <>
            <Typography.Subtitle1>
              {moment(nextTraining).isSame(Date.now(), "day")
                ? intl.formatMessage(messages.today)
                : moment(nextTraining).format("DD-MM-YYYY")}
              &nbsp;{intl.formatMessage(messages.at)}{" "}
            </Typography.Subtitle1>
            <TimeWrapper>
              <HoursBox>
                {trainingSetup[nextDayTraining].split(":")[0]}
              </HoursBox>
              <ColonBox>{":"}</ColonBox>
              <MinutesBox>
                {trainingSetup[nextDayTraining].split(":")[1]}
              </MinutesBox>
            </TimeWrapper>
          </>
        )}
        {attendance && (
          <Grid style={{ paddingTop: 16 }} xs={12}>
            <Typography.H6>
              {intl.formatMessage(messages.attendance)}: ({attendance.length})
            </Typography.H6>
          </Grid>
        )}
        {attendance &&
          attendance.map((player: any) => {
            return (
              <Grid
                container
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={5}>
                  <Typography.Subtitle1>
                    {player.player.name}&nbsp;{player.player.surname}
                  </Typography.Subtitle1>
                </Grid>
                <Grid item xs={7}>
                  {player.attendance && <CheckedIcon />}
                  {!player.attendance && <UnCheckedIcon />}
                </Grid>
              </Grid>
            );
          })}
      </CardTraining>
      {!nextMatch && (
        <>
          <Typography.H5 style={{ paddingTop: 20 }}>
            {intl.formatMessage(messages.nextMatch)} {">"}
          </Typography.H5>
          <Typography.Subtitle1>
            {intl.formatMessage(messages.noMatch)}
          </Typography.Subtitle1>
        </>
      )}
      {nextMatch && (
        <>
          <ActionLink to={`/matches/${nextMatch.id}`}>
            <Typography.H5 style={{ paddingTop: 20 }}>
              {intl.formatMessage(messages.nextMatch)} {">"}
            </Typography.H5>
          </ActionLink>
          <Typography.Subtitle1>
            {moment(nextMatch.date).isSame(Date.now(), "day")
              ? intl.formatMessage(messages.today)
              : moment(nextMatch.date).format("dddd, DD-MM-YYYY")}
            &nbsp;at {nextMatch.time}
          </Typography.Subtitle1>
          <Grid style={{ paddingTop: 16 }} xs={12}>
            <Typography.H6>
              {intl.formatMessage(messages.attendance)}: (
              {nextMatch.playersConfirmed.length})
            </Typography.H6>
            {nextMatch.playersConfirmed &&
              nextMatch.playersConfirmed.map((player: any) => {
                return (
                  <Grid
                    container
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={5}>
                      <Typography.Subtitle1>
                        {player.name}&nbsp;{player.surname}
                      </Typography.Subtitle1>
                    </Grid>
                    <Grid item xs={7}>
                      <CheckedIcon />
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <NextMatchCard match={nextMatch} />
        </>
      )}
    </>
  );
});
export default CoachTraininingComponent;
