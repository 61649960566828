import React, { useState, useEffect } from "react";
import { Fab } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EndGameIcon from "@material-ui/icons/CheckCircleOutline";

import messages from "./PageViewMatch.intl";
import { styled } from "@material-ui/styles";
import { injectIntl } from "react-intl";
import { QUERY_MATCH, UPDATE_MATCH_EVENT, END_MATCH } from "./MatchQueries";
import { useMutation, useQuery } from "@apollo/client";
import UserAvatar from "../user-avatar";
import { IPlayer } from "../../types";
import {
  YellowCardIcon,
  RedCardIcon,
  GoalIcon,
  OwnGoalBadge,
  ButtonRow,
  AwayTeamButton,
} from "./StyledElements";
import DelIcon from "@material-ui/icons/RemoveCircleOutline";
import { IStyledArguments } from "../../types/styled-arguments";
import Formation5 from "./Formation5";
import Substitutions from "./Substitutions";
import BallIcon from "@material-ui/icons/SportsSoccer";
import Formation7 from "./Formation7";
import Formation9 from "./Formation9";
import Formation11 from "./Formation11";

const Header = styled("div")({
  color: "#005473",
  fontSize: "x-large",
  fontWeight: 700,
  textAlign: "center",
  position: "absolute",
  top: 16,
  display: "flex",
  flexWrap: "wrap",
});

// const MatchInfo = styled("div")({
//   color: "white",
//   fontSize: "x-small",
//   fontWeight: 700,
//   // textAlign: "center",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   display: "flex",
//   flexWrap: "wrap",
//   paddingLeft: 8,
//   paddingBottom: 8,
// });

const OpacityLayer = styled("div")({
  backgroundColor: "#ffffff",
  opacity: 0.6,
  filter: "alpha(opacity=60)",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
});

const ScoreBox = styled("div")({
  backgroundImage: "url('/images/stadium.jpg')",
  // backgroundColor: "red",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "185px",
  backgroundRepeat: "no-repeat",
  color: "white",
  top: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: 8,
  position: "relative",
  marginBottom: 16,
});

const ScoreWrapper = styled("div")({
  margin: "16px",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  position: "absolute",
  bottom: 12,
  left: "calc(50% - 85px)",
});

const LocalScore = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    width: "60px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
    textAlign: "center",
    fontWeight: 700,
  };
});

const AwayScore = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    width: "60px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
    textAlign: "center",
    fontWeight: 700,
  };
});

const VSBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    color: theme.palette.primary.dark,
    fontWeight: 700,
    margin: "3px",
  };
});
const AwayTeamControls = styled("div")(({ theme }: IStyledArguments) => {
  return {
    display: "flex",
    flexDirection: "row",
    margin: "16px",
    border: `1px solid ${theme.palette.extra.borderColor}`,
    borderRadius: "8px",
    padding: "16px",
    alignItems: "baseline",
    justifyContent: "space-between",
    flexWrap: "wrap",
  };
});
const ScorerListHome = styled("div")({
  color: "blueviolet",
  fontSize: "x-small",
  fontWeight: 700,
  position: "absolute",
  bottom: 24,
  left: 0,
  display: "flex",
  flexWrap: "wrap",
  paddingLeft: 8,
  paddingBottom: 8,
  maxWidth: "100px",
  alignItems: "center",
});
const ScorerListAway = styled("div")({
  color: "blueviolet",
  fontSize: "x-small",
  fontWeight: 700,
  position: "absolute",
  bottom: 24,
  right: 0,
  display: "flex",
  flexWrap: "wrap",
  paddingLeft: 8,
  paddingBottom: 8,
  maxWidth: "100px",
  alignItems: "center",
});
const AdmonishedAway = styled("div")({
  color: "blueviolet",
  fontSize: "x-small",
  fontWeight: 700,
  position: "absolute",
  bottom: 24,
  left: 0,
  display: "flex",
  flexWrap: "wrap",
  paddingLeft: 8,
  paddingBottom: 8,
  maxWidth: "100px",
  alignItems: "center",
});
const AdmonishedHome = styled("div")({
  color: "blueviolet",
  fontSize: "x-small",
  fontWeight: 700,
  position: "absolute",
  bottom: 24,
  right: 0,
  display: "flex",
  flexWrap: "wrap",
  paddingLeft: 8,
  paddingBottom: 8,
  maxWidth: "100px",
  alignItems: "center",
});

const MatchComponentV2 = injectIntl<any>(
  ({ matchId, callback, intl, team, lineUp }) => {
    const { data, refetch } = useQuery(QUERY_MATCH, {
      variables: {
        id: matchId,
      },
    });
    const [score, setScore] = useState({ home: 0, away: 0 });
    const [scorerList, setScorerList] = useState<{ [index: string]: number }>(
      {}
    );
    const [yellows, setYellows] = useState<{ [index: string]: number }>({});
    const [reds, setReds] = useState<{ [index: string]: number }>({});
    const [ownGoals, setOwnGoals] = useState<number>(0);
    const [endMatch, { data: dataEndtMatch }] = useMutation(END_MATCH);
    const [updateMatchEvent, { data: matchUpdated }] = useMutation(
      UPDATE_MATCH_EVENT
    );
    const [substitutions, setSubstituions] = useState();
    const [players, setPlayers] = useState<any>();
    const [alineacion, setAlineacion] = useState<any>(lineUp);
    const [match, setMatch] = useState<any>();

    useEffect(() => {
      // const selected: { [index: string]: boolean } = {};

      if (data && data.match) {
        console.log("[MATCHCOMPONENTV2], match data received", data.match);
        setMatch(data.match);
      }
    }, [data]);

    useEffect(() => {
      if (match && match.playersConfirmed) {
        let playersOpen: { [index: string]: boolean } = {};
        let players: { [index: string]: IPlayer } = {};
        match.playersConfirmed.forEach((player: IPlayer) => {
          playersOpen[player.id] = false;
          players[player.id] = player;
        });
        setPlayers(players);
        // console.log("players-------");
        // console.log(playersOpen);
        // setOpen(playersOpen);
        // console.log("lineUp-----");
        // console.log(alineacion);
        // console.log("lineUp-----");
        const subs: any = [];
        Object.keys(playersOpen).forEach((player: any) => {
          console.log(player);
          var isInTheLineUp = Object.values(alineacion).some(
            (p) => p === player
          );
          if (!isInTheLineUp) {
            subs.push(players[player]);
          }
        });
        setSubstituions(subs);
      }
    }, [match, alineacion]);

    useEffect(() => {
      console.log("SCORE ----> ", data.match.score);
      setScore(data.match.score);
      const mapScorerList: { [index: string]: number } = {};
      if (data.match.scorerList) {
        for (let scorerFromDB of data.match.scorerList) {
          console.log("[DEBUG]", scorerFromDB);
          console.log("[DEBUG] map", mapScorerList);
          // if (scorerFromDB) {
          const key = scorerFromDB.player.id;
          console.log("[DEBUG] key", key);
          // console.log(key);
          if (!mapScorerList[key]) {
            mapScorerList[key] = scorerFromDB.goals;
          }
          // }
        }
        console.log("[SCORER_LIST From QUERY]", mapScorerList);
        // console.log(mapScorerList);
        setScorerList(mapScorerList);
      }
    }, [players]);

    useEffect(() => {
      // handleUpadateMatchEvent();
      console.log("[SCORER_LIST]*******", scorerList);
      console.log("*******SCORE", score);
      if (scorerList || score.away > 0 || score.home > 0) {
        handleUpadateMatchEvent();
      }
    }, [scorerList, yellows, reds, ownGoals, score]);

    useEffect(() => {
      if (dataEndtMatch) {
        callback(dataEndtMatch.endMatch.status);
      }
    }, [dataEndtMatch]);

    useEffect(() => {
      if (matchUpdated) {
        refetch();
      }
    }, [matchUpdated]);

    const handleYellowCard = (playerId: string) => {
      const newYellows = { ...yellows };

      if (newYellows[playerId]) {
        newYellows[playerId] = newYellows[playerId] + 1;
      } else {
        newYellows[playerId] = 1;
      }
      if (newYellows[playerId] === 2) {
        const newReds = { ...reds };
        newReds[playerId] = 1;
        setReds(newReds);
      }
      setYellows(newYellows);
    };

    const handleRedCard = (playerId: string) => {
      const newReds = { ...reds };

      if (newReds[playerId]) {
        newReds[playerId] = newReds[playerId] + 1;
      } else {
        newReds[playerId] = 1;
      }
      setReds(newReds);
    };

    const handleScoreGoal = (playerId: string) => {
      const newScore = { ...score };
      newScore.home = score.home + 1;
      setScore(newScore);
      const newScorerList = { ...scorerList };
      if (newScorerList[playerId]) {
        newScorerList[playerId] = newScorerList[playerId] + 1;
      } else {
        newScorerList[playerId] = 1;
      }
      console.log("[SCORER_LIST] handleScoreGoal", newScorerList);
      setScorerList(newScorerList);
      // handleUpadateMatchEvent();
    };

    //TODO: dont delete this!
    const handleRemoveScoredGoal = (playerId: string) => {
      const newScore = { ...score };
      if (newScore.home > 0) {
        newScore.home = score.home - 1;
        setScore(newScore);
        const newScorerList = { ...scorerList };
        if (newScorerList[playerId] && newScorerList[playerId] >= 1) {
          newScorerList[playerId] = newScorerList[playerId] - 1;
        }
        setScorerList(newScorerList);
      }
    };

    const handleScoreAwayGoal = () => {
      const newScore = { ...score };
      newScore.away = score.away + 1;
      setScore(newScore);
    };

    const handleScoreOwnAwayGoal = () => {
      const newScore = { ...score };
      newScore.home = score.home + 1;
      setScore(newScore);
      setOwnGoals(ownGoals + 1);
    };

    const handleRemoveOwnAwayGoal = () => {
      const newScore = { ...score };
      newScore.home = score.home - 1;
      setScore(newScore);
      setOwnGoals(ownGoals - 1);
    };

    const handleRemoveAwayGoal = () => {
      const newScore = { ...score };
      newScore.away = score.away - 1;
      setScore(newScore);
    };

    // const handleClose = () => {
    //   setOpen(false);
    // };

    // const handleOpen = (id: any) => {
    //   const newOpen = { ...open };
    //   newOpen[id] = true;
    //   setOpen(newOpen);
    // };

    const handleBack = (status: string) => {
      callback(status);
    };

    const handleSubstitution = (matchEvent: any) => {
      // handleUpadateMatchEvent();
      const newAlineacion = { ...alineacion };
      console.log("[debug] handleSubstitution ", newAlineacion);
      const positionChanged = Object.keys(newAlineacion).find((position) => {
        if (newAlineacion[position] === matchEvent.playerOut) {
          return position;
        }
      });
      if (positionChanged) {
        newAlineacion[positionChanged] = matchEvent.playerIn;
        console.log(
          "[debug] handleSubstitution new alineacion",
          newAlineacion,
          positionChanged
        );
        setAlineacion(newAlineacion);
      }
    };

    const handleMatchEvent = (matchEvent: any) => {
      if (matchEvent.type === "Goal") {
        console.log("goal from ", matchEvent.player);
        handleScoreGoal(matchEvent.player);
      } else if (matchEvent.type === "yellowCard") {
        console.log("yellow from ", matchEvent.player);
        handleYellowCard(matchEvent.player);
      } else if (matchEvent.type === "removeGoal") {
        console.log("remove goal from ", matchEvent.player);
        handleRemoveScoredGoal(matchEvent.player);
      } else if (matchEvent.type === "redCard") {
        console.log("red from ", matchEvent.player);
        handleRedCard(matchEvent.player);
      } else if (matchEvent.type === "substitution") {
        console.log("substitution ", matchEvent);
        //handleRedCard(matchEvent.player);
        console.log("[debug] handleSubstitution event -> ", matchEvent);
        handleSubstitution(matchEvent);
      }
    };

    const handleEndMatch = async () => {
      console.log("endMatch");
      console.log(scorerList);
      const pushScorerList: any[] = [];
      Object.keys(scorerList).forEach(function(key) {
        console.log(key);
        console.log(scorerList[key]);
        pushScorerList.push({
          playerId: key,
          value: scorerList[key],
        });
      });
      console.log("pushScorerList");
      console.log(pushScorerList);

      const admonishedList: any[] = [];
      Object.keys(yellows).forEach((key) => {
        admonishedList.push({
          playerId: key,
          yellow: yellows[key],
          red: reds[key] || 0,
        });
      });
      Object.keys(reds).forEach((key) => {
        if (!admonishedList.some((element: any) => element.playerId === key)) {
          admonishedList.push({
            playerId: key,
            yellow: 0,
            red: reds[key],
          });
        }
      });

      // console.log("admonishedList----------");
      // console.log(admonishedList);
      await endMatch({
        variables: {
          matchId: matchId,
          scoreHome: score.home,
          scoreAway: score.away,
          scorerList: pushScorerList,
          ownGoals,
          admonisheds: admonishedList,
        },
      });
    };
    const handleUpadateMatchEvent = async () => {
      console.log("updateMatchEvent");
      console.log(scorerList);
      const pushScorerList: any[] = [];
      Object.keys(scorerList).forEach(function(key) {
        console.log(key);
        console.log(scorerList[key]);
        pushScorerList.push({
          playerId: key,
          value: scorerList[key],
        });
      });
      console.log("pushScorerList");
      console.log(pushScorerList);

      const admonishedList: any[] = [];
      Object.keys(yellows).forEach((key) => {
        admonishedList.push({
          playerId: key,
          yellow: yellows[key],
          red: reds[key] || 0,
        });
      });
      Object.keys(reds).forEach((key) => {
        if (!admonishedList.some((element: any) => element.playerId === key)) {
          admonishedList.push({
            playerId: key,
            yellow: 0,
            red: reds[key],
          });
        }
      });

      // console.log("admonishedList----------");
      // console.log(admonishedList);
      await updateMatchEvent({
        variables: {
          matchId: matchId,
          scoreHome: score.home,
          scoreAway: score.away,
          scorerList: pushScorerList,
          ownGoals,
          admonisheds: admonishedList,
        },
      });
    };

    return (
      <>
        {match && players ? (
          <>
            <ScoreBox>
              <OpacityLayer />
              <Header>
                {match.isHomeTeamLocal ? (
                  <>{match.homeTeam.name}</>
                ) : (
                  <>{match.awayTeam}</>
                )}

                {" - "}
                {match.isHomeTeamLocal ? (
                  <>{match.awayTeam}</>
                ) : (
                  <>{match.homeTeam.name}</>
                )}
              </Header>
              {/* <MatchInfo>
                <img
                  src="/images/match/soccer-field.png"
                  width="16"
                  height="16"
                />
                <div style={{ marginLeft: "8px" }}>1st half</div>
              </MatchInfo> */}
              <>
                {match.isHomeTeamLocal ? (
                  <>
                    <ScorerListHome>
                      {Object.keys(scorerList).map((scorer: any) => {
                        console.log("[DEBUG1] scorer", scorer);
                        console.log("[DEBUG1] players", players);
                        return (
                          <>
                            {scorerList[scorer] > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                }}
                              >
                                <BallIcon />
                                {players[scorer].name} ({scorerList[scorer]})
                              </div>
                            )}
                          </>
                        );
                      })}
                    </ScorerListHome>
                    <AdmonishedHome>
                      {Object.keys(yellows).map((scorer: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                        >
                          <YellowCardIcon />
                          {players[scorer].name}
                        </div>
                      ))}
                      {Object.keys(reds).map((scorer: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                        >
                          <RedCardIcon />
                          {players[scorer].name}
                        </div>
                      ))}
                    </AdmonishedHome>
                  </>
                ) : (
                  <>
                    <ScorerListAway>
                      {Object.keys(scorerList).map((scorer: any) => {
                        console.log("[DEBUG2] scorer", scorer);
                        console.log("[DEBUG2] players", players);
                        console.log("[DEBUG2] scoreList", scorerList);
                        return (
                          <>
                            {scorerList[scorer] > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  alignItems: "center",
                                }}
                              >
                                <BallIcon />
                                {players[scorer].name} ({scorerList[scorer]})
                              </div>
                            )}
                          </>
                        );
                      })}
                    </ScorerListAway>
                    <AdmonishedAway>
                      {Object.keys(yellows).map((scorer: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                        >
                          <YellowCardIcon />
                          {players[scorer].name}
                        </div>
                      ))}
                      {Object.keys(reds).map((scorer: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                        >
                          <RedCardIcon />
                          {players[scorer].name}
                        </div>
                      ))}
                    </AdmonishedAway>
                  </>
                )}
              </>
              {/* )} */}
              <ScoreWrapper>
                <LocalScore>
                  {match.isHomeTeamLocal ? (
                    <>{score.home}</>
                  ) : (
                    <>{score.away}</>
                  )}
                </LocalScore>
                <VSBox>{"-"}</VSBox>
                <AwayScore>
                  {match.isHomeTeamLocal ? (
                    <>{score.away}</>
                  ) : (
                    <>{score.home}</>
                  )}
                </AwayScore>
              </ScoreWrapper>
            </ScoreBox>
            <div style={{ padding: "5px", position: "relative", top: "-80px" }}>
              {match && match.config && match.config.numPlayers === 5 && (
                <Formation5
                  matchId={matchId}
                  match={match}
                  lineUp={alineacion}
                  team={team}
                  subs={substitutions}
                  isMatchOngoing={true}
                  scorerList={scorerList}
                  callback={(numSelected: any) => {
                    console.log("Seleccionados::::", numSelected);
                    // setSelectedPlayers(numSelected);
                  }}
                  onMatchEvent={(matchEvent: any) =>
                    handleMatchEvent(matchEvent)
                  }
                />
              )}
              {match && match.config && match.config.numPlayers === 7 && (
                <Formation7
                  matchId={matchId}
                  match={match}
                  lineUp={alineacion}
                  team={team}
                  subs={substitutions}
                  isMatchOngoing={true}
                  scorerList={scorerList}
                  callback={(numSelected: any) => {
                    console.log("Seleccionados::::", numSelected);
                    // setSelectedPlayers(numSelected);
                  }}
                  onMatchEvent={(matchEvent: any) =>
                    handleMatchEvent(matchEvent)
                  }
                />
              )}
              {match && match.config && match.config.numPlayers === 9 && (
                <Formation9
                  matchId={matchId}
                  match={match}
                  lineUp={alineacion}
                  team={team}
                  subs={substitutions}
                  isMatchOngoing={true}
                  scorerList={scorerList}
                  callback={(numSelected: any) => {
                    console.log("Seleccionados::::", numSelected);
                    // setSelectedPlayers(numSelected);
                  }}
                  onMatchEvent={(matchEvent: any) =>
                    handleMatchEvent(matchEvent)
                  }
                />
              )}
              {match && match.config && match.config.numPlayers === 11 && (
                <Formation11
                  matchId={matchId}
                  match={match}
                  lineUp={alineacion}
                  team={team}
                  subs={substitutions}
                  isMatchOngoing={true}
                  scorerList={scorerList}
                  callback={(numSelected: any) => {
                    console.log("Seleccionados::::", numSelected);
                    // setSelectedPlayers(numSelected);
                  }}
                  onMatchEvent={(matchEvent: any) =>
                    handleMatchEvent(matchEvent)
                  }
                />
              )}
              {/* <!-- substitutions --> */}
              <Substitutions players={substitutions} />

              {/* <!-- away team goal --> */}
              <AwayTeamControls>
                <Fab
                  variant="extended"
                  color="inherit"
                  aria-label="add"
                  onClick={(_) => handleScoreAwayGoal()}
                  style={{ marginBottom: 16 }}
                >
                  <GoalIcon />
                  <AwayTeamButton>{match.awayTeam}</AwayTeamButton>
                </Fab>
                {score.away > 0 && (
                  <Fab
                    size="small"
                    color="inherit"
                    aria-label="add"
                    onClick={(_) => handleRemoveAwayGoal()}
                    style={{ marginLeft: 8, marginBottom: 16 }}
                  >
                    <DelIcon />
                  </Fab>
                )}

                <Fab
                  variant="extended"
                  color="inherit"
                  aria-label="add"
                  onClick={(_) => handleScoreOwnAwayGoal()}
                >
                  <GoalIcon />
                  Own Goal
                </Fab>

                {ownGoals > 0 && (
                  <Fab
                    size="small"
                    color="inherit"
                    aria-label="add"
                    onClick={(_) =>
                      // handleRemoveScoredGoal(player.id)
                      // alert("borrar own goal")
                      handleRemoveOwnAwayGoal()
                    }
                    style={{ marginLeft: 16 }}
                  >
                    <DelIcon />
                  </Fab>
                )}

                {ownGoals > 0 && (
                  <OwnGoalBadge
                    color="secondary"
                    badgeContent={ownGoals}
                    style={{ margin: 16 }}
                  >
                    <UserAvatar
                      style={{ backgroundColor: "#F3421C" }}
                      user={{
                        name: intl.formatMessage(messages.own),
                        surname: intl.formatMessage(messages.goal),
                      }}
                    />
                  </OwnGoalBadge>
                )}
              </AwayTeamControls>
            </div>

            <ButtonRow style={{ marginRight: 16, marginBottom: "16px" }}>
              <Fab
                color="secondary"
                onClick={() => handleBack("READY")}
                style={{ marginRight: 16, borderRadius: 32 }}
              >
                {/* {intl.formatMessage(messages.backToPrepare)} */}
                <ArrowBackIcon />
              </Fab>
              <Fab color="primary" onClick={handleEndMatch}>
                {/* {intl.formatMessage(messages.endGame)} */}
                <EndGameIcon />
              </Fab>
            </ButtonRow>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  }
);

export default MatchComponentV2;
