import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "NewTeamForm.title",
    defaultMessage: "Add new team"
  },
  name: {
    id: "NewTeamForm.Name",
    defaultMessage: "Name"
  },
  location: {
    id: "NewTeamForm.location",
    defaultMessage: "Location"
  },
  colorHome: {
    id: "NewTeamForm.colorHome",
    defaultMessage: "Color home"
  },
  colorAway: {
    id: "NewTeamForm.colorAway",
    defaultMessage: "Color away"
  }
});
