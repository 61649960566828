import React, { useState, useEffect } from "react";
// import { FormattedMessage } from "react-intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import {
  Card,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Button from "../button";
import { injectIntl } from "react-intl";
import Typography from "../typography";
import messages from "./PageViewMatch.intl";
import { CONFIGURE_MATCH } from "./MatchQueries";
import { useMutation } from "@apollo/client";
import Formation11 from "./Formation11";
import Formation7 from "./Formation7";
import Formation9 from "./Formation9";
import Formation5 from "./Formation5";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexWrap: "wrap",
  padding: theme.spacing(2),
}));

const Title = styled("div")(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

const ConfigurationConfirmedBox = styled("div")(
  ({ theme }: IStyledArguments) => ({
    flex: 1,
    flexFlow: "column",
    borderLeft: "solid",
    borderColor: theme.palette.extra.borderColor,
    padding: "16px",
  })
);

const numberOfPlayers = [5, 7, 9, 11];
const numberOfParts = [1, 2, 4];
const timeOfPart = [10, 15, 20, 25, 30, 35, 40, 45];
const numOfChanges = [3, 4, 5];

const MatchConfigurationCard = injectIntl<any>(
  ({ id, matchConfiguration, team, onChange, onConfigured, lineUp, intl }) => {
    console.log("[matchConfigurationCard] lineup -> ", lineUp);

    const [selectedNumberOfPlayers, setSelectedNumberOfPlayers] = useState<
      number
    >();
    const [selectedNumberOfParts, setSelectedNumberOfParts] = useState<
      number
    >();
    const [selectedTimeOfPart, setSelectedTimeOfPart] = useState<number>();
    const [selectedNumOfChanges, setSelectedNumOfChanges] = useState<number>();
    const [noLimitInSubs, setNoLimitInSubs] = useState<boolean>(false);
    const [configureMatch, { data }] = useMutation(CONFIGURE_MATCH);
    const [config, setConfig] = useState<any>(
      matchConfiguration && { ...matchConfiguration }
    );
    const [initialFormation, setInitialFormation] = useState<number>();

    useEffect(() => {
      if (lineUp) {
        setInitialFormation(lineUp);
      }
    }, [lineUp]);

    useEffect(() => {
      if (initialFormation) {
        console.log("[MatchConfigurationCard] initial formation changed...");
        onChange(initialFormation);
      }
    }, [initialFormation]);

    useEffect(() => {
      if (data && data.configureMatch) {
        setConfig({
          numPlayers: data.configureMatch.config.numPlayers,
          gameParts: data.configureMatch.config.gameParts,
          timePerPart: data.configureMatch.config.timePerPart,
          substitutions: data.configureMatch.config.substitutions,
        });
        onConfigured();
      }
      if (config) {
        console.log("config has changed!!", config);
        console.log(config);
      }
    }, [data]);

    const handleNumberOfPlayersChange = (e: any) => {
      console.log("handleNumberOfPlayersChange");
      console.log(e);
      setSelectedNumberOfPlayers(e);
    };

    const handleNumberOfPartsChange = (e: any) => {
      setSelectedNumberOfParts(e);
    };
    const handleTimeOfPartChange = (e: any) => {
      setSelectedTimeOfPart(e);
    };
    const handleNumOfChangesChange = (e: any) => {
      setSelectedNumOfChanges(e);
    };
    const handleSave = () => {
      console.log({
        numPlayers: selectedNumberOfPlayers,
        gameParts: selectedNumberOfParts,
        timePerPart: selectedTimeOfPart,
      });
      configureMatch({
        variables: {
          matchId: id,
          numPlayers: Number(selectedNumberOfPlayers),
          gameParts: Number(selectedNumberOfParts),
          timePerPart: Number(selectedTimeOfPart),
          substitutions: noLimitInSubs ? -1 : Number(selectedNumOfChanges),
        },
      });
    };
    const handleNoLimitInSubs = (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextState = e.target.checked;
      setNoLimitInSubs(nextState);
    };

    return (
      <CardWrapper style={{ marginBottom: 16 }}>
        <Header>
          <Row>
            <Grid item xs={8}>
              <Title>{intl.formatMessage(messages.configureMatch)}:</Title>
            </Grid>
          </Row>
        </Header>
        <Content>
          {!config && (
            <>
              <FormControl style={{ marginTop: 16 }}>
                <InputLabel htmlFor="age-native-helper">
                  Select number of players
                </InputLabel>
                <NativeSelect
                  id="number-of-players-select"
                  placeholder="Select number of players"
                  value={selectedNumberOfPlayers}
                  onChange={(e: any) => {
                    console.log(e);
                    handleNumberOfPlayersChange(e && e.target.value);
                  }}
                >
                  <option aria-label="None" value="" />
                  {Object.keys(numberOfPlayers).map((key: any, idx: any) => (
                    <option key={idx} value={numberOfPlayers[key]}>
                      {numberOfPlayers[key]}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>

              <FormControl style={{ marginTop: 16 }}>
                <InputLabel htmlFor="age-native-helper">
                  Select number of parts in the game
                </InputLabel>
                <NativeSelect
                  id="number-of-parts-select"
                  placeholder="Select number of parts"
                  value={selectedNumberOfParts}
                  onChange={(e: any) =>
                    handleNumberOfPartsChange(e && e.target.value)
                  }
                >
                  <option aria-label="None" value="" />
                  {Object.keys(numberOfParts).map((key: any, idx: any) => (
                    <option key={idx} value={numberOfParts[key]}>
                      {numberOfParts[key]}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <FormControl style={{ marginTop: 16 }}>
                <InputLabel htmlFor="age-native-helper">
                  Select time of each part
                </InputLabel>
                <NativeSelect
                  id="time-of-parts-select"
                  placeholder="Select time"
                  value={selectedTimeOfPart}
                  onChange={(e: any) =>
                    handleTimeOfPartChange(e && e.target.value)
                  }
                >
                  <option aria-label="None" value="" />
                  {Object.keys(timeOfPart).map((key: any, idx: any) => (
                    <option key={idx} value={timeOfPart[key]}>
                      {timeOfPart[key]}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <div style={{ padding: 8, marginTop: 16 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noLimitInSubs}
                      onChange={(event) => handleNoLimitInSubs(event)}
                      color="secondary"
                    />
                  }
                  label={intl.formatMessage(messages.noLimitSubstitutions)}
                />
              </div>
              <FormControl style={{ flex: 1, marginTop: 16 }}>
                <InputLabel htmlFor="age-native-helper">
                  Select number of substitutions
                </InputLabel>
                <NativeSelect
                  id="num-of-changes-select"
                  placeholder="Select substitutions"
                  value={selectedNumOfChanges}
                  onChange={(e: any) =>
                    handleNumOfChangesChange(e && e.target.value)
                  }
                  disabled={noLimitInSubs}
                >
                  <option aria-label="None" value="" />
                  {Object.keys(numOfChanges).map((key: any, idx: any) => (
                    <option key={idx} value={numOfChanges[key]}>
                      {numOfChanges[key]}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <div style={{ padding: 8, marginTop: 16 }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={handleSave}
                >
                  save config
                </Button>
              </div>
            </>
          )}
          {config && (
            <>
              <ConfigurationConfirmedBox>
                <Typography.Subtitle1>
                  {intl.formatMessage(messages.numPlayers)}: {config.numPlayers}
                </Typography.Subtitle1>
                <Typography.Subtitle1>
                  {intl.formatMessage(messages.gameParts)}: {config.gameParts}
                </Typography.Subtitle1>
                <Typography.Subtitle1>
                  {intl.formatMessage(messages.timePerPart)}:{" "}
                  {config.timePerPart}
                </Typography.Subtitle1>
                <Typography.Subtitle1>
                  {intl.formatMessage(messages.substitutions)}:{" "}
                  {config.substitutions === -1
                    ? "No limit"
                    : config.selectedNumOfChanges}
                </Typography.Subtitle1>
              </ConfigurationConfirmedBox>
              <Typography.H6 style={{ marginTop: "1em" }}>
                {intl.formatMessage(messages.selectFormation)}
              </Typography.H6>
              {config && config.numPlayers === 11 && (
                <Formation11
                  matchId={id}
                  lineUp={initialFormation}
                  team={team}
                  callback={(lineUp: any) => {
                    console.log("Seleccionados::::", lineUp);
                    setInitialFormation(lineUp);
                  }}
                />
              )}
              {config && config.numPlayers === 9 && (
                <Formation9
                  matchId={id}
                  lineUp={initialFormation}
                  team={team}
                  callback={(lineUp: any) => {
                    console.log("Seleccionados::::", lineUp);
                    setInitialFormation(lineUp);
                  }}
                />
              )}
              {config && config.numPlayers === 7 && (
                <Formation7
                  matchId={id}
                  lineUp={initialFormation}
                  team={team}
                  callback={(lineUp: any) => {
                    console.log("Seleccionados::::", lineUp);
                    // setSelectedPlayers(numSelected);
                    setInitialFormation(lineUp);
                  }}
                />
              )}
              {config && config.numPlayers === 5 && (
                <Formation5
                  matchId={id}
                  lineUp={initialFormation}
                  team={team}
                  callback={(lineUp: any) => {
                    console.log("Seleccionados::::", lineUp);
                    // setSelectedPlayers(lineUp);
                    setInitialFormation(lineUp);
                  }}
                />
              )}
            </>
          )}
        </Content>
      </CardWrapper>
    );
  }
);

export default MatchConfigurationCard;
