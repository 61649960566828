import React, { useState, useEffect } from "react";
import { createStyles } from "@material-ui/styles";
import Typography from "../typography";
// import DelIcon from "@material-ui/icons/RemoveCircleOutline";
import {
  Grid,
  Chip,
  makeStyles,
  Theme,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  // List,
  // ListItem,
  // ListItemText,
  // Divider,
  // Fab,
  // Badge,
  Switch,
} from "@material-ui/core";
import Button from "../button";
import { RouteComponentProps } from "@reach/router";
import { injectIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import { IPlayer } from "../../types";
// import UserAvatar from "../user-avatar";
import FinalState from "./FinalState";
import {
  QUERY_MATCH,
  START_MATCH,
  CREATE_MATCH_CALL,
  // END_MATCH,
  UPDATE_STATUS,
} from "./MatchQueries";
import Instructions from "../form-utils/Instructions";
import HeaderMatch from "./HeaderMatch";
import {
  // YellowCardIcon,
  // RedCardIcon,
  // GoalIcon,
  // OwnGoalBadge,
  Content,
  Container,
  Centered,
  ButtonRow,
  // AwayTeamButton,
  TacticsIcon,
} from "./StyledElements";
import messages from "./PageViewMatch.intl";

import { PaperLayout } from "../atoms";
import MatchComponent from "./MatchComponentV2";
// import SquadCard from "./SquadCard";
import MatchConfigurationCard from "./MatchConfigurationCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

const PageViewMatch = injectIntl<{ id: string } & RouteComponentProps>(
  ({ id, intl }) => {
    const classes = useStyles();
    const { data, refetch } = useQuery(QUERY_MATCH, {
      variables: {
        id,
      },
    });

    const [state, setState] = useState<{ [index: string]: boolean }>({});

    const [match, setMatch] = useState<any>();
    const [available, setAvailable] = useState(0);
    const [matchStatus, setMatchStatus] = useState("");
    const [score, setScore] = useState({ home: 0, away: 0 });
    const [scorerList, setScorerList] = useState<{ [index: string]: number }>(
      {}
    );

    const [createCall, { data: dataCall }] = useMutation(CREATE_MATCH_CALL);
    const [startMatch, { data: dataStartMatch }] = useMutation(START_MATCH);
    // const [{ data: dataEndtMatch }] = useMutation(END_MATCH);
    const [updateStatus] = useMutation(UPDATE_STATUS);

    // lineup completed
    const [lineUp, setLineUp] = useState<any>();
    const [lineUpCompleted, setLineUpCompleted] = useState<boolean>(false);
    const [selectedPlayers, setSelectedPlayers] = useState<number>(0);

    useEffect(() => {
      const selected: { [index: string]: boolean } = {};

      if (data && data.match) {
        console.log("[PageViewMatch], match data received", data.match);
        for (let player of data.match.homeTeam.squad) {
          selected[player.id] = false;
        }

        for (let player of data.match.playersConfirmed) {
          selected[player.id] = true;
        }

        // console.log(selected);
        setState(selected);
        setMatchStatus(data.match.status);
        setScore(data.match.score);
        const mapScorerList: { [index: string]: number } = {};
        if (data.match.scorerList) {
          for (let scorerFromDB of data.match.scorerList) {
            // console.log(scorerFromDB);
            // if (scorerFromDB) {
            const key = scorerFromDB.player.id;
            // console.log(key);
            if (!mapScorerList[key]) {
              mapScorerList[key] = scorerFromDB.goals;
            }
            // }
          }
          // console.log(mapScorerList);
          setScorerList(mapScorerList);
        }
        setMatch(data.match);

        if (data.match.lineUp) {
          const lineUpMapped: any = {};
          data.match.lineUp.forEach((entry: any) => {
            lineUpMapped[entry.position] = entry.player;
          });
          setLineUp(lineUpMapped);
          console.log("[PageViewMatch], lineUp", lineUpMapped);
        }
      }
    }, [data]);

    useEffect(() => {
      if (dataCall) {
        setMatchStatus(dataCall.createMatchCall.status);
        console.log("mandando la lista de convocados......");
        console.log(dataCall.createMatchCall);
        const updatedMatch: any = { ...match };
        if (updatedMatch) {
          updatedMatch.playersConfirmed =
            dataCall.createMatchCall.playersConfirmed;
        }
        console.log(updatedMatch);
        setMatch(updatedMatch);
      }
    }, [dataCall]);

    useEffect(() => {
      if (dataStartMatch) {
        setMatchStatus(dataStartMatch.startMatch.status);
        setScore(dataStartMatch.startMatch.score);
      }
    }, [dataStartMatch]);

    // useEffect(() => {
    //   if (dataEndtMatch) {
    //     setMatchStatus(dataEndtMatch.endMatch.status);
    //   }
    // }, [dataEndtMatch]);

    useEffect(() => {
      if (lineUp) {
        console.log("lineUp changed ", lineUp);
        const selected = calculatePlayerSelected();
        console.log("numPlayers selected changed... ", selected);
        setSelectedPlayers(selected);
        if (match && match.config && selected === match.config.numPlayers)
          setLineUpCompleted(true);
      }
    }, [lineUp]);

    useEffect(() => {
      if (matchStatus) {
        updateStatus({
          variables: {
            matchId: id,
            status: matchStatus,
          },
          // refetchQueries: ["matchDetails"],
        });
      }
    }, [matchStatus]);

    // useEffect(() => {
    //   if (dataUpdateStatus) {
    //     refetch();
    //   }
    // }, [dataUpdateStatus]);

    const calculatePlayerSelected = () => {
      let selected = 0;
      if (lineUp) {
        const keys = Object.keys(lineUp);
        console.log("calculating selected players----");
        keys.forEach((key) => {
          if (lineUp[key] !== "") {
            console.log(lineUp[key]);
            selected++;
          }
        });
      }
      return selected;
    };

    const handleChange = (name: string) => (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setState({ ...state, [name]: event.target.checked });
      const newAvailable = available + (event.target.checked ? 1 : -1);
      setAvailable(newAvailable);

      console.log(state);
    };

    const handleCreateCall = async () => {
      const availablePlayers = Object.keys(state).filter((key) => {
        if (state[key] === true) {
          return key;
        }
      });
      console.log(availablePlayers);

      await createCall({
        variables: {
          matchId: id,
          availablePlayers,
        },
      });
    };

    const handleBack = async (status: string) => {
      setMatchStatus(status);
    };

    const handleStartMatch = async () => {
      await startMatch({
        variables: {
          matchId: id,
        },
      });
    };

    // const loaderClasses = useLoaderStyles({});
    console.log("isHomeTeamLocal");
    console.log(match && match.isHomeTeamLocal);
    return (
      <PaperLayout>
        {/* <Loader
          className={loaderClasses.root}
          errorMessage={intl.formatMessage(messages.error)}
          loading={loading}
          error={error}
          data={data}
        > */}
        {match && matchStatus !== "ONGOING" && (
          <>
            <HeaderMatch
              match={match}
              score={score}
              matchStatus={matchStatus}
            />
            <Content>
              <Container>
                {matchStatus === "PREPARING" && (
                  <>
                    <Grid item xs={12}>
                      <Instructions
                        message={intl.formatMessage(messages.instructions)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Centered style={{ paddingTop: 25 }}>
                        <TacticsIcon />
                      </Centered>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend">
                          {intl.formatMessage(messages.selectPlayers)}
                        </FormLabel>
                        <FormGroup>
                          {match.homeTeam &&
                            match.homeTeam.squad.map((player: IPlayer) => (
                              <FormControlLabel
                                control={
                                  // <Checkbox
                                  //   checked={state[player.id]}
                                  //   onChange={handleChange(player.id)}
                                  //   value={player.id}
                                  // />
                                  <Switch
                                    checked={state[player.id]}
                                    onChange={handleChange(player.id)}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                label={`${player.name} ${player.surname}`}
                              />
                            ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography.Subtitle1>{`${intl.formatMessage(
                        messages.playersConfirmed
                      )}: ${(match.playersConfirmed &&
                        match.playersConfirmed.length) ||
                        0}`}</Typography.Subtitle1>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography.Subtitle1>{`${intl.formatMessage(
                        messages.location
                      )}: ${match.location}`}</Typography.Subtitle1>
                    </Grid>
                    <Grid item xs={12}>
                      <Chip
                        size="small"
                        color="primary"
                        label={`${intl.formatMessage(messages.kickOff)}: ${
                          match.time
                        }`}
                        onClick={() => null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonRow>
                        <Button onClick={handleCreateCall}>
                          {intl.formatMessage(messages.markConfirmed)}
                        </Button>
                      </ButtonRow>
                    </Grid>
                  </>
                )}
                {matchStatus === "READY" && (
                  <>
                    <Grid item xs={12}>
                      <Instructions
                        message={intl.formatMessage(messages.infoSelected)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MatchConfigurationCard
                        id={id}
                        lineUp={lineUp}
                        matchConfiguration={match.config}
                        onChange={(lineUp: any) => {
                          console.log("[====================");
                          console.log("[====================");
                          console.log("[====================");
                          console.log("[Match Ready] lineUp", lineUp);
                          setLineUp(lineUp);
                        }}
                        onConfigured={() => {
                          console.log("configuration finished refetch...");
                          refetch();
                        }}
                        team={match.playersConfirmed}
                      />
                    </Grid>
                    {match && match.config && (
                      <Grid item xs={12}>
                        <Typography.Subtitle1>{`${selectedPlayers}/${match.config.numPlayers} Selected`}</Typography.Subtitle1>
                      </Grid>
                    )}

                    <Grid item xs={12} style={{ paddingBottom: 8 }}>
                      <Chip
                        size="small"
                        color="primary"
                        label={`${intl.formatMessage(messages.kickOff)}: ${
                          match.time
                        }`}
                        onClick={() => null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonRow>
                        <Button
                          color="default"
                          onClick={() => handleBack("PREPARING")}
                          style={{ marginRight: 16 }}
                        >
                          {intl.formatMessage(messages.backToPrepare)}
                        </Button>
                        <Button
                          onClick={handleStartMatch}
                          disabled={!lineUpCompleted}
                        >
                          {intl.formatMessage(messages.startGame)}
                        </Button>
                      </ButtonRow>
                    </Grid>
                  </>
                )}

                {matchStatus === "FINISHED" && (
                  <FinalState id={id} match={match} scorerList={scorerList} />
                )}
              </Container>
            </Content>
          </>
        )}
        {match && matchStatus === "ONGOING" && (
          <MatchComponent
            lineUp={lineUp}
            matchConfiguration={match.config}
            team={match.playersConfirmed}
            match={match}
            matchId={id}
            callback={(status: string) => {
              setMatchStatus(status);
            }}
          />
        )}
        {/* </Loader> */}
      </PaperLayout>
    );
  }
);

export default PageViewMatch;
