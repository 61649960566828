import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "PageViewLeague.Error",
    defaultMessage: "Error loading team details"
  },
  scorers: {
    id: "PageViewLeague.scorers",
    defaultMessage: "Scorers"
  },
  squad: {
    id: "PageViewLeague.squad",
    defaultMessage: "Squad"
  },
  matchesPlayed: {
    id: "PageViewLeague.matchesPlayed",
    defaultMessage: "Matches played"
  },
  won: {
    id: "PageViewLeague.won",
    defaultMessage: "Won"
  },
  draw: {
    id: "PageViewLeague.draw",
    defaultMessage: "Draw"
  },
  lost: {
    id: "PageViewLeague.lost",
    defaultMessage: "Lost"
  },
  league: {
    id: "PageViewLeague.league",
    defaultMessage: "League"
  },
  addLeague: {
    id: "PageViewLeague.addLeague",
    defaultMessage: "Add to a league"
  },
  noLeagueTitle: {
    id: "PageViewLeague.noLeagueTitle",
    defaultMessage:
      "Your team is not in a league yet, you can add it in a league clicking below"
  },
  endLeague: {
    id: "PageViewLeague.endLeague",
    defaultMessage: "Finish league"
  },
  confirmEndLeagueTitle: {
    id: "PageViewLeague.confirmEndLeagueTitle",
    defaultMessage: "End League"
  },
  confirmEndLeagueMessage: {
    id: "PageViewLeague.confirmEndLeagueMessage",
    defaultMessage:
      "Are you sure you want to end this leeague, all the data will be stored as a historical records, this action can not be undo."
  },
  confirmEndLeagueOK: {
    id: "PageViewLeague.confirmEndLeagueOK",
    defaultMessage: "end"
  },
  confirmEndLeagueKO: {
    id: "PageViewLeague.confirmEndLeagueKO",
    defaultMessage: "cancel"
  },
  leagueBreadcrumbTitle: {
    id: "PageViewLeague.leagueBreadcrumbTitle",
    defaultMessage: "Leagues"
  },
  TableTab: {
    id: "PageViewLeague.TableTab",
    defaultMessage: "Table"
  },
  TeamsTab: {
    id: "PageViewLeague.TeamsTab",
    defaultMessage: "Teams"
  },
  MatchesTab: {
    id: "PageViewLeague.MatchesTab",
    defaultMessage: "Match day"
  },
  addTeam: {
    id: "PageViewLeague.addTeam",
    defaultMessage: "Add team"
  },
  newTeam: {
    id: "PageViewLeague.newTeam",
    defaultMessage: "New team"
  },
  save: {
    id: "PageViewLeague.save",
    defaultMessage: "Save"
  }
});
