import "ts-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
// import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import LocaleContextProvider from "./components/locale-context-provider";
import * as serviceWorker from "./serviceWorker.js";
import App from "./App";
import theme from "./theme";
import { supportedLocales, defaultLocale } from "./locale";
import { createApolloClient } from "./apollo";

import "./index.css";
import AuthContextProvider from "./components/auth-context-provider";
import "isomorphic-fetch";

const client = createApolloClient();

const language: string =
  localStorage.getItem("locale") ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language;

// console.log("localeeeeeeeeeessssssss");
// console.log(localStorage.getItem("locale"));

const currentLocale: string = supportedLocales.includes(language)
  ? language
  : defaultLocale;

ReactDOM.render(
  <ApolloProvider client={client}>
    {/* <ApolloHooksProvider client={client}> */}
    <ThemeProvider theme={theme}>
      <LocaleContextProvider locale={currentLocale}>
        <AuthContextProvider apolloCache={client.cache}>
          <App />
        </AuthContextProvider>
      </LocaleContextProvider>
    </ThemeProvider>
    {/* </ApolloHooksProvider> */}
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
