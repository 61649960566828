import "date-fns";
import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
import { ITeam } from "../../types";
import {
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Link from "../link";
import Button from "../button";
// import gql from "graphql-tag";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";
import { ActionLink } from "../atoms";
import { gql, useMutation } from "@apollo/client";
import SnackBar from "../snackbar";

import useIsManager from "../../hooks/useIsManager";

const END_LEAGUE_MUTATION = gql`
  mutation EndLeague($leagueId: ID!) {
    endLeague(leagueId: $leagueId)
  }
`;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper
//     },
//     inline: {
//       display: "inline"
//     },
//     margin: {
//       margin: theme.spacing(2)
//     },
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       width: 200
//     }
//   })
// );

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  //   height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Title = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
  currentUser: any;
}

const LeagueCard = injectIntl<ITeamSummaryCardProps>(
  ({ id, team, currentUser, intl }) => {
    const [open, setOpen] = useState(false);
    const [ended, setEnded] = useState(false);
    const [endLeague, { data, error }] = useMutation(END_LEAGUE_MUTATION);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    const handleEndLeague = async () => {
      console.log("league", team.league);
      await endLeague({
        variables: {
          leagueId: team.league.id,
        },
      });
    };

    useEffect(() => {
      if (data) {
        console.log("response endLeague", data);
        setEnded(data.endLeague);
        setOpen(false);
      }
    }, [data, error]);
    //   console.log("team=====================");
    //   console.log(team);
    //   // const classes = useStyles();

    //   const [state, setState] = useState({
    //     monday: (team.trainingSetup && team.trainingSetup.monday) || false,
    //     tuesday: (team.trainingSetup && team.trainingSetup.tuesday) || false,
    //     wednesday: (team.trainingSetup && team.trainingSetup.wednesday) || false,
    //     thrusday: (team.trainingSetup && team.trainingSetup.thrusday) || false,
    //     friday: (team.trainingSetup && team.trainingSetup.friday) || false,
    //     saturday: (team.trainingSetup && team.trainingSetup.saturday) || false,
    //     sunday: (team.trainingSetup && team.trainingSetup.sunday) || false
    //   });
    //   console.log("state ========");
    //   console.log(state);

    //   const [selectedDate, setSelectedDate] = useState<Date | null>(
    //     new Date(
    //       `2019-08-18T${(team.trainingSetup && team.trainingSetup.time) || "10:00"}`
    //     )
    //   );

    //   const [saveTraining] = useMutation(SETUP_TRAININGS);

    //   const handleDateChange = (date: Date | null) => {
    //     console.log("date================");
    //     console.log(date);
    //     setSelectedDate(date);
    //   };

    //   const handleChange = (name: string) => (
    //     event: React.ChangeEvent<HTMLInputElement>
    //   ) => {
    //     setState({ ...state, [name]: event.target.checked });
    //   };

    //   const handleSave = async () => {
    //     console.log("trying to save ------");
    //     console.log("state:");
    //     console.log(state);
    //     console.log("time");
    //     console.log(selectedDate);

    //     let time = "";
    //     if (selectedDate) {
    //       time = moment(selectedDate).format("HH:mm");
    //     }
    //     await saveTraining({
    //       variables: {
    //         id,
    //         ...state,
    //         time
    //       }
    //     });
    //   };

    return (
      <>
        {useIsManager(currentUser.role) && (
          <CardWrapper data-testid={`group-summary-${id}`}>
            <Header>
              <Row>
                <Grid item xs={8}>
                  {!team.league && (
                    <Title to="/">{intl.formatMessage(messages.league)}</Title>
                  )}
                  {team.league && (
                    <Title to={`/leagues/${team.league.id}`}>
                      {intl.formatMessage(messages.league)}: {team.league.name}
                      {">"}
                    </Title>
                  )}
                </Grid>
              </Row>
            </Header>
            <Content>
              {!team.league && !ended && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography.Subtitle1>
                      {intl.formatMessage(messages.noLeagueTitle)}
                    </Typography.Subtitle1>
                  </Grid>
                  <Grid item xs={12}>
                    <ActionLink to={`/leagues/${team.id}/addLeague`}>
                      <Button>{intl.formatMessage(messages.addLeague)}</Button>
                    </ActionLink>
                  </Grid>
                </Grid>
              )}
              {team.league && (
                <>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {intl.formatMessage(messages.confirmEndLeagueTitle)}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {intl.formatMessage(messages.confirmEndLeagueMessage)}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleEndLeague} color="primary">
                        {intl.formatMessage(messages.confirmEndLeagueOK)}
                      </Button>
                      <Button onClick={handleClose} color="primary" autoFocus>
                        {intl.formatMessage(messages.confirmEndLeagueKO)}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Grid container>
                    <Grid item xs={6}>
                      {team.league.location}
                    </Grid>
                    <Grid item xs={6}>
                      {team.league.startDate}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 8, marginBottom: 8 }}
                    >
                      {/* <ActionLink to={`/leagues/${team.id}/addLeague`}> */}
                      <Button onClick={handleClickOpen}>
                        {intl.formatMessage(messages.endLeague)}
                      </Button>
                      {/* </ActionLink> */}
                    </Grid>
                  </Grid>
                </>
              )}
            </Content>
          </CardWrapper>
        )}
        <SnackBar
          data-testid="error-message"
          open={ended}
          // message={intl.formatMessage(messages.errorUpdatingPassword)}
          message="League deleted"
          variant="success"
          actionText="close"
          action={handleClose}
        />
      </>
    );
  }
);

export default LeagueCard;
