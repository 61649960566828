import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { styled } from "@material-ui/styles";
import { injectIntl } from "react-intl";
import { RouteComponentProps } from "@reach/router";
import messages from "./PagePeople.intl";
import Players from "../players";
import Teams from "../teams";
import { Theme, Paper } from "@material-ui/core";
import Matches from "../matches-tab";
import queryString from "query-string";
import SnackBar from "../snackbar";
import CancelIcon from "@material-ui/icons/CancelOutlined";

const StyledPaper = styled(Paper)<Theme>(({ theme }) => ({
  boxSizing: "border-box",
  marginBottom: theme.spacing(5),
  // textAlign: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: 830,
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    boxShadow: "none",
  },
}));

const TabContainer = styled("div")<Theme>(({ theme }) => ({
  // position: "relative",
  // top: theme.layout.tabsHeight + 1,
  backgroundColor: theme.palette.extra.default,
  // paddingBottom: `${theme.layout.footerHeight}px`,
}));

enum TabNames {
  players = "players",
  teams = "teams",
  matches = "matches",
}
const PATH_TO_TAB: { [path: string]: string } = {
  "/people/players": TabNames.players,
  "/people/teams": TabNames.teams,
  "/people/matches": TabNames.matches,
};
const TAB_TO_PATH: { [tabName: string]: string } = {
  [TabNames.players]: "/people/players",
  [TabNames.teams]: "/people/teams",
  [TabNames.matches]: "/people/matches",
};

const TabsWrapper = styled("div")<Theme>(({ theme }) => ({
  // position: "fixed",
  width: "100%",
  // width: `calc(100% - ${theme.layout.navDrawerWidthClosed}px)`,
  // top: "66px", //theme.layout.headerHeight,
  paddingTop: 10,
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: "#fff",
  borderBottom: `1px solid ${theme.palette.extra.borderColor}`,
  // marginBottom: 16,
}));

type PagePeopleProps = Required<
  Pick<RouteComponentProps, "navigate" | "path" | "location">
>;

const PagePeople = injectIntl<PagePeopleProps>(
  ({ intl, navigate, path, location }) => {
    const currentTab = PATH_TO_TAB[path] || TabNames.players;

    let params = queryString.parse(location.search);
    let userDeleted = params["userDeleted"];
    console.log("user-deleted-----", userDeleted);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(
      Boolean(userDeleted)
    );

    const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
      navigate(TAB_TO_PATH[newValue], {
        replace: true,
      });
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentTab]);

    return (
      <StyledPaper>
        <TabsWrapper>
          <Tabs
            centered
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
          >
            <Tab
              label={intl.formatMessage(messages.players)}
              data-testid="players-tab"
              value={TabNames.players}
            />
            <Tab
              label={intl.formatMessage(messages.teams)}
              data-testid="teams-tab"
              value={TabNames.teams}
            />
            <Tab
              label={intl.formatMessage(messages.matches)}
              data-testid="matches-tab"
              value={TabNames.matches}
            />
          </Tabs>
        </TabsWrapper>
        <TabContainer>
          {currentTab === TabNames.players && (
            <Players refetchPlayers={Boolean(userDeleted)} />
          )}
          {currentTab === TabNames.teams && <Teams />}
          {currentTab === TabNames.matches && <Matches />}
        </TabContainer>
        <SnackBar
          data-testid="delete-error"
          open={openSnackbar}
          message="user has been deleted"
          variant="success"
          action={() => {
            setOpenSnackbar(false);
          }}
          actionText={<CancelIcon />}
        />
      </StyledPaper>
    );
  }
);

export default PagePeople;
