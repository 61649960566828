import React, { useState, useEffect } from "react";
// import { FormattedMessage } from "react-intl";
import { styled, makeStyles, createStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
import { ITeam, IMatch } from "../../types";
import { Card, Grid, Chip, Theme } from "@material-ui/core";
import Typography from "../typography";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";
import moment from "moment";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

export const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  flexDirection: "row",
  paddingtop: "20px",
  justifyContent: "space-between",
});

const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
}

const getNumberOfMatchesWon = (matches: any) => {
  console.log("matches--------");
  console.log(matches);
  if (matches) {
    const numberOfWonGames = matches
      .map((match: any) => (match && match.isWon ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfWonGames);
    return numberOfWonGames;
  }
  return 0;
};

const getNumberOfMatchesTied = (matches: any) => {
  console.log("matches--------");
  console.log(matches);
  if (matches) {
    const numberOTiedMatches = matches
      .map((match: any) => (match && match.isTied ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOTiedMatches);
    return numberOTiedMatches;
  }
  return 0;
};

const getNumberOfMatchesLost = (matches: any) => {
  console.log("matches--------");
  console.log(matches);
  if (matches) {
    const numberOfLostMatches = matches
      .map((match: any) => (match && match.isLost ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfLostMatches);
    return numberOfLostMatches;
  }
  return 0;
};

// const StyledScoreValue = styled("div")(({ theme }: IStyledArguments) => ({
//   fontSize: 46,
//   fontWeight: 600,
//   color: theme.palette.primary.main,
// }));

// const getResult = (match: IMatch) => {
//   let result = "draw";
//   if (match.score.home > match.score.away) {
//     result = "won";
//   } else if (match.score.home < match.score.away) {
//     result = "lost";
//   }
//   return result;
// };

const getResultColor = (match: IMatch) => {
  let result = "lightGray";
  if (match.score.home > match.score.away) {
    result = "green";
  } else if (match.score.home < match.score.away) {
    result = "red";
  }
  return result;
};

const TeamCard = injectIntl<ITeamSummaryCardProps>(({ id, team, intl }) => {
  const [sortedMatches, setSortedMatches] = useState<IMatch[]>([]);
  console.log("[debug]team===================== matches...", team.matches);
  console.log(team);

  const classesList = useStylesList();
  console.log("[debug]classes=====================", classesList);
  const matchesWon = getNumberOfMatchesWon(team.matches);
  const matchesTied = getNumberOfMatchesTied(team.matches);
  const matchesLost = getNumberOfMatchesLost(team.matches);

  useEffect(() => {
    if (team && team.matches) {
      const tmpMaches = [...team.matches];
      try {
        const sortedMatchesByDate = tmpMaches.sort(
          (a, b) =>
            Number(b.date.replace(new RegExp("-", "g"), "")) -
            Number(a.date.replace(new RegExp("-", "g"), ""))
        );
        setSortedMatches(sortedMatchesByDate);
      } catch (e) {
        console.log("[error], error sorting", e);
      }
    }
  }, [team]);

  return (
    <CardWrapper data-testid={`group-summary-${id}`}>
      <Header>
        <Row>
          <Grid item xs={12}>
            <Typography.H6>
              {`${intl.formatMessage(
                messages.matchesPlayed
              )}: ${(team.matches && team.matches.length) || 0}`}
            </Typography.H6>
          </Grid>
        </Row>
      </Header>
      <Content>
        <Grid item xs={12}>
          <Row>
            <Grid item xs={4}>
              <Chip
                color="primary"
                size="small"
                label={`${matchesWon} ${intl.formatMessage(messages.won)}`}
                onClick={() => null}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                color="default"
                size="small"
                label={`${matchesTied} ${intl.formatMessage(messages.draw)}`}
                onClick={() => null}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                color="secondary"
                size="small"
                label={`${matchesLost} ${intl.formatMessage(messages.lost)}`}
                onClick={() => null}
              />
            </Grid>
          </Row>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {sortedMatches.map((match: IMatch) => (
              <div
                style={{
                  border: "1px dashed #b2b2b2",
                  marginBottom: "0.5em",
                  borderRadius: "0.5em",
                  padding: "0.6em",
                  paddingLeft: "1px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: `2px solid ${getResultColor(match)}`,
                    paddingLeft: "1em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Body
                    style={{
                      color: `${match.isHomeTeamLocal ? "#159ad7" : "orange"}`,
                      margin: 0,
                    }}
                  >
                    <strong>
                      {`${match.isHomeTeamLocal ? "Home" : "Away"}`}
                    </strong>
                  </Typography.Body>
                  <Typography.Body style={{ margin: 0 }}>
                    {moment(match.date).format("MMM")}
                  </Typography.Body>
                  <Typography.H6 style={{ margin: 0 }}>
                    {moment(match.date).format("DD")}
                  </Typography.H6>
                </div>
                <div>
                  <Typography.H6>{`VS ${match.awayTeam}`}</Typography.H6>
                </div>
                <div>
                  {match.isHomeTeamLocal ? (
                    <Typography.Body>{`${match.score.home} - ${match.score.away}`}</Typography.Body>
                  ) : (
                    <Typography.Body>{`${match.score.away} - ${match.score.home}`}</Typography.Body>
                  )}
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </Content>
    </CardWrapper>
  );
});

export default TeamCard;
