import React, { Component } from 'react';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';

interface IOptionProps extends MenuItemProps {
  value: any;
  children: any;
}

class Option extends Component<IOptionProps> {
  public render(): React.ReactNode {
    const { value, children, ...props } = this.props;
    return (
      <MenuItem value={value} {...props} button>
        {children}
      </MenuItem>
    );
  }
}

export default Option;
