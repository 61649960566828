import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { IMatch } from "../../types";
import Link from "../link";
import { injectIntl } from "react-intl";
import messages from "./PageViewMatch.intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: theme.spacing(2)
    },
    paper: {
      padding: theme.spacing(1, 2)
    }
  })
);

const Breadcrumb = injectIntl<{ match: IMatch }>(({ match, intl }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            {intl.formatMessage(messages.team)}
          </Link>
          <Link color="inherit" to="/people/matches">
            {intl.formatMessage(messages.matches)}
          </Link>
          <Typography color="textPrimary">
            {match.homeTeam.name} vs {match.awayTeam}
          </Typography>
        </Breadcrumbs>
      </Paper>
      <br />
    </div>
  );
});

export default Breadcrumb;
