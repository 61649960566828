import { defineMessages } from "react-intl";

export default defineMessages({
  createPassword: {
    id: "ResetPassword.CreatePassword",
    defaultMessage: "Create New Password",
  },
  password: {
    id: "ResetPassword.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "ResetPassword.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  updatePassword: {
    id: "ResetPassword.UpdatePassword",
    defaultMessage: "Update password",
  },
  errorUpdatingPassword: {
    id: "ResetPassword.ErrorUpdatingPassword",
    defaultMessage: "There has been an error updating your password.",
  },
  passwordUpdated: {
    id: "ResetPassword.passwordUpdated",
    defaultMessage: "Password updated",
  },
});
