import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "PageViewExercise.Error",
    defaultMessage: "Error loading match details",
  },
  instructionsTitle: {
    id: "PageViewExercise.instructionsTitle",
    defaultMessage: "Info",
  },
  selectPlayers: {
    id: "PageViewExercise.selectPlayers",
    defaultMessage: "Select available players",
  },
  instructions: {
    id: "PageViewExercise.instructions",
    defaultMessage:
      "Here you can find the list of players that have already accepted the invitation to the match, but you can also add new players to conform the final list to start the Match.",
  },
  markConfirmed: {
    id: "PageViewExercise.markConfirmed",
    defaultMessage: "Mark as confirmed",
  },
  location: {
    id: "PageViewExercise.location",
    defaultMessage: "Location",
  },
  playersConfirmed: {
    id: "PageViewExercise.playersConfirmed",
    defaultMessage: "Players confirmed",
  },
  kickOff: {
    id: "PageViewExercise.kickOff",
    defaultMessage: "Kick Off",
  },
  library: {
    id: "PageViewExercise.library",
    defaultMessage: "Library",
  },
  matches: {
    id: "PageViewExercise.matches",
    defaultMessage: "Matches",
  },
  startGame: {
    id: "PageViewExercise.startGame",
    defaultMessage: "Start game",
  },
  ownGoal: {
    id: "PageViewExercise.ownGoal",
    defaultMessage: "Own goal",
  },
  endGame: {
    id: "PageViewExercise.endGame",
    defaultMessage: "End game",
  },
  own: {
    id: "PageViewExercise.own",
    defaultMessage: "Own",
  },
  goal: {
    id: "PageViewExercise.goal",
    defaultMessage: "Goal",
  },
  backMatches: {
    id: "PageViewExercise.backMatches",
    defaultMessage: "Back to matches",
  },
  backToPrepare: {
    id: "PageViewExercise.backToPrepare",
    defaultMessage: "Back",
  },
  images: {
    id: "PageViewExercise.images",
    defaultMessage: "Images",
  },
  delete: {
    id: "PageViewExercise.delete",
    defaultMessage: "Delete",
  },
  errorDeleting: {
    id: "PageViewExercise.errorDeleting",
    defaultMessage:
      "Error deleting exercise, it is in use by any training, delete the training first.",
  },
});
