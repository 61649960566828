import React from 'react';
import { FormikHandlers } from 'formik';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';

interface ITextInput extends OutlinedTextFieldProps {
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: any;
    name: string;
  };
}

const TextInput = ({ field, inputProps, ...props }: ITextInput) => {
  const { onChange, onBlur, ...inputFieldProps } = field;

  return (
    <TextField
      {...props}
      {...inputFieldProps}
      onChange={onChange || props.onChange}
      onBlur={onBlur || props.onBlur}
      inputProps={{ ...inputProps }}
    />
  );
};

TextInput.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  field: {},
};

export default TextInput;
