import React from 'react';
import { Formik, FormikActions, FormikProps, Field } from 'formik';
import InputErrorMessage from '../../input-error-message';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import messages from '../PageResetPassword.intl';
import PasswordInput from '../../password-input';
import { matchesPassword, validPassword } from '../../form-utils';
import {
  VerticalForm,
  ActionButton,
} from '../../unauthenticated-layout-elements';

export interface IResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

interface IResetPasswordForm extends InjectedIntlProps {
  onSubmit: (arg: IResetPasswordFormValues) => void;
}

const ResetPasswordForm = ({ onSubmit, intl }: IResetPasswordForm) => (
  <Formik
    initialValues={{ password: '', confirmPassword: '' }}
    onSubmit={(
      values: IResetPasswordFormValues,
      actions: FormikActions<IResetPasswordFormValues>
    ) => {
      onSubmit(values);
      actions.setSubmitting(false);
    }}
    render={({
      errors,
      touched,
      values,
    }: FormikProps<IResetPasswordFormValues>) => (
      <VerticalForm data-testid="reset-password-form">
        <Field
          validate={(value: string) => validPassword(intl)(value)}
          name="password"
          error={errors.password && touched.password}
          component={PasswordInput}
          inputProps={{
            'data-testid': 'password',
            autoComplete: 'password',
          }}
          label={intl.formatMessage(messages.password)}
        />
        <InputErrorMessage name="password" />
        <Field
          validate={(value: string) =>
            matchesPassword(intl)(value, values.password)
          }
          name="confirmPassword"
          error={errors.confirmPassword && touched.confirmPassword}
          component={PasswordInput}
          inputProps={{
            'data-testid': 'confirm-password',
            autoComplete: 'Confirm password',
          }}
          label={intl.formatMessage(messages.confirmPassword)}
        />
        <InputErrorMessage
          name="confirmPassword"
          data-testid="confirm-password-error"
        />
        <div>
          <ActionButton>
            <FormattedMessage {...messages.updatePassword} />
          </ActionButton>
        </div>
      </VerticalForm>
    )}
  />
);

export default injectIntl(ResetPasswordForm);
