import React, { useState, useEffect } from "react";
import IconAdd from "@material-ui/icons/Add";
import { styled, withStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { storage } from "../../firebase/firebase";
import useAuthContext from "../../hooks/useAuthContext";
import { Button } from "@material-ui/core";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ADD_IMAGE_MATCH = gql`
  mutation AddImageToMatch($id: ID!, $url: String!) {
    addImageToMatch(id: $id, url: $url) {
      images
    }
  }
`;

const GET_MATCH_IMAGES = gql`
  query getMatch($id: ID!) {
    match(id: $id) {
      images
    }
  }
`;

const AddPictureBox = styled("div")(({ theme }: IStyledArguments) => ({
  border: "solid 1px",
  borderRadius: "8px",
  borderStyle: "dashed",
  borderColor: theme.palette.extra.borderColor,
  width: "80px",
  height: "80px",
  position: "relative",
  "&:hover": {
    cursor: "pointer",
  },
  margin: theme.spacing(1, 1, 1, 0),
}));

const ImageBox = ({ url, index, onClick }: any) => {
  const Box = styled("div")(({ theme }: any) => ({
    border: "solid 1px",
    borderRadius: "8px",
    borderColor: theme.palette.extra.borderColor,
    background: `url(${url}) no-repeat center`,
    backgroundSize: "cover",
    width: "80px",
    height: "80px",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
    margin: theme.spacing(1, 1, 1, 0),
  }));

  return <Box key={`imageBox-${index}`} onClick={() => onClick(index)} />;
};

const TransparentButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "0px ",
    lineHeight: 1.5,
    width: "100%",
    height: "100%",
    // backgroundColor: "#0063cc",
    // borderColor: "#0063cc",
    "&:hover": {
      backgroundColor: "#61616121",
      //   borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //   backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const PictureUploader = ({ id }: any) => {
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState<any>();
  const [imageAsUrl, setImageAsUrl] = useState<any>(allInputs);
  const [images, setImages] = useState([]);
  const [addImage, { data }] = useMutation(ADD_IMAGE_MATCH);
  const { data: dataImages, refetch } = useQuery(GET_MATCH_IMAGES, {
    variables: { id },
  });
  const [auth] = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (data && data.addImageToMatch) {
      console.log("data.addImageToMatch");
      console.log(data.addImageToMatch);
      refetch();
    }
  }, [data]);

  useEffect(() => {
    if (dataImages && dataImages.match) {
      setImages(dataImages.match.images);
    }
  }, [dataImages]);

  useEffect(() => {
    console.log("uploaded");
    console.log(imageAsUrl);
    //TODO: store url on DB
    if (imageAsUrl && imageAsUrl.imgUrl) {
      addImage({
        variables: {
          id,
          url: imageAsUrl.imgUrl,
        },
      });
    }
  }, [imageAsUrl]);

  useEffect(() => {
    console.log("image selected");
    console.log(imageAsFile);
    if (imageAsFile) {
      handleFireBaseUpload();
    }
  }, [imageAsFile]);

  const handleImageAsFile = (e: any) => {
    const image = e.target.files[0];
    setImageAsFile(() => image);
  };

  //   const handleFireBaseUpload = (e: any) => {
  //       e.preventDefault();
  const handleFireBaseUpload = () => {
    console.log("start of upload");
    // async magic goes here...
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const userId = (auth && auth.user && auth.user.id) || undefined;

    const uploadTask = storage
      .ref(`/images/${userId}/${imageAsFile.name}`)
      .put(imageAsFile);

    uploadTask.on("state_changed", {
      next: (snapshot: any) => {
        //takes a snap shot of the process as it is happening
        console.log(snapshot);
        let percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        // // Stop after receiving one update.
        // unsubscribe();
      },
      error: (err) => {
        //catches the errors
        console.log(err);
      },
      complete: () => {
        console.log("completed");
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(`images/${userId}`)
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageAsUrl((prevObject: any) => ({
              ...prevObject,
              imgUrl: fireBaseUrl,
            }));
          });
      },
    });
  };

  const handleOpenLightBox = (index: number) => {
    setIndex(index);
    setIsOpen(true);
  };

  // const images2 = [
  //   "https://firebasestorage.googleapis.com/v0/b/footik-bff.appspot.com/o/images%2F5d9b3a18c75e1add7502affc%2Fconnor-coyne-OgqWLzWRSaI-unsplash.jpg?alt=media&token=ae3f6764-685f-4267-819b-1b59f306ed9b",
  //   "https://firebasestorage.googleapis.com/v0/b/footik-bff.appspot.com/o/images%2F5d9b3a18c75e1add7502affc%2Fmickael-tournier-qVM2QlBWOmM-unsplash.jpg?alt=media&token=d773ba7e-40f1-48e2-ad1a-946c58947fe7",
  //   "https://firebasestorage.googleapis.com/v0/b/footik-bff.appspot.com/o/images%2F5d9b3a18c75e1add7502affc%2Fguillaume-de-germain-i65Foix4Aa4-unsplash.jpg?alt=media&token=70603f4c-02d4-40a5-9aa2-874ccfc491cb",
  // ];

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <AddPictureBox key="pictureBox1">
        <TransparentButton
          // @ts-ignore
          component="label"
          endIcon={
            <IconAdd
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                margin: "auto auto",
                color: "grey",
              }}
            />
          }
        >
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageAsFile}
          />
        </TransparentButton>
      </AddPictureBox>
      {images &&
        images.map((image, index) => (
          <ImageBox url={image} index={index} onClick={handleOpenLightBox} />
        ))}

      {isOpen && images && images.length > 0 && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default PictureUploader;
