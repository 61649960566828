import React from "react";
import NewPlayerForm from "./new-team-form";
import { IFormValues } from "./new-team-form/NewTeamForm";
import { ITeam } from "../../types";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  team?: ITeam;
}

const StepInitial = ({ onGoNext, team }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    onGoNext(formValues);
  };
  return <NewPlayerForm onSubmit={handleSubmit} {...team} />;
};

export default StepInitial;
