import React from "react";
import {
  Dialog,
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IConfirmDialog {
  title: string;
  message: string;
  callback: any;
  trigger: any;
}

const ConfirmDialog = ({
  title,
  message,
  callback,
  trigger,
}: IConfirmDialog) => {
  const [open, setOpen] = React.useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    callback(true);
    setOpen(false);
  };
  const handleDisagree = () => {
    callback(false);
    setOpen(false);
  };

  const TriggerComponent = () => {
    const Comp = trigger({ onOpen });
    return Comp;
  };

  return (
    <div style={{ width: "100%" }}>
      <TriggerComponent />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDisagree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
