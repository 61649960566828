import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { gql } from "@apollo/client";
import Typography from "../typography";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import CheckedIcon from "@material-ui/icons/CheckCircleOutline";
import UnCheckedIcon from "@material-ui/icons/HighlightOffOutlined";
import CalendarComponent from "./CalendarComponent";
import moment from "moment";
import NextMatchCard from "./NexMatchCard";
import { injectIntl } from "react-intl";
import messages from "./PageDashboard.intl";

const NEXT_MATCH = gql`
  query NextMatch($teamId: ID!) {
    nextMatch(teamId: $teamId) {
      id
      date
      homeTeam {
        name
      }
      isHomeTeamLocal
      awayTeam
      location
      time
      status
      playersConfirmed {
        id
      }
    }
  }
`;

const ATTEND_MATCH = gql`
  mutation AttendMatch($matchId: ID!, $playerId: ID!, $isComing: Boolean!) {
    attendMatch(matchId: $matchId, playerId: $playerId, isComing: $isComing) {
      date
      playersConfirmed {
        id
      }
    }
  }
`;
interface IPlayerEvents {
  teamId: string;
  trainingWeekList: any;
  attendance?: any;
  user?: any;
}

const PlayerEvents = injectIntl<IPlayerEvents>(
  ({ teamId, trainingWeekList, attendance, user, intl }) => {
    const { data: nextMatchData } = useQuery(NEXT_MATCH, {
      variables: {
        teamId,
      },
    });
    const [nextMatch, setNextMatch] = useState<any>();
    const [
      nextMatchAttendanceConfirmed,
      setNextMatchAttendanceConfirmed,
    ] = useState(false);

    const [attendMatch, { data: attendMatchData }] = useMutation(ATTEND_MATCH);

    const handleAttendMatch = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const nextState = event.target.checked;
      await attendMatch({
        variables: {
          matchId: nextMatch.id,
          playerId: user.playerLink,
          isComing: nextState,
        },
      });
    };

    useEffect(() => {
      if (nextMatchData) {
        setNextMatch(nextMatchData.nextMatch);

        // console.log("nextMatchData.playersConfirmed");
        // console.log(nextMatchData.nextMatch.playersConfirmed);
        const amIInTheList =
          nextMatchData.nextMatch &&
          nextMatchData.nextMatch.playersConfirmed &&
          nextMatchData.nextMatch.playersConfirmed.filter(
            (element: any) => element.id === user.playerLink
          );
        console.log("amIInTheList", amIInTheList);

        setNextMatchAttendanceConfirmed(
          amIInTheList && amIInTheList.length > 0
        );
      }
    }, [nextMatchData]);

    useEffect(() => {
      if (attendMatchData) {
        const amIInTheList =
          attendMatchData.attendMatch.playersConfirmed &&
          attendMatchData.attendMatch.playersConfirmed.filter(
            (element: any) => element.id === user.playerLink
          );
        // console.log("amIInTheList", amIInTheList);

        setNextMatchAttendanceConfirmed(amIInTheList.length > 0);
      }
    }, [attendMatchData]);

    return (
      <>
        <CalendarComponent
          nextMatch={nextMatch}
          trainingWeekList={trainingWeekList}
        />
        {attendance && (
          <Grid style={{ paddingTop: 16 }} xs={12}>
            <Typography.H6>Attendance: ({attendance.length})</Typography.H6>
          </Grid>
        )}
        {attendance &&
          attendance.map((player: any) => {
            console.log("attendance player...");
            console.log(player);
            return (
              <Grid
                container
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={5}>
                  <Typography.Subtitle1>
                    {player.player.name}&nbsp;{player.player.surname}
                  </Typography.Subtitle1>
                </Grid>
                <Grid item xs={7}>
                  {player.attendance && <CheckedIcon />}
                  {!player.attendance && <UnCheckedIcon />}
                </Grid>
              </Grid>
            );
          })}
        <Typography.H5 style={{ paddingTop: 20 }}>
          {intl.formatMessage(messages.nextMatch)} {">"}
        </Typography.H5>
        {!nextMatch && (
          <Typography.Subtitle1>
            {intl.formatMessage(messages.noMatch)}
          </Typography.Subtitle1>
        )}
        {nextMatch && (
          <>
            <Typography.Subtitle1>
              {moment(nextMatch.date).isSame(Date.now(), "day")
                ? "Today"
                : moment(nextMatch.date).format("dddd, DD-MM-YYYY")}
              &nbsp;at {nextMatch.time}
            </Typography.Subtitle1>

            <FormControlLabel
              control={
                <Switch
                  checked={nextMatchAttendanceConfirmed}
                  onChange={(event) => handleAttendMatch(event)}
                  color="secondary"
                />
              }
              label={intl.formatMessage(messages.confirmAttendance)}
            />
            <NextMatchCard match={nextMatch} />
          </>
        )}
      </>
    );
  }
);

export default PlayerEvents;
