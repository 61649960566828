import React from "react";

import {
  Grid,
  // makeStyles,
  Theme,
  // FormControl,
  // FormLabel,
  // FormControlLabel,
  // RadioGroup,
  // Radio,
  styled,
  // createStyles,
  Card,
  // Chip
} from "@material-ui/core";
import Typography from "../typography";
import Content from "../content";
import { IStyledArguments } from "../../types/styled-arguments";
// import Chip from "../chip";

const GridContainer = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
});

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     formControl: {
//       margin: theme.spacing(3),
//     },
//   })
// );

const Main = styled("main")<Theme>(({ theme }) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  background: theme.palette.common.white,
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  padding: 8,
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  height: "100%",
  display: "flex",
}));

const TableTab = ({ league }: any) => {
  // const classes = useStyles();
  // const [value, setValue] = useState("right");

  // const handleChange = (event: React.ChangeEvent<{}>) => {
  //   setValue((event.target as HTMLInputElement).value);
  // };

  return (
    <Main>
      <MainContent container>
        <GridContainer>
          <Grid item xs={12}>
            <Typography.H5>Information:</Typography.H5>
          </Grid>
          <Grid item xs={12}>
            <CardWrapper>
              <Grid item xs={6}>
                <Typography.H6>{league.name}</Typography.H6>
              </Grid>
              {/* <Grid item xs={6}>
                <Typography.H6>{player.player.surname}</Typography.H6>
              </Grid>
              <Grid item xs={8}>
                <Typography.Subtitle1>
                  {player.player.team.name}
                </Typography.Subtitle1>
              </Grid>

              <Grid item xs={12}>
                <Typography.Subtitle1>
                  {`Matches played: ${player.player.totalMatches}`}
                </Typography.Subtitle1>
              </Grid>
              <Grid item xs={4}>
                <Chip
                  size="small"
                  variant="default"
                  color="primary"
                  label={`${player.player.totalGoals || 0} Goals`}
                  onClick={() => null}
                />
              </Grid> */}
            </CardWrapper>
          </Grid>
        </GridContainer>
      </MainContent>
    </Main>
  );
};

export default TableTab;
