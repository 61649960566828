import React from "react";
import { injectIntl } from "react-intl";
import messages from "./Teams.intl";
import { styled, makeStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { Theme, Fab } from "@material-ui/core";
import Content from "../content";
import TeamList from "./TeamList";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Loader from "../loader";
import AddIcon from "@material-ui/icons/Add";
import Link from "../link";
import useIsManager from "../../hooks/useIsManager";

export const TEAMS_QUERY = gql`
  query GetTeams {
    allTeams {
      id
      name
      location
      colorHome
      colorAway
      foundationYear
      coach {
        id
        name
        nickName
      }
      squad {
        id
        name
        surname
        number
        nickName
        position
      }
      matches {
        id
        score {
          home
          away
        }
        isWon
        isTied
        isLost
      }
      league {
        id
        name
        location
        startDate
      }
    }
    currentUser {
      id
      role
    }
  }
`;

const FooterContainer = styled("div")<Theme>(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  height: 90,
  width: "auto",
  right: 0,
  position: "fixed",
  bottom: 70,
}));

const useLoaderStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: `calc(100vh - ${theme.layout.headerHeight -
      theme.layout.tabsHeight}px )`, // compensate for list filter bar height
  },
}));

const Main = styled("main")<Theme>(({ theme }) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  background: theme.palette.common.white,
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledActionLink = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    ...theme.typography.caption,
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    textTransform: "uppercase",
  };
});

const Teams = injectIntl(({ intl }) => {
  const { data, loading } = useQuery<any>(TEAMS_QUERY);
  const loaderClasses = useLoaderStyles({});
  return (
    <>
      <Main>
        <Loader
          className={loaderClasses.root}
          errorMessage={intl.formatMessage(messages.loadingError)}
          loading={loading}
          // error={error}
          data={data}
        >
          {({ allTeams, currentUser }) => (
            <MainContent container>
              <TeamList items={allTeams} />

              <FooterContainer>
                {useIsManager(currentUser.role) && (
                  <StyledActionLink to="/teams/newteam">
                    <Fab
                      aria-label="add team"
                      color="secondary"
                      style={{ zIndex: 1200 }}
                    >
                      <AddIcon />
                    </Fab>
                  </StyledActionLink>
                )}
              </FooterContainer>
            </MainContent>
          )}
        </Loader>
      </Main>
    </>
  );
});

export default Teams;
