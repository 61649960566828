import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { blueGrey } from "@material-ui/core/colors";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from "@material-ui/icons/Share";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
// import gql from "graphql-tag";
import { gql, useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: blueGrey[500],
  },
}));

const REMOVE_NOTIFICATION = gql`
  mutation RemoveNotification($id: ID!, $notificationId: ID!) {
    removeNotification(id: $id, notificationId: $notificationId) {
      op
      error
    }
  }
`;

const NotificationsCard = injectIntl<any>(
  ({ userId, notifications, callback }) => {
    const classes = useStyles();
    const [deleteNotification] = useMutation(REMOVE_NOTIFICATION);

    const handleReadNotificaton = async (notificationId: string) => {
      await deleteNotification({
        variables: {
          id: userId,
          notificationId: notificationId,
        },
      });
      callback();
    };
    return (
      <>
        {notifications &&
          notifications.map((not: any) => {
            if (!not.read) {
              return (
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        Co
                      </Avatar>
                    }
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={() => handleReadNotificaton(not.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    title="New notification"
                    subheader={not.timestamp}
                  />
                  <CardMedia
                    className={classes.media}
                    image="/images/notifications.jpg"
                    title="Paella dish"
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {not.message}
                    </Typography>
                  </CardContent>
                  {/* <CardActions disableSpacing>
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                  <IconButton aria-label="share">
                    <ShareIcon />
                  </IconButton>
                </CardActions> */}
                </Card>
              );
            } else {
              return <></>;
            }
          })}
      </>
    );
  }
);

export default NotificationsCard;
