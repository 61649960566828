import firebase from "firebase/app";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyCzxOenmDfsj7dlNZlfcsAvWU9-Fw3ypHc",
  authDomain: "footik-bff.firebaseapp.com",
  databaseURL: "https://footik-bff.firebaseio.com",
  projectId: "footik-bff",
  storageBucket: "footik-bff.appspot.com",
  messagingSenderId: "340496999320",
  appId: "1:340496999320:web:9fcfe713e6fb19c93481ed",
  measurementId: "G-HXJMJS3MWP",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//analytics is optional for this tutoral
// firebase.analytics();

const storage = firebase.storage();

export { storage, firebase as default };
