import { defineMessages } from "react-intl";

export default defineMessages({
  won: {
    id: "TeamSummaryCard.won",
    defaultMessage: "won"
  },
  drawn: {
    id: "TeamSummaryCard.drawn",
    defaultMessage: "drawn"
  },
  lost: {
    id: "TeamSummaryCard.lost",
    defaultMessage: "lost"
  },
  players: {
    id: "TeamSummaryCard.players",
    defaultMessage: "players"
  },
  totalMatches: {
    id: "TeamSummaryCard.totalMatches",
    defaultMessage: "Total matches"
  },
  league: {
    id: "TeamSummaryCard.league",
    defaultMessage: "League"
  },
  noLeague: {
    id: "TeamSummaryCard.noLeague",
    defaultMessage: "no league included yet"
  }
});
