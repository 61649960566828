import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
// import { IStyledArguments } from "../../types/styled-arguments";
import { Grid, Theme, Tabs, Tab } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { injectIntl } from "react-intl";
// import gql from "graphql-tag";
import { gql, useQuery } from "@apollo/client";
// import Loader from "../loader";
import messages from "./PageViewLeague.intl";

// import Breadcrumb from "./Breadcrumb";
// import Link from "../link";
import Content from "../content";
import TableTab from "./TableTab";
import TeamsTab from "./TeamsTab";

import LeagueCreation from "./LeagueCreation";

const LEAGUE_QUERY = gql`
  query leagueDetails($id: ID!) {
    league(id: $id) {
      id
      name
      location
      startDate
      endDate
      teams
      status
    }
    currentUser {
      id
      role
    }
  }
`;

// const useLoaderStyles = makeStyles(() => ({
//   // root: {
//   //   minHeight: `calc(100vh - ${theme.layout.headerHeight -
//   //     theme.layout.tabsHeight}px )` // compensate for list filter bar height
//   // }
//   root: {
//     // minHeight: `calc(100vh - ${theme.layout.headerHeight - 150}px )` // compensate for list filter bar height
//   }
// }));

// const Header = styled("div")(({ theme }: IStyledArguments) => ({
//   background: theme.palette.extra.lighterGrey,
//   display: "flex",
//   justifyContent: "space-between",
//   padding: theme.spacing(1, 1.5, 1, 1.5)
// }));

// const Container = styled(props => (
//   <Grid container {...props} direction="row" />
// ))({
//   flex: 1,
//   justifyContent: "space-between",
//   paddingBottom: 8
// });

// const Title = styled(Link)(({ theme }: IStyledArguments) => ({
//   ...theme.typography.h6,
//   marginBottom: 0
// }));

// const Main = styled("main")<Theme>(({ theme }) => ({
//   display: "block", // IE
//   // minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
//   background: theme.palette.common.white,
//   paddingBottom: "70px"
// }));

// const MainContent = styled(Content)<Theme>(({ theme }) => ({
//   padding: theme.spacing(4)
// }));

// const GridContainer = styled(props => (
//   <Grid container {...props} direction="row" />
// ))({
//   flex: 1,
//   justifyContent: "space-between"
// });

// type PagePlayerProps = Required<Pick<RouteComponentProps, "navigate" | "path">>;

const TabContainer = styled("div")<Theme>(({ theme }) => ({
  position: "relative",
  top: theme.layout.tabsHeight + 1,
  backgroundColor: theme.palette.extra.default,
  paddingBottom: `${theme.layout.footerHeight}px`,
}));

enum TabNames {
  table = "Table",
  teams = "Teams",
  matches = "Matches",
}
// const PATH_TO_TAB: { [path: string]: string } = {
//   "/leagues/players": TabNames.players,
//   "/people/teams": TabNames.teams,
//   "/people/matches": TabNames.matches
// };
// const TAB_TO_PATH: { [tabName: string]: string } = {
//   [TabNames.players]: "/people/players",
//   [TabNames.teams]: "/people/teams",
//   [TabNames.matches]: "/people/matches"
// };

const TabsWrapper = styled("div")<Theme>(({ theme }) => ({
  position: "fixed",
  width: "100%",
  // width: `calc(100% - ${theme.layout.navDrawerWidthClosed}px)`,
  top: "66px", //theme.layout.headerHeight,
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: "#fff",
  borderBottom: `1px solid ${theme.palette.extra.borderColor}`,
}));

type PagePeopleProps = Required<Pick<RouteComponentProps, "navigate" | "path">>;

const PageViewTeam = injectIntl<{ id: string } & PagePeopleProps>(
  ({ id, intl, navigate, path }) => {
    const PATH_TO_TAB: { [path: string]: string } = {
      "/leagues/:id/table": TabNames.table,
      "/leagues/:id/teams": TabNames.teams,
      "/leagues/:id/matches": TabNames.matches,
    };

    const TAB_TO_PATH: { [tabName: string]: string } = {
      [TabNames.table]: `/leagues/${id}/table`,
      [TabNames.teams]: `/leagues/${id}/teams`,
      [TabNames.matches]: `/leagues/${id}/matches`,
    };
    const currentTab = PATH_TO_TAB[path] || TabNames.table;
    const [league, setLeague] = useState<any>();

    const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
      navigate(TAB_TO_PATH[newValue], {
        replace: true,
      });
    };
    // ({ id, intl }) => {
    // const { data, loading, error } = useQuery(LEAGUE_QUERY, {
    const { data } = useQuery(LEAGUE_QUERY, {
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (data) {
        console.log("league", data.league);
        setLeague(data.league);
      }
    }, [data]);

    // const loaderClasses = useLoaderStyles({});

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentTab]);

    return (
      <>
        {league && league.status === "NOT_STARTED" && <LeagueCreation />}

        {/* <Loader
          className={loaderClasses.root}
          errorMessage={intl.formatMessage(messages.error)}
          loading={loading}
          error={error}
          data={data}
        > */}
        {/* {({ league }) => ( */}
        {league && league.status === "ON_GOING" && (
          <>
            <TabsWrapper>
              <Content alignItems="stretch">
                <Grid container>
                  <Grid item xs={12}>
                    <Tabs
                      centered
                      value={currentTab}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      scrollButtons="auto"
                    >
                      <Tab
                        label={intl.formatMessage(messages.TableTab)}
                        data-testid="players-tab"
                        value={TabNames.table}
                      />
                      <Tab
                        label={intl.formatMessage(messages.TeamsTab)}
                        data-testid="teams-tab"
                        value={TabNames.teams}
                      />
                      <Tab
                        label={intl.formatMessage(messages.MatchesTab)}
                        data-testid="matches-tab"
                        value={TabNames.matches}
                      />
                    </Tabs>
                  </Grid>
                </Grid>
              </Content>
            </TabsWrapper>
            <TabContainer>
              {currentTab === TabNames.table && <TableTab league={league} />}
              {currentTab === TabNames.teams && <TeamsTab league={league} />}
            </TabContainer>
          </>
        )}
        {/* </Loader> */}
      </>
    );
  }
);

export default PageViewTeam;
