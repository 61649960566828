import { defineMessages } from "react-intl";

export default defineMessages({
  details: {
    id: "PageViewScouting.details",
    defaultMessage: "Details",
  },
  noPlayers: {
    id: "PageViewScouting.noPlayers",
    defaultMessage: "No players in your following list yet",
  },
  noPlayers_add: {
    id: "PageViewScouting.noPlayers_add",
    defaultMessage: "You can start adding players",
  },
  noPlayers_here: {
    id: "PageViewScouting.noPlayers_here",
    defaultMessage: "here",
  },
});
