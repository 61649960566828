import { styled } from "@material-ui/styles";
import Link from "../link";
import { IStyledArguments } from "../../types/styled-arguments";

const ActionLink = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    ...theme.typography.caption,
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    textTransform: "uppercase"
  };
});

export default ActionLink;
