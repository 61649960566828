import React from "react";
import RegisterForm from "./register-form";
import { IFormValues } from "./register-form/RegisterForm";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  checkValid: boolean;
  userDetails: any;
  registrationDetails: any;
  newUser: boolean;
}

const StepInitial = ({
  onGoNext,
  userDetails,
  checkValid,
  registrationDetails,
  newUser,
}: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    onGoNext(formValues);
  };

  return (
    <RegisterForm
      onSubmit={handleSubmit}
      checkValid={checkValid}
      newUser={newUser}
      registrationDetails={registrationDetails}
      {...userDetails}
    />
  );
};

export default StepInitial;
