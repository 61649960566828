import { defineMessages } from "react-intl";

export default defineMessages({
  description: {
    id: "Exercises.description",
    defaultMessage: "Description",
  },
  task: {
    id: "Exercises.task",
    defaultMessage: "Task",
  },
  details: {
    id: "Exercises.details",
    defaultMessage: "Details",
  },
  addToTraining: {
    id: "Exercises.addToTraining",
    defaultMessage: "Select",
  },
  removeFromTraining: {
    id: "Exercises.removeFromTraining",
    defaultMessage: "Remove",
  },
  noResults: {
    id: "Exercises.noResults",
    defaultMessage: "No Exercises, create the first one.",
  },
});
