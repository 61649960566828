import React, { useState, useEffect } from "react";
// import { gql } from "@apollo/client";
import { RouteComponentProps, Redirect } from "@reach/router";
import LoginForm from "./login-form";
import { ILoginFormValues } from "./login-form/LoginForm";
import { actions } from "../auth-context-provider";
import useAuthContext from "../../hooks/useAuthContext";
import Snackbar from "../snackbar";
import { gql, useMutation } from "@apollo/client";
import messages from "./login-form/LoginForm.intl";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { UnauthenticatedLayout } from "../unauthenticated-layout-elements";
import Cookies from "universal-cookie";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const PageLogin = injectIntl(
  (props: RouteComponentProps & InjectedIntlProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [state, dispatch] = useAuthContext();
    /* istanbul ignore next */
    const handleClose = () => {
      setOpenErrorMessage(false);
    };

    let hasLoginError = false;
    const [login, { error, data, loading }] = useMutation(LOGIN_MUTATION);

    useEffect(() => {
      if (error) {
        setOpenErrorMessage(true);
      }
    }, [error]);

    useEffect(() => {
      if (loading) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [loading]);

    hasLoginError = !!error;

    useEffect(() => {
      if (!state.token && data && data.login) {
        console.log("data.login");
        console.log(data.login);

        const cookies = new Cookies();
        cookies.set("hasLogged", Date.now(), { path: "/" });
        console.log("login: ", cookies.get("hasLogged"));
        dispatch(actions.setToken(data.login));
      }
    }, [state.token, data && data.login]);

    if (state.isLoggedIn) {
      console.log("LOGGED IN___________________");
      return <Redirect to="/dashboard" noThrow />;
    }

    return (
      <>
        <Backdrop
          className={classes.backdrop}
          open={open}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={openErrorMessage}
          message={props.intl.formatMessage(messages.loginError)}
          variant="error"
          onClose={handleClose}
        />
        <UnauthenticatedLayout>
          <LoginForm
            onSubmit={({ email, password }: ILoginFormValues) =>
              login({
                variables: {
                  email: email.toLowerCase().trim(),
                  password,
                },
              }).catch(() => null)
            }
            hasLoginError={hasLoginError}
          />
        </UnauthenticatedLayout>
      </>
    );
  }
);

export default PageLogin;
