import { defineMessages } from "react-intl";

export default defineMessages({
  addToTeamTitle: {
    id: "PageNewExercise.addToTeamTitle",
    defaultMessage: "(Optional) Add player to team",
  },
  save: {
    id: "PageNewExercise.save",
    defaultMessage: "Save",
  },
  skip: {
    id: "PageNewExercise.skip",
    defaultMessage: "Skip",
  },
  email: {
    id: "PageNewExercise.email",
    defaultMessage: "Email",
  },
  name: {
    id: "PageNewExercise.Name",
    defaultMessage: "Name",
  },
  shortDescription: {
    id: "PageNewExercise.shortDescription",
    defaultMessage: "Short description",
  },
  nickname: {
    id: "PageNewExercise.Nickname",
    defaultMessage: "Nickname",
  },
  number: {
    id: "PageNewExercise.Number",
    defaultMessage: "Number",
  },
  dateOfBirth: {
    id: "PageNewExercise.DateOfBirth",
    defaultMessage: "Date of birth (yyyy-mm-dd)",
  },
  password: {
    id: "PageNewExercise.Password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "PageNewExercise.ConfirmPassword",
    defaultMessage: "Confirm password",
  },
  passwordError: {
    id: "PageNewExercise.PasswordError",
    defaultMessage: "Password requirements not fulfilled",
  },
  passwordNotMatch: {
    id: "PageNewExercise.PasswordNotMatch",
    defaultMessage: "Passwords do not match",
  },
  requiredFieldError: {
    id: "PageNewExercise.RequiredFieldError",
    defaultMessage: "Required field",
  },
  newExercise: {
    id: "PageNewExercise.newExercise",
    defaultMessage: "New exercise",
  },
  loginLinkMessage: {
    id: "PageNewExercise.loginLink",
    defaultMessage: "Already have an account?",
  },
  login: {
    id: "PageNewExercise.login",
    defaultMessage: "Sign in",
  },
  passwordHintTitle: {
    id: "PageNewExercise.passwordHintTitle",
    defaultMessage: "Password field",
  },
  passwordHintMustBe: {
    id: "PageNewExercise.passwordHintMustBe",
    defaultMessage: "Your password must:",
  },
  passwordHint8Characters: {
    id: "PageNewExercise.passwordHint8Characters",
    defaultMessage: "Be at least 8 characters long",
  },
  passwordHint1Uppercase: {
    id: "PageNewExercise.passwordHint1Uppercase",
    defaultMessage: "Contain at least 1 upper case character",
  },
  passwordHint1Lowercase: {
    id: "PageNewExercise.passwordHint1Lowercase",
    defaultMessage: "Contain at least 1 lower case character",
  },
  passwordHint1Number: {
    id: "PageNewExercise.passwordHint1Number",
    defaultMessage: "Contain at least 1 number",
  },
  passwordHint1SpecialCharacter: {
    id: "PageNewExercise.passwordHint1SpecialCharacter",
    defaultMessage: "Contain at least 1 special character",
  },
  passwordHintNotSameEmail: {
    id: "PageNewExercise.passwordHintNotSameEmail",
    defaultMessage: "Not be the same as your email address",
  },
  next: {
    id: "PageNewExercise.next",
    defaultMessage: "Next",
  },
  category: {
    id: "PageNewExercise.category",
    defaultMessage: "Category",
  },
  subcategory: {
    id: "PageNewExercise.subcategory",
    defaultMessage: "Subcategory",
  },
  team: {
    id: "PageNewExercise.team",
    defaultMessage: "team",
  },
});
