import { defineMessages } from "react-intl";

export default defineMessages({
  nextTrainings: {
    id: "CalendarComponent.nextTrainings",
    defaultMessage: "Next trainings",
  },
  noTraining: {
    id: "CalendarComponent.noTraining",
    defaultMessage: "No training set",
  },
  today: {
    id: "CalendarComponent.today",
    defaultMessage: "Today",
  },
  attendance: {
    id: "CalendarComponent.attendance",
    defaultMessage: "Attendance",
  },
  nextMatch: {
    id: "CalendarComponent.nextMatch",
    defaultMessage: "Next match",
  },
  noMatch: {
    id: "CalendarComponent.noMatch",
    defaultMessage: "No match set yet",
  },
  at: {
    id: "CalendarComponent.at",
    defaultMessage: "at",
  },
  mondayShort: {
    id: "CalendarComponent.mondayShort",
    defaultMessage: "Mo",
  },
  tuesdayShort: {
    id: "CalendarComponent.tuesdayShort",
    defaultMessage: "Tu",
  },
  wednesdayShort: {
    id: "CalendarComponent.wednesdayShort",
    defaultMessage: "Wd",
  },
  thursdayShort: {
    id: "CalendarComponent.thursdayShort",
    defaultMessage: "Th",
  },
  fridayShort: {
    id: "CalendarComponent.fridayShort",
    defaultMessage: "Fr",
  },
  saturdayShort: {
    id: "CalendarComponent.saturdayShort",
    defaultMessage: "St",
  },
  sundayShort: {
    id: "CalendarComponent.sundayShort",
    defaultMessage: "Su",
  },
  legendMatches: {
    id: "CalendarComponent.legendMatches",
    defaultMessage: "Matches",
  },
  legendTrainings: {
    id: "CalendarComponent.legendTrainings",
    defaultMessage: "Trainings",
  },
  legendToday: {
    id: "CalendarComponent.legendToday",
    defaultMessage: "Today",
  },
  confirmAttendance: {
    id: "CalendarComponent.confirmAttendance",
    defaultMessage: "Confirm attendance",
  },
  location: {
    id: "CalendarComponent.location",
    defaultMessage: "Location",
  },
});
