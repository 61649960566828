import React, { useEffect, useState } from "react";

import { styled } from "@material-ui/styles";

import { IStyledArguments } from "../../types/styled-arguments";
// import BallIcon from "@material-ui/icons/SportsSoccer";
// import {
//   YellowCardIcon,
//   RedCardIcon,
//   //   GoalIcon,
//   //   OwnGoalBadge,
//   //   ButtonRow,
//   //   AwayTeamButton,
// } from "./StyledElements";
import { IPlayer } from "../../types";

const Header = styled("div")({
  color: "#005473",
  fontSize: "x-large",
  fontWeight: 700,
  textAlign: "center",
  position: "absolute",
  top: 16,
  display: "flex",
  flexWrap: "wrap",
});

// const MatchInfo = styled("div")({
//   color: "white",
//   fontSize: "x-small",
//   fontWeight: 700,
//   // textAlign: "center",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   display: "flex",
//   flexWrap: "wrap",
//   paddingLeft: 8,
//   paddingBottom: 8,
// });

const OpacityLayer = styled("div")({
  backgroundColor: "#ffffff",
  opacity: 0.6,
  filter: "alpha(opacity=60)",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
});

const ScoreBox = styled("div")({
  backgroundImage: "url('/images/stadium.jpg')",
  // backgroundColor: "red",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "260px",
  backgroundRepeat: "no-repeat",
  color: "white",
  top: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: 8,
  position: "relative",
  marginBottom: 16,
});

const ScoreWrapper = styled("div")({
  margin: "16px",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  position: "absolute",
  bottom: 12,
  left: "calc(50% - 85px)",
});

const LocalScore = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    width: "60px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
    textAlign: "center",
    fontWeight: 700,
  };
});

const AwayScore = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    width: "60px",
    height: "90px",
    display: "inline",
    padding: "9px",
    color: "white",
    textAlign: "center",
    fontWeight: 700,
  };
});

const VSBox = styled("div")(({ theme }: IStyledArguments) => {
  return {
    fontSize: "xxx-large",
    borderRadius: "8px",
    height: "90px",
    display: "inline",
    color: theme.palette.primary.dark,
    fontWeight: 700,
    margin: "3px",
  };
});
// const AwayTeamControls = styled("div")(({ theme }: IStyledArguments) => {
//   return {
//     display: "flex",
//     flexDirection: "row",
//     margin: "16px",
//     border: `1px solid ${theme.palette.extra.borderColor}`,
//     borderRadius: "8px",
//     padding: "16px",
//     alignItems: "baseline",
//     justifyContent: "space-between",
//     flexWrap: "wrap",
//   };
// });
// const ScorerListHome = styled("div")({
//   color: "blueviolet",
//   fontSize: "x-small",
//   fontWeight: 700,
//   position: "absolute",
//   bottom: 24,
//   left: 0,
//   display: "flex",
//   flexWrap: "wrap",
//   paddingLeft: 8,
//   paddingBottom: 8,
//   maxWidth: "100px",
//   alignItems: "center",
// });
// const ScorerListAway = styled("div")({
//   color: "blueviolet",
//   fontSize: "x-small",
//   fontWeight: 700,
//   position: "absolute",
//   bottom: 24,
//   right: 0,
//   display: "flex",
//   flexWrap: "wrap",
//   paddingLeft: 8,
//   paddingBottom: 8,
//   maxWidth: "100px",
//   alignItems: "center",
// });
// const AdmonishedAway = styled("div")({
//   color: "blueviolet",
//   fontSize: "x-small",
//   fontWeight: 700,
//   position: "absolute",
//   bottom: 24,
//   left: 0,
//   display: "flex",
//   flexWrap: "wrap",
//   paddingLeft: 8,
//   paddingBottom: 8,
//   maxWidth: "100px",
//   alignItems: "center",
// });
// const AdmonishedHome = styled("div")({
//   color: "blueviolet",
//   fontSize: "x-small",
//   fontWeight: 700,
//   position: "absolute",
//   bottom: 24,
//   right: 0,
//   display: "flex",
//   flexWrap: "wrap",
//   paddingLeft: 8,
//   paddingBottom: 8,
//   maxWidth: "100px",
//   alignItems: "center",
// });

const Score = ({ match }: any) => {
  const [players, setPlayers] = useState<any>();

  useEffect(() => {
    if (match && match.playersConfirmed) {
      let playersOpen: { [index: string]: boolean } = {};
      let players: { [index: string]: IPlayer } = {};
      match.playersConfirmed.forEach((player: IPlayer) => {
        playersOpen[player.id] = false;
        players[player.id] = player;
      });
      setPlayers(players);
    }
  }, [match]);

  return (
    <ScoreBox>
      <OpacityLayer />
      <Header>
        {match.isHomeTeamLocal ? (
          <>{match.homeTeam.name}</>
        ) : (
          <>{match.awayTeam}</>
        )}

        {" - "}
        {match.isHomeTeamLocal ? (
          <>{match.awayTeam}</>
        ) : (
          <>{match.homeTeam.name}</>
        )}
      </Header>
      {/* <MatchInfo>
        <img src="/images/match/soccer-field.png" width="16" height="16" />
        <div style={{ marginLeft: "8px" }}>1st half</div>
      </MatchInfo> */}
      {/* {scorerList && Object.keys(scorerList).length > 0 && ( */}
      {players && (
        <>
          {match.isHomeTeamLocal ? (
            <>
              {/* <ScorerListHome>
                {match.scorerList &&
                  match.scorerList.map((scorer: any) => {
                    // console.log("[SCORER] index->", index);
                    // const scorer = match.scorerList[index];
                    console.log("[SCORER] scorer->", scorer);
                    console.log("[SCORER] scorer player->", scorer.player);
                    console.log(
                      "[SCORER] scorer player name->",
                      scorer.player && scorer.player.name
                    );
                    console.log("[SCORER] players ->", players);
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "center",
                        }}
                      >
                        <BallIcon />
                        {scorer.player && scorer.player.name}&nbsp;(
                        {scorer.value})
                      </div>
                    );
                  })}
              </ScorerListHome> */}
              {/* <AdmonishedHome>
              {Object.keys(yellows).map((scorer: any) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <YellowCardIcon />
                  {players[scorer].name}
                </div>
              ))}
              {Object.keys(reds).map((scorer: any) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <RedCardIcon />
                  {players[scorer].name}
                </div>
              ))}
            </AdmonishedHome> */}
            </>
          ) : (
            <>
              {/* <ScorerListAway>
                {match.scorerList &&
                  match.scorerList.map((scorer: any) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <BallIcon />
                      {players[scorer].name}
                    </div>
                  ))}
              </ScorerListAway> */}
              {/* <AdmonishedAway>
              {Object.keys(yellows).map((scorer: any) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <YellowCardIcon />
                  {players[scorer].name}
                </div>
              ))}
              {Object.keys(reds).map((scorer: any) => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <RedCardIcon />
                  {players[scorer].name}
                </div>
              ))}
            </AdmonishedAway> */}
            </>
          )}
        </>
      )}
      <ScoreWrapper>
        <LocalScore>
          {match.isHomeTeamLocal ? (
            <>{match.score.home}</>
          ) : (
            <>{match.score.away}</>
          )}
        </LocalScore>
        <VSBox>{"-"}</VSBox>
        <AwayScore>
          {match.isHomeTeamLocal ? (
            <>{match.score.away}</>
          ) : (
            <>{match.score.home}</>
          )}
        </AwayScore>
      </ScoreWrapper>
    </ScoreBox>
  );
};

export default Score;
