import { defineMessages } from 'react-intl';

export default defineMessages({
  showPassword: {
    id: 'PasswordInput.ShowPassword',
    defaultMessage: 'Show password',
  },
  hidePassword: {
    id: 'PasswordInput.HidePassword',
    defaultMessage: 'Hide password',
  },
  togglePasswordVisibilityDescription: {
    id: 'PasswordInput.TogglePasswordVisibilityDescription',
    defaultMessage: 'Toggle password visibility',
  },
});
