import React from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { Card, Fab } from "@material-ui/core";
import Typography from "../typography";
import Link from "../link";
import IconErrorOutline from "@material-ui/icons/ErrorOutline";
import IconRightChevron from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import messages from "./DashboardCoachInfoCard.intl";
import { injectIntl } from "react-intl";
import { IUser } from "../../types";
import UserAvatar from "../user-avatar";
import EditIcon from "@material-ui/icons/EditOutlined";

const StyledCard = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
}));

const StyledHeader = styled("div")(({ theme }: IStyledArguments) => {
  return {
    // backgroundColor: "red", //theme.palette.extra.grey,
    backgroundImage: "url(/images/back3.png)",
    backgroundAttachment: "initial",
    backgroundSize: "cover",
    textAlign: "center",
    padding: theme.spacing(2.5),
    position: "relative",
  };
});

const StyledBody = styled("div")(({ theme }: IStyledArguments) => {
  return {
    backgroundColor: theme.palette.extra.backgroundGrey,
    padding: "25px 5px",
  };
});

const StyledExpireDate = styled(Typography.Caption)(
  ({ theme }: IStyledArguments) => {
    return {
      display: "block",
      marginTop: theme.spacing(2.5),
    };
  }
);

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  // padding: theme.spacing(1, 1.5, 1, 1.5)
}));

const StyledName = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    fontSize: 23,
    lineHeight: 1.39,
    fontWeight: 100,
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.alternateFontFamily,
    verticalAlign: "bottom",
    marginRight: "-20px",
  };
});

const StyledSeatsRemaining = styled(Typography.Caption)({
  display: "block",
  textAlign: "center",
});

const StyledInfoIcon = styled((props) => <IconErrorOutline {...props} />)({
  fontSize: 14,
  lineHeight: 1.4,
  verticalAlign: "middle",
});

const StyledDataPointsContainer = styled("div")({
  marginTop: 20,
  display: "flex",
  justifyContent: "space-between",
});

const StyledDataPoint = styled("div")({
  display: "block",
  minWidth: 100,
  textAlign: "center",
});

const StyledDataPointValue = styled("div")({
  fontSize: 46,
  fontWeight: 700,
});

const StyledDataPointText = styled(Typography.Caption)(
  ({ theme }: IStyledArguments) => {
    return {
      ...theme.typography.caption,
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
      display: "block",
      marginTop: -8,
      fontWeight: 100,
    };
  }
);

const StyledLinearProgress = styled((props) => (
  <LinearProgress color="secondary" variant="determinate" {...props} />
))({});

const StyledLink = styled(Link)(({ theme }: IStyledArguments) => {
  return {
    color: theme.palette.primary.dark,
  };
});

interface IDataPoint {
  value: number;
  text: string;
  url: string;
  // disabled?: boolean;
}

const DataPoint = ({ value, text, url }: IDataPoint) => {
  return (
    <StyledDataPoint>
      <StyledLink to={url}>
        <StyledDataPointValue>{value}</StyledDataPointValue>
        <StyledDataPointText>{text}</StyledDataPointText>
      </StyledLink>
    </StyledDataPoint>
  );
};

interface ISubscriptionInfoCardProps {
  user: IUser;
  players: number;
  teams: number;
  matches: number;
}

const DashboardPlayerInfoCard = injectIntl<ISubscriptionInfoCardProps>(
  ({ user, players, matches, teams, intl }) => {
    return (
      <StyledCard>
        <StyledHeader>
          <Typography.Overline
            gutterBottom={false}
            color="textSecondary"
            data-testid="subscription-name"
            style={{ fontWeight: 100 }}
          >
            {user.role}
          </Typography.Overline>
          <StyledName to="/profile">
            {user.name} {user.surname}
            <IconRightChevron
              color="secondary"
              fontSize="large"
              style={{ verticalAlign: "middle" }}
            />
          </StyledName>
          <Centered>
            <UserAvatar
              style={{ width: 80, height: 80, fontSize: "3rem" }}
              user={{
                name: user.name,
                surname: user.surname,
                avatarUrl: user.avatarUrl,
              }}
            ></UserAvatar>
            {false && (
              <Fab
                style={{
                  position: "absolute",
                  left: "calc(50% + 18px)",
                  top: "139px",
                }}
                variant="round"
                size="small"
                color="inherit"
                aria-label="add"
              >
                <EditIcon />
              </Fab>
            )}
          </Centered>
          <StyledExpireDate>
            {/* <FormattedMessage
              {...messages.expiresInDays}
              values={{ daysToExpire: daysToExpire < 0 ? 0 : daysToExpire }}
            />{" "} */}
            {/* {player.dateOfBirth} */}
            {/* {`Birthday: ${intl.formatDate(
              new Date(Number(player.dateOfBirth))
            )}`} */}
            {user.email}
          </StyledExpireDate>
        </StyledHeader>
        <StyledLinearProgress value={100} />
        <StyledBody>
          <StyledSeatsRemaining>
            <StyledInfoIcon />{" "}
            {/* <FormattedMessage
              {...messages.seatsRemaining}
              values={{ seatsRemaining }}
            />{" "} */}
            {/* you don't have any notification */}
            {intl.formatMessage(messages.noNotifications)}
          </StyledSeatsRemaining>
          <StyledDataPointsContainer>
            <DataPoint
              value={teams || 0}
              text={intl.formatMessage(messages.teamsLabel, {
                n: teams || 0,
              })}
              url="/people/teams"
            />
            <DataPoint
              value={matches || 0}
              text={intl.formatMessage(messages.matchesLabel, {
                n: matches || 0,
              })}
              url="/people/matches"
            />
            <DataPoint
              value={players || 0}
              text={intl.formatMessage(messages.playersLabel, {
                n: players || 0,
              })}
              url="/people/players"
            />
          </StyledDataPointsContainer>
        </StyledBody>
      </StyledCard>
    );
  }
);

export default DashboardPlayerInfoCard;
