import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
// import { injectIntl } from "react-intl";
import messages from "./PageViewTraining.intl";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  paragraph: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  header: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#f5f5f5",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#f5f5f5",
  },
  notes: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#f5f5f5",
  },
  section: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 10,
    flexGrow: 1,
    borderLeft: "3px solid #ccc",
    padding: "16px",
  },
  sectionHeader: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    flexGrow: 1,
    borderLeft: "3px solid #ccc",
    padding: "16px",
  },
  sectionFooter: {
    margin: 10,
    flexGrow: 1,
  },
  semiSection: {
    marginLeft: 40,
    marginTop: 10,
    flexGrow: 1,
    borderLeft: "3px solid #ccc",
    padding: "16px",
  },
  imageLogo: {
    width: 120,
    height: 120,
    marginLeft: 40,
  },
  imageFooter: {
    marginTop: 10,
    width: 40,
    height: 40,
    marginLeft: 40,
  },
  imagePitch: {
    marginTop: 20,
    width: 200,
    height: 110,
  },
  imageExercise: {
    marginTop: 20,
    width: 200,
    height: 150,
    marginLeft: 20,
  },
  notesBox: {
    marginTop: 20,
    width: 225,
    height: 110,
    backgroundColor: "#f5f5f5",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    fontFamily: "Oswald",
    marginRight: 10,
  },
  titleFooter: {
    fontSize: 12,
    fontFamily: "Oswald",
    marginRight: 10,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 10,
    fontFamily: "Oswald",
  },
  text: {
    // margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Oswald",
    color: "grey",
  },
  textHeader: {
    // margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Oswald",
    fontWeight: "bold",
    marginRight: 8,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const PDFComponent = ({ training, userName, intl }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.imageLogo} src="/images/Footik.png" />

          <View style={styles.sectionHeader}>
            <Text style={styles.title}>{training.name}</Text>
            <Text style={styles.title}>
              {intl.formatMessage(messages.duration)} {">"}{" "}
              {training.trainingDuration} min
            </Text>

            <View style={styles.paragraph}>
              <Text style={styles.textHeader}>
                {intl.formatMessage(messages.createdBy)} {">"}
              </Text>
              <Text style={styles.text}>{userName}</Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.textHeader}>
                {intl.formatMessage(messages.date)} {">"}{" "}
              </Text>
              <Text style={styles.text}>{moment().format("DD-MM-YYYY")}</Text>
            </View>
          </View>
        </View>

        {training &&
          training.sections &&
          training.sections.map((section: any) => (
            <>
              {section.exercises &&
                section.exercises.map((exercise: any) => (
                  <>
                    <View style={styles.section} wrap={false}>
                      <Text style={styles.title}>
                        {intl.formatMessage(messages.section)} {section.name} (
                        {section.sectionDuration} min)
                      </Text>

                      <View style={styles.paragraph}>
                        <Text style={styles.textHeader}>
                          {intl.formatMessage(messages.exercise)} {">"}
                        </Text>
                        <Text style={styles.text}>{exercise.name}</Text>
                      </View>
                      <View style={styles.paragraph}>
                        <Text style={styles.textHeader}>
                          {intl.formatMessage(messages.description)} {">"}
                        </Text>
                        <Text style={styles.text}>
                          {exercise.shortDescription}
                        </Text>
                      </View>

                      <Text style={styles.textHeader}>
                        {intl.formatMessage(messages.task)} {">"}
                      </Text>
                      <Text style={styles.text}>
                        {exercise.taskDevelopment}
                      </Text>
                    </View>
                    <View style={styles.section}>
                      {exercise.images &&
                        exercise.images.map((url: any) => {
                          return (
                            <Image style={styles.imageExercise} src={url} />
                          );
                        })}
                    </View>
                    <View style={styles.paragraph} wrap={false}>
                      <View style={styles.semiSection}>
                        <Text style={styles.title}>
                          {intl.formatMessage(messages.tactics)} {">"}
                        </Text>
                        <Image
                          style={styles.imagePitch}
                          src="/images/footballPitch.jpg"
                        />
                      </View>
                      <View style={styles.semiSection}>
                        <Text style={styles.title}>
                          {intl.formatMessage(messages.notes)} {">"}
                        </Text>
                        <Text style={styles.notesBox}> </Text>
                      </View>
                    </View>
                    <Text break></Text>
                  </>
                ))}
            </>
          ))}
        <View style={styles.footer} fixed>
          <Image style={styles.imageFooter} src="/images/Footik.png" />
          <View style={styles.sectionFooter}>
            <Text style={styles.titleFooter}>{training.name}</Text>
            <Text style={styles.text}>{userName}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFComponent;
