import React from "react";
import NewTrainingForm from "./new-training-form";
import { IFormValues } from "./new-training-form/NewTrainingForm";

export interface IStepInitialProps {
  onGoNext: (values: any) => void;
  // exerciseDetails: any;
}

const StepInitial = ({ onGoNext }: IStepInitialProps) => {
  const handleSubmit = (formValues: IFormValues) => {
    console.log("!!!!!!!!!!!!!");
    console.log(formValues);
    onGoNext(formValues);
  };
  return (
    <NewTrainingForm
      // style={{ backgroundColor: "rgb(255,255,255, 0.9)" }}
      onSubmit={handleSubmit}
    />
  );
};

export default StepInitial;
