import "date-fns";
import React from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
import { Card, Grid } from "@material-ui/core";

import { injectIntl } from "react-intl";
import messages from "./PageViewPlayer.intl";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
  textAlign: "left",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  padding: theme.spacing(2),
}));

const Title = styled("div")(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

const ContactDetailsCard = injectIntl<any>(({ user, intl }) => {
  return (
    <>
      <CardWrapper style={{ marginBottom: 16 }}>
        <Header>
          <Row>
            <Grid item xs={12}>
              {user && (
                <Title>
                  {intl.formatMessage(messages.contactDetails)}
                  {">"}
                </Title>
              )}
            </Grid>
          </Row>
        </Header>
        <Content>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography.Subtitle1>email</Typography.Subtitle1>
            <Typography.Subtitle2>{user.email}</Typography.Subtitle2>
          </div>
        </Content>
      </CardWrapper>
    </>
  );
});

export default ContactDetailsCard;
