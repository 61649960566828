import React, { SyntheticEvent } from "react";
// import AdminsIcon from "@material-ui/icons/PeopleOutline";
import AccountIcon from "@material-ui/icons/PersonOutline";
import LogoutIcon from "@material-ui/icons/ExitToApp";
// import SubscriptionIcon from "@material-ui/icons/Restore";
import { styled } from "@material-ui/styles";
import { MenuItem, Menu, Theme, makeStyles } from "@material-ui/core";
import { IStyledArguments } from "../../../types/styled-arguments";
import { actions } from "../../auth-context-provider";
import useAuthContext from "../../../hooks/useAuthContext";
import { IUser } from "../../../types";
// import SubscriptionManagersList from "./SubscriptionManagersList";
import messages from "./UserMenu.intl";
import { FormattedMessage } from "react-intl";
import UserAvatar from "../../user-avatar";
// import { Permission } from "../../auth-context-provider";
// import ProgrammeAdminsList from "./ProgrammeAdminsList";
import MyProfileDetails from "./MyProfileDetails";
import useLocaleContext from "../../../hooks/useLocaleContext";
import LanguageSwitcher from "../../language-switcher";

const Avatar = styled(UserAvatar)(({ theme }: IStyledArguments) => ({
  margin: theme.spacing(1),
  cursor: "pointer",
}));

const MenuItemText = styled("div")({
  minWidth: 170,
  marginRight: 10,
});

const useMenuStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(3),
  },
}));

type ICurrentDrawerState =
  | null
  | "my-profile-details"
  | "subscription-managers"
  | "programme-admins";

const UserMenu = () => {
  const menuStyles = useMenuStyles({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentDrawer, setCurrentDrawer] = React.useState<ICurrentDrawerState>(
    null
  );
  // const [state, dispatch, utils] = useAuthContext();
  const [state, dispatch] = useAuthContext();
  const [currentLocale, saveLocale] = useLocaleContext();

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget as any);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const canViewSubscriptionManagers = utils.hasPermission(
  //   Permission.SUBSCRIPTION_MANAGERS_VIEW
  // );

  // const canAddProgrammeAdmins = utils.hasPermission(
  //   Permission.PROGRAMMES_MANAGE_ADMINS
  // );

  return (
    <div>
      <Avatar
        user={state.user as IUser}
        data-testid="user-icon"
        role="button"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        keepMounted
        classes={menuStyles}
        id="user-menu"
        data-testid="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setCurrentDrawer("my-profile-details");
            handleClose();
          }}
          data-testid="user-menu-my-profile-details"
        >
          <MenuItemText>
            <FormattedMessage {...messages.myProfileDetails} />
          </MenuItemText>
          <AccountIcon />
        </MenuItem>
        <MenuItem>
          <LanguageSwitcher
            locale={currentLocale}
            onChange={saveLocale}
            locales={[
              { id: "en", label: "English (UK)" },
              { id: "en-GB", label: "Español (ES)" },
              // { id: "es_ES", label: "Spanish (ES)" }
            ]}
          />
        </MenuItem>
        {/* <MenuItem onClick={handleClose} data-testid="user-menu-my-account">
          <MenuItemText>
            <FormattedMessage {...messages.myAccount} />
          </MenuItemText>
          <SubscriptionIcon />
        </MenuItem> */}
        {/* {canViewSubscriptionManagers && (
          <MenuItem
            onClick={() => {
              setCurrentDrawer("subscription-managers");
              handleClose();
            }}
            data-testid="user-menu-subscription-managers"
          >
            <MenuItemText>
              <FormattedMessage {...messages.subscriptionManagers} />
            </MenuItemText>
            <AdminsIcon />
          </MenuItem>
        )} */}
        {/* {canAddProgrammeAdmins && (
          <MenuItem
            onClick={() => {
              setCurrentDrawer("programme-admins");
              handleClose();
            }}
            data-testid="user-menu-programme-admins"
          >
            <MenuItemText>
              <FormattedMessage {...messages.programmeAdmins} />
            </MenuItemText>
            <AdminsIcon />
          </MenuItem>
        )} */}
        <MenuItem
          onClick={() => dispatch(actions.logout())}
          data-testid="user-menu-logout"
        >
          <MenuItemText>
            <FormattedMessage {...messages.logout} />
          </MenuItemText>
          <LogoutIcon />
        </MenuItem>
      </Menu>
      <MyProfileDetails
        open={currentDrawer === "my-profile-details"}
        onClose={() => setCurrentDrawer(null)}
      />
      {/* {canViewSubscriptionManagers && (
        <SubscriptionManagersList
          open={currentDrawer === "subscription-managers"}
          onClose={() => setCurrentDrawer(null)}
        />
      )} */}
      {/* {canAddProgrammeAdmins && (
        <ProgrammeAdminsList
          open={currentDrawer === "programme-admins"}
          onClose={() => setCurrentDrawer(null)}
        />
      )} */}
    </div>
  );
};

export default UserMenu;
