import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import {
  UnauthenticatedLayout,
  Title,
} from "../unauthenticated-layout-elements";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./PageResetPassword.intl";
import ResetPasswordForm from "./reset-password-form";
import { useMutation } from "@apollo/client";
import SnackBar from "../snackbar";
// import useAuthContext from "../../hooks/useAuthContext";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { ActionLink } from "../atoms";
import { Button } from "@material-ui/core";

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $token: String!) {
    updatePassword(password: $password, token: $token) {
      reset
      error
    }
  }
`;

const ButtonRow = styled("div")(({ theme }: IStyledArguments) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
}));

const PageResetPassword = injectIntl<RouteComponentProps<{ token: string }>>(
  ({ token, intl }) => {
    // const [state] = useAuthContext();
    const [updatePasswordCall, { error, data }] = useMutation(UPDATE_PASSWORD);
    const [email, setEmail] = useState<string>("");
    const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    // if (state.isLoggedIn || data) {
    //   return <Redirect to="/login" noThrow />;
    // }

    useEffect(() => {
      console.log("token");
      console.log(token);
      if (token) {
        console.log(atob(token));
        setEmail(JSON.parse(atob(token)).email || "");
      }
    }, [token]);

    useEffect(() => {
      if (data) {
        if (data.updatePassword && data.updatePassword.error !== "") {
          setErrorMessage(data.updatePassword.error);
        }
        if (data && data.updatePassword && data.updatePassword.reset) {
          setPasswordUpdated(data.updatePassword.reset);
        }
      }
    }, [data]);

    return (
      <>
        <UnauthenticatedLayout>
          <Title>
            <FormattedMessage {...messages.createPassword} />
          </Title>
          <p>{email}</p>
          {!passwordUpdated && (
            <ResetPasswordForm
              onSubmit={async ({ password }: any) => {
                await updatePasswordCall({
                  variables: {
                    password,
                    token,
                  },
                });
              }}
            />
          )}
          {errorMessage !== "" && <p>{errorMessage}</p>}
          {passwordUpdated && (
            <ButtonRow>
              <ActionLink to="/login">
                <Button>Login</Button>
              </ActionLink>
            </ButtonRow>
          )}
        </UnauthenticatedLayout>
        <SnackBar
          data-testid="error-message"
          open={!!error || errorMessage !== ""}
          message={intl.formatMessage(messages.errorUpdatingPassword)}
          variant="error"
        />
        <SnackBar
          data-testid="error-message"
          open={passwordUpdated}
          message={intl.formatMessage(messages.passwordUpdated)}
          variant="success"
        />
      </>
    );
  }
);

export default PageResetPassword;
