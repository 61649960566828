import React, { useRef, useState, useEffect } from 'react';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import {
  FormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  Theme,
} from '@material-ui/core';
import ReactDOM from 'react-dom';
import { styled, makeStyles } from '@material-ui/styles';
import { InputLabelProps } from '@material-ui/core/InputLabel';
import { FormControlProps } from '@material-ui/core/FormControl';

interface ISelectProps extends SelectProps {
  label?: any;
  id: string;
  light?: boolean;
  minWidth?: number | string;
}

interface ILightVersionComponent {
  light: boolean;
}

const SelectWrapper = styled(
  ({
    minWidth,
    ...props
  }: FormControlProps & { minWidth?: number | string }) => (
    <FormControl {...props} />
  )
)<Theme, { minWidth?: number | string }>(({ theme, minWidth }) => ({
  margin: theme.spacing(1, 0),
  minWidth: minWidth || 120,
}));

const SelectElement = styled(({ selectClasses, ...props }) => (
  <MuiSelect classes={selectClasses} {...props} />
))({ minHeight: 51 });

const useSelectStyles = makeStyles((theme: Theme) => ({
  root: {},
  icon: {
    color: theme.palette.common.white,
  },
}));

const useInputStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    '& $notchedOutline': {
      borderColor: theme.palette.extra.lightBorder,
    },
    '&:hover $notchedOutline, &$focused $notchedOutline': {
      borderColor: theme.palette.extra.lightBorderActive,
    },
  },
  notchedOutline: {},
  focused: {},
}));

const InputLabel = styled(
  React.forwardRef(
    (
      { light, ...props }: ILightVersionComponent & InputLabelProps,
      ref: any
    ) => <MuiInputLabel {...props} ref={ref} />
  )
)<Theme, ILightVersionComponent>(({ light, theme }) =>
  !light
    ? {
        fontSize: 14,
      }
    : {
        '&&': {
          color: theme.palette.common.white,
          fontSize: 14,
        },
      }
);

const Select = ({
  label,
  id,
  value,
  onChange,
  children,
  inputProps,
  light = false,
  startAdornment,
  fullWidth = false,
  minWidth,
  ...props
}: ISelectProps) => {
  const inputLabelRef = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputStyles = useInputStyles(props);
  const selectStyles = useSelectStyles(props);

  useEffect(() => {
    if (!label) {
      return;
    }
    const labelElement: any = ReactDOM.findDOMNode(inputLabelRef.current);
    setLabelWidth(labelElement.offsetWidth);
  }, [label]);

  return (
    <SelectWrapper variant="outlined" fullWidth={fullWidth} minWidth={minWidth}>
      {label && (
        <InputLabel
          light={light}
          ref={inputLabelRef}
          htmlFor={id}
          data-testid="label-input"
          shrink={!!labelWidth}
        >
          {label}
        </InputLabel>
      )}
      <SelectElement
        selectClasses={light ? selectStyles : undefined}
        value={value}
        onChange={onChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            classes={light ? inputStyles : undefined}
            name={id}
            id={id}
            startAdornment={startAdornment}
          />
        }
        inputProps={inputProps}
        {...props}
      >
        {children}
      </SelectElement>
    </SelectWrapper>
  );
};

export default Select;
