import React from "react";
import HeaderBar from "./header-bar";
// import useLocaleContext from "../../hooks/useLocaleContext";
// import LanguageSwitcher from "../language-switcher";
import DrawerMenu from "./drawer-menu";
import { Box } from "@material-ui/core";
// import MenuIcon from "@material-ui/icons/Menu";
// import HeaderLogo from "./HeaderLogo";
// import Link from "../link";

const Header = () => {
  // const [currentLocale, saveLocale] = useLocaleContext();

  return (
    <HeaderBar data-testid="header">
      {/* <Link to="/">
        <MenuIcon />
      </Link> */}
      <DrawerMenu />
      <Box
        flex={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* <LanguageSwitcher
          light
          locale={currentLocale}
          onChange={saveLocale}
          locales={[
            { id: "en", label: "English (UK)" },
            { id: "en-GB", label: "Español (ES)" }
            // { id: "es_ES", label: "Spanish (ES)" }
          ]}
        /> */}
        {/* <HeaderLogo /> */}
      </Box>
    </HeaderBar>
  );
};

export default Header;
