import "date-fns";
import React from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import Typography from "../typography";
// import { ITeam } from "../../types";
import {
  Card,
  Grid,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogContentText,
  // DialogActions,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  // Fab,
  Chip,
} from "@material-ui/core";
import BallIcon from "@material-ui/icons/SportsSoccer";

// import Link from "../link";
// import Button from "../button";
// import gql from "graphql-tag";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";
// import { ActionLink } from "../atoms";
// import SnackBar from "../snackbar";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper
//     },
//     inline: {
//       display: "inline"
//     },
//     margin: {
//       margin: theme.spacing(2)
//     },
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       width: 200
//     }
//   })
// );

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  //   height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  // display: "flex",
  // flex: 1,
  // justifyContent: "space-between",
  padding: theme.spacing(2),
}));

const Title = styled("div")(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

// interface ITeamSummaryCardProps {
//   id: string;
//   data: any;
// }

const HistoricalCard = injectIntl<any>(
  //   ({ id, team, currentUser, intl }) => {
  ({ data, intl }) => {
    console.log("hisoricalData");
    console.log(data);
    // const [open, setOpen] = useState(false);
    // const [ended, setEnded] = useState(false);
    // const [endLeague, { data, error }] = useMutation(END_LEAGUE_MUTATION);
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };

    // const handleClose = () => {
    //   setOpen(false);
    // };
    // const handleEndLeague = async () => {
    //   console.log("league", team.league);
    //   await endLeague({
    //     variables: {
    //       leagueId: team.league.id,
    //     },
    //   });
    // };

    // useEffect(() => {
    //   if (data) {
    //     console.log("response endLeague", data);
    //     setEnded(data.endLeague);
    //     setOpen(false);
    //   }
    // }, [data, error]);
    //   console.log("team=====================");
    //   console.log(team);
    //   // const classes = useStyles();

    //   const [state, setState] = useState({
    //     monday: (team.trainingSetup && team.trainingSetup.monday) || false,
    //     tuesday: (team.trainingSetup && team.trainingSetup.tuesday) || false,
    //     wednesday: (team.trainingSetup && team.trainingSetup.wednesday) || false,
    //     thrusday: (team.trainingSetup && team.trainingSetup.thrusday) || false,
    //     friday: (team.trainingSetup && team.trainingSetup.friday) || false,
    //     saturday: (team.trainingSetup && team.trainingSetup.saturday) || false,
    //     sunday: (team.trainingSetup && team.trainingSetup.sunday) || false
    //   });
    //   console.log("state ========");
    //   console.log(state);

    //   const [selectedDate, setSelectedDate] = useState<Date | null>(
    //     new Date(
    //       `2019-08-18T${(team.trainingSetup && team.trainingSetup.time) || "10:00"}`
    //     )
    //   );

    //   const [saveTraining] = useMutation(SETUP_TRAININGS);

    //   const handleDateChange = (date: Date | null) => {
    //     console.log("date================");
    //     console.log(date);
    //     setSelectedDate(date);
    //   };

    //   const handleChange = (name: string) => (
    //     event: React.ChangeEvent<HTMLInputElement>
    //   ) => {
    //     setState({ ...state, [name]: event.target.checked });
    //   };

    //   const handleSave = async () => {
    //     console.log("trying to save ------");
    //     console.log("state:");
    //     console.log(state);
    //     console.log("time");
    //     console.log(selectedDate);

    //     let time = "";
    //     if (selectedDate) {
    //       time = moment(selectedDate).format("HH:mm");
    //     }
    //     await saveTraining({
    //       variables: {
    //         id,
    //         ...state,
    //         time
    //       }
    //     });
    //   };

    return (
      <>
        <CardWrapper>
          <Header>
            <Row>
              <Grid item xs={8}>
                {data && (
                  <Title>
                    {intl.formatMessage(messages.historicalData)}
                    {">"}
                  </Title>
                )}
              </Grid>
            </Row>
          </Header>
          <Content>
            {data &&
              data.map((season: any) => {
                console.log("season");
                console.log(season);
                return (
                  // <div style={{ width: "100%" }}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={`panel-${season.id}`}
                      style={{ width: "100%" }}
                    >
                      <Typography.Subtitle1>
                        {season.league.name} - {season.league.startDate}
                      </Typography.Subtitle1>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        {season.matches &&
                          season.matches.map((match: any) => (
                            <Section>
                              <SectionTitle>
                                {match.isHomeTeamLocal
                                  ? `${match.homeTeam.name} vs ${match.awayTeam}`
                                  : `${match.awayTeam} vs ${match.homeTeam.name}`}
                                <Typography.Subtitle1>
                                  {`${match.location} - ${match.date} ${match.time}`}
                                </Typography.Subtitle1>
                                <Typography.Subtitle2>
                                  {match.isHomeTeamLocal
                                    ? `${match.score.home} vs ${match.score.away}`
                                    : `${match.score.away} vs ${match.score.home}`}
                                </Typography.Subtitle2>
                              </SectionTitle>
                              {match.isWon && (
                                <Chip
                                  style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    borderRadius: 16,
                                  }}
                                  icon={
                                    <BallIcon
                                      style={{
                                        color: "white",
                                      }}
                                    />
                                  }
                                  label={intl.formatMessage(messages.won)}
                                />
                              )}
                              {match.isTie && (
                                <Chip
                                  style={{
                                    backgroundColor: "orange",
                                    color: "white",
                                    borderRadius: 16,
                                  }}
                                  icon={
                                    <BallIcon
                                      style={{
                                        color: "white",
                                      }}
                                    />
                                  }
                                  label={intl.formatMessage(messages.draw)}
                                />
                              )}
                              {match.isLost && (
                                <Chip
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    borderRadius: 16,
                                  }}
                                  icon={
                                    <BallIcon
                                      style={{
                                        color: "white",
                                      }}
                                    />
                                  }
                                  label={intl.formatMessage(messages.lost)}
                                />
                              )}
                            </Section>
                          ))}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  // </div>
                );
              })}
          </Content>
        </CardWrapper>
        {/* <SnackBar
          data-testid="error-message"
          open={ended}
          // message={intl.formatMessage(messages.errorUpdatingPassword)}
          message="League deleted"
          variant="success"
          actionText="close"
          action={handleClose}
        /> */}
      </>
    );
  }
);

export default HistoricalCard;
