import React, { useState, useEffect } from "react";

import { Grid, Theme, styled, Chip, TextField } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import Button from "../button";
import Content from "../content";
import Typography from "../typography";
import { injectIntl } from "react-intl";
// import gql from "graphql-tag";
import { gql, useMutation } from "@apollo/client";
import messages from "./PageViewPlayer.intl";

import moment from "moment";
import { IStyledArguments } from "../../types/styled-arguments";

const SAVE_NOTE = gql`
  mutation CreateNote($playerId: ID!, $note: String!) {
    createNote(input: { playerId: $playerId, note: $note }) {
      note
      date
    }
  }
`;

const GridContainer = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
});

const Main = styled("main")<Theme>(({ theme }) => ({
  display: "block", // IE
  minHeight: `calc(100vh - ${theme.layout.headerHeight + 49}px )`,
  background: theme.palette.common.white,
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: `solid ${theme.palette.extra.borderColor}`,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const NotesTab = injectIntl<any>(({ id, notes, intl }) => {
  const [notesStored, setNotesStored] = useState(notes || []);
  const [newNote, setNewNote] = useState("");
  const [saveNote, { data }] = useMutation(SAVE_NOTE);

  const handleAddNote = (event: React.ChangeEvent<any>) => {
    setNewNote(event.target.value);
  };

  const handleSaveNote = async () => {
    await saveNote({
      variables: {
        playerId: id,
        note: newNote,
      },
    });
    setNewNote("");
  };

  useEffect(() => {
    if (data && data.createNote) {
      const newNotesStored = [...notesStored];
      newNotesStored.push(data.createNote);
      setNotesStored(newNotesStored);
    }
  }, [data]);

  // const classes = useStyles();
  return (
    <Main>
      <MainContent container>
        <GridContainer>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography.H5>
                {intl.formatMessage(messages.annotations)}:
              </Typography.H5>
            </Grid>
            <SectionTitle>Notes:</SectionTitle>
            {notesStored &&
              notesStored.length > 0 &&
              notesStored.map((note: any) => (
                <Section>
                  <Chip
                    style={{ borderRadius: 16, padding: 5, marginBottom: 16 }}
                    icon={<TodayIcon />}
                    label={moment(Number(note.date)).format("DD-MM-YYYY")}
                    variant="outlined"
                    color="secondary"
                  />
                  <div>{note.note}</div>
                </Section>
              ))}

            <Grid item xs={12}>
              {/* <textarea
                placeholder={intl.formatMessage(messages.addNote)}
                value={newNote}
                rows={6}
                cols={30}
                onChange={handleAddNote}
              ></textarea> */}
              <TextField
                style={{ width: "100%", marginBottom: 16 }}
                id="outlined-multiline-static"
                label={intl.formatMessage(messages.addNote)}
                multiline
                rows={6}
                value={newNote}
                variant="outlined"
                onChange={handleAddNote}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <ButtonRow> */}
              {/* <StyledActionLink to="/people/players"> */}
              <Button onClick={handleSaveNote}>
                {intl.formatMessage(messages.save)}
              </Button>
              {/* </StyledActionLink> */}
              {/* </ButtonRow> */}
            </Grid>
          </Grid>
        </GridContainer>
      </MainContent>
    </Main>
  );
});

export default NotesTab;
