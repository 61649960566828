import { defineMessages } from "react-intl";

export default defineMessages({
  congratulations: {
    id: "PageRegister.congratulations",
    defaultMessage: "Congratulations,",
  },
  accountCreated: {
    id: "PageRegister.accountCreated",
    defaultMessage:
      "your account has been created properly, please check your email to validate your account.",
  },
});
