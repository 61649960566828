import React, { useState, useEffect } from "react";
import { Formik, Form as FormikForm, Field, FormikProps } from "formik";
import { DrawerProps } from "@material-ui/core/Drawer";
import messages from "./UserMenu.intl";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Drawer,
  DrawerWrapper,
  DrawerHeader,
  DrawerTitle,
  CloseButton,
  DrawerContent,
} from "./DrawerElements";
import { styled } from "@material-ui/core/styles";
import TextInput from "../../text-input";
import InputErrorMessage from "../../input-error-message";
import { required } from "../../form-utils";
import Button from "../../button";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
// import Loader from "../../loader";
import { Grid, Chip } from "@material-ui/core";
import SnackBar from "../../snackbar";
// import Chip from "../../chip";
// import FaceIcon from "@material-ui/icons/Face";
import WorkIcon from "@material-ui/icons/Work";
import { UserAvatarUploader } from "../../atoms/";

interface IFormValues {
  name: string;
  surname: string;
  email: string;
  role: string;
  password: string;
  passwordConfirmation: string;
}

const StyledForm = styled(FormikForm)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const FormButton = styled((props) => <Button size="large" {...props} />)(
  (props) => ({
    marginTop: props.theme.spacing(4),
  })
);

export const USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      name
      surname
      email
      role
      telephone
      workspace
      avatarUrl
    }
  }
`;

// export const UPDATE_USER_PROFILE = gql`
//   mutation($userProfile: UpdateUserProfileInput!) {
//     updateUserProfile(userProfile: $userProfile) {
//       id
//       name
//       surname
//       language
//     }
//   }
// `;

// interface IUserData {
//   currentUser: any;
// }

// const useStyles = makeStyles(theme => ({
//   root: {
//     minHeight: `calc(100vh - ${theme.layout.headerHeight + 112}px )` // Remove the Drawer Header height
//   }
// }));

const ProfileDetails = injectIntl<DrawerProps>(({ open, onClose, intl }) => {
  // const [showUpdateError, setShowUpdateError] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const { data, error } = useQuery(USER_QUERY, { skip: !open });

  // const [
  //   updateUserProfile,
  //   { loading: updateUserLoading, error: updateUserError }
  // ] = useMutation(UPDATE_USER_PROFILE);

  // useEffect(() => {
  //   if (updateUserError) {
  //     setShowUpdateError(true);
  //   }
  // }, [updateUserError]);

  useEffect(() => {
    if (data) {
      console.log("data------");
      console.log(data);
      setCurrentUser(data.currentUser);
    }
    if (error) {
      console.log("error loading user data", error);
    }
  }, [data]);

  const onCloseDrawer = (e = {}) => {
    // setShowInstructions(false);
    // setShowUpdateError(false);
    if (onClose) {
      onClose(e, "backdropClick");
    }
  };

  // const classes = useStyles({});

  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <DrawerWrapper data-testid="my-profile-details">
          <DrawerHeader>
            <DrawerTitle>
              <FormattedMessage {...messages.myProfileDetailsTitle} />
            </DrawerTitle>
            <CloseButton
              onClick={onCloseDrawer}
              edge="end"
              aria-label={intl.formatMessage(messages.close)}
              data-testid="close-my-profile-details"
            />
          </DrawerHeader>
          {/* <Loader
            className={classes.root}
            // {...queryResult}
            loading={loading}
            error={error}
            errorMessage={intl.formatMessage(messages.loadUserError)}
          > */}
          {/* {({ currentUser }) => { */}
          {currentUser && (
            <DrawerContent>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UserAvatarUploader
                  style={{ width: 80, height: 80, fontSize: "3rem" }}
                  user={{
                    name: (currentUser && currentUser.name) || "F",
                    surname: (currentUser && currentUser.surname) || "O",
                    email: (currentUser && currentUser.email) || "",
                    avatarUrl: (currentUser && currentUser.avatarUrl) || "",
                  }}
                />
              </div>
              <Formik
                initialValues={{
                  name: (currentUser && currentUser.name) || "",
                  surname: (currentUser && currentUser.surname) || "",
                  email: (currentUser && currentUser.email) || "",
                  role: (currentUser && currentUser.role) || "",
                  password: "",
                  passwordConfirmation: "",
                }}
                isInitialValid={true}
                onSubmit={() => null}
                // onSubmit={(values: IFormValues) => {
                // updateUserProfile({
                //   variables: {
                //     userProfile: {
                //       id: currentUser.id,
                //       name: values.name,
                //       surname: values.surname,
                //       language: currentUser.language
                //     }
                //   }
                // })
                //   .then(() => onCloseDrawer())
                //   .catch(() => null);
                // }}
                // render={({ errors, isValid }: FormikProps<IFormValues>) => {
                render={({ errors }: FormikProps<IFormValues>) => {
                  return (
                    <>
                      <StyledForm data-testid="my-profile-details-form">
                        <Field
                          name="name"
                          validate={required(intl)}
                          error={errors.name}
                          component={TextInput}
                          inputProps={{
                            "data-testid": "name",
                          }}
                          disabled
                          label={intl.formatMessage(messages.firstName)}
                        />
                        <InputErrorMessage name="name" />
                        <Field
                          name="surname"
                          validate={required(intl)}
                          error={errors.surname}
                          component={TextInput}
                          inputProps={{
                            "data-testid": "surname",
                          }}
                          disabled
                          label={intl.formatMessage(messages.lastName)}
                        />
                        <InputErrorMessage name="surname" />
                        <Field
                          name="email"
                          component={TextInput}
                          inputProps={{
                            "data-testid": "email",
                          }}
                          disabled
                          label={intl.formatMessage(messages.email)}
                        />
                        <Field
                          name="role"
                          component={TextInput}
                          inputProps={{
                            "data-testid": "role",
                          }}
                          disabled
                          label={intl.formatMessage(messages.role)}
                        />
                        <Chip
                          icon={<WorkIcon />}
                          size="medium"
                          variant="default"
                          label={currentUser.workspace}
                          onClick={() => null}
                          color="primary"
                        />
                        {/* 

                          <Field
                            name="password"
                            error={errors.password && touched.password}
                            validate={(value: string) =>
                              value === ''
                                ? undefined
                                : validPassword(intl)(value, values.email)
                            }
                            component={PasswordInput}
                            inputProps={{
                              'data-testid': 'password',
                            }}
                            label={intl.formatMessage(messages.password)}
                            onFocus={() => setShowInstructions(true)}
                          />
                          <InputErrorMessage name="password" />
                          {showInstructions && <FormPasswordInstructions />}
                          <Field
                            name="passwordConfirmation"
                            error={
                              errors.passwordConfirmation &&
                              touched.passwordConfirmation
                            }
                            validate={(value: string) =>
                              matchesPassword(intl)(value, values.password)
                            }
                            component={PasswordInput}
                            inputProps={{
                              'data-testid': 'password-confirmation',
                            }}
                            label={intl.formatMessage(messages.confirmPassword)}
                          />
                          <InputErrorMessage name="passwordConfirmation" /> */}
                        <Grid container spacing={2}>
                          <Grid item xs="auto">
                            <FormButton
                              type="submit"
                              data-testid="update-button"
                              // disabled={!isValid || updateUserLoading}
                              // disabled={!isValid}
                              disabled
                            >
                              {intl.formatMessage(messages.update)}
                            </FormButton>
                          </Grid>
                          <Grid item xs="auto">
                            <FormButton
                              data-testid="cancel-button"
                              onClick={onCloseDrawer}
                              color="default"
                            >
                              {intl.formatMessage(messages.cancel)}
                            </FormButton>
                          </Grid>
                        </Grid>
                      </StyledForm>
                    </>
                  );
                }}
              />
            </DrawerContent>
          )}
          {/* </Loader> */}
        </DrawerWrapper>
      </Drawer>
      <SnackBar
        data-testid="snackbar-error-message"
        open={false}
        // open={showUpdateError}
        message={intl.formatMessage(messages.updateUserError)}
        variant="error"
        onClose={
          // onClose is called by SnackBar after 6s
          /* istanbul ignore next */ () => {
            // setShowUpdateError(false);
          }
        }
      />
    </>
  );
});

export default ProfileDetails;
