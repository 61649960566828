import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "../typography";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { gql } from "@apollo/client";
import { Avatar } from "@material-ui/core";
import { IWorkspace } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IJoinWorkspaceDialog {
  isOpen: boolean;
  workspaceInfo: IWorkspace;
  callback(result: any): void;
}

export const WORKSPACE_INFO = gql`
  query WorkspaceInfo($id: String!) {
    workspace(id: $id) {
      id
      owner {
        name
        surname
      }
      description
    }
  }
`;

export default function SelectTypeOfUserDialog({
  isOpen,
  workspaceInfo,
  callback,
}: IJoinWorkspaceDialog) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    callback({ selected: null });
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography.H6 className={classes.title}>User type</Typography.H6>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: "1em", padding: 16 }}>
          <Typography.H6>{`Welcome to ${workspaceInfo.owner.name} ${workspaceInfo.owner.surname}'s workspace `}</Typography.H6>
        </div>
        <List>
          <ListItem
            button
            onClick={() => {
              callback({ selected: "parent" });
              setOpen(false);
            }}
          >
            <Avatar
              style={{ width: "60px", height: "60px", marginRight: "24px" }}
              src={"/images/parent.png"}
            />
            <ListItemText
              primary="I'm a parent"
              secondary="Set up both yourself and a player"
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              callback({ selected: "player" });
              setOpen(false);
            }}
          >
            <Avatar
              style={{ width: "60px", height: "60px", marginRight: "24px" }}
              src={"/images/individual.png"}
            />
            <ListItemText primary="I'm a player" secondary="Set up by myself" />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              callback({ selected: "coach" });
              setOpen(false);
            }}
            disabled={true}
          >
            <Avatar
              style={{ width: "60px", height: "60px", marginRight: "24px" }}
              src={"/images/coach.png"}
            />
            <ListItemText
              primary="I'm a coach assistant (coming soon)"
              secondary="I need to get approved"
            />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}
