import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Fab,
  Button,
  Card,
} from "@material-ui/core";

import Typography from "../typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { gql } from "@apollo/client";
import { styled } from "@material-ui/styles";
import { ActionLink } from "../atoms";
import AddIcon from "@material-ui/icons/Add";
import { injectIntl } from "react-intl";
import messages from "./Trainings.intl";
import { IStyledArguments } from "../../types/styled-arguments";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export const GET_TRAININGS = gql`
  query {
    allTrainingSessions {
      id
      name
      trainingDuration
      sections {
        name
        exercises {
          id
          name
          objectives
          shortDescription
        }
        durations {
          exerciseId
          duration
        }
        sectionDuration
      }
    }
    allExercises {
      id
      name
      shortDescription
      category
      subcategory
      objectives
      taskDevelopment
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "11px",
      width: "100%",
      backgroundColor: theme.palette.extra.lighterGrey,
      padding: "16px",
      minHeight: "75vh",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const FooterContainer = styled("div")<Theme>(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  height: 90,
  width: "auto",
  right: 0,
  position: "fixed",
  bottom: 70,
}));

const Section = styled("div")(({ theme }: IStyledArguments) => ({
  borderLeft: "solid",
  borderColor: theme.palette.extra.borderColor,
  padding: "16px",
  marginBottom: "16px",
}));

const SectionTitle = styled("div")(({ theme }: IStyledArguments) => ({
  color: theme.palette.extra.borderColor,
  fontWeight: "bolder",
  fontSize: "large",
  marginBottom: "5px",
}));

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  backgroundImage: "url('/images/trainingBG.jpg')",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "450px",
  display: "flex",
  margin: "15px",
  paddingLeft: theme.spacing(2),
  position: "relative",
}));

const Title = styled("div")({
  color: "White",
  fontWeight: "bolder",
  fontSize: "x-large",
  position: "absolute",
  bottom: 0,
});

const ExercisesCollapse = injectIntl(({ intl }) => {
  const classes = useStyles();

  const { data, loading } = useQuery<any>(GET_TRAININGS);
  const [allTrainings, setAllTrainings] = useState<any>();
  const [allExercises, setAllExercises] = useState<any>();
  const [open, setOpen] = React.useState(false);
  // console.log(data);

  useEffect(() => {
    if (loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loading]);

  useEffect(() => {
    if (data && data.allTrainingSessions) {
      console.log(data.allTrainingSessions);
      setAllTrainings(data.allTrainingSessions);
    }
    if (data && data.allExercises) {
      setAllExercises(data.allExercises);
    }
  }, [data]);
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (!allTrainings || allTrainings.length === 0) && (
        <CardWrapper>
          <Title>
            {allExercises && allExercises.length > 0
              ? intl.formatMessage(messages.noResults)
              : intl.formatMessage(messages.noExercises)}
          </Title>
        </CardWrapper>
      )}
      {allTrainings &&
        allTrainings.map((training: any) => (
          <ExpansionPanel key={`expansion-panel-training-${training.id}`}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="training-panel"
              id="training-panel"
            >
              <Typography.Subtitle1>{training.name}</Typography.Subtitle1>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {training.sections &&
                  training.sections.map((section: any) => (
                    <ExpansionPanel
                      key={`expansion-panel-section-${section.id}`}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`section-${section.name}`}
                      >
                        <Typography.Subtitle1>
                          {`${section.name} (${section.sectionDuration}) min`}
                        </Typography.Subtitle1>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {section.exercises &&
                            section.exercises.map((exercise: any) => (
                              <Section key={`exercise-${exercise.id}`}>
                                <SectionTitle>
                                  {`${exercise.name} (${0})min`}
                                </SectionTitle>
                                <Typography.Subtitle2>
                                  {exercise.shortDescription}
                                </Typography.Subtitle2>
                              </Section>
                            ))}
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                <ActionLink
                  style={{ marginTop: "16px" }}
                  to={`/trainings/${training.id}`}
                >
                  <Button variant="contained" color="secondary">
                    {intl.formatMessage(messages.details)}
                  </Button>
                </ActionLink>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

      <FooterContainer>
        {allExercises && allExercises.length > 0 && (
          <ActionLink to="/library/newtraining">
            <Fab
              aria-label="add team"
              color="secondary"
              style={{ zIndex: 1200 }}
            >
              <AddIcon />
            </Fab>
          </ActionLink>
        )}
      </FooterContainer>
    </div>
  );
});

export default ExercisesCollapse;
