import React from "react";
import messages from "./FormValidators.intl";
import { styled } from "@material-ui/core";
import Typography from "../typography";
import { FormattedMessage } from "react-intl";

const PasswordInstructionsContainer = styled("div")((props) => ({
  borderRadius: 5,
  background: "#9AF5F5",
  margin: props.theme.spacing(2, 0, 1),
  textAlign: "left",
  padding: "16px",
}));

const InstructionsList = styled("ul")((props) => ({
  margin: props.theme.spacing(1, 0, 0, 0),
  paddingInlineStart: "20px",
}));

const Instruction = styled(Typography.Subtitle2)((props) => ({
  color: `${props.theme.palette.text.secondary}`,
}));

const FormPasswordInstructions = () => (
  <PasswordInstructionsContainer>
    <Typography.Caption>
      <FormattedMessage {...messages.passwordHintTitle} />
    </Typography.Caption>
    <Instruction>
      <FormattedMessage {...messages.passwordHintMustBe} />
    </Instruction>
    <InstructionsList>
      {/* <li>
        <Instruction>
          <FormattedMessage {...messages.passwordHintNotSameEmail} />
        </Instruction>
      </li> */}
      <li>
        <Instruction>
          <FormattedMessage {...messages.passwordHint8Characters} />
        </Instruction>
      </li>
      <li>
        <Instruction>
          <FormattedMessage {...messages.passwordHint1Uppercase} />
        </Instruction>
      </li>
      <li>
        <Instruction>
          <FormattedMessage {...messages.passwordHint1Lowercase} />
        </Instruction>
      </li>
      <li>
        <Instruction>
          <FormattedMessage {...messages.passwordHint1Number} />
        </Instruction>
      </li>
      <li>
        <Instruction>
          <FormattedMessage {...messages.passwordHint1SpecialCharacter} />
        </Instruction>
      </li>
    </InstructionsList>
  </PasswordInstructionsContainer>
);

export default FormPasswordInstructions;
