import React, { useState } from "react";
import {
  Formik,
  FormikActions,
  Form as FormikForm,
  Field,
  FormikProps,
} from "formik";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import TextInput from "../../text-input";
import Typography from "../../typography";
import InputErrorMessage from "../../input-error-message";

import messages from "./EditPlayerForm.intl";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../../types/styled-arguments";
import RegisterStepButton from "../register-step-button";
import { required } from "../../form-utils";

import Select from "../../select";
import Option from "../../select/option";
import { SelectProps } from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const Title = styled(({ children }) => (
  <Typography.H5>{children}</Typography.H5>
))((props: IStyledArguments) => {
  const { md } = props.theme.breakpoints.values;

  return {
    marginBottom: 23,
    [`@media (max-width: ${md}px)`]: {
      marginBottom: 27,
    },
  };
});

const ImageHeader = styled("div")(({ theme }: IStyledArguments) => ({
  padding: theme.spacing(0, 1, 1, 1),
  width: "100%",
}));

const PositionSelect = injectIntl<SelectProps>(({ onChange, value }) => {
  const positions: { [index: string]: string } = {
    GOALKEEPER: "Goal Keeper",
    LEFTBACK: "Left Back",
    RIGHTBACK: "Right Back",
    CENTREBACK: "Centre Back",
    CENTREMIDFIELD: "Centre Midfield",
    LEFTMIDFIELD: "Left Midfield",
    RIGHTMIDFIELD: "Right Midfield",
    STRIKER: "Striker",
  };

  return (
    <Select
      id="position-select"
      label="Position"
      value={value}
      onChange={onChange}
      fullWidth
    >
      {Object.keys(positions).map((key, idx) => (
        <Option key={idx} value={key}>
          {positions[key]}
        </Option>
      ))}
    </Select>
  );
});

export interface IFormValues {
  // email: string;
  name: string;
  surname: string;
  nickname?: string;
  number?: number;
  position: string;
  dateOfBirth: string;
}

interface IForm extends InjectedIntlProps {
  onSubmit: (arg: IFormValues) => void;
  checkValid: boolean;
  // email: string;
  name: string;
  surname: string;
  nickname?: string;
  number?: number;
  position: string;
  dateOfBirth: string;
}

const StyledForm = styled(FormikForm)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const NewPlayerForm = ({
  onSubmit,
  checkValid,
  // email,
  name,
  surname,
  number,
  position,
  nickname,
  dateOfBirth,
  intl,
}: IForm) => {
  const [positionOption, setPositionOption] = useState<string>("");
  console.log("name---------");
  console.log(name);
  const date = new Date(Number(dateOfBirth));
  const formattedDate =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };
  return (
    <Formik
      initialValues={{
        // email,
        name,
        surname,
        number,
        position,
        nickname,
        dateOfBirth: formattedDate,
      }}
      isInitialValid={checkValid}
      onSubmit={(values: IFormValues, actions: FormikActions<IFormValues>) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      render={({
        errors,
        touched,
        // values,
        isValid,
        setFieldValue,
      }: FormikProps<IFormValues>) => (
        <>
          <ImageHeader>
            <svg
              id="Layer_5"
              enable-background="new 0 0 64 64"
              height="70px"
              viewBox="0 0 64 64"
              width="70px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="32"
                x2="32"
                y1="63"
                y2="1"
              >
                <stop offset="0" stop-color="#FF5722" />
                <stop offset="1" stop-color="#0bb1d3" />
              </linearGradient>
              <path
                d="m5 39h1.052l-1.028 19.559c-.243 2.19 1.341 4.172 3.54 4.418.162.016.299.023.437.023 2.041 0 3.749-1.53 3.975-3.566l1.024-9.809 1.025 9.814c.223 2.031 1.932 3.561 3.973 3.561.139 0 .276-.007.446-.024 2.19-.245 3.774-2.227 3.536-4.359l-1.031-19.617h1.051c2.206 0 4-1.794 4-4v-9c0-5.093-3.484-9.376-8.19-10.623 1.933-1.461 3.19-3.772 3.19-6.377 0-4.411-3.589-8-8-8s-8 3.589-8 8c0 2.605 1.257 4.916 3.19 6.377-4.706 1.247-8.19 5.53-8.19 10.623v9c0 2.206 1.794 4 4 4zm14.23 21.986c-.084.009-.158.014-.232.014-1.02 0-1.874-.765-1.985-1.773l-1.904-18.227h1.891v-2h-6v2h1.891l-1.903 18.22c-.12 1.087-1.077 1.881-2.209 1.768-1.096-.122-1.888-1.112-1.761-2.266l1.247-23.722h11.47l1.252 23.779c.122 1.096-.67 2.086-1.757 2.207zm-7.23-43.986h4c.231 0 .457.018.683.035l-.63 3.965h-4.106l-.63-3.965c.226-.017.452-.035.683-.035zm3.735 6-1.589 10h-.293l-1.589-10zm9.265 3v9c0 1.103-.897 2-2 2h-1.157l-.844-16.053-1.998.105.629 11.948h-3.459l2.479-15.601c3.673 1.134 6.35 4.56 6.35 8.601zm-17-17c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6zm-5 17c0-4.041 2.677-7.467 6.35-8.601l2.479 15.601h-3.458l.628-11.947-1.998-.105-.844 16.052h-1.157c-1.103 0-2-.897-2-2zm52.636-3c-1.32 0-2.584.352-3.695 1h-8.941v2h6.586l-22.514 22.514-2.314 12.729 12.729-2.314 23.356-23.356c1.391-1.392 2.157-3.242 2.157-5.209 0-4.06-3.304-7.364-7.364-7.364zm-18.855 30h-2.367l15.086-15.086 3.086 3.086-15.044 15.044zm-6.825-4.542 15.044-15.044 3.086 3.086-15.086 15.086v-2.367zm16.458-16.458 1.586-1.586 7.586 7.586-1.586 1.586zm-17.621 18.229 2.207.552v4.219h4.219l.552 2.207-8.529 1.551zm30.636-16.072-2.429 2.429-7.586-7.586 2.429-2.429c.999-.999 2.381-1.571 3.793-1.571 2.958 0 5.364 2.406 5.364 5.364 0 1.412-.572 2.794-1.571 3.793zm-20.429-27.743 3.707-3.707-1.414-1.414-2.707 2.707h-9.586v10h10zm-8 5.586v-6h5.586l-2.586 2.586-1.293-1.293-1.414 1.414 2.707 2.707 3-3v3.586zm-2 14h10v-10h-10zm2-8h6v6h-6zm22-4v-2h2v2zm-10-2h8v2h-8zm0-4h12v2h-12zm12-2h-12v-2h12zm-12 14h12v2h-12zm0-4h12v2h-12z"
                fill="url(#SVGID_1_)"
              />
            </svg>
          </ImageHeader>
          <Title>
            <FormattedMessage {...messages.registerTitle} />
          </Title>
          <StyledForm data-testid="add-player-form">
            <Field
              name="name"
              validate={required(intl)}
              error={errors.name && touched.name}
              component={TextInput}
              inputProps={{
                "data-testid": "name",
              }}
              label={intl.formatMessage(messages.name)}
            />
            <InputErrorMessage name="name" />
            <Field
              name="surname"
              validate={required(intl)}
              error={errors.surname && touched.surname}
              component={TextInput}
              inputProps={{
                "data-testid": "surname",
              }}
              label={intl.formatMessage(messages.surname)}
            />
            <InputErrorMessage name="surname" />
            {/* <Field
              name="nickname"
              validate={required(intl)}
              error={errors.nickname && touched.nickname}
              component={TextInput}
              inputProps={{
                "data-testid": "nickname"
              }}
              label={intl.formatMessage(messages.nickname)}
            />
            <InputErrorMessage name="nickname" /> */}
            <Field
              name="number"
              validate={required(intl)}
              error={errors.number && touched.number}
              component={TextInput}
              inputProps={{
                "data-testid": "number",
              }}
              label={intl.formatMessage(messages.number)}
            />
            <InputErrorMessage name="number" />
            {/* <Field
            name="password"
            validate={(value: string) =>
              validPassword(intl)(value, values.email)
            }
            component={PasswordInput}
            inputProps={{
              "data-testid": "password"
            }}
            label={intl.formatMessage(messages.password)}
          />
          <InputErrorMessage name="password" /> */}
            {/* <FormPasswordInstructions /> */}
            {/* <Field
            name="passwordConfirmation"
            validate={(value: string) =>
              matchesPassword(intl)(value, values.password)
            }
            component={PasswordInput}
            inputProps={{
              "data-testid": "password-confirmation"
            }}
            label={intl.formatMessage(messages.confirmPassword)}
          />
          <InputErrorMessage name="passwordConfirmation" /> */}

            <PositionSelect
              value={positionOption}
              // onChange={(e: any) => {
              //   alert(e.target.value);
              //   setPositionOption(e.target.value);
              //   position = positionOption;
              // }}
              onChange={(event: any) => {
                setPositionOption(event.target.value);
                setFieldValue("position", event.target.value);
              }}
            />

            {/* <Field
              name="dateOfBirth"
              validate={required(intl)}
              error={errors.number && touched.dateOfBirth}
              component={TextInput}
              inputProps={{
                "data-testid": "dateOfBirth"
              }}
              label={intl.formatMessage(messages.dateOfBirth)}
            />
            <InputErrorMessage name="dateOfBirth" /> */}

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date of birth"
                format="yyyy-MM-dd"
                value={selectedDate}
                onChange={(date: any) => {
                  handleDateChange(date);
                  setFieldValue(
                    "dateOfBirth",
                    moment(date).format("YYYY-MM-DD")
                  );
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>

            <div>
              <RegisterStepButton
                type="submit"
                data-testid="go-next-button"
                disabled={!isValid}
              >
                Save
              </RegisterStepButton>
            </div>
          </StyledForm>
        </>
      )}
    />
  );
};

export default injectIntl(NewPlayerForm);
