import React, { useEffect } from "react";
import { styled } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
import { Grid, Chip, Theme, Fab, Paper } from "@material-ui/core";

import { RouteComponentProps } from "@reach/router";
import { injectIntl } from "react-intl";
// import gql from "graphql-tag";
import { gql, useQuery } from "@apollo/client";
import Loader from "../loader";
import messages from "./PageViewPlayer.intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InfoTab from "./InfoTab";
import NotesTab from "./NotesTab";
import StatsTab from "./StatsTab";
import Breadcrumb from "./Breadcrumb";
import { ActionLink } from "../atoms";
import EditIcon from "@material-ui/icons/EditOutlined";
import useIsManager from "../../hooks/useIsManager";
import FaceIcon from "@material-ui/icons/Face";
// import CopyIcon from "@material-ui/icons/FileCopy";
// import SendIcon from "@material-ui/icons/SendRounded";
import UserAvatar from "../user-avatar";
// import DeleteIcon from "@material-ui/icons/DeleteForever";

const QUERY_PLAYER = gql`
  query playerDetails($id: ID!) {
    player(id: $id) {
      id
      name
      surname
      nickName
      number
      position
      dateOfBirth
      isInvited
      avatarUrl
      user {
        id
        email
      }
      scouting
      team {
        id
        name
      }
      notes {
        note
        date
      }
      totalGoals
      totalMatches
      reviews {
        data {
          speed
          vision
          dribbling
          shoot
          physical
          leadership
          defense
        }
        date
      }
    }
    getPlayerHistoricalData(playerId: $id) {
      league {
        name
        startDate
        endDate
      }
      team {
        name
      }
      totalGoals
      yellowCards
      redCards
    }
    currentUser {
      id
      role
    }
  }
`;

// const SEND_INVITE = gql`
//   mutation InvitePlayer($id: ID!, $email: String!, $role: String!) {
//     invitePlayer(id: $id, email: $email, role: $role)
//   }
// `;

enum TabNames {
  information = "information",
  notes = "notes",
  statistics = "statistics",
}

const TabContainer = styled("div")({
  position: "relative",
  top: "auto",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
});

const StyledPaper = styled(Paper)<Theme>(({ theme }) => ({
  boxSizing: "border-box",
  marginBottom: theme.spacing(5),
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: 830,
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    boxShadow: "none",
  },
}));

const TabsWrapper = styled("div")<Theme>(({ theme }) => ({
  position: "initial",
  width: "100%",
  top: "56px",
  zIndex: theme.zIndex.drawer - 1,
  borderBottom: `1px solid ${theme.palette.extra.borderColor}`,
  backgroundImage: "url(/images/soccer-open-field.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  backgroundPosition: "bottom",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

const Container = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
  paddingBottom: 8,
});

type PagePlayerProps = Required<Pick<RouteComponentProps, "navigate" | "path">>;

const PageViewPlayer = injectIntl<{ id: string } & PagePlayerProps>(
  ({ id, navigate, path, intl }) => {
    console.log("[pvplayer]~~~~~~~", id);
    console.log(id);
    const { data, loading, refetch } = useQuery(QUERY_PLAYER, {
      variables: {
        id,
      },
    });

    const handleRefresh = () => {
      refetch();
    };

    const PATH_TO_TAB: { [path: string]: string } = {
      "/players/:id/information": TabNames.information,
      "/players/:id/notes": TabNames.notes,
      "/players/:id/statistics": TabNames.statistics,
    };

    const TAB_TO_PATH: { [tabName: string]: string } = {
      [TabNames.information]: `/players/${id}/information`,
      [TabNames.notes]: `/players/${id}/notes`,
      [TabNames.statistics]: `/players/${id}/statistics`,
    };

    const currentTab = PATH_TO_TAB[path] || TabNames.information;

    const handleChangeTabs = (_: React.ChangeEvent<{}>, newValue: number) => {
      navigate(TAB_TO_PATH[newValue], {
        replace: true,
      });
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentTab]);

    useEffect(() => {
      if (data && data.player) {
        // console.log("adddddddddddd");
        // console.log(data.player);
        // setPlayer(data.player);
      }
      // if (error) {
      //   console.log("error");
      //   console.log(error);
      // }
    }, [data]);

    return (
      <StyledPaper>
        <Loader
          errorMessage={intl.formatMessage(messages.error)}
          loading={loading}
          // error={error}
          data={data}
        >
          {({ player, getPlayerHistoricalData, currentUser }) => (
            <>
              <TabsWrapper>
                <Header>
                  <Container>
                    <Grid item xs={12}>
                      <Breadcrumb player={player} />
                    </Grid>
                    <Grid item xs={12}>
                      <Centered>
                        <UserAvatar
                          style={{ width: 80, height: 80, fontSize: "3rem" }}
                          user={{
                            name: player.name,
                            surname: player.surname,
                            avatarUrl: player.avatarUrl,
                          }}
                        ></UserAvatar>
                        {player.number && (
                          <Chip
                            style={{
                              position: "absolute",
                              left: "calc(50% + 25px)",
                              borderRadius: "50%",
                              width: "32px",
                              height: "32px",
                            }}
                            size="small"
                            label={player.number}
                            onClick={() => null}
                            color="secondary"
                          />
                        )}
                        {useIsManager(currentUser.role) && (
                          <>
                            <ActionLink
                              to={`/players/${id}/edit`}
                              style={{
                                position: "absolute",
                                left: "calc(50% + 31px)",
                                top: "190px",
                              }}
                            >
                              <Fab
                                variant="round"
                                size="small"
                                color="inherit"
                                aria-label="add"
                              >
                                <EditIcon />
                              </Fab>
                            </ActionLink>
                            {player.isInvited && (
                              <Chip
                                style={{
                                  position: "absolute",
                                  left: "calc(50% + -112px)",
                                  top: "125px",
                                  backgroundColor: "#1ca2c9",
                                  color: "white",
                                }}
                                icon={<FaceIcon style={{ color: "white" }} />}
                                size="small"
                                label={intl.formatMessage(messages.invited)}
                              />
                            )}
                            {player.user && (
                              <Chip
                                style={{
                                  position: "absolute",
                                  left: "calc(50% + -132px)",
                                  top: "123px",
                                  color: "white",
                                }}
                                icon={<FaceIcon style={{ color: "white" }} />}
                                size="small"
                                label={intl.formatMessage(messages.accepted)}
                              />
                            )}
                          </>
                        )}
                      </Centered>
                    </Grid>
                  </Container>
                </Header>
                <Grid
                  container
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    color: "white",
                  }}
                >
                  <Grid item xs={12}>
                    <Tabs
                      centered
                      value={currentTab}
                      onChange={handleChangeTabs}
                      indicatorColor="secondary"
                      textColor="primary"
                      variant="fullWidth"
                      scrollButtons="auto"
                    >
                      <Tab
                        label={intl.formatMessage(messages.tabInfo)}
                        data-testid="information-tab"
                        value={TabNames.information}
                        style={{
                          color: "white",
                        }}
                      />
                      <Tab
                        label={intl.formatMessage(messages.tabNotes)}
                        data-testid="notes-tab"
                        value={TabNames.notes}
                        disabled={!useIsManager(currentUser.role)}
                        style={{
                          color: "white",
                        }}
                      />
                      <Tab
                        label={intl.formatMessage(messages.tabEvaluations)}
                        data-testid="statistics-tab"
                        value={TabNames.statistics}
                        disabled={!useIsManager(currentUser.role)}
                        style={{
                          color: "white",
                        }}
                      />
                    </Tabs>
                  </Grid>
                </Grid>
              </TabsWrapper>
              <TabContainer>
                {currentTab === TabNames.information && (
                  <InfoTab
                    player={player}
                    historicalData={getPlayerHistoricalData}
                    callback={handleRefresh}
                  />
                )}
                {currentTab === TabNames.notes && (
                  <NotesTab id={id} notes={player.notes} />
                )}
                {currentTab === TabNames.statistics && (
                  <StatsTab reviews={player.reviews} id={id} />
                )}
              </TabContainer>
            </>
          )}
        </Loader>
      </StyledPaper>
    );
  }
);

export default PageViewPlayer;
