import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Slide,
  TextField,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IConfirmDialog {
  title: string;
  message: string;
  callback: any;
  trigger: any;
}

const EditTeamNameDialog = ({
  title,
  message,
  callback,
  trigger,
}: IConfirmDialog) => {
  const [open, setOpen] = React.useState(false);
  const [newName, setName] = React.useState<string>("");

  const onOpen = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    callback(newName);
    setOpen(false);
  };
  const handleDisagree = () => {
    callback(false);
    setOpen(false);
  };

  const TriggerComponent = () => {
    const Comp = trigger({ onOpen });
    return Comp;
  };

  const handleEditName = (evt: any) => {
    setName(evt.target.value);
  };

  return (
    <div>
      <TriggerComponent />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDisagree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New name"
            fullWidth
            value={newName}
            onChange={handleEditName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAgree} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditTeamNameDialog;
