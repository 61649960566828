import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import MuiDrawer, { DrawerProps } from "@material-ui/core/Drawer";
import { styled } from "@material-ui/styles";
import {
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  withStyles,
  Theme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Typography from "../../typography";
import { IStyledArguments } from "../../../types/styled-arguments";
import { IUser } from "../../../types";
import messages from "./DrawerMenu.intl";
import { InjectedIntlProps, injectIntl } from "react-intl";
import UserAvatar from "../../user-avatar";
import AppTheme from "../../../theme";
// import zIndex from "@material-ui/core/styles/zIndex";

export const DrawerWrapper = styled("div")({
  height: "100%",
  width: "90vw",
  display: "flex",
  flexDirection: "column",
});

const paperStyles = {
  root: {
    top: "0px", //AppTheme.layout.headerHeight,
    // height: `calc(100% - ${AppTheme.layout.headerHeight}px)`,
    height: "100vh", //`calc(100% - ${AppTheme.layout.headerHeight}px)`,
  },
};

export const DrawerHeader = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.grey,
  display: "flex",
  alignItems: "center",
  minHeight: "112px",
  padding: theme.spacing(0, 3.5),
  justifyContent: "space-between",
}));

export const DrawerContent = styled(({ scrollable, ...props }) => (
  <div {...props} />
))<Theme, { scrollable?: boolean }>(({ theme, scrollable }) => ({
  background: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  overflowY: scrollable ? "auto" : "hidden",
  padding: theme.spacing(2, 3.5),
}));

export const CloseButton = styled((props) => (
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
))(({ theme }: IStyledArguments) => ({
  background: theme.palette.common.white,
  borderRadius: "50%",
  height: 48,
  width: 48,
  minWidth: 48,
  boxShadow: theme.shadows[4],
}));

const useListItemStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    position: "relative",
  },
}));

const ManagersList = styled(List)(({ theme }: IStyledArguments) => ({
  maxHeight: "100%",
  margin: theme.spacing(0, -3.5),
  width: `${AppTheme.layout.menuDrawerWidth}px`,
  overflowY: "auto",
  padding: theme.spacing(2, 3.5),
}));

const Border = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.grey,
  position: "absolute",
  bottom: 0,
  height: 1,
  width: `calc(100% - 56px)`,
  right: 0,
}));

const SingleRow = ({
  message,
  classes,
}: {
  classes: object;
  message: string;
}) => (
  <ListItem classes={classes}>
    <ListItemText primary={message} />
  </ListItem>
);

export const Users = injectIntl(
  ({
    users,
    loading,
    intl,
  }: InjectedIntlProps & {
    users: IUser[];
    loading: boolean;
  }) => {
    const listItemStyles = useListItemStyles({});

    return (
      <ManagersList>
        {loading && (
          <SingleRow
            classes={listItemStyles}
            message={intl.formatMessage(messages.loadingResults)}
          />
        )}
        {!loading && !users.length && (
          <SingleRow
            classes={listItemStyles}
            message={intl.formatMessage(messages.noResults)}
          />
        )}
        {!loading &&
          users.length > 0 &&
          users.map((el, idx) => (
            <ListItem classes={listItemStyles} key={idx}>
              <ListItemAvatar>
                <UserAvatar user={el} />
              </ListItemAvatar>
              <ListItemText
                primary={`${el.name} ${el.surname}`}
                secondary={`${el.email}`}
              />
              <Border />
            </ListItem>
          ))}
      </ManagersList>
    );
  }
);

export const Drawer = withStyles(paperStyles)(
  ({ open, onClose, children, classes }: DrawerProps & { classes: object }) => (
    <MuiDrawer
      anchor="left"
      hideBackdrop
      open={open}
      onClose={onClose}
      PaperProps={{ classes }}
    >
      {children}
    </MuiDrawer>
  )
);

export const DrawerTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography.H4 gutterBottom={false}>{children}</Typography.H4>
);

export const StyledBackIcon = styled((props) => (
  <ChevronLeftIcon {...props} color="secondary" fontSize="large" />
))({
  cursor: "pointer",
  verticalAlign: "bottom",
  marginLeft: -10,
  marginRight: 10,
});
