import React, { useState, useEffect } from "react";
import { createStyles, styled } from "@material-ui/styles";
import { Button, Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { injectIntl } from "react-intl";
// import gql from "graphql-tag";
import { gql, useQuery, useMutation } from "@apollo/client";
// import Loader from "../loader";
import messages from "./PageViewTeam.intl";

import Breadcrumb from "./Breadcrumb";
import TeamCard from "./TeamCard";
import ScorersCard from "./ScorersCard";
import SquadCard from "./SquadCard";
import Content from "../content";
import TrainingCard from "./TrainingCard";
import LeagueCard from "./LeagueCard";
import { PaperLayout } from "../atoms";
import GamesChart from "./GamesChart";
import HistoricalCard from "./HistoricalCard";
import Modal from "@material-ui/core/Modal";
import EditIcon from "@material-ui/icons/EditRounded";

import useIsManager from "../../hooks/useIsManager";
import Typography from "../typography";
import EditTeamNameDialog from "./EditTeamNameDialog";

const TEAM_QUERY = gql`
  query teamDetails($id: ID!) {
    team(id: $id) {
      id
      name
      location
      colorHome
      colorAway
      foundationYear
      coach {
        id
        name
        nickName
      }
      squad {
        id
        name
        surname
        avatarUrl
        number
        position
        totalGoals
      }
      matches {
        id
        date
        homeTeam {
          id
          name
        }
        isHomeTeamLocal
        awayTeam
        status
        score {
          home
          away
        }
        isWon
        isTied
        isLost
      }
      trainingSetup {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      league {
        id
        name
        startDate
        location
      }
    }
    currentUser {
      id
      role
    }
    getHistoricalData(teamId: $id) {
      league {
        name
        startDate
        endDate
      }
      matches {
        isHomeTeamLocal
        homeTeam {
          id
          name
        }
        isHomeTeamLocal
        awayTeam
        status
        location
        date
        time
        score {
          home
          away
        }
        isWon
        isTied
        isLost
      }
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation EditTeam($id: ID!, $input: TeamInput!) {
    editTeam(id: $id, input: $input) {
      ok
    }
  }
`;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: "90vw",
    height: "50vh",
    postion: "relative",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

// const useLoaderStyles = makeStyles(() => ({
//   // root: {
//   //   minHeight: `calc(100vh - ${theme.layout.headerHeight -
//   //     theme.layout.tabsHeight}px )` // compensate for list filter bar height
//   // }
//   root: {
//     // minHeight: `calc(100vh - ${theme.layout.headerHeight - 150}px )` // compensate for list filter bar height
//   },
// }));

// const Header = styled("div")(({ theme }: IStyledArguments) => ({
//   background: theme.palette.extra.lighterGrey,
//   display: "flex",
//   justifyContent: "space-between",
//   padding: theme.spacing(1, 1.5, 1, 1.5),
// }));

const Container = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
  paddingBottom: 8,
  flexDirection: "row",
});

// const Title = styled(Link)(({ theme }: IStyledArguments) => ({
//   ...theme.typography.h6,
//   color: "white",
//   position: "absolute",
//   bottom: 0,
//   fontSize: "x-large",
// }));

const TitleContainer = styled("div")({
  color: "white",
  position: "absolute",
  bottom: 0,
  fontSize: "x-large",
  fontWeight: 100,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Main = styled("main")<Theme>(({ theme }) => ({
  display: "block", // IE
  background: theme.palette.common.white,
  paddingBottom: "70px",
}));

const MainContent = styled(Content)<Theme>(({ theme }) => ({
  padding: theme.spacing(4),
}));

const GridContainer = styled((props) => (
  <Grid container {...props} direction="row" />
))({
  flex: 1,
  justifyContent: "space-between",
});

const HeaderWrapper = styled("div")<Theme>(({ theme }) => ({
  position: "relative",
  width: "100%",
  // top: "56px",
  zIndex: theme.zIndex.drawer - 1,
  borderBottom: `1px solid ${theme.palette.extra.borderColor}`,
  backgroundImage: "url(/images/soccer-open-field.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  backgroundPosition: "bottom",
  padding: theme.spacing(2),
  minHeight: "30vh",
}));

const getNumberOfMatchesWon = (matches: any) => {
  if (matches) {
    const numberOfWonGames = matches
      .map((match: any) => (match && match.isWon ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfWonGames);
    return numberOfWonGames;
  }
  return 0;
};

const getNumberOfMatchesDrawn = (matches: any) => {
  if (matches) {
    const numberOTiedMatches = matches
      .map((match: any) => (match && match.isTied ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOTiedMatches);
    return numberOTiedMatches;
  }
  return 0;
};

const getNumberOfMatchesLost = (matches: any) => {
  if (matches) {
    const numberOfLostMatches = matches
      .map((match: any) => (match && match.isLost ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfLostMatches);
    return numberOfLostMatches;
  }
  return 0;
};

type PagePlayerProps = Required<Pick<RouteComponentProps, "navigate" | "path">>;

const PageViewTeam = injectIntl<{ id: string } & PagePlayerProps>(
  ({ id, intl }) => {
    const { data, refetch } = useQuery(TEAM_QUERY, {
      variables: {
        id,
      },
    });

    const [team, setTeam] = useState<any>();
    const [historicalData, setHistoricalData] = useState<any>();
    const [currentUser, setCurrentUser] = useState<any>();
    const [editTeam, { data: editData }] = useMutation(UPDATE_TEAM);

    const [modalStyle] = useState(getModalStyle);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
      if (data && data.currentUser) {
        setCurrentUser(data.currentUser);
      }
      if (data && data.team) {
        setTeam(data.team);
      }
      if (data && data.getHistoricalData) {
        setHistoricalData(data.getHistoricalData);
      }
    }, [data]);

    useEffect(() => {
      console.log("[name]", editData);
      if (editData && editData.editTeam.ok === 1) {
        refetch();
      }
    }, [editData]);

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };
    const handleSaveName = async (newName: string): Promise<void> => {
      if (team) {
        editTeam({
          variables: {
            id,
            input: {
              name: newName,
              location: team.location,
              colorHome: team.colorHome,
              colorAway: team.colorAway,
              foundationYear: team.foundationYear,
            },
          },
        });
      }
    };

    const classes = useStyles();
    // const loaderClasses = useLoaderStyles({});
    return (
      <>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            {intl.formatMessage(messages.actionNoReverse)}
            <div style={{ position: "absolute", bottom: 10 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                >
                  Delete
                </Button>
                <Button
                  style={{ marginLeft: "50%" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {/* <Loader
          className={loaderClasses.root}
          errorMessage={intl.formatMessage(messages.error)}
          loading={loading}
          error={error}
          data={data}
        > */}
        {/* {loading && <Loader />} */}
        {/* {currentUser && <div>{currentUser.role}</div>} */}
        {team ? (
          <PaperLayout>
            <HeaderWrapper>
              <Container>
                <Grid item xs={12}>
                  <Breadcrumb team={team} />
                </Grid>
                <TitleContainer>
                  <Typography.H5 style={{ color: "white" }}>
                    {team.name}
                  </Typography.H5>
                  <EditTeamNameDialog
                    title="Edit team name"
                    message="here you can change the team name"
                    callback={handleSaveName}
                    // callback={() => alert("jola")}
                    trigger={({ onOpen }: any) => (
                      <IconButton
                        style={{
                          border: "1px solid white",
                          color: "white",
                          marginLeft: 8,
                        }}
                        size="small"
                        onClick={onOpen}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  />
                </TitleContainer>
              </Container>
            </HeaderWrapper>
            <Main>
              <MainContent container>
                <GridContainer>
                  {team && team.matches && team.matches.length > 0 && (
                    <Grid item xs={12} sm={6} style={{ padding: "8px" }}>
                      <GamesChart
                        data={[
                          {
                            id: "lost",
                            label: `${intl.formatMessage(messages.lost)}`,
                            value: `${getNumberOfMatchesLost(team.matches)}`,
                            color: "hsl(10, 70%, 56%)",
                          },
                          {
                            id: "drawn",
                            label: `${intl.formatMessage(messages.draw)}`,
                            value: `${getNumberOfMatchesDrawn(team.matches)}`,
                            color: "hsl(210, 70%, 50%)",
                          },
                          {
                            id: "won",
                            label: `${intl.formatMessage(messages.won)}`,
                            value: `${getNumberOfMatchesWon(team.matches)}`,
                            color: "#77dd91",
                          },
                        ]}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} style={{ padding: "8px" }}>
                    <TeamCard key={team.id} id={team.id} team={team} />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ padding: "8px" }}>
                    <ScorersCard key={team.id} id={team.id} team={team} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
                    <SquadCard
                      key={team.id}
                      id={team.id}
                      team={team}
                      currentUser={currentUser}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ padding: "8px" }}>
                    <TrainingCard
                      key={team.id}
                      id={team.id}
                      team={team}
                      currentUser={currentUser}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ padding: "8px" }}>
                    {currentUser && (
                      <LeagueCard
                        key={team.id}
                        id={team.id}
                        team={team}
                        currentUser={currentUser}
                      />
                    )}
                  </Grid>
                  {historicalData && (
                    <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
                      <HistoricalCard data={historicalData} />
                    </Grid>
                  )}
                  {currentUser && (
                    <Grid>
                      <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
                        {useIsManager(currentUser.role) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenModal}
                            disabled
                          >
                            Delete
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </GridContainer>
              </MainContent>
            </Main>
          </PaperLayout>
        ) : (
          <div>Loading... </div>
        )}
        {/* </Loader> */}
      </>
    );
  }
);

export default PageViewTeam;
