import React from "react";
// import messages from "./FormValidators.intl";
import { styled } from "@material-ui/core";
import Typography from "../typography";

const PasswordInstructionsContainer = styled("div")(props => ({
  borderRadius: 5,
  background: "rgba(173, 2, 252, 0.5)",
  margin: props.theme.spacing(2, 0, 1),
  textAlign: "left",
  padding: "16px"
}));

const Instruction = styled(Typography.Subtitle2)(props => ({
  color: `${props.theme.palette.text.secondary}`
}));

const Instructions = ({ message }: { message: string }) => (
  <PasswordInstructionsContainer>
    <Typography.Caption>
      {/* <FormattedMessage {...messages.passwordHintTitle} /> */}
      Info
    </Typography.Caption>
    <Instruction>
      {/* <FormattedMessage {...messages.passwordHintMustBe} /> */}
      {message}
    </Instruction>
  </PasswordInstructionsContainer>
);

export default Instructions;
