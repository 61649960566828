import React, { useState, useEffect } from "react";
import Avatar, { AvatarProps } from "@material-ui/core/Avatar";
import { styled, withStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../../types/styled-arguments";
import { storage } from "../../../firebase/firebase";
import useAuthContext from "../../../hooks/useAuthContext";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { gql, useMutation } from "@apollo/client";

const ADD_AVATAR = gql`
  mutation AddAvatar($email: String!, $avatarUrl: String!) {
    addAvatar(email: $email, avatarUrl: $avatarUrl)
  }
`;

const StyledAvatar = styled(Avatar)({
  border: "solid 3px",
  position: "absolute",
  // "&:hover": {
  //   backgroundColor: "red",
  //   cursor: "pointer",
  // },
});

interface IUser {
  name: string;
  email: string;
  surname: string;
  number?: string;
  avatarUrl: string;
}

const AddPictureBox = styled("div")(({ theme }: IStyledArguments) => ({
  border: "solid 1px",
  borderRadius: "50%",
  borderStyle: "dashed",
  borderColor: theme.palette.extra.borderColor,
  width: "90px",
  height: "90px",
  position: "relative",
  "&:hover": {
    cursor: "pointer",
  },
  // margin: theme.spacing(1, 1, 1, 0),
}));

// const ImageBox = ({ url, index, onClick }: any) => {
//   const Box = styled("div")(({ theme }: any) => ({
//     border: "solid 1px",
//     borderRadius: "8px",
//     borderColor: theme.palette.extra.borderColor,
//     background: `url(${url}) no-repeat center`,
//     backgroundSize: "cover",
//     width: "80px",
//     height: "80px",
//     position: "relative",
//     "&:hover": {
//       cursor: "pointer",
//     },
//     margin: theme.spacing(1, 1, 1, 0),
//   }));

//   return <Box key={`imageBox-${index}`} onClick={() => onClick(index)} />;
// };

const TransparentButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "0px ",
    lineHeight: 1.5,
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    // backgroundColor: "#0063cc",
    // borderColor: "#0063cc",
    "&:hover": {
      backgroundColor: "#61616121",
      //   borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //   backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

interface IUserAvatarProps extends AvatarProps {
  user: IUser;
}

const getInitials = (p: IUser) => `${p.name[0] || ""}${p.surname[0] || ""}`;
// ${p.number || ""}
// const getNumber = (p: IUser) => `${p.number || ""}`;

const UserAvatar = ({ user, ...props }: IUserAvatarProps) => {
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState<any>();
  const [imageAsUrl, setImageAsUrl] = useState<any>(allInputs);
  const [avatarImage, setAvatarImage] = useState<string>(user.avatarUrl);
  const [addAvatar] = useMutation(ADD_AVATAR);
  const [auth] = useAuthContext();

  useEffect(() => {
    console.log("uploaded");
    console.log(imageAsUrl);
    //TODO: store url on DB
    if (imageAsUrl && imageAsUrl.imgUrl) {
      setAvatarImage(imageAsUrl.imgUrl);
      addAvatar({
        variables: {
          email: user.email,
          avatarUrl: imageAsUrl.imgUrl,
        },
      });
      console.log("url: ", imageAsUrl.imgUrl);
    }
  }, [imageAsUrl]);

  useEffect(() => {
    console.log("image selected");
    console.log(imageAsFile);
    if (imageAsFile) {
      handleFireBaseUpload();
    }
  }, [imageAsFile]);

  const handleFireBaseUpload = () => {
    console.log("start of upload");
    // async magic goes here...
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const userId = (auth && auth.user && auth.user.id) || undefined;

    const uploadTask = storage
      .ref(`/images/${userId}/${imageAsFile.name}`)
      .put(imageAsFile);

    uploadTask.on("state_changed", {
      next: (snapshot: any) => {
        //takes a snap shot of the process as it is happening
        console.log(snapshot);
        let percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        // // Stop after receiving one update.
        // unsubscribe();
      },
      error: (err) => {
        //catches the errors
        console.log(err);
      },
      complete: () => {
        console.log("completed");
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(`images/${userId}`)
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageAsUrl((prevObject: any) => ({
              ...prevObject,
              imgUrl: fireBaseUrl,
            }));
          });
      },
    });
  };

  const handleImageAsFile = (e: any) => {
    const image = e.target.files[0];
    setImageAsFile(() => image);
  };

  return (
    <>
      <AddPictureBox key="pictureBox1">
        <TransparentButton
          // @ts-ignore
          component="label"
          endIcon={
            <EditIcon
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                margin: "auto auto",
                color: "grey",
              }}
            />
          }
        >
          {avatarImage !== "" ? (
            <Avatar
              style={{ width: "80px", height: "80px", position: "absolute" }}
              src={avatarImage}
            />
          ) : (
            <StyledAvatar {...props}>{getInitials(user)}</StyledAvatar>
          )}
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageAsFile}
          />
        </TransparentButton>
      </AddPictureBox>
    </>
  );
};

export default UserAvatar;
