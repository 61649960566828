import React, { useState, useEffect } from "react";
// import { Form as FormikForm } from "formik";
import { DrawerProps } from "@material-ui/core/Drawer";
import messages from "./DrawerMenu.intl";
import { injectIntl } from "react-intl";
import {
  Drawer,
  DrawerWrapper,
  DrawerHeader,
  CloseButton,
  DrawerContent,
} from "./DrawerElements";
import { styled } from "@material-ui/core/styles";
// import TextInput from "../../text-input";
// import InputErrorMessage from "../../input-error-message";
// import { required } from "../../form-utils";
// import Button from "../../button";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
// import Loader from "../../loader";
import { Chip } from "@material-ui/core";
import SnackBar from "../../snackbar";
// import Chip from "../../chip";
// import FaceIcon from "@material-ui/icons/Face";
// import WorkIcon from "@material-ui/icons/Work";
// import useLocaleContext from "../../../hooks/useLocaleContext";
import { UserAvatarUploader } from "../../atoms";
import HeaderLogo from "../HeaderLogo";
// import LanguageSwitcher from "../../language-switcher";
import HomeIcon from "@material-ui/icons/HomeRounded";
import TeamsIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import MatchIcon from "@material-ui/icons/SportsSoccerRounded";
import LibraryIcon from "@material-ui/icons/ImportContactsRounded";
import WorkIcon from "@material-ui/icons/Work";

import { actions } from "../../auth-context-provider";
import useAuthContext from "../../../hooks/useAuthContext";
import { FormattedMessage } from "react-intl";

import LogoutIcon from "@material-ui/icons/ExitToApp";
import { Link } from "@reach/router";
import useIsAdmin from "../../../hooks/useIsAdmin";
import Typography from "../../typography";

// interface IFormValues {
//   name: string;
//   surname: string;
//   email: string;
//   role: string;
//   password: string;
//   passwordConfirmation: string;
// }

// const StyledForm = styled(FormikForm)(() => ({
//   display: "flex",
//   flexDirection: "column",
// }));

// const FormButton = styled((props) => <Button size="large" {...props} />)(
//   (props) => ({
//     marginTop: props.theme.spacing(4),
//   })
// );

const StyledButton = styled("div")({
  display: "flex",
  width: "100%",
  backgroundColor: "#00BCD4",
  height: "140px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  color: "white",
});

const StyledLink = styled(Link)({
  width: "48%",
  height: "140px",
  paddingRight: "5px",
  marginTop: "5px",
  // color: "inherit",
  textDecoration: "inherit",
  zIndex: 600,
  display: "flex",
  backgroundColor: "#00BCD4",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  color: "white",
});

export const USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      name
      surname
      email
      role
      telephone
      workspace
      avatarUrl
    }
  }
`;

const ProfileDetails = injectIntl<DrawerProps>(({ open, onClose, intl }) => {
  const [currentUser, setCurrentUser] = useState<any>();
  // const [currentLocale, saveLocale] = useLocaleContext();

  const [state, dispatch] = useAuthContext();

  const { data, error } = useQuery(USER_QUERY, { skip: !open });

  useEffect(() => {
    if (data) {
      console.log("data------");
      console.log(data);
      setCurrentUser(data.currentUser);
    }
    if (error) {
      console.log("error loading user data", error);
    }
  }, [data]);

  const onCloseDrawer = (e = {}) => {
    if (onClose) {
      onClose(e, "backdropClick");
    }
  };

  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <DrawerWrapper data-testid="my-profile-details">
          <DrawerHeader>
            <HeaderLogo />
            {/* <DrawerTitle>
              Footik
            </DrawerTitle> */}
            {/* <LanguageSwitcher
              locale={currentLocale}
              onChange={saveLocale}
              locales={[
                { id: "en", label: "English (UK)" },
                { id: "en-GB", label: "Español (ES)" },
                // { id: "es_ES", label: "Spanish (ES)" }
              ]}
            /> */}
            <CloseButton
              onClick={onCloseDrawer}
              edge="end"
              aria-label={intl.formatMessage(messages.close)}
              data-testid="close-my-profile-details"
            />
          </DrawerHeader>
          {/* <Loader
            className={classes.root}
            // {...queryResult}
            loading={loading}
            error={error}
            errorMessage={intl.formatMessage(messages.loadUserError)}
          > */}
          {/* {({ currentUser }) => { */}
          {currentUser && (
            <DrawerContent scrollable={true}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <UserAvatarUploader
                  style={{ width: 80, height: 80, fontSize: "3rem" }}
                  user={{
                    name: (currentUser && currentUser.name) || "F",
                    surname: (currentUser && currentUser.surname) || "O",
                    email: (currentUser && currentUser.email) || "",
                    avatarUrl: (currentUser && currentUser.avatarUrl) || "",
                  }}
                />
                {state && state.user && (
                  <Typography.H6
                    style={{ fontWeight: 100 }}
                  >{`${state.user.name} ${state.user.surname}`}</Typography.H6>
                )}
                <Chip
                  icon={<WorkIcon fontSize="small" />}
                  variant="outlined"
                  label={`Workspce: ${currentUser.workspace}`}
                  onClick={() => null}
                  color="primary"
                  style={{ borderRadius: 18, padding: "3px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "8px",
                  justifyContent: "space-around",
                }}
              >
                <StyledLink to="/">
                  <HomeIcon fontSize="large" />
                </StyledLink>
                <StyledLink to="/people/matches">
                  <MatchIcon fontSize="large" />
                </StyledLink>

                {useIsAdmin() && (
                  <StyledLink to="/library/exercises">
                    <LibraryIcon fontSize="large" />
                  </StyledLink>
                )}

                <StyledLink to="/people/players">
                  <TeamsIcon fontSize="large" />
                </StyledLink>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <StyledButton
                  onClick={() => dispatch(actions.logout())}
                  data-testid="user-menu-logout"
                  style={{ backgroundColor: "#FF5722" }}
                >
                  <FormattedMessage {...messages.logout} />
                  <LogoutIcon fontSize="large" />
                </StyledButton>
              </div>
            </DrawerContent>
          )}
        </DrawerWrapper>
      </Drawer>
      <SnackBar
        data-testid="snackbar-error-message"
        open={false}
        // open={showUpdateError}
        message={intl.formatMessage(messages.updateUserError)}
        variant="error"
        onClose={
          // onClose is called by SnackBar after 6s
          /* istanbul ignore next */ () => {
            // setShowUpdateError(false);
          }
        }
      />
    </>
  );
});

export default ProfileDetails;
