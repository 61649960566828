import React, { useState } from "react";

export interface ILocaleContextValue
  extends Array<string | ((e: string) => void)> {
  0: string;
  1: (e: string) => void;
}

/* istanbul ignore next */
const LocaleContext = React.createContext<ILocaleContextValue>(["", () => ""]);

interface ILocaleContextProviderProps {
  children: React.ReactNode;
  locale: string;
}

export default ({ children, locale }: ILocaleContextProviderProps) => {
  const [currentLocale, setCurrentLocale] = useState(locale);

  const saveLocale = (newLocale: string) => {
    setCurrentLocale(newLocale);
    localStorage.setItem("locale", newLocale);
  };

  return (
    <LocaleContext.Provider value={[currentLocale, saveLocale] as any}>
      {children}
    </LocaleContext.Provider>
  );
};

export { LocaleContext };
