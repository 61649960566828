import { defineMessages } from "react-intl";

export default defineMessages({
  description: {
    id: "TrainingSession.description",
    defaultMessage: "Description",
  },
  task: {
    id: "TrainingSession.task",
    defaultMessage: "Task",
  },
  details: {
    id: "TrainingSession.details",
    defaultMessage: "Details",
  },
  noResults: {
    id: "TrainingSession.noResults",
    defaultMessage: "No trainings.",
  },
  noExercises: {
    id: "TrainingSession.noExercises",
    defaultMessage: `No exercises, create an exercise before creating a training.`,
  },
});
