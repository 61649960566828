import { defineMessages } from "react-intl";

export default defineMessages({
  exercises: {
    id: "PageCoachLibrary.exercises",
    defaultMessage: "Exercises",
  },
  trainings: {
    id: "PageCoachLibrary.trainings",
    defaultMessage: "Trainings",
  },
  admin: {
    id: "PageCoachLibrary.admin",
    defaultMessage: "Admin",
  },
  trainingDeleted: {
    id: "PageCoachLibrary.trainingDeleted",
    defaultMessage: "Training has been deleted",
  },
  exerciseDeleted: {
    id: "PageCoachLibrary.exerciseDeleted",
    defaultMessage: "Exercise has been deleted",
  },
});
