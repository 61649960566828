import React, { useEffect, useState } from "react";
import messages from "./PageDashboard.intl";
import Button from "../button";
import { styled } from "@material-ui/styles";
import { Grid, Card, SvgIcon, TextField } from "@material-ui/core";
import Typography from "../typography";
import { ActionLink } from "../atoms";
import { injectIntl } from "react-intl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import PeopleIcon from "@material-ui/icons/People";
import { IStyledArguments } from "../../types/styled-arguments";
import { gql } from "@apollo/client";
import { useMutation, useLazyQuery } from "@apollo/client";
import SnackBar from "../snackbar";
import SelectTypeOfUserDialog from "./SelectTypeOfUserDialog";
import { IUser, IWorkspace } from "../../types";
import ValidatePasswordDialog from "../validate-password-dialog/ValidatePasswordDialog";
import { LOGIN_MUTATION } from "../page-login/PageLogin";
import LoaderV2 from "../loaderV2";

import useAuthContext from "../../hooks/useAuthContext";
import Cookies from "universal-cookie";
import { actions } from "../auth-context-provider";
import { USER_QUERY } from "../auth-context-provider/AuthContextProvider";
import { CREATE_PLAYER_LINK_USER } from "../pages/create-and-link-player/CreateAndLinkPlayer";

export const WORKSPACE_INFO = gql`
  query WorkspaceInfo($id: String!) {
    workspace(id: $id) {
      id
      owner {
        name
        surname
      }
      description
    }
  }
`;

const SWITCH_WORKSPACE = gql`
  mutation SwitchWorkspace($workspaceId: String!, $role: String!) {
    switchWorkspace(workspaceId: $workspaceId, role: $role) {
      id
    }
  }
`;

// const MainContent = styled(Content)<Theme>(({ theme }) => ({
//   padding: theme.spacing(4),
// }));

// const TopContainer = styled(Grid)<Theme>(({ theme }) => ({
//   padding: theme.spacing(6, 0),
// }));

// const Item = styled(Grid)({
//   marginTop: 0,
// });

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     paper: {
//       position: "absolute",
//       width: 400,
//       backgroundColor: theme.palette.background.paper,
//       border: "2px solid #000",
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//     },
//   })
// );

// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//     width: "90vw",
//     height: "50vh",
//   };
// }

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   const top = 50 + rand();
//   const left = 50 + rand();

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

function HomeIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        // style="enable-background:new 0 0 512 512;"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M256,60c-12.267,0-24.495,0.344-36.669,1.017v358.078l27.781,13.284c2.996,1.432,5.965,2.965,8.887,4.588
			c2.922-1.623,5.892-3.156,8.888-4.589l27.777-13.282V61.017C280.492,60.344,268.266,60,256,60z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M189.331,63.374c-24.801,2.517-49.302,6.428-73.333,11.743L116,341.298c0,17.243,10.062,33.196,25.633,40.643
			l47.699,22.809L189.331,63.374L189.331,63.374z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M395.998,75.115c-24.033-5.314-48.534-9.225-73.334-11.741V404.75l47.703-22.81C385.938,374.494,396,358.541,396,341.297
			L395.998,75.115z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M455.998,28.614l-10.964-3.063C384.345,8.597,320.745,0,256,0C191.253,0,127.651,8.598,66.961,25.553l-10.963,3.063
			L56,341.298c0,20.196,5.752,39.814,16.634,56.734c10.585,16.458,25.495,29.611,43.115,38.037l105.483,50.44
			c8.961,4.284,17.292,9.847,24.763,16.534L256,512l10.005-8.957c7.469-6.687,15.801-12.25,24.764-16.535l105.483-50.439
			c17.62-8.426,32.529-21.579,43.115-38.037c10.882-16.92,16.634-36.538,16.634-56.734L455.998,28.614z M425.229,351.947
			c-3.562,24.503-19.229,46.207-41.92,57.058l-105.482,50.439c-4.726,2.259-9.373,4.827-13.813,7.633l-8.013,5.064l-8.014-5.063
			c-4.44-2.806-9.087-5.374-13.814-7.634l-105.482-50.439c-0.81-0.388-1.612-0.789-2.404-1.204
			c-21.392-11.201-36.081-32.226-39.516-55.854c-0.509-3.5-0.771-7.058-0.771-10.649L85.998,51.377l11.483-2.77
			C148.677,36.261,202.011,30,256,30c53.983,0,107.315,6.26,158.514,18.605l11.484,2.769L426,341.298
			C426,344.889,425.739,348.447,425.229,351.947z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </SvgIcon>
  );
}

const OpacityLayer = styled("div")({
  backgroundColor: "#ffffff",
  opacity: 0.5,
  // filter: "alpha(opacity=60)",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
});

const Container = styled(Grid)({
  marginTop: 0,
  padding: 16,
});

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  display: "flex",
  borderRadius: "8px",
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 1.5),
}));

const Header = styled("div")({
  color: "#005473",
  fontSize: "x-large",
  fontWeight: 200,
  textAlign: "center",
  position: "absolute",
  top: 16,
  display: "flex",
  flexWrap: "wrap",
  borderRadius: 8,
});

const Main = styled("div")({
  backgroundImage: "url('/images/stadium4.jpg')",
  backgroundPosition: "bottom",
  backgroundAttachment: "initial",
  backgroundSize: "cover",
  height: "200px",
  backgroundRepeat: "no-repeat",
  color: "white",
  top: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: 8,
  position: "relative",
  fontWeight: 500,
});

interface IInitialPageProps {
  user: IUser;
  callback(): void;
}

const InitialPage = injectIntl<IInitialPageProps>(
  ({ user, callback, intl }) => {
    const [newWorkspace, setNewWorkspace] = useState("");
    const [workspaceInfo, setWorkspaceInfo] = useState<IWorkspace>();
    // const [modalStyle] = useState(getModalStyle);
    const [openModal, setOpenModal] = useState(false);
    const [openUserTypeModal, setOpenUserTypeModal] = useState(false);
    const [userType, setUserType] = useState<string>("parent");
    const [joinFlag, setJoinFlag] = useState<boolean>(false);
    const [workspaceError, setWorkspaceError] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");

    const [loadWorkspace, { data }] = useLazyQuery<{
      workspace: IWorkspace;
    }>(WORKSPACE_INFO, {
      fetchPolicy: "no-cache",
    });

    const [loadCurrentUser, { data: currentUserData }] = useLazyQuery<{
      currentUser: IUser;
    }>(USER_QUERY, {
      fetchPolicy: "no-cache",
    });

    const [
      switchWorkspace,
      { data: switchWorkspaceData, loading: switchWorkspaceLoading },
    ] = useMutation(SWITCH_WORKSPACE);

    const [login, { data: loginData, loading: loadingLogin }] = useMutation(
      LOGIN_MUTATION
    );

    const [
      smokeLogin,
      { error: errorLogin, data: smokeLoginData, loading: smokeLoadingLogin },
    ] = useMutation(LOGIN_MUTATION);

    const [creatPlayerAndLink] = useMutation(CREATE_PLAYER_LINK_USER);

    const [state, dispatch] = useAuthContext();
    console.log(state);
    // const [modalBody, setModalBody] = useState<any>();
    // const [open, setOpen] = useState(false);

    // useEffect(() => {
    //   handleJoinWorkspace();
    // }, [newWorkspace]);

    useEffect(() => {
      console.log("[Join]Data", data);
      if (data && data.workspace) {
        setWorkspaceInfo(data.workspace);
      } else if (data && data.workspace === null && joinFlag) {
        setWorkspaceError(true);
      }
    }, [data]);

    useEffect(() => {
      if (newWorkspace !== "") {
        setJoinFlag(true);
      }
    }, [newWorkspace]);

    useEffect(() => {
      if (workspaceInfo) {
        //setOpenUserTypeModal(true);
        setOpenModal(true);
      }
    }, [workspaceInfo]);

    useEffect(() => {
      if (loginData) {
        console.log("[process] login complete", loginData);
        // console.log("[process] openning modal to select user type");

        // setOpenUserTypeModal(true);

        //new
        dispatch(actions.setUser(loginData.login));
        const cookies = new Cookies();
        cookies.set("hasLogged", Date.now(), { path: "/" });
        console.log("login: ", cookies.get("hasLogged"));
        dispatch(actions.setToken(loginData.login));
      }
    }, [loginData]);

    useEffect(() => {
      if (password && password.length > 0) {
        console.log("[process] login", password);
        // login({ variables: { email: user.email, password } });
        // setOpenUserTypeModal(true);
      }
    }, [password]);

    useEffect(() => {
      if (smokeLoginData && smokeLoginData.login) {
        console.log("[process] login", password);
        // login({ variables: { email: user.email, password } });
        setOpenUserTypeModal(true);
      }
    }, [smokeLoginData]);

    useEffect(() => {
      if (switchWorkspaceData) {
        console.log("[process] switchWorkspaceData", switchWorkspaceData);
        console.log("[process] loading current user");
        loadCurrentUser();
      }
    }, [switchWorkspaceData]);

    useEffect(() => {
      console.log("[process] current user", currentUserData);
      if (currentUserData && currentUserData.currentUser) {
        login({ variables: { email: user.email, password } });
      }
    }, [currentUserData]);

    useEffect(() => {
      console.log("[process] user type", userType);
      if (userType && userType === "player") {
        creatPlayerAndLink({
          variables: {
            name: user.name,
            surname: user.surname,
            userId: user.id,
            workspaceId: newWorkspace,
          },
        });
      } else if (userType === "coach") {
        console.log("[process] is coach calling callback");
        callback();
      }
    }, [userType]);

    // useEffect(() => {
    //   if (loginData && loginData.login) {
    //     console.log("data.login");
    //     console.log(loginData);

    //     const cookies = new Cookies();
    //     cookies.set("hasLogged", Date.now(), { path: "/" });
    //     console.log("login: ", cookies.get("hasLogged"));
    //     dispatch(actions.setToken(loginData.login));
    //     setOpenUserTypeModal(true);
    //   }
    // }, [state.token, loginData]);

    const handleChangeWorkspace = (evt: any) => {
      setNewWorkspace(evt.target.value);
    };

    const handleJoinWorkspace = async () => {
      if (newWorkspace !== "") {
        const result = await loadWorkspace({
          variables: {
            id: newWorkspace,
          },
        });
        if (result !== undefined) {
          // setOpenUserTypeModal(true);
          setOpenModal(true);
        }
      }
    };

    // const handleSetUserType = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   console.log("[evt]", event.target);
    //   console.log("[evt]1", event.target.value);
    //   setUserType(event.target.value);
    // };

    // const handleOpenModal = () => {
    //   console.log("abriendo modal");
    //   setOpenModal(true);
    // };

    // const handleCloseModal = () => {
    //   console.log("cerrando modal");
    //   setOpenModal(false);
    // };
    // const handleAcceptJoinWorkspace = () => {
    //   console.log("accept modal");
    //   //TODO call the mutation to join the new workspace
    //   setOpenModal(false);
    // };
    // const handleCancellJoinWorkspace = () => {
    //   console.log("cancelando ");
    //   setOpenModal(false);
    // };
    const handleCallbackChooseUserType = (result: {
      selected: string | null;
    }) => {
      console.log("[process] result from modal ", result);
      setOpenUserTypeModal(false);
      if (result.selected) {
        setUserType(result.selected);
        switchWorkspace({
          variables: { workspaceId: newWorkspace, role: result.selected },
        });
      }
    };

    const handleValidatePassword = (password: string) => {
      setPassword(password);
      smokeLogin({ variables: { email: user.email, password } });
      setOpenModal(false);
    };

    return (
      <>
        <CardWrapper>
          {(loadingLogin || switchWorkspaceLoading || smokeLoadingLogin) && (
            <LoaderV2 />
          )}
          {workspaceInfo ? (
            <SelectTypeOfUserDialog
              callback={(result) => {
                handleCallbackChooseUserType(result);
              }}
              workspaceInfo={workspaceInfo}
              isOpen={openUserTypeModal}
            />
          ) : null}
          <ValidatePasswordDialog
            isOpen={openModal}
            callback={handleValidatePassword}
          />
          <Main>
            <OpacityLayer />
            <Header>
              {intl.formatMessage(messages.initialInstructionsHeader)}
            </Header>
          </Main>
          <Content>
            <Container item xs={12}>
              <Typography.Subtitle1
                style={{ fontWeight: 100, color: "#757575" }}
              >
                {intl.formatMessage(messages.initialInstructions)}
              </Typography.Subtitle1>

              <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage(messages.initialInstructions1)}
                  />
                  <ActionLink to="/teams/newteam">
                    <Button>{intl.formatMessage(messages.createTeam)}</Button>
                  </ActionLink>
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={intl.formatMessage(messages.initialInstructions2)}
                  />
                </ListItem>
              </List>
              <Divider />
              <Typography.H6
                style={{ marginTop: 16, fontWeight: 100, color: "#757575" }}
              >
                {intl.formatMessage(messages.joinExistingTeam)}
              </Typography.H6>
              <Typography.Subtitle1
                style={{ fontWeight: 100, color: "#757575" }}
              >
                {intl.formatMessage(messages.initialInstructionsJoinWorkspace)}
              </Typography.Subtitle1>
              <TextField
                value={newWorkspace}
                type="text"
                variant="outlined"
                style={{ marginTop: 8, marginBottom: 16 }}
                onChange={handleChangeWorkspace}
              />
              <Button
                disabled={newWorkspace === ""}
                onClick={handleJoinWorkspace}
              >
                {intl.formatMessage(messages.joinTeam)}
              </Button>
            </Container>
          </Content>
        </CardWrapper>

        <SnackBar
          data-testid="error-message"
          open={!!workspaceError}
          message={intl.formatMessage(messages.workspaceNoFound)}
          variant="error"
          actionText="close"
          action={() => setWorkspaceError(false)}
        />
        <SnackBar
          data-testid="error-message-login"
          open={!!errorLogin}
          message="Wrong Password"
          variant="error"
          actionText="close"
          action={() => setWorkspaceError(false)}
        />
      </>
    );
  }
);

export default InitialPage;
