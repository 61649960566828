import { defineMessages } from 'react-intl';

export default defineMessages({
  recoverPassword: {
    id: 'SendResetPassword.RecoverPassword',
    defaultMessage: 'Recover password',
  },
  takeBackLogin: {
    id: 'SendResetPassword.TakeBackLogin',
    defaultMessage: 'Take me back to sign in',
  },
  sendLink: {
    id: 'SendResetPassword.SendLink',
    defaultMessage: 'Send Link',
  },
  emailAddressLabel: {
    id: 'SendResetPassword.EmailAddressLabel',
    defaultMessage: 'Email address',
  },
  requiredFieldError: {
    id: 'SendResetPassword.RequiredFieldError',
    defaultMessage: 'Required field',
  },
  errorInvalidEmail: {
    id: 'SendResetPassword.ErrorInvalidEmail',
    defaultMessage: 'Please enter a valid email address',
  },
  emailSent: {
    id: 'SendResetPassword.EmailSent',
    defaultMessage: 'Email Sent',
  },
  emailSentMessage: {
    id: 'SendResetPassword.EmailSentMessage',
    defaultMessage:
      'If your supplied email address exists within our platform, you will receive an email containing a password reset link. If you do not receive an email, please check your SPAM folder.',
  },
  backToLoginButton: {
    id: 'SendResetPassword.BackToLoginButton',
    defaultMessage: 'Back to sign in',
  },
});
